import { Injector, Type } from '@angular/core';
import { EntityDockingPaneService } from '../entity/services/entity-docking-pane.service';
import { FilteredViewService } from '../filter/services/filteredView.service';
import { ViewType } from './app-types/ViewType';
import { environment } from '../../../environments/environment';
import { EntityPanelTool } from '../../entity-panels/entity-panels.types';
import { ViewTypeService } from '../../services/viewType.service';
import { AppDataService } from '../../services/app-data.service';
import { SecurityService } from '../../services/security.service';
import { ClientContext } from '@datagalaxy/shared/monitoring/data-access';
import { AppConfigService } from '@datagalaxy/webclient/config';
import { DgZone } from '@datagalaxy/domain';
import { ActivatedRoute } from '@angular/router';

export class ClientContextUtil {
    /** may fail depending on the execution context. In that case returns a partial clientContext */
    public static getClientContext(injector: Injector) {
        try {
            const getService = <T>(type: Type<T>) => {
                const service = injector.get(type, null);
                if (!service && !environment.production) {
                    console.warn('getClientContext-getService', type.name);
                }
                return service;
            };
            const route = getService(ActivatedRoute);
            const url = route.snapshot.url.join('/');
            const listViewType = url.includes('flat')
                ? 'flat'
                : url.includes('tree')
                  ? 'tree'
                  : 'hierarchical';
            const currentStateName = url;

            const IsFullPageMode = url.includes('full');

            const entityDockingPaneService = getService(
                EntityDockingPaneService,
            );
            const isDockingPaneVisible =
                !entityDockingPaneService.isPanelHidden;
            const isDockingPaneCollapsed =
                entityDockingPaneService.isPanelCollapsed;
            const activeTool =
                isDockingPaneVisible &&
                EntityPanelTool[entityDockingPaneService.activeTool];

            const filteredViewService = getService(FilteredViewService);
            const dgZone = DgZone.Module;
            const hasTypeFilter =
                filteredViewService?.hasCurrentTypeFilter(dgZone);
            const hasGlobalTextFilter =
                filteredViewService?.hasCurrentGlobalTextFilter(dgZone);
            const hasOtherFilter =
                filteredViewService?.hasCurrentOtherFilter(dgZone);
            const filteredViewId =
                filteredViewService?.getCurrentViewId(dgZone);
            const activeFilterIdString = filteredViewId?.toString();
            const hasSavedFilter = !!filteredViewId && filteredViewId != -1;

            const appDataService = getService(AppDataService);
            const userService = appDataService?.currentUserService;
            const userTitle = appDataService?.currentUserTitle;
            const userRole = appDataService?.currentUserRole;
            const licenseLevel = appDataService?.currentUserLicenseLevelString;

            const appConfigService = getService(AppConfigService);
            const dgAppVersion = appConfigService?.getAppVersionFullInfo();

            const securityService = getService(SecurityService);
            const isMonoWorkspace = securityService?.isSingleWorkspaceClient();
            const isClientAdmin = securityService?.isCurrentUserClientAdmin();

            const viewTypeService = getService(ViewTypeService);
            const currentViewType =
                viewTypeService.viewType === ViewType.Technical
                    ? 'technical'
                    : 'functional';

            const userAgent = navigator.userAgent;
            const graphicResolution = `${window.outerWidth}x${window.outerHeight}`;

            return new ClientContext(
                currentStateName,
                currentViewType,
                IsFullPageMode,
                listViewType,
                isDockingPaneVisible,
                activeFilterIdString,
                userAgent,
                graphicResolution,
                hasSavedFilter,
                hasTypeFilter,
                hasGlobalTextFilter,
                hasOtherFilter,
                isDockingPaneCollapsed,
                activeTool,
                licenseLevel,
                isClientAdmin,
                isMonoWorkspace,
                userService,
                userRole,
                userTitle,
                dgAppVersion,
            );
        } catch (e) {
            if (!environment.production) {
                console.warn('getClientContext failed', e);
            }
        }
    }
}
