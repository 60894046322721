import {
    patchState,
    signalStoreFeature,
    withComputed,
    withMethods,
    withState,
} from '@ngrx/signals';
import { computed } from '@angular/core';
import { withRequestStatus } from '@datagalaxy/shared/signal-store-common';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import {
    AttributeFieldState,
    attributeFieldStateDefaultValue,
} from './attribute-field-state';

/**
 * @deprectaed Use withCommonAttribute from libs/meta-attribute/feature/fields
 */
export function withCommonAttribute() {
    return signalStoreFeature(
        withState<AttributeFieldState>(attributeFieldStateDefaultValue),
        withRequestStatus(),
        withComputed(({ attributePath }) => ({
            attributeKey: computed(() =>
                getAttributeKeyFromAttributePath(attributePath()),
            ),
        })),
        withMethods((store) => ({
            init(state: Partial<AttributeFieldState>) {
                patchState(store, state);
            },
            setEntity(entity: EntityItem) {
                patchState(store, { entity });
            },
            touch() {
                patchState(store, touch());
            },
        })),
    );
}

export function touch(): { touched: boolean } {
    return { touched: true };
}

export function getAttributeKeyFromAttributePath(
    attributePath: string,
): string {
    return attributePath.split('.').pop() ?? '';
}
