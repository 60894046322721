import {
    ChangeDetectorRef,
    Component,
    forwardRef,
    inject,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {
    MetaObjectTypeCardAdapterPipe,
    MetaObjectTypeCardComponent,
} from '@datagalaxy/meta-object-feature-object-card';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { EntityUtils } from '@datagalaxy/webclient/entity/utils';
import { ScreenCategory } from '@datagalaxy/webclient/screen/data-access';
import { DxyNavBreadcrumbComponent } from '../../../navigation/dxy-nav-breadcrumb/dxy-nav-breadcrumb.component';
import { ScreenService } from '../../../screens/screen.service';
import { AppEventsService } from '../../../services/AppEvents.service';
import { DxyScreenLayoutCategoryComponent } from '../../screens-layout/dxy-screen-layout-category/dxy-screen-layout-category';
import { IScreenData } from '../../screens-layout/screens-layout.types';
import { DataUtil } from '../../util/DataUtil';
import { DxyEntityHeaderTitleComponent } from '../dxy-entity-header-title/dxy-entity-header-title.component';
import { EntityEventService } from '../services/entity-event.service';
import { EntityService } from '../services/entity.service';
import { CoreUtil } from '@datagalaxy/core-util';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-entity-header',
    templateUrl: 'dxy-entity-header.component.html',
    styleUrls: ['dxy-entity-header.component.scss'],
    standalone: true,
    imports: [
        forwardRef(() => DxyNavBreadcrumbComponent),
        DxyEntityHeaderTitleComponent,
        DxyScreenLayoutCategoryComponent,
        MetaObjectTypeCardComponent,
        MetaObjectTypeCardAdapterPipe,
    ],
})
export class DxyEntityHeaderComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() entityData: EntityItem;
    @Input() readOnly?: boolean;

    private cd = inject(ChangeDetectorRef);

    protected headerScreenCategory?: ScreenCategory;
    protected headerScreenData?: IScreenData;

    public constructor(
        private entityService: EntityService,
        private appEventsService: AppEventsService,
        private entityEventService: EntityEventService,
        private screenService: ScreenService,
    ) {
        super();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        super.onChange(changes, 'entityData', () => this.updateData(), true);
    }

    public ngOnInit() {
        super.subscribe(this.appEventsService.viewTypeChange$, () =>
            this.updateData(),
        );
        const moduleType = DataUtil.getDefaultServerTypeFromModule(
            DataUtil.getModuleFromServerType(this.entityData.ServerType),
        );
        super.registerSubscription(
            this.entityEventService.subscribeEntityTechnologyUpdate(
                moduleType,
                (entityItem) => this.onEntityUpdate(entityItem),
            ),
        );
    }

    private async updateData() {
        if (!this.entityData) {
            return;
        }
        await this.updateCurrentLayout();
    }

    private async onEntityUpdate(entityItem: EntityItem) {
        if (
            entityItem.ReferenceId != this.entityData.ReferenceId &&
            !this.entityData.HddData.Parents.some(
                (ancestor) =>
                    ancestor.DataReferenceId == entityItem.ReferenceId,
            )
        ) {
            return;
        }
        EntityUtils.mergeEntity(this.entityData, entityItem, true);
        this.entityData = CoreUtil.cloneDeep(this.entityData);

        this.cd.detectChanges();
    }

    private async updateCurrentLayout() {
        await this.updateEditableAttributes();
    }

    private async updateEditableAttributes() {
        let entityAttributes =
            await this.entityService.getEntityAttributesForDetails(
                this.entityData.ServerType,
            );
        if (this.readOnly) {
            entityAttributes =
                EntityUtils.getAttributesReadOnlyClones(entityAttributes);
        }
        const screenDto = this.screenService.getSpaceScreenForEntity(
            this.entityData,
            false,
        );
        const screenLayout = this.screenService.resolveScreenLayout(screenDto);
        this.headerScreenCategory = screenLayout?.Categories.find(
            (c) => c.IsHeader,
        );

        this.headerScreenData = {
            entityAttributes,
            entityData: this.entityData,
        };
    }
}
