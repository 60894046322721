import { FilterOperator } from '@datagalaxy/filter-domain';

export class FilterUtil {
    // note: we're using private cache properties for not to create webpack circular references

    private static _valuelessOperators: FilterOperator[];
    private static get valuelessOperators() {
        return (
            FilterUtil._valuelessOperators ??
            (FilterUtil._valuelessOperators = [
                FilterOperator.FieldIsEmpty,
                FilterOperator.FieldHasValue,
                FilterOperator.DateTimeBeforeCurrentWeek,
                FilterOperator.DateTimeBeforePastMonth,
                FilterOperator.DateTimeBeforePastWeek,
                FilterOperator.DateTimeBeforeToday,
                FilterOperator.DateTimeCurrentMonth,
                FilterOperator.DateTimeCurrentWeek,
                FilterOperator.DateTimePastHour,
                FilterOperator.DateTimePastMonth,
                FilterOperator.DateTimePastWeek,
                FilterOperator.DateTimeToday,
                FilterOperator.DateTimeYesterday,
                FilterOperator.DateTimeBeforeCurrentMonth,
                FilterOperator.DateTimeCurrentYear,
                FilterOperator.DateTimeLast365Days,
            ])
        );
    }

    public static isValuelessOperator(operator: FilterOperator) {
        return FilterUtil.valuelessOperators.includes(operator);
    }
    public static isNullableOperator(operator: FilterOperator) {
        return (
            operator == FilterOperator.FieldIsEmpty ||
            operator == FilterOperator.FieldHasValue
        );
    }
    public static isMultiValueOperator(operator: FilterOperator) {
        return (
            operator == FilterOperator.ListContains ||
            operator == FilterOperator.ListMatchAll ||
            operator == FilterOperator.ListExcludes
        );
    }

    // used to get if Filter Item is valid with only 1 out of n values
    public static canHaveOneOrManyValuesOperator(operator: FilterOperator) {
        return operator == FilterOperator.RangeContains;
    }
}
