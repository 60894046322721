import { deserialize, inheritSerialization } from 'cerialize';
import { UserDto } from './user.dto';

@inheritSerialization(UserDto)
export class UserPublicData extends UserDto {
    @deserialize public ExternalId?: string;
    @deserialize public HasValidLicense?: boolean;
    @deserialize public Title?: string;
    @deserialize public Service?: string;
    @deserialize public Role?: string;
    @deserialize public ClientId?: string;
    @deserialize public IsUserDeleted?: boolean;
    @deserialize public IsToken?: boolean;
    @deserialize public IsSupport?: boolean;
}
