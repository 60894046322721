import { AiGeneratedString } from '@datagalaxy/webclient/suggestion/domain';

export interface AutoDescriptionState {
    acceptedSuggestion: AiGeneratedString | undefined;
    pendingSuggestion: AiGeneratedString | undefined;
    generating: boolean;
    generationError: unknown;
    freshdeskArticleId: string;
}

export const initialState: AutoDescriptionState = {
    acceptedSuggestion: undefined,
    pendingSuggestion: undefined,
    generating: false,
    generationError: undefined,
    freshdeskArticleId: '35000275598',
};
