import { Injectable } from '@angular/core';
import { AppDataService } from '../services/app-data.service';
import { IWidgetConfigDb } from '../widgets-dashboard/WidgetUtil';
import { BaseServiceParameter } from '@datagalaxy/data-access';
import {
    ClientApiService,
    ClientLoginParameter,
    SetImageParameter,
    UpdateClientSpaceParameter,
} from '@datagalaxy/webclient/client/data-access';
import { ClientStorageService } from '../services/clientStorage.service';
import { MasterDataService } from '@datagalaxy/webclient/modeler/data-access';
import { CoreUtil } from '@datagalaxy/core-util';
import { WorkspaceStore } from '@datagalaxy/webclient/workspace/data-access';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';
import { LandingPageType } from '../user/user-settings.types';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { getLocalId } from '@datagalaxy/utils';
import { ClientRouterService } from '@datagalaxy/webclient/client/feature';
import { Router } from '@angular/router';
import { AppConfigService } from '@datagalaxy/webclient/config';
import { UserSettingValueService } from '@datagalaxy/users/data-access';

@Injectable({ providedIn: 'root' })
export class ClientService {
    constructor(
        private clientApiService: ClientApiService,
        private appDataService: AppDataService,
        private clientStorageService: ClientStorageService,
        private masterDataService: MasterDataService,
        private appConfigService: AppConfigService,
        private userSettingsService: UserSettingValueService,
        private workspaceStore: WorkspaceStore,
        private clientRouter: ClientRouterService,
        private router: Router,
    ) {}

    public async goToLandingPage(client?: string) {
        const commands = await this.getLandingPageRouteCommands(client);
        return await this.router.navigate(commands);
    }

    /**
     * Redirect the user based on its navigation settings
     */
    public async getLandingPageRouteCommands(
        clientId?: string,
    ): Promise<unknown[]> {
        const currentSpace = this.workspaceStore.currentSpace;

        const res = await this.userSettingsService.getUserSettingValue(
            userSettingsValues.navigation.category,
            userSettingsValues.navigation.routes.landingPage,
        );
        const route = this.getLandingPageIdentifier(res, currentSpace);
        const clientUrlPath = this.clientRouter.getClientUrlCommands(clientId);

        return [...clientUrlPath, ...route];
    }

    public async updateClientSpace(opt: {
        clientName?: string;
        clientDescription?: string;
        userSessionTimeoutMinutes?: number;
        widgets?: IWidgetConfigDb[];
    }) {
        if (opt.widgets) {
            this.appDataService.setClientWidgets(opt.widgets);
        }
        const uiSettingsJSON = this.appDataService.getClientUISettingsJSON();
        const updateClientSpaceParameter = new UpdateClientSpaceParameter(
            opt.clientName,
            opt.clientDescription,
            opt.userSessionTimeoutMinutes,
            uiSettingsJSON,
        );
        const result = await this.clientApiService.updateClientSpace(
            updateClientSpaceParameter,
        );
        this.appDataService.updateClientData(
            result.ClientFriendlyName,
            result.ClientDescription,
            result.ClientUISettingsJSON,
        );
        return result;
    }

    public async clientLogin(clientId: string) {
        if (this.appConfigService.ENABLE_AUTH_V2) {
            return;
        }

        try {
            if (!this.appDataService.hasAuthorizedClientId(clientId)) {
                this.clientStorageService.clearCookie();
                clientId = this.appDataService.getFirstAuthorizedClientId();
            }

            this.appDataService.clearLogginFailed();
            this.appDataService.setLoginInProgress(true);

            // Init User session Ids
            this.appDataService.clearUserSessionId();

            // Process client login
            this.masterDataService.clear(false); // masterDataService listens to client login deserialization event
            const loginToken = this.appDataService.getLoginToken();
            const clp = new ClientLoginParameter();
            clp.LoginToken = loginToken;
            clp.ClientId = clientId;

            try {
                const clr = await this.clientApiService.clientLogin(clp);
                this.appDataService.setIsClientAuthenticated(true);
                // await this.clientServiceManager.initializeServices(clr, clp.ClientId)
                return clr;
            } catch (e) {
                this.appDataService.setIsClientAuthenticated(false);
                this.appDataService.setLoginFailed(true);
                throw e;
            } finally {
                this.appDataService.setLoginInProgress(false);
            }
        } catch (err) {
            CoreUtil.warn('err', err);
        }
    }

    public async logout() {
        if (this.appConfigService.ENABLE_AUTH_V2) {
            return;
        }
        await this.clientApiService.clientLogout();
    }

    public updateClientSpaceSecurity(userSessionTimeoutMinutes: number) {
        return this.updateClientSpace({ userSessionTimeoutMinutes });
    }

    public async getClientStatistics() {
        const serviceParameters = new BaseServiceParameter();
        return this.clientApiService.getClientStatistics(serviceParameters);
    }

    public getImageUrl(hash: string) {
        return this.clientApiService.getImageUrl(hash);
    }

    public async setImage(setImageParameter: SetImageParameter) {
        return this.clientApiService.setImage(setImageParameter);
    }

    private getLandingPageIdentifier(
        landingPageStr: string,
        spaceIdr: IWorkspaceIdentifier,
    ) {
        const landingPage = LandingPageType[landingPageStr];
        const spaceId = getLocalId(spaceIdr.spaceId);
        switch (landingPage) {
            case LandingPageType.WorkspaceHome:
                return ['space', spaceId, spaceIdr.versionId, 'home'];
            case LandingPageType.Dashboard:
                return ['dashboard'];
            case LandingPageType.Tasks:
                return ['tasks'];
            default:
                return ['space', spaceId, spaceIdr.versionId, 'home'];
        }
    }
}
