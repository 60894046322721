import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BACKEND_API_CONFIG, IApiConfig } from '@datagalaxy/data-access';

@Injectable({
    providedIn: 'root',
})
export class TechnologyUrlService {
    public constructor(
        @Inject(BACKEND_API_CONFIG) private config: IApiConfig,
    ) {}
    public getImageUrl(hash: string): string {
        return !hash
            ? ''
            : Location.joinWithSlash(
                  this.config.baseUrl,
                  `api/ClientAdmin/GetImage?hash=${encodeURIComponent(hash)}`,
              );
    }
}
