import { inject, Injectable } from '@angular/core';
import {
    AttributeValueTranslationService,
    MultilingualStateService,
} from '@datagalaxy/webclient/multilingual/feature';
import { TextAttributeValueParameters } from './text-attribute-value-parameters';
import {
    EntityApiService,
    UpdateAttributeAction,
    UpdateEntityAttributeParameter,
} from '@datagalaxy/webclient/entity/data-access';
import { ServerType } from '@datagalaxy/dg-object-model';
import { EntityEventDispatcher } from '@datagalaxy/webclient/entity/events';
import { getAttributeKeyFromAttributePath } from '../../common/common-attribute-feature';
import { CoreUtil } from '@datagalaxy/core-util';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

@Injectable({ providedIn: 'root' })
export class TextAttributeValueUpdater {
    attributeValueTranslationService = inject(AttributeValueTranslationService);
    multilingualStateService = inject(MultilingualStateService);
    entityApiService = inject(EntityApiService);
    entityEventService = inject(EntityEventDispatcher);

    async update(updateParameters: TextAttributeValueParameters) {
        if (
            this.attributeValueTranslationService.displayTranslatedValues(
                updateParameters.attributePath,
            )
        ) {
            await this.updateTranslation(updateParameters);
        } else {
            await this.updateMetaModelValue(updateParameters);
        }
    }

    private async updateTranslation(
        updateParameters: TextAttributeValueParameters,
    ) {
        await this.attributeValueTranslationService.updateAttributeTranslation(
            updateParameters.entity,
            updateParameters.attributePath,
            updateParameters.updatedValue,
            updateParameters.metaModelValue,
        );
    }

    private async updateMetaModelValue(
        updateParameters: TextAttributeValueParameters,
    ) {
        const { entity, attributePath, updatedValue } = updateParameters;
        const attributeKey = getAttributeKeyFromAttributePath(attributePath);
        const parameter = new UpdateEntityAttributeParameter(
            [entity.ReferenceId],
            ServerType[entity.ServerType],
            false,
            attributeKey,
            updatedValue,
            UpdateAttributeAction.SetValue,
        );
        await this.entityApiService.updateEntity(parameter);
        this.dispatchUpdatedEntityEvent(entity, attributeKey, updatedValue);
    }

    /**
     * Dispatch an updated event for the entity
     * to ensure other components displaying this attribute are updated.
     * @param entity
     * @param attributeKey
     * @param updatedValue
     */
    private dispatchUpdatedEntityEvent(
        entity: EntityItem,
        attributeKey: string,
        updatedValue: string,
    ) {
        // Clone the entity to make sure the reference changes
        // and listners detect correctly the change
        const updatedEntity = CoreUtil.clone(entity);
        updatedEntity.Attributes = CoreUtil.clone(entity.Attributes);
        updatedEntity.setAttributeValue(attributeKey, updatedValue);
        this.entityEventService.dispatchEvent({
            entity: updatedEntity,
            eventName: 'updated',
        });
    }
}
