import { inject, Injectable, TemplateRef } from '@angular/core';
import {
    FlexibleConnectedPositionStrategyOrigin,
    Overlay,
    OverlayPositionBuilder,
    OverlayRef,
} from '@angular/cdk/overlay';
import { TooltipPosition, tooltipPositions } from './tooltip-position';
import { ComponentPortal } from '@angular/cdk/portal';
import { TooltipComponent } from './tooltip.component';
import {
    filter,
    fromEvent,
    map,
    of,
    Subject,
    switchMap,
    takeUntil,
    timer,
} from 'rxjs';
import {
    DXY_TOOLTIP_OPTIONS,
    TooltipOptions,
} from '../tooltip-directive/tooltip-options';

export type TooltipDestroyRef = () => void;
export type TooltipContent = string | undefined | TemplateRef<unknown>;

/**
 * Represents an active tooltip instance
 */
interface TooltipInstance {
    overlayRef: OverlayRef;
    tooltipElement: HTMLElement;
}

/**
 * ## Role
 * - Add/remove material tooltip on native Element
 * - Show/hide a template content at a given position
 * (for DxyRichTooltipDirective & DxyRichTooltipContent)
 */
@Injectable({ providedIn: 'root' })
export class TooltipService {
    private overlay = inject(Overlay);
    private overlayPositionBuilder = inject(OverlayPositionBuilder);
    private options = inject(DXY_TOOLTIP_OPTIONS);

    public setTooltip(
        element: Element,
        content: TooltipContent | (() => TooltipContent),
        options?: Partial<TooltipOptions>,
    ): TooltipDestroyRef {
        if (!content) return () => {};

        const opts = this.getOptions(options || {});
        const mouseEnter$ = fromEvent(element, 'mouseenter');
        const mouseLeave$ = fromEvent(element, 'mouseleave');
        const mouseClick$ = fromEvent(element, 'click');
        const destroy$ = new Subject<void>();

        let tooltipInstance: TooltipInstance | null = null;
        let tooltipOpened = false;

        const tooltip$ = mouseEnter$.pipe(
            takeUntil(destroy$),
            filter(() => !tooltipOpened),
            switchMap(() =>
                timer(opts.showDelay).pipe(
                    takeUntil(destroy$),
                    takeUntil(mouseLeave$),
                ),
            ),
            map(() => (typeof content == 'function' ? content() : content)),
            filter((content) => !!content),
            switchMap((content) => {
                if (!document.contains(element)) {
                    return of();
                }
                tooltipInstance = this.createTooltip(element, content, opts);

                tooltipOpened = true;

                const tooltipMouseEnter$ = fromEvent(
                    tooltipInstance.tooltipElement,
                    'mouseenter',
                );
                const tooltipMouseLeave$ = fromEvent(
                    tooltipInstance.tooltipElement,
                    'mouseleave',
                );

                const tooltipDestroy$ = new Subject<void>();

                const destroyTooltip = () => {
                    this.destroyTooltip(tooltipInstance);
                    tooltipDestroy$.next();
                    tooltipDestroy$.complete();
                    tooltipOpened = false;
                };

                mouseLeave$
                    .pipe(
                        takeUntil(tooltipDestroy$),
                        switchMap(() =>
                            timer(opts.hideDelay).pipe(
                                takeUntil(mouseEnter$),
                                takeUntil(
                                    opts.autoHide
                                        ? tooltipDestroy$
                                        : tooltipMouseEnter$,
                                ),
                            ),
                        ),
                    )
                    .subscribe(() => destroyTooltip());

                mouseClick$
                    .pipe(takeUntil(tooltipDestroy$))
                    .subscribe(() => destroyTooltip());

                // Handle tooltip mouse interactions (only if autoHide is false)
                if (!opts.autoHide) {
                    tooltipMouseLeave$
                        .pipe(
                            takeUntil(tooltipDestroy$),
                            switchMap(() =>
                                timer(opts.hideDelay).pipe(
                                    takeUntil(mouseEnter$),
                                    takeUntil(tooltipMouseEnter$),
                                ),
                            ),
                        )
                        .subscribe(() => destroyTooltip());
                }

                return tooltipDestroy$;
            }),
        );

        const tooltipSub = tooltip$.subscribe();

        return () => {
            destroy$.next();
            destroy$.complete();
            tooltipSub.unsubscribe();

            if (tooltipInstance) {
                this.destroyTooltip(tooltipInstance);
                tooltipInstance = null;
                tooltipOpened = false;
            }
        };
    }

    public show(
        target: FlexibleConnectedPositionStrategyOrigin,
        content: string | TemplateRef<unknown>,
        opt?: {
            position?: TooltipPosition;
            autoHide?: boolean;
        },
    ): TooltipDestroyRef {
        const tooltipInstance = this.createTooltip(target, content, opt);

        return () => this.destroyTooltip(tooltipInstance);
    }

    /**
     * Creates a tooltip and returns its instance
     */
    private createTooltip(
        target: FlexibleConnectedPositionStrategyOrigin,
        content?: string | TemplateRef<unknown>,
        options?: Partial<TooltipOptions>,
    ): TooltipInstance {
        const position = options?.position || 'above';

        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(target)
            .withPositions(tooltipPositions[position])
            .withPush(false);

        const overlayRef = this.overlay.create({ positionStrategy });

        const portal = new ComponentPortal(TooltipComponent);
        const tooltipRef = overlayRef.attach(portal);

        tooltipRef.setInput('content', content);
        tooltipRef.changeDetectorRef.detectChanges();

        return {
            overlayRef,
            tooltipElement: tooltipRef.location.nativeElement,
        };
    }

    /**
     * Destroys an active tooltip instance
     */
    private destroyTooltip(instance: TooltipInstance | null) {
        if (!instance) return;

        instance.overlayRef.dispose();
    }

    private getOptions(options?: Partial<TooltipOptions>): TooltipOptions {
        return {
            position: options?.position || this.options.position || 'above',
            showDelay: options?.showDelay ?? this.options.showDelay ?? 500,
            hideDelay: options?.hideDelay ?? this.options.hideDelay ?? 500,
            autoHide: options?.autoHide ?? this.options.autoHide ?? true,
        };
    }
}
