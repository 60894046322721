import { Component, Input, OnInit } from '@angular/core';
import { IMentionComponent } from '@datagalaxy/core-ui/rich-text';
import { RichTextMentionService } from '../rich-text-mention.service';
import { RichTextUserMention } from '../../rich-text-mention.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { TranslateModule } from '@ngx-translate/core';
import { DxyProfileAvatarComponent } from '../../../shared-ui/dxy-profile-avatar/dxy-profile-avatar.component';
import { NgClass, NgIf } from '@angular/common';
import { TooltipDirective } from '@datagalaxy/ui/tooltip';
import { RichTextContent } from '@datagalaxy/rich-text-utils';

/** Generic rich-text mention renderer with icon and text */
@Component({
    // Note: When compiled by dxy-rich-text-field-control, this selector is not used
    selector: 'dxy-rich-text-mention',
    templateUrl: 'dxy-mention.component.html',
    styleUrls: ['dxy-mention.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        DxyProfileAvatarComponent,
        TranslateModule,
        TooltipDirective,
    ],
})
export class DxyMentionComponent
    extends DxyBaseComponent
    implements IMentionComponent, OnInit
{
    //#region IMentionComponent - Do not rename those properties
    @Input() mentionId: string;
    @Input() richTextDoc: RichTextContent;
    //#endregion

    public label: string;
    public iconClass: string;
    public userId: string;
    public iconUrl: string;
    public tooltipKey: string;

    constructor(private richTextMentionService: RichTextMentionService) {
        super();
    }

    ngOnInit() {
        this.initAsync();
    }

    private async initAsync() {
        const mention = await this.richTextMentionService.getMention(
            this.richTextDoc,
            this.mentionId,
        );
        if (!mention) {
            return;
        }
        this.label = mention.label;
        this.iconClass = mention.iconClass;
        this.userId =
            mention instanceof RichTextUserMention ? mention.targetId : null;
        this.iconUrl = mention.iconUrl;
        this.tooltipKey = mention.tooltipKey;
    }
}
