import { NgClass } from '@angular/common';
import {
    AfterContentChecked,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    input,
    signal,
    viewChild,
} from '@angular/core';
import { TagColor } from '../common/tag-color';
import { TagVariant } from '../common/tag-variant';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
@Component({
    selector: 'dxy-tag',
    standalone: true,
    templateUrl: './tag.component.html',
    imports: [NgClass, EllipsisTooltipDirective],
    styleUrl: './tag.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent implements AfterContentChecked {
    public color = input<TagColor>('default');
    public variant = input<TagVariant>('secondary');
    public colorSpot = input(false);
    public size = input<'small' | 'medium'>('medium');
    public iconGlyphClass = input<string>();

    protected hasContent = signal(false);
    private tagText = viewChild<ElementRef<HTMLElement>>('tagText');

    @HostBinding('class') get classes() {
        return `${this.color()} ${this.size()} ${this.variant()}`;
    }

    ngAfterContentChecked(): void {
        this.hasContent.set(!!this.tagText()?.nativeElement.textContent);
    }
}
