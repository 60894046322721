import { inject, Injectable } from '@angular/core';
import {
    BackendApiService,
    BaseServiceParameter,
} from '@datagalaxy/data-access';
import { GetTechnologiesResult } from './technology-api.types';

@Injectable({ providedIn: 'root' })
export class TechnologyApiService {
    private backendApiService = inject(BackendApiService);

    public async getTechnologies(param: BaseServiceParameter) {
        return await this.backendApiService.postPromise(
            'Technology/GetTechnologies',
            param,
            GetTechnologiesResult,
        );
    }
}
