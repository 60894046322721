import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import { DxyModalService } from '../shared/dialogs/DxyModalService';
import { CampaignFormModalComponent } from './campaign-form-modal/campaign-form-modal.component';
import {
    ICampaignFormModalInput,
    ICampaignFormModalOutput,
} from './campaign-form-modal/campaign-form-modal.types';
import {
    ICampaignDetailsAddObjectModalInput,
    ICampaignDetailsAddObjectModalOutput,
} from './campaign-details-add-object-modal/campaign-details-add-object-modal.types';
import { CampaignDetailsAddObjectModalComponent } from './campaign-details-add-object-modal/campaign-details-add-object-modal.component';
import { UserService } from '../services/user.service';
import { CampaignDto } from '@datagalaxy/webclient/campaign/data-access';
import { CampaignSelectFormModalComponent } from './campaign-select-form-modal/campaign-selection-form-modal.component';
import {
    ICampaignSelectFormModalInput,
    ICampaignSelectFormModalOutput,
} from './campaign-select-form-modal/campaign-select-form-modal.types';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';

@Injectable({ providedIn: 'root' })
export class CampaignUiService extends BaseService {
    constructor(
        private dxyModalService: DxyModalService,
        private userService: UserService,
    ) {
        super();
    }

    public async openCampaignFormModal(
        spaceIdr: IWorkspaceIdentifier,
        objectReferenceIds?: string[],
        hideToasterOnSuccess?: boolean,
    ): Promise<ICampaignFormModalOutput> {
        const owners = this.userService.getUserList({
            includeSupportUsers: true,
        });
        return await this.dxyModalService.open<
            CampaignFormModalComponent,
            ICampaignFormModalInput,
            ICampaignFormModalOutput
        >({
            loadComponent: () =>
                import(
                    './campaign-form-modal/campaign-form-modal.component'
                ).then((m) => m.CampaignFormModalComponent),
            data: {
                spaceIdr,
                objectReferenceIds,
                owners,
                hideToasterOnSuccess,
            },
        });
    }

    public async openCampaignSelectFormModal(
        spaceIdr: IWorkspaceIdentifier,
        objectReferenceIds: string[],
    ): Promise<ICampaignSelectFormModalOutput> {
        return await this.dxyModalService.open<
            CampaignSelectFormModalComponent,
            ICampaignSelectFormModalInput,
            ICampaignSelectFormModalOutput
        >({
            loadComponent: () =>
                import(
                    './campaign-select-form-modal/campaign-selection-form-modal.component'
                ).then((m) => m.CampaignSelectFormModalComponent),
            data: { spaceIdr, objectReferenceIds: objectReferenceIds },
        });
    }

    public async openAddCampaignObjectsModal(
        campaign: CampaignDto,
        spaceIdr: IWorkspaceIdentifier,
    ): Promise<ICampaignDetailsAddObjectModalOutput> {
        return this.dxyModalService.open<
            CampaignDetailsAddObjectModalComponent,
            ICampaignDetailsAddObjectModalInput,
            ICampaignDetailsAddObjectModalOutput
        >({
            loadComponent: () =>
                import(
                    './campaign-details-add-object-modal/campaign-details-add-object-modal.component'
                ).then((m) => m.CampaignDetailsAddObjectModalComponent),
            data: { campaign, spaceIdr },
        });
    }
}
