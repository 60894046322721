<dxy-action-list [actions]="actions" [data]="actionData" [hideActions]="isMini">
    <div *ngIf="tagData" class="user-tag-container">
        <dxy-user-badge-cell
            [userData]="tagData"
            [mini]="isMini"
        ></dxy-user-badge-cell>
    </div>
    <div *ngIf="isDefault && isMini" class="glyph-star"></div>
    <div *ngIf="showUserName" class="user-name-container">
        <span
            class="user-name"
            dxyEllipsisTooltip
            [dxyEllipsisTooltipDisabled]="disableTooltip"
            >{{ userName }}</span
        >
    </div>
    <div class="badge-container">
        <span
            *ngIf="displayUserBadge"
            [ngClass]="userBadgeGlyphClass"
            [dxyTooltip]="userBadgeTooltip"
        ></span>
    </div>
    <div *ngIf="locked" class="lock" [dxyTooltip]="lockTooltipKey | translate">
        <span class="glyph-lock"></span>
    </div>
</dxy-action-list>

<span
    *ngIf="licenseLevel"
    class="dg5-grid-badge"
    [dxyTooltip]="'UI.User.Profile.LicenseLevel' | translate"
>
    {{ licenseLevel }}
</span>
