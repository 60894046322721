import { Route, Routes } from '@angular/router';
import { AuthGuard, AuthUserGuard } from '../auth/auth.guard';
import { ClientFirstAccessGuard } from './first-access/client-first-access.guard';
import {
    ClientBackwardCompatGuard,
    ClientGuard,
    ClientQueryParamsGuard,
} from './client.guard';
import { SEARCH_RESULTS_PATH } from './client.route-paths';

export const clientWorkspaces: Route = {
    path: 'spaces-list',
    loadComponent: () =>
        import('./client-workspace-list/client-workspace-list.component').then(
            (m) => m.ClientWorkspaceListComponent,
        ),
    data: {
        featureCode: 'MANAGE_WORKSPACE',
        header: {
            titleKey: 'UI.ViewIdentifier.clientSpacesList',
        },
    },
};

export const tasks: Route = {
    path: 'tasks',
    loadComponent: () =>
        import('../tasks/dxy-task-search/dxy-task-search.component').then(
            (m) => m.DxyTaskSearchComponent,
        ),
    data: {
        featureCode: 'MAIN_TASKS',
        header: {
            titleKey: 'UI.ViewIdentifier.clientTasks',
        },
    },
};

export const clientAdmin: Route = {
    path: 'administration',
    loadChildren: () =>
        import('../client-admin/client-admin.routes').then(
            (m) => m.clientAdminRoutes,
        ),
};

export const dashboard: Route = {
    path: 'dashboard',
    loadChildren: () =>
        import('../widgets-dashboard/widgets-dashboard.routes').then(
            (m) => m.widgetsDashboardRoutes,
        ),
};

export const searchResults: Route = {
    path: SEARCH_RESULTS_PATH,
    loadChildren: () =>
        import('../search/search.routes').then((m) => m.searchRoutes),
};

export const user: Route = {
    path: 'user',
    loadChildren: () => import('../user/user.routes').then((m) => m.userRoutes),
};

export const firstAccessRoute: Route = {
    path: 'first-access',
    loadChildren: () =>
        import('./first-access/first-access.routes').then(
            (m) => m.firstAccessRoutes,
        ),
};

export const workspace: Route = {
    path: 'space',
    loadChildren: () =>
        import('../workspace/workspace.routes').then((m) => m.workspaceRoutes),
};

export const clientRoutes: Routes = [
    {
        path: '',
        canMatch: [ClientBackwardCompatGuard],
        canActivate: [AuthGuard, AuthUserGuard, ClientGuard],
        children: [
            {
                path: '',
                loadComponent: () =>
                    import('./client.component').then((m) => m.ClientComponent),
                canActivate: [ClientFirstAccessGuard, ClientQueryParamsGuard],
                children: [
                    clientAdmin,
                    clientWorkspaces,
                    workspace,
                    dashboard,
                    tasks,
                    searchResults,
                    user,
                ],
            },
            firstAccessRoute,
        ],
    },
];
