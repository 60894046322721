import {
    autoserializeAs,
    deserializeAs,
    inheritSerialization,
    serialize,
    serializeAs,
} from 'cerialize';
import {
    EntityTaskDTO,
    ObjectTaskStatus,
    ObjectTaskType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { AttributeFilter } from '@datagalaxy/webclient/attribute/data-access';
import { Filter } from '@datagalaxy/filter-domain';

/**
 * As a matter of module separation, BaseEntitySocialParameter exists in both
 * "Task" and "Comment" modules. The implementation is supposed to be the same
 */
@inheritSerialization(BaseServiceParameter)
export class BaseEntitySocialParameter extends BaseServiceParameter {
    @autoserializeAs(String) public EntityDataReferenceId: string;
    @autoserializeAs(String) public EntityDataTypeName: string;

    constructor(entityId: string, serverType: ServerType, versionId?: string) {
        super();
        this.EntityDataReferenceId = entityId;
        this.EntityDataTypeName = ServerType[serverType];
        if (versionId) {
            this.VersionId = versionId;
        }
    }
}

@inheritSerialization(BaseEntitySocialParameter)
export class GetEntityTasksParameter extends BaseEntitySocialParameter {
    @serialize public WithChildren: boolean;

    constructor(
        entityId: string,
        serverType: ServerType,
        withChildren: boolean,
    ) {
        super(entityId, serverType);
        this.WithChildren = withChildren;
    }
}

@inheritSerialization(BaseServiceParameter)
export class GetUserTasksParameter extends BaseServiceParameter {
    @serialize public ParentReferenceId: string;
    @serialize public SearchTerm: string;
    @serializeAs(Filter) public Filters: Filter[];

    constructor(parentDataId: string, searchTerm: string, filters?: Filter[]) {
        super();
        this.ParentReferenceId = parentDataId;
        this.SearchTerm = searchTerm;
        this.Filters = filters || [];
    }
}

@inheritSerialization(BaseServiceResult)
export class GetEntityTasksResult extends BaseServiceResult {
    @deserializeAs(EntityTaskDTO) public Tasks!: EntityTaskDTO[];
    @deserializeAs(AttributeFilter)
    public AttributeFilters!: Array<AttributeFilter>;
}

@inheritSerialization(BaseEntitySocialParameter)
export class CreateEntityTaskParameter extends BaseEntitySocialParameter {
    @serialize public Text: string;
    @serialize public Title: string;
    @serialize public AssignedToUserId: string;
    @serialize public Type: string;
    @serialize public DueTime: string;

    constructor(
        entityId: string,
        serverType: ServerType,
        versionId: string,
        title: string,
        text: string,
        assigneeId: string,
        type: ObjectTaskType,
        dueTime: string,
    ) {
        super(entityId, serverType);
        this.VersionId = versionId;
        this.Title = title;
        this.Text = text;
        this.AssignedToUserId = assigneeId;
        this.Type = ObjectTaskType[type];
        this.DueTime = dueTime;
    }
}

@inheritSerialization(BaseServiceResult)
export class CreateEntityTaskResult extends BaseServiceResult {
    @deserializeAs(EntityTaskDTO) public CreatedTask!: EntityTaskDTO;
}

@inheritSerialization(BaseEntitySocialParameter)
export class UpdateEntityTaskParameter extends BaseEntitySocialParameter {
    @serialize public TaskId: string;
    @serialize public Title: string;
    @serialize public Text: string;
    @serialize public Status: string;
    @serialize public Response?: string;
    @serialize public AssignedToUserId: string;
    @serialize public Type: string;
    @serialize public DueTime?: string;

    constructor(
        entityId: string,
        versionId: string,
        serverType: ServerType,
        taskId: string,
        title: string,
        text: string,
        assignedToUserId: string,
        status?: ObjectTaskStatus,
        response?: string,
        type?: ObjectTaskType,
        dueTime?: string,
    ) {
        super(entityId, serverType, versionId);
        this.TaskId = taskId;
        this.Title = title;
        this.Text = text;
        this.Status = status ? ObjectTaskStatus[status] : '';
        this.AssignedToUserId = assignedToUserId;
        this.Response = response;
        this.Type = type ? ObjectTaskType[type] : '';
        this.DueTime = dueTime;
    }
}

@inheritSerialization(BaseServiceResult)
export class UpdateEntityTaskResult extends BaseServiceResult {
    @deserializeAs(EntityTaskDTO) public UpdatedTask!: EntityTaskDTO;
}

@inheritSerialization(BaseEntitySocialParameter)
export class DeleteEntityTasksParameter extends BaseEntitySocialParameter {
    @autoserializeAs(String) public TaskIds: string[];

    constructor(
        entityId: string,
        versionId: string,
        serverType: ServerType,
        taskId: string,
    ) {
        super(entityId, serverType, versionId);
        this.TaskIds = [taskId];
    }
}
