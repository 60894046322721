<label>
    <input
        type="checkbox"
        [name]="name()"
        [checked]="checked() === true"
        [attr.indeterminate]="checked() === 'indeterminate' ? true : null"
        [disabled]="disabled()"
        (change)="onValueChange()"
    />

    <div class="checkbox-custom">
        <i [ngClass]="checkboxIcon()"></i>
    </div>

    @if (label()) {
        <span [innerHTML]="label()"></span>
    }
    <ng-content></ng-content>
</label>
