import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { LicenseDTO } from '@datagalaxy/license-domain';

@inheritSerialization(BaseServiceResult)
export class GetLicensesResult extends BaseServiceResult {
    @deserializeAs(LicenseDTO) public Licenses?: LicenseDTO[];
    @deserialize public IsErrorInvalidClientId?: boolean;

    constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceParameter)
export class UpdateUserLicenseParameter extends BaseServiceParameter {
    @serialize public PersonId: string;
    @serialize public LicenseId: number;

    constructor(personId: string, licenseId: number) {
        super();
        this.PersonId = personId;
        this.LicenseId = licenseId;
    }
}
