import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import {
    TextQualityStatistics,
    TextQualityVoteStatus,
} from '@datagalaxy/webclient/attribute/domain';
import { TranslateModule } from '@ngx-translate/core';
import { DxyPercentageDonutComponent } from '@datagalaxy/core-ui';
import { NgClass, NgIf } from '@angular/common';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { TooltipDirective } from '@datagalaxy/ui/tooltip';

@Component({
    selector: 'dxy-attribute-quality-score-dropdown',
    templateUrl: './dxy-attribute-quality-score-dropdown.component.html',
    styleUrls: ['./dxy-attribute-quality-score-dropdown.component.scss'],
    standalone: true,
    imports: [
        MatLegacyMenuModule,
        NgIf,
        DxyPercentageDonutComponent,
        TranslateModule,
        DxyIconButtonDirective,
        NgClass,
        TooltipDirective,
    ],
})
export class DxyAttributeQualityScoreDropdownComponent extends DxyBaseComponent {
    @Input() qualityStatistics: TextQualityStatistics;
    @Input() vote: TextQualityVoteStatus;
    @Input() isOutdated: boolean;
    @Output() onSetUserVote = new EventEmitter<TextQualityVoteStatus>();

    public get score() {
        return Math.round(this.qualityStatistics?.Score * 100);
    }
    public get totalVoteCount() {
        return (
            this.qualityStatistics.LikeVoteCount +
            this.qualityStatistics.DislikeVoteCount
        );
    }
    public get totalLikesCount() {
        return this.qualityStatistics.LikeVoteCount;
    }
    public get totalDislikesCount() {
        return this.qualityStatistics.DislikeVoteCount;
    }
    public get isLiked() {
        return this.vote === TextQualityVoteStatus.Like;
    }
    public get isDisliked() {
        return this.vote === TextQualityVoteStatus.Dislike;
    }
    public get hasVoted() {
        return (
            this.vote === TextQualityVoteStatus.Like ||
            this.vote === TextQualityVoteStatus.Dislike
        );
    }
    public get likeTooltip() {
        return this.vote === TextQualityVoteStatus.Like
            ? 'UI.TextQualityScore.Dropdown.CancelTT'
            : 'UI.TextQualityScore.Dropdown.LikeTT';
    }
    public get dislikeTooltip() {
        return this.vote === TextQualityVoteStatus.Dislike
            ? 'UI.TextQualityScore.Dropdown.CancelTT'
            : 'UI.TextQualityScore.Dropdown.DislikeTT';
    }
    public get voteHint() {
        let suffixKey: string;

        if (!this.totalVoteCount && !this.hasVoted) {
            suffixKey = 'FirstToVote';
        } else if (this.isOutdated) {
            suffixKey = 'Outdated';
        } else if (this.hasVoted) {
            suffixKey = 'HasVoted';
        } else {
            suffixKey = 'AddYourVote';
        }

        return `UI.TextQualityScore.Dropdown.VoteHint.${suffixKey}`;
    }
    public get voteHintTooltip() {
        return this.isOutdated
            ? 'UI.TextQualityScore.Dropdown.VoteHintTooltip.Outdated'
            : '';
    }

    constructor(private functionalLogService: FunctionalLogService) {
        super();
    }

    public onDropdownToggle(open: boolean) {
        this.functionalLogService.logFunctionalAction(
            'ROBOT_QUALITY_SCORE',
            CrudOperation.A,
            open ? CrudActionType.Unhide : CrudActionType.Hide,
        );
    }

    public onToggleLike(event: Event) {
        event.stopPropagation();
        const vote = this.isLiked
            ? TextQualityVoteStatus.None
            : TextQualityVoteStatus.Like;
        this.functionalLogService.logFunctionalAction(
            'ROBOT_QUALITY_SCORE_VOTE',
            CrudOperation.A,
            vote === TextQualityVoteStatus.Like
                ? CrudActionType.Like
                : CrudActionType.CancelLike,
        );
        this.updateVoteCounts(this.vote, vote);
        this.vote = vote;
        this.onSetUserVote.emit(vote);
    }

    public onToggleDislike(event: Event) {
        event.stopPropagation();
        const vote = this.isDisliked
            ? TextQualityVoteStatus.None
            : TextQualityVoteStatus.Dislike;
        this.functionalLogService.logFunctionalAction(
            'ROBOT_QUALITY_SCORE_VOTE',
            CrudOperation.A,
            vote === TextQualityVoteStatus.Dislike
                ? CrudActionType.Dislike
                : CrudActionType.CancelDislike,
        );
        this.updateVoteCounts(this.vote, vote);
        this.vote = vote;
        this.onSetUserVote.emit(vote);
    }

    private updateVoteCounts(
        previousVote: TextQualityVoteStatus,
        currentVote: TextQualityVoteStatus,
    ) {
        if (currentVote === TextQualityVoteStatus.Like) {
            this.qualityStatistics.LikeVoteCount++;
        } else if (currentVote === TextQualityVoteStatus.Dislike) {
            this.qualityStatistics.DislikeVoteCount++;
        }

        if (previousVote === TextQualityVoteStatus.Like) {
            this.qualityStatistics.LikeVoteCount--;
        } else if (previousVote === TextQualityVoteStatus.Dislike) {
            this.qualityStatistics.DislikeVoteCount--;
        }
    }
}
