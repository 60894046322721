import { deserialize, inheritSerialization, serialize } from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';

@inheritSerialization(BaseServiceParameter)
export class RequestPasswordResetParameter extends BaseServiceParameter {
    @serialize public Email: string;

    constructor(email: string) {
        super();
        this.Email = email;
    }
}

@inheritSerialization(BaseServiceResult)
export class RequestPasswordResetResult extends BaseServiceResult {
    /** NOTE: Only used and filled in Bunkerized deployments, when no Mail Server is available */
    @deserialize public ResetPasswordUrl!: string;
}

@inheritSerialization(BaseServiceParameter)
export class ChangePasswordParameter extends BaseServiceParameter {
    @serialize public Email?: string;
    @serialize public ClientId?: string;
    @serialize public Old?: string;
    @serialize public New?: string;
}

@inheritSerialization(BaseServiceResult)
export class ChangePasswordResult extends BaseServiceResult {
    @deserialize public NewLoginToken!: string;
}
