/* eslint-disable @typescript-eslint/no-explicit-any */
import { effect, Injectable } from '@angular/core';
import { ToasterService } from '../../../services/toaster.service';
import { BaseService } from '@datagalaxy/core-ui';
import { ScriptLoaderService } from '../../../core/script-loader.service';
import { AppDataService } from '../../../services/app-data.service';
import { FreshDeskApiService } from './fresh-desk-api.service';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { LanguageService } from '@datagalaxy/translate';
import { AppConfigService } from '@datagalaxy/webclient/config';

/** Service for Online Help */
@Injectable({ providedIn: 'root' })
export class FreshDeskService extends BaseService {
    private get freshDeskWidget() {
        return (window as any).FreshworksWidget;
    }

    private get enabled() {
        return this.appConfigService.ONLINE_HELP;
    }

    constructor(
        private toasterService: ToasterService,
        private appConfigService: AppConfigService,
        private appDataService: AppDataService,
        private freshDeskApiService: FreshDeskApiService,
        private scriptLoader: ScriptLoaderService,
        private languageService: LanguageService,
    ) {
        super();

        effect(() => {
            const lang = this.languageService.getLanguage();

            this.onLangChange(lang);
        });
    }

    public async init() {
        if (!this.enabled) {
            return;
        }
        await this.loadWidgets();
    }

    public async getTicketTypes(): Promise<string[]> {
        return await this.freshDeskApiService.fetchTicketTypes();
    }

    public async createTicket(data: FormData): Promise<void> {
        await this.freshDeskApiService.createTicket(data);
    }

    public openFreshworksWidget() {
        if (this.appConfigService.ANALYTICS_SEGMENT_ENABLED && this.enabled) {
            if (this.freshDeskWidget) {
                this.freshDeskWidget('open');
            } else {
                //Warning : ad blocker !
                this.toasterService.showRequestBlockedError();
            }
        } else {
            window.open(
                'https://datagalaxy.freshdesk.com/fr/support/solutions',
                '_blank',
            );
        }
    }

    /**
     * Takes an `articleId` as parameter and opens said article in Freshworks Widget.
     *
     * @param articleId Number associated to an article by Freshdesk. Can be found in the article URL.
     */
    private openArticle(articleId: number) {
        this.freshDeskWidget('open', 'article', { id: articleId });
    }

    /**
     * Opens Task Search article in Freshworks Widget
     */
    public openTaskSearchArticle() {
        this.openArticle(35000131690);
    }

    /**
     * Opens Versioning Dashboard article in Freshworks Widget
     */
    public openVersioningArticle() {
        this.openArticle(35000044639);
    }

    public openModuleArticle(dgModule: DgModule) {
        let articleNumber;
        switch (dgModule) {
            case DgModule.Glossary:
                articleNumber = 35000141539;
                break;
            case DgModule.Catalog:
                articleNumber = 35000141599;
                break;
            case DgModule.Processing:
                articleNumber = 35000019573;
                break;
            case DgModule.Usage:
                articleNumber = 35000131306;
                break;
        }
        if (!articleNumber) {
            return;
        }
        this.openArticle(articleNumber);
    }

    public openMetabotArticle() {
        this.openArticle(35000139851);
    }

    public openVersionComparatorArticle() {
        this.openArticle(35000132224);
    }

    private async onLangChange(lang: string) {
        await this.freshDeskApiService.updateContactLang(
            this.appDataService.currentUserEmail,
            lang,
        );
    }

    private async loadWidgets() {
        await this.scriptLoader.loadScript('/scripts/freshworks-init.js');
    }
}
