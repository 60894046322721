import {
    DestroyRef,
    Directive,
    inject,
    input,
    OnInit,
    SimpleChange,
} from '@angular/core';
import { DxyBaseDirective } from '@datagalaxy/ui/core';
import { GridComponent, PersistedGridState } from '@datagalaxy/ui/grid';
import { UserGridSettingsService } from './user-grid-settings.service';
import { debounceTime } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
    standalone: true,
    selector: 'dxy-grid[dxyGridUserSettings]',
})
export class UserGridSettingsDirective
    extends DxyBaseDirective
    implements OnInit
{
    gridId = input.required<string>({
        alias: 'dxyGridUserSettings',
    });
    disabled = input(false, {
        alias: 'dxyGridUserSettingsDisabled',
    });

    private destroyRef = inject(DestroyRef);

    constructor(
        private host: GridComponent,
        private userGridSettingsService: UserGridSettingsService,
    ) {
        super();
    }

    ngOnInit() {
        if (this.disabled()) {
            return;
        }

        void this.initAsync();

        this.host.gridStateChange
            .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
            .subscribe((gridState: PersistedGridState) =>
                this.onGridStateChange(gridState),
            );
    }

    private async initAsync() {
        this.host.startLoading();
        const gridState = await this.userGridSettingsService.getGridState(
            this.gridId(),
        );

        if (!gridState) {
            this.host.endLoading();
            return;
        }
        this.host.endLoading();
        this.host.gridState = gridState;
        this.host.ngOnChanges({
            gridState: new SimpleChange(null, gridState, false),
        });
    }

    private async onGridStateChange(gridState: PersistedGridState) {
        if (this.disabled()) {
            return;
        }

        await this.userGridSettingsService.saveGridState(
            this.gridId(),
            gridState,
        );
    }
}
