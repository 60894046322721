import { ConnectedPosition } from '@angular/cdk/overlay';

const menuTriggerSpacingInPx = 3;

export type MenuAlignment = 'left' | 'right' | 'both';

export const getMenuPosition = (
    alignment: MenuAlignment | undefined,
): ConnectedPosition[] => {
    return alignment === 'right' ? bottomRightPosition : bottomLeftPosition;
};

const bottomLeftPosition: ConnectedPosition[] = [
    {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetY: menuTriggerSpacingInPx,
    },
    {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetY: -menuTriggerSpacingInPx,
    },
];

const bottomRightPosition: ConnectedPosition[] = [
    {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
        offsetY: menuTriggerSpacingInPx,
    },
    {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom',
        offsetY: -menuTriggerSpacingInPx,
    },
];
