import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import { MsTeamsApiService } from './api/ms-teams-api.service';
import { UserNotificationChannelsStateService } from './state/user-notification-channels-state.service';
import { msTeamsNotificationChannel } from './ms-teams-notification-channel';
import { DxyModalService } from '../shared/dialogs/DxyModalService';
import { MsTeamsNotificationsDeactivationModalComponent } from './ms-teams-notifications-deactivation-modal/ms-teams-notifications-deactivation-modal.component';
import { MsTeamsNotificationsActivationModalComponent } from '../user/ms-teams-notifications-activation-modal/ms-teams-notifications-activation-modal.component';

@Injectable({ providedIn: 'root' })
export class MsTeamsChannelUiService extends BaseService {
    private channelName = msTeamsNotificationChannel.name;

    constructor(
        private dxyModalService: DxyModalService,
        private msTeamsApiService: MsTeamsApiService,
        private userNotificationChannelsStateService: UserNotificationChannelsStateService,
    ) {
        super();
    }

    public openActivateTeamsNotificationsModal() {
        this.dxyModalService.open<
            MsTeamsNotificationsActivationModalComponent,
            void,
            boolean
        >({
            loadComponent: () =>
                import(
                    '../user/ms-teams-notifications-activation-modal/ms-teams-notifications-activation-modal.component'
                ).then((m) => m.MsTeamsNotificationsActivationModalComponent),
            width: '680px',
        });
    }

    public openDeactivateTeamsNotificationsModal() {
        this.dxyModalService.open<
            MsTeamsNotificationsDeactivationModalComponent,
            void,
            boolean
        >({
            loadComponent: () =>
                import(
                    './ms-teams-notifications-deactivation-modal/ms-teams-notifications-deactivation-modal.component'
                ).then((m) => m.MsTeamsNotificationsDeactivationModalComponent),
        });
    }

    public async askIntegrationActivation() {
        await this.msTeamsApiService.askIntegrationActivation();
        this.userNotificationChannelsStateService.setUserChannelAsked(
            this.channelName,
            true,
        );
    }
}
