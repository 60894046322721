import { Inject, Injectable, NgZone } from '@angular/core';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { DIALOG_SERVICE_TOKEN, IDialogService } from '@datagalaxy/ui/dialog';
import { LegacyToaster } from '@datagalaxy/ui/toaster';
import { TranslationApiService } from '@datagalaxy/webclient/multilingual/data-access';
import { TranslateService } from '@ngx-translate/core';
import { MultilingualStateService } from '../multilingual-state.service';
import {
    TranslationOrigin,
    TranslationStatus,
} from '@datagalaxy/webclient/multilingual/domain';
import {
    ATTRIBUTES_FINDER_TOKEN,
    AttributesFinder,
} from '@datagalaxy/webclient/attribute/feature';
import { LanguageService } from '@datagalaxy/translate';

@Injectable({ providedIn: 'root' })
export class AttributeTranslationRegenerationService {
    constructor(
        @Inject(DIALOG_SERVICE_TOKEN)
        private dialogService: IDialogService,
        @Inject(ATTRIBUTES_FINDER_TOKEN)
        private attributeFinder: AttributesFinder,
        private translationApiService: TranslationApiService,
        private languageService: LanguageService,
        private multilingualStateService: MultilingualStateService,
        private translate: TranslateService,
        private toaster: LegacyToaster,
        private ngZone: NgZone,
    ) {}

    public async askForRegeneration(
        entity: IEntityIdentifier,
        attributePath: string,
        sourceLangValue?: string,
    ) {
        if (
            !(await this.shouldByPassModal(
                entity,
                attributePath,
                sourceLangValue,
            ))
        ) {
            const regenerate = await this.dialogService.confirm({
                titleKey: 'Multilingual.RegenerateTranslation.title',
                message: this.translate.instant(
                    'Multilingual.RegenerateTranslation.message',
                    {
                        attributeName:
                            await this.getAttributeDisplayName(attributePath),
                    },
                ),
                cancelButtonKey:
                    'Multilingual.RegenerateTranslation.doNotTranslate',
                confirmButtonKey:
                    'Multilingual.RegenerateTranslation.translateToOtherLanguages',
                confirmButtonColor: 'ai',
                confirmButtonGlyph: 'glyph-language-ai',
            });

            if (!regenerate) {
                return;
            }
        }

        await this.regenerateTranslations(entity, attributePath);
    }

    public async askForDeletion(
        entity: IEntityIdentifier,
        attributePath: string,
        sourceLangValue?: string,
    ) {
        if (
            await this.shouldByPassModal(entity, attributePath, sourceLangValue)
        ) {
            return;
        }
        const confirmDeletion = await this.dialogService.confirm({
            titleKey: 'Multilingual.RemoveTranslation.title',
            message: this.translate.instant(
                'Multilingual.RemoveTranslation.message',
                {
                    attributeName:
                        await this.getAttributeDisplayName(attributePath),
                },
            ),
            cancelButtonKey: 'Multilingual.RemoveTranslation.doNotDelete',
            confirmButtonKey: 'Multilingual.RemoveTranslation.delete',
            confirmButtonGlyph: 'glyph-delete',
        });

        if (!confirmDeletion) {
            return;
        }

        await this.resetTranslations(entity, attributePath);
    }

    private async regenerateTranslations(
        entityIdentifier: IEntityIdentifier,
        attributePath: string,
    ) {
        this.ngZone.runTask(async () => {
            this.multilingualStateService.startRegneration();
            try {
                await this.translationApiService.regenerateAttributeTranslations(
                    {
                        from: this.languageService.getLanguage(),
                        entityIdentifier,
                        attributePath,
                    },
                );
            } finally {
                this.multilingualStateService.regenerationEnded();
            }
        });
    }

    private async resetTranslations(
        entityIdentifier: IEntityIdentifier,
        attributePath: string,
    ) {
        this.ngZone.runTask(async () => {
            await this.translationApiService.resetAttributeTranslations({
                entityIdentifier,
                attributePath,
            });
            this.toaster.successToast({
                title: this.translate.instant(
                    'Multilingual.RemoveTranslation.successTitle',
                ),
                message: this.translate.instant(
                    'Multilingual.RemoveTranslation.successMessage',
                ),
            });
        });
    }

    private async shouldByPassModal(
        entity: IEntityIdentifier,
        attributePath: string,
        sourceLangValue?: string,
    ) {
        const clientSpaceSourceLanguage =
            this.multilingualStateService.state.settings?.sourceLanguageCode;
        const translations =
            await this.translationApiService.getObjectTranslations(entity);
        if (this.languageService.getLanguage() !== clientSpaceSourceLanguage) {
            translations.push({
                entityIdentifier: entity,
                languageCode: clientSpaceSourceLanguage ?? '',
                translatedAttributeValues: [
                    {
                        attributePath,
                        value: sourceLangValue ?? '',
                        origin: TranslationOrigin.Human,
                        status: TranslationStatus.Ok,
                    },
                ],
            });
        }
        const hasNoTranslationsInOtherLanguages = translations.every((t) =>
            t.translatedAttributeValues.every(
                (v) =>
                    v.attributePath !== attributePath ||
                    t.languageCode == this.languageService.getLanguage() ||
                    !v.value,
            ),
        );

        return hasNoTranslationsInOtherLanguages;
    }

    private async getAttributeDisplayName(attributePath: string) {
        const attribute =
            await this.attributeFinder.findAttributeByPath(attributePath);
        return attribute?.translatedDisplayName ?? attribute?.DisplayName;
    }
}
