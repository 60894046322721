import { EntityType } from '@datagalaxy/dg-object-model';
import { MetaObjectType } from '@datagalaxy/meta-object-domain';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'objectTypeAdapter',
    standalone: true,
})
export class ObjectTypeAdapterPipe implements PipeTransform {
    public transform(value: EntityType): MetaObjectType {
        return EntityType[value] as MetaObjectType;
    }
}
