import { Inject, Injectable } from '@angular/core';
import {
    HttpTransportType,
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    LogLevel,
} from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { EventLog } from '@datagalaxy/dg-object-model';
import { LoggerService } from '@datagalaxy/shared/logger';
import { BACKEND_API_CONFIG, IApiConfig } from '../types';

const receiveEventLogMethodName = 'ReceiveEventLogAsync';

@Injectable({ providedIn: 'root' })
export class SignalRService {
    private hubConnection: HubConnection;
    private eventLogSubject: Subject<EventLog> = new Subject<EventLog>();
    public eventLog$ = this.eventLogSubject.asObservable();

    public constructor(
        private logger: LoggerService,
        @Inject(BACKEND_API_CONFIG) config: IApiConfig,
    ) {
        window;
        const url = Location.joinWithSlash(
            window.location.origin,
            'event-logs',
        );
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(url, {
                accessTokenFactory: () => config.getJWT?.() ?? '',
                transport: HttpTransportType.WebSockets,
                skipNegotiation: true,
            })
            .configureLogging(LogLevel.Error)
            .withAutomaticReconnect()
            .build();
    }

    public emitEventLog(eventLog: EventLog) {
        this.eventLogSubject.next(eventLog);
    }

    public async startConnection() {
        this.hubConnection.serverTimeoutInMilliseconds = 60 * 1000;
        try {
            if (this.hubConnection.state === HubConnectionState.Connected) {
                return;
            }
            await this.hubConnection.start();
            this.hubConnection.on(
                receiveEventLogMethodName,
                (eventLog: EventLog) => {
                    this.emitEventLog(eventLog);
                },
            );
        } catch (error) {
            this.logger.error(
                'Error while starting the event logs signalr hub connection',
                error,
            );
        }
    }

    public async stopConnection() {
        try {
            if (this.hubConnection.state === HubConnectionState.Disconnected) {
                return;
            }
            this.hubConnection.off(receiveEventLogMethodName);
            await this.hubConnection.stop();
        } catch (error) {
            this.logger.error(
                'Error while stopping the event logs signalr hub connection',
                error,
            );
        }
    }
}
