import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { IVersioningSelectorInputs } from '../helpers/IVersioningSelectorInputs';
import { VersioningSelectorCore } from '../helpers/versioningSelectorCore';
import { VersioningEventService } from '../services/versioning-event.service';
import { VersioningUiService } from '../services/versioning-ui.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IVersioningSelectorMoreText } from '../versioning-selector.types';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import {
    WorkspaceService,
    WorkspaceStore,
} from '@datagalaxy/webclient/workspace/data-access';
import {
    ProjectVersion,
    ProjectVersionStatus,
} from '@datagalaxy/webclient/versioning/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyLogFunctionalDirective } from '../../directives/dxy-log-functional.directive';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { TooltipDirective } from '@datagalaxy/ui/tooltip';

@Component({
    selector: 'dxy-versioning-selector',
    templateUrl: './dxy-versioning-selector.component.html',
    styleUrls: ['./dxy-versioning-selector.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatLegacyMenuModule,
        DxyIconButtonDirective,
        NgClass,
        TranslateModule,
        DxyLogFunctionalDirective,
        NgFor,
        TooltipDirective,
    ],
})
export class DxyVersioningSelectorComponent
    extends DxyBaseComponent
    implements IVersioningSelectorInputs, OnInit, OnChanges
{
    @Input() readonly = false;
    //#region component bindings
    @Input() projectIdr: IWorkspaceIdentifier;
    @Input() noNavigator: boolean;
    @Input() openOnDisplayNameClick: boolean;
    @Input() includeArchived: boolean;
    @Input() selectedTextTrackerId: string;
    @Input() selectedCaretTrackerId: string;
    /** Provide methods to get additional text and class for each version */
    @Input() moreText?: IVersioningSelectorMoreText;
    //#endregion

    //#region component and html bindings
    @Input() isVersionSelectable: boolean;
    @Input() enableFavorite: boolean;
    @Input() hideOfficialVersion: boolean;
    /** Class name to add to the mat-menu panel (which actual parent is the document body, not the component) */
    @Input() menuClass: string;
    /** Emitted when a menu is opened or closed. The argument is true on open. */
    @Output() readonly openClose = new EventEmitter<boolean>();
    @Output() get onProjectVersionSelected() {
        return this.core.onProjectVersionSelected;
    }
    @Output() get onDisplayNameClick() {
        return this.core.onDisplayNameClick;
    }
    /** Emitted on start and end of versions loading. The argument is true on start. */
    @Output() get onProjectVersionsLoading() {
        return this.core.onProjectVersionsLoading;
    }

    //#endregion

    //#region html bindings
    public defaultVersionTooltip: string;
    public get showDropDown() {
        return this.core.showDropDown;
    }
    public get selectedVersionName() {
        return this.core.selectedVersionName;
    }
    public get showStars() {
        return this.core.showStars;
    }
    public get showNavigatorLink() {
        return this.core.showNavigatorLink;
    }
    public get hasOfficialVersion() {
        return this.core.hasOfficialVersion;
    }
    public get hasActiveVersions() {
        return this.core.hasActiveVersions;
    }
    public get hasReleaseCandidateVersions() {
        return this.core.hasReleaseCandidateVersions;
    }
    public get officialVersion() {
        return this.core.officialVersion;
    }
    public get activeVersions() {
        return this.core.activeVersions;
    }
    public get releaseCandidateVersions() {
        return this.core.releaseCandidateVersions;
    }
    public get archivedVersions() {
        return this.core.archivedVersions;
    }
    public get hasArchivedVersions() {
        return this.core.hasArchivedVersions;
    }
    public get useMoreText() {
        return !!this.moreText;
    }
    //#endregion

    private core: VersioningSelectorCore;

    constructor(
        private translate: TranslateService,
        private cd: ChangeDetectorRef,
        dxyModalService: DxyModalService,
        versioningUiService: VersioningUiService,
        versioningEventService: VersioningEventService,
        workspaceService: WorkspaceService,
        workspaceStore: WorkspaceStore,
    ) {
        super();
        this.core = new VersioningSelectorCore(
            dxyModalService,
            versioningUiService,
            versioningEventService,
            workspaceService,
            workspaceStore,
            (subject, action) => super.subscribe(subject, action),
            (subscription) => super.registerSubscription(subscription),
            (...args) => this.log('(core)', ...args),
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onAnyChanges(changes, this, () => this.core.onInputChange(this));
        super.onChange(
            changes,
            'projectIdr',
            async (spaceIdr: IWorkspaceIdentifier) => {
                await this.core.onProjectIdrChanged(spaceIdr);
                this.cd.detectChanges();
            },
        );
    }

    ngOnInit() {
        this.defaultVersionTooltip = this.translate.instant(
            'UI.Versioning.defaultVersionTooltip',
        );
        this.core.init(this).then(() => this.cd.detectChanges());
    }

    //#region API
    public hasAny(
        statuses: ProjectVersionStatus[],
        predicate?: (version: ProjectVersion) => boolean,
    ) {
        return this.core.hasAny(statuses, predicate);
    }
    //#endregion

    public getFeatureCode(version: ProjectVersion) {
        return this.core.getFeatureCode(version);
    }
    public isFavoriteButtonVisible(version: ProjectVersion) {
        return this.core.isFavoriteButtonVisible(version);
    }

    public onVersionClick(version: ProjectVersion) {
        this.core.onVersionClick(version);
    }
    public onDisplayNameClickInternal(event: Event) {
        if (this.openOnDisplayNameClick) {
            return;
        }
        event.stopPropagation();
        this.core.onProjectVersionSelected.emit({
            projectVersion: this.core.selected,
            isUserAction: true,
        });
    }
    public onNavigatorLinkClick() {
        this.core.onNavigatorLinkClick();
    }
    public onStarClick(event: Event, version: ProjectVersion) {
        this.core.onStarClick(event, version);
    }

    public menuOpenClose(isOpen: boolean) {
        this.log('menuOpenClose', isOpen);
        this.openClose.emit(isOpen);
    }

    public getMoreTextClass(version: ProjectVersion) {
        return this.moreText?.getMoreTextClass?.(version);
    }
    public getMoreText(version: ProjectVersion) {
        return this.moreText?.getMoreText?.(version);
    }
}
