import { MonitoringApiService } from '@datagalaxy/shared/monitoring/data-access';
import { Route, Routes } from '@angular/router';
import { inject } from '@angular/core';
import { AppHashGuard } from './app.guard';

const healthCheckRoute: Route = {
    path: 'is-up',
    loadComponent: () =>
        import('./app-main/dxy-is-up/dxy-is-up.component').then(
            (m) => m.DxyIsUpComponent,
        ),
    resolve: {
        upData: () => inject(MonitoringApiService).healthCheck(),
    },
};

const seeYouSoonRoute: Route = {
    path: `see-you-soon`,
    loadComponent: () =>
        import('@datagalaxy/webclient/auth/ui').then(
            (m) => m.SeeYouSoonComponent,
        ),
};

const expiredLicense: Route = {
    path: 'expired-trial',
    loadComponent: () =>
        import(
            './pages/account-expired-trial-page/account-expired-trial-page.component'
        ).then((m) => m.AccountExpiredTrialPageComponent),
};

export const error: Route = {
    path: 'error',
    loadComponent: () =>
        import('./errorPages/dxy-error-page/dxy-error-page.component').then(
            (m) => m.DxyErrorPageComponent,
        ),
};

const clientRoutes: Route = {
    path: '',
    loadChildren: () =>
        import('./client/client.routes').then((m) => m.clientRoutes),
};

export const appRoutes: Routes = [
    {
        path: '',
        canActivate: [AppHashGuard],
        children: [
            healthCheckRoute,
            seeYouSoonRoute,
            expiredLicense,
            error,
            clientRoutes,
        ],
    },
    { path: '**', redirectTo: '/' },
];
