import { Injectable } from '@angular/core';
import { AnalyticService } from '../../services/analytic.service';
import { ViewTypeService } from '../../services/viewType.service';
import { ViewType } from '../../shared/util/app-types/ViewType';
import {
    IUserFirstAccessData,
    IUserSegmentationData,
} from './first-access.types';
import { UserService } from '../../services/user.service';
import { AppConfigService } from '@datagalaxy/webclient/config';
import {
    UserPublicData,
    userSettingsValues,
} from '@datagalaxy/webclient/user/domain';
import { UserSettingValueService } from '@datagalaxy/users/data-access';

const userFirstAccessCompletionTag = 'USD_isCompleted';

@Injectable({ providedIn: 'root' })
export class FirstAccessService {
    // Cached response
    private _isCompleted: boolean = null;
    private _savedUrl: string;

    public get savedUrl() {
        return this._savedUrl;
    }

    constructor(
        private userService: UserService,
        private userSettingsService: UserSettingValueService,
        private viewTypeService: ViewTypeService,
        private analyticService: AnalyticService,
        private appConfigService: AppConfigService,
    ) {}

    public saveUrl(url: string) {
        this._savedUrl = url;
    }

    public clearUrl() {
        this._savedUrl = null;
    }

    public async isCompleted() {
        if (this.appConfigService.DISABLE_FIRST_ACCESS) {
            return true;
        }

        if (this._isCompleted !== null) {
            return this._isCompleted;
        }

        const completions = await Promise.allSettled([
            this.isLegacySegmentationCompleted(),
            this.isFirstAccessCompleted(),
        ]);

        // Completion is met if at least one of both tests return true
        this._isCompleted =
            completions.filter(
                (r) => r.status === 'fulfilled' && r.value === true,
            ).length >= 1;

        return this._isCompleted;
    }

    public async publishCompletion(
        user: UserPublicData,
        firstAccessData: IUserFirstAccessData,
    ) {
        // Persist accepted legal terms and privacy policy conditions
        const { acceptedPrivacyPolicy, acceptedTermsOfUse } = firstAccessData;
        await this.persistFirstAccessValues({
            acceptedPrivacyPolicy,
            acceptedTermsOfUse,
        });
        // Language preference
        await this.userService.setUserLanguage(
            user,
            firstAccessData.language.languageCode,
        );
        // Objects ViewType display
        await this.persistViewType(firstAccessData.profile as ViewType);
        this._isCompleted = true;

        await this.notifyFirstAccessDone();
    }

    public async notifyFirstAccessDone() {
        await this.analyticService.addUserMetaData({
            [userFirstAccessCompletionTag]: true,
        });
    }

    private async isFirstAccessCompleted() {
        const res = await this.userSettingsService.getUserSettingValue(
            userSettingsValues.firstAccess.category,
            userSettingsValues.firstAccess.routes.data,
        );
        const data = (res ? JSON.parse(res) : {}) as IUserFirstAccessData;
        return data.acceptedPrivacyPolicy && data.acceptedTermsOfUse;
    }

    /** @comment See readme */
    private async isLegacySegmentationCompleted() {
        const userData = await this.getSegmentationData();
        return userData.isCompleted;
    }

    private async getSegmentationData(): Promise<IUserSegmentationData> {
        const res = await this.userSettingsService.getUserSettingValue(
            userSettingsValues.userSegmentation.category,
            userSettingsValues.userSegmentation.routes.data,
        );
        return (res ? JSON.parse(res) : {}) as IUserSegmentationData;
    }

    private async persistFirstAccessValues(
        firstAccessAcceptance: Pick<
            IUserFirstAccessData,
            'acceptedPrivacyPolicy' | 'acceptedTermsOfUse'
        >,
    ) {
        return await this.userSettingsService.setUserSettingValue(
            userSettingsValues.firstAccess.category,
            userSettingsValues.firstAccess.routes.data,
            JSON.stringify(firstAccessAcceptance),
        );
    }

    private async persistViewType(viewType: ViewType) {
        this.viewTypeService.setViewType(viewType);
    }
}
