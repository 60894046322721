import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { HddUtil } from '../../../util/HddUtil';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { LinkedDataItem } from '@datagalaxy/webclient/entity/domain';
import { DgEntityLinkShortcutCellComponent } from '../dg-entity-link-shortcut-cell/dg-entity-link-shortcut-cell.component';
import { NgForOf, NgIf } from '@angular/common';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { TooltipDirective } from '@datagalaxy/ui/tooltip';

/**
 * ## Role
 * Display a collection of entity links
 */
@Component({
    standalone: true,
    selector: 'app-dg-entity-link-shortcut-collection-cell',
    templateUrl: 'dg-entity-link-shortcut-collection-cell.component.html',
    styleUrls: ['dg-entity-link-shortcut-collection-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DgEntityLinkShortcutCellComponent,
        NgIf,
        NgForOf,
        TooltipDirective,
    ],
})
export class DgEntityLinkShortcutCollectionCellComponent
    extends DxyBaseComponent
    implements OnChanges
{
    @Input() hdds?: HierarchicalData[];
    public displayedHdds: HierarchicalData[];

    public moreCount = 0;
    public readonly moreCountSize = 35;
    public get tooltipText() {
        return DgEntityLinkShortcutCollectionCellComponent.getHdDisplayNamesAsJoinedString(
            this.hdds,
        );
    }

    private readonly letterWidth = 8.5;
    private readonly linkMinWidth = 30;
    private get el() {
        return this.elementRef.nativeElement;
    }

    constructor(private elementRef: ElementRef<HTMLElement>) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'hdds', () => this.refreshLayout(), true);
    }

    public static getValueAsJoinedString(paramValue: LinkedDataItem[]) {
        const hds =
            DgEntityLinkShortcutCollectionCellComponent.getHdsFromLinkDataItems(
                paramValue,
            );
        return DgEntityLinkShortcutCollectionCellComponent.getHdDisplayNamesAsJoinedString(
            hds,
        );
    }

    public static getHdsFromLinkDataItems(ldis: LinkedDataItem[]) {
        return (
            ldis
                ?.map(
                    (hd) =>
                        hd &&
                        HddUtil.deserializeHierarchicalData(hd.LinkedData),
                )
                ?.filter((hd) => hd) ?? []
        );
    }

    private static getHdDisplayNamesAsJoinedString(
        hds: HierarchicalData[],
        separator = ', ',
    ) {
        return (
            hds
                ?.map((hd) => hd?.Data?.DisplayName)
                .filter((s) => s)
                .join(separator) ?? ''
        );
    }

    public refreshLayout() {
        this.moreCount = 0;
        if (!this.hdds?.length) {
            this.displayedHdds = [];
            return;
        }
        if (this.hdds.length <= 2) {
            this.displayedHdds = [...this.hdds];
            return;
        }
        this.fillTwoLines();
        this.moreCount = this.hdds.length - this.displayedHdds.length;
    }

    /** Fill 2 lines only by adding elements to displayedHdds */
    private fillTwoLines() {
        const lineWidth = this.el.parentElement?.offsetWidth;
        const firstHdd = this.hdds[0];
        let currentWidth = this.getHddWidth(firstHdd);
        this.displayedHdds = [firstHdd];
        const currentLine = currentWidth < lineWidth ? 1 : 2;
        if (currentLine == 1) {
            // Fill first line if first hdd let some space
            this.fillLine(lineWidth, currentWidth);
        }
        currentWidth = 0;
        // Fill second line
        this.fillLine(lineWidth, currentWidth, true);
    }
    /** Fill line remaining space by adding elements to displayedHdds */
    private fillLine(
        lineWidth: number,
        currentWidth: number,
        withMoreCount = false,
    ) {
        const index = this.displayedHdds.length;
        if (this.hdds.length < index) {
            return;
        }
        const isLast = this.hdds.length == index + 1;
        for (const hdd of this.hdds.slice(index)) {
            const w = this.getHddWidth(hdd);
            currentWidth += w;
            let availableWidth = lineWidth;
            if (withMoreCount && !isLast) {
                availableWidth -= this.moreCountSize;
            }
            if (currentWidth > availableWidth) {
                return;
            }
            this.displayedHdds.push(hdd);
        }
    }
    private getHddWidth(hdd: HierarchicalData) {
        return hdd.DisplayName.length * this.letterWidth + this.linkMinWidth;
    }
}
