import {
    setFulfilled,
    setPending,
    withRequestStatus,
} from '@datagalaxy/shared/signal-store-common';
import {
    patchState,
    signalStore,
    withHooks,
    withMethods,
    withState,
} from '@ngrx/signals';
import { MetaObjectTypeCardDisplayService } from './meta-object-type-card-display.service';
import { inject } from '@angular/core';
import { MetaObjectCardDisplayMode } from './meta-object-type-card-display.types';

export type MetaObjectTypeCardDisplayState = {
    objectCardDisplayMode: MetaObjectCardDisplayMode;
};

const defaultState: MetaObjectTypeCardDisplayState = {
    objectCardDisplayMode: 'techno-with-chip',
};

export const MetaObjectTypeCardStore = signalStore(
    { providedIn: 'root' },
    withState<MetaObjectTypeCardDisplayState>(defaultState),
    withRequestStatus(),
    withMethods(
        (
            store,
            displayModeService = inject(MetaObjectTypeCardDisplayService),
        ) => ({
            async updateDisplayMode(displayMode: MetaObjectCardDisplayMode) {
                patchState(store, setPending());

                await displayModeService.setUserObjectCardDisplayMode(
                    displayMode,
                );

                patchState(
                    store,
                    { objectCardDisplayMode: displayMode },
                    setFulfilled(),
                );
            },
        }),
    ),
    withHooks({
        async onInit(
            store,
            metaObjectTypeCardDisplayService = inject(
                MetaObjectTypeCardDisplayService,
            ),
        ) {
            patchState(store, setPending());

            const objectCardDisplayMode =
                await metaObjectTypeCardDisplayService.getUserObjectCardDisplayMode();

            patchState(store, { objectCardDisplayMode }, setFulfilled());
        },
    }),
);
