import {
    BaseMasterData,
    IHas$type,
    IHasReferenceId,
    IIsData,
} from '../master-data';
import { IIdr, ServerType } from '@datagalaxy/dg-object-model';
import {
    Deserialize,
    deserialize,
    deserializeAs,
    INewable,
    inheritSerialization,
    Serialize,
    serialize,
    serializeAs,
} from 'cerialize';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';

const debug = false;
export const emptyRef =
    '00000000-0000-0000-0000-0000000000ff:00000000-0000-0000-0000-0000000000ff';
export type TBase = BaseMasterData;
type TProducer = CRefData<TBase> & Partial<IIsData>;

/** Object with *ServerType* and *$id*\/ReferenceId properties, referencing a masterdata object.
 * Those values equal *ServerType* and *ReferenceId* properties of the masterdata object.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface IRef<T extends IHasReferenceId = IHasReferenceId>
    extends IHasReferenceId,
        IHas$type {
    $id: string;
    ServerType: ServerType;
}

/** Reference to a master-data object to be loaded to/from a master-data repository */
export class Ref<T extends TBase = TBase> implements IRef, IIdr {
    //#region - instance

    public $type!: string; // for deserialization only
    @serialize public $id!: string; //#archi-masterdata-saveData
    @serializeAs('_refServerType') public ServerType!: ServerType; //#archi-masterdata-saveData
    _!: T; // not used, but needed for type parameter propagation

    //#region static

    //#region master-data repository dependency injector
    static setResolver(getObject: <T extends TBase>(r: Ref<T>) => T | null) {
        if (typeof getObject == 'function') {
            Ref.getObject = getObject;
        }
    }

    private static getObject: <T extends TBase>(r: Ref<T>) => T | null = () => {
        CoreUtil.warn('resolver not set');
        return null;
    };
    //#endregion

    //#region operators
    /** Returns true if the given object is falsy or its ReferenceId is falsy or is emptyRef */
    static isVoid(o: IIdr) {
        const id = o?.ReferenceId;
        return !id || id == emptyRef;
    }

    /** Returns the ReferenceId if possible and if it's not the *EmptyRef* value, else null */
    static idOrNull(o: IIdr) {
        return Ref.isVoid(o) ? null : o.ReferenceId;
    }

    /** Returns true if both objects are falsy, or both are not and have the same ReferenceId - and optionally the same ServerType */
    static areSame(a: IIdr, b: IIdr, checkType = false) {
        return (
            (!a && !b) ||
            (a &&
                b &&
                a.ReferenceId == b.ReferenceId &&
                (!checkType || a.ServerType == b.ServerType))
        );
    }

    /** Returns true if the given array contains a Ref to the given object. By default only the ReferenceId is compared */
    static includes<T extends IIdr>(idrs: T[], r: IIdr, checkType = false) {
        return idrs?.some((o) => Ref.areSame(o, r, checkType));
    }

    /** Returns the first occurence of a Ref to the given object from the given array. By default only the ReferenceId is compared */
    static find<T extends IIdr>(idrs: T[], r: IIdr, checkType = false) {
        return idrs?.find((o) => Ref.areSame(o, r, checkType));
    }

    /** Adds a Ref to the given T object (or the given Ref&lt;T&gt; itself) to the given array, if not already present in the array */
    static addIfNotExists<T extends TBase, TRef extends Ref<T>>(
        refs: Ref<T>[],
        o: T | TRef,
    ) {
        if (refs && !Ref.includes(refs, o)) {
            refs.push(Ref.from(o));
        }
    }

    /** Removes any occurence in the given array of Ref&lt;T&gt; that matches a Ref to the given T object (or the given Ref&lt;T&gt; itself) */
    static remove<T extends TBase, TRef extends Ref<T>>(
        refs: Ref<T>[],
        o: T | TRef,
    ) {
        CollectionsHelper.remove(refs, (r) => Ref.areSame(r, o));
    }

    //#endregion

    //#region factories
    /** Returns a new Ref&lt;T&gt; given an object having a *ReferenceId* and a *ServerType* */
    static from<T extends TBase>(o: IIdr) {
        return o && new Ref<T>(o.ServerType, o.ReferenceId);
    }

    //#endregion

    //#region factory providers
    /** Returns a function that, given an id, instanciates a Ref&lt;T&gt; having the provided ServerType */
    static forId<T extends TBase>(st: ServerType) {
        return (id: string) => new Ref<T>(st, id);
    }

    /** Returns a function that, given an array of ids, instanciates an array of Ref&lt;T&gt; having the provided ServerType */
    static forIds<T extends TBase>(st: ServerType) {
        return (ids: string[]) => ids?.map((id) => new Ref<T>(st, id)) ?? [];
    }

    //#endregion

    //#endregion - static

    @serialize
    public get ReferenceId() {
        return this.$id;
    } //#archi-masterdata-saveData

    /** Returns the master-data instance of the referenced object - or null if not loaded */
    public get obj() {
        return Ref.isVoid(this) ? null : Ref.getObject<T>(this);
    }

    public constructor(st?: ServerType, id?: string) {
        this.init(st, id);
    }

    public init(st: ServerType | undefined, id: string | undefined) {
        this.ServerType = st || ServerType.Unknown;
        this.$id = id || emptyRef;
        return this;
    }

    public clear() {
        return this.init(ServerType.Unknown, emptyRef);
    }

    /** Automatically called by [JSON.Stringify](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#tojson_behavior) */
    toJSON() {
        //#archi-masterdata-saveData
        // Only fields decorated with @serialize or @serializeAs() are sent to the back-end
        return Serialize(this);
    }

    //#endregion - instance
}

/** Returns a deserializer function to be passed to a @deserializeAs() decorator from the *cerialize* lib,
 * for to instanciate a property being deserialized by processing a sub-property of it */
export function fromSubKey<T>(
    subKeyNameOrDottedPath: string,
    type?: INewable<T>,
    log = debug,
) {
    const fetch = (obj: object) =>
        CoreUtil.getObjectPathValue(obj, subKeyNameOrDottedPath, null as T);
    const process = type
        ? (obj: object) => Deserialize(fetch(obj), type) as T
        : fetch;
    return (obj: object) => {
        try {
            const res = Array.isArray(obj) ? obj.map(process) : process(obj);
            if (log) {
                CoreUtil.log(
                    'fromSubKey',
                    obj,
                    subKeyNameOrDottedPath,
                    type?.name,
                    res,
                );
            }
            return res;
        } catch (e) {
            CoreUtil.warn(e);
            return null;
        }
    };
}

/** Shortcut for `fromSubKey('Ref', type)` */
export function subRef<T>(type: INewable<T>, log = false) {
    return fromSubKey<T>('Ref', type, log);
}

/** Interface for an object producing a Ref (cf Ref.ts and meta-data.service) */
export interface IIsRef<T extends TBase> {
    /** Instanciates a Ref&lt;T&gt; object based on data retrieved during deserialization */
    asRef(): Ref<T>;
}

/** Given an array of producers, returns an array of Ref&lt;T&gt; objects */
export function asRefs<T extends TBase>(
    refProducers: IIsRef<T>[],
    keepNulls = false,
) {
    return (
        CollectionsHelper.filter(
            refProducers?.map((p) => p?.asRef()),
            undefined,
            !keepNulls,
        ) ?? []
    );
}

/** Ref-able deserialized data processing base class. Extracts a *$type* value. */
export abstract class CHasType implements IHas$type {
    @deserialize $type!: string;

    static OnDeserialized(instance: unknown, json: unknown) {
        if (debug) {
            CoreUtil.log('OnDeserialized', instance, json);
        }
    }
}

/** Ref-able deserialized data processing base class. Extracts *$type* and *$id* values */
@inheritSerialization(CHasType)
export abstract class CHasIdAndType
    extends CHasType
    implements IHasReferenceId
{
    @deserialize $id!: string;

    get ReferenceId() {
        return this.$id;
    }
}

/** Extracts a *$type* value, and an id from a *$ref* property,
 * and can produce a Ref&lt;T&gt; object from those values and the given ServerType value. */
@inheritSerialization(CHasType)
export abstract class CRefIdr<T extends TBase = TBase>
    extends CHasType
    implements IRef<T>, IIsRef<T>, IHasReferenceId
{
    @deserializeAs('$ref') $id!: string;
    @deserialize RefString!: string;
    abstract readonly serverType: ServerType;

    public get ServerType() {
        return this.serverType;
    }

    public get ReferenceId() {
        return this.$id;
    }

    asRef() {
        return new Ref<T>(this.serverType, this.$id);
    }
}

/** Extracts a *$type* value, and an id from a *$ref* sub-property,
 * and can produce a Ref&lt;T&gt; object from those values and the given ServerType value. */
@inheritSerialization(CRefIdr)
export abstract class CRefSubIdr<T extends TBase = TBase> extends CRefIdr<T> {
    override $type = '';
    @deserializeAs(fromSubKey('$ref')) override $id = '';
}

/** Base class for Ref-able data extraction. Can:
 * - produce a Ref&lt;T&gt; object,
 * - produce a T object
 * - recursively gather sub producers of T objects
 * */
@inheritSerialization(CHasIdAndType)
export abstract class CRefData<T extends TBase>
    extends CHasIdAndType
    implements IRef<T>, IIsRef<T>
{
    public get ServerType() {
        return this.serverType;
    }

    protected abstract readonly ctorType: INewable<T>;
    protected abstract readonly serverType: ServerType;

    constructor() {
        super();
    }

    /** To be overriden by subclass for *getData* to operate. Note: Order may be relevant.
     * Returns internal producers (*CRefData* instances that implement *asData()*) */
    protected abstract getProducers(): TProducer[];

    public asRef() {
        return new Ref<T>(this.serverType, this.$id);
    }

    /** Returns a map of (T object->parent) instanciated by this and internal producers, ordered internal-most first */
    public getData() {
        const parentByChild = new Map<TBase, TBase | undefined>();

        function visit(p: TProducer, parentData?: TBase) {
            if (!p) {
                return;
            }
            const data = p.asData?.();
            p.getProducers()?.forEach((cp) => visit(cp, data));
            if (data) {
                parentByChild.set(data, parentData);
            }
        }

        visit(this);
        return parentByChild;
    }
}
