<button
    *ngIf="!watching"
    (click)="watch()"
    dxyIconButton
    variant="tertiary"
    [disabled]="loading$ | async"
    [dxyTooltip]="'UI.EntityHeader.actions.watchTooltip' | translate"
>
    <i class="glyph-show"></i>
</button>

<button
    *ngIf="watching"
    (click)="unwatch()"
    dxyIconButton
    variant="tertiary"
    class="active"
    [disabled]="loading$ | async"
    [dxyTooltip]="'UI.EntityHeader.actions.unwatchTooltip' | translate"
>
    <i class="glyph-watch-on"></i>
</button>
