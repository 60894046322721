import {
    ChangeDetectionStrategy,
    Component,
    inject,
    input,
    OnInit,
    viewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { TranslationInputActionsComponent } from '@datagalaxy/webclient/multilingual/feature';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { FeatureFlagDirective } from '@datagalaxy/feature-flag';
import { AttributeFieldComponent } from '../../common/attribute-field-component.interface';
import { TextAttributeFieldStore } from './text-attribute-field-store';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

@Component({
    selector: 'dxy-text-attribute-field',
    templateUrl: './text-attribute-field.component.html',
    styleUrls: ['./text-attribute-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    providers: [TextAttributeFieldStore],
    imports: [
        DxyFieldTextComponent,
        FormsModule,
        TranslateModule,
        TranslationInputActionsComponent,
        DxyIconButtonDirective,
        FeatureFlagDirective,
    ],
})
export class TextAttributeFieldComponent
    implements OnInit, AttributeFieldComponent
{
    readonly store = inject(TextAttributeFieldStore);

    entity = input.required<EntityItem>();
    attributeMetaInfo = input.required<AttributeMetaInfo>();
    readonly = input<boolean>(false);
    mini = input<boolean>(false);

    field = viewChild(DxyFieldTextComponent);

    protected textMaxLength =
        ServerConstants.AttributeConstants.AttributeTextLimit;

    ngOnInit() {
        this.store.init({
            entity: this.entity(),
            attributePath: this.attributeMetaInfo().AttributePath,
            readonly: this.readonly(),
        });
        this.store.setAttributeMetaInfo(this.attributeMetaInfo());
        this.store.loadTranslations();
    }

    public onValueChange(value: string) {
        this.store.setUpdatedValue(value);
    }
    public async onFieldBlur(_$event: FocusEvent) {
        await this.store.persistUpdatedValue();
    }
    public onFieldFocus() {
        this.store.touch();
    }

    public focusField() {
        this.field()?.doFocus();
    }
}
