import { Component, HostBinding, input } from '@angular/core';
import { UiUtil } from '@datagalaxy/core-ui';
import { NgClass } from '@angular/common';

export interface IWorkspaceIcon {
    trigram: string;
    iconUrl?: string;
}

export type WorkspaceIconSize = 'xs' | 'small' | 'medium' | 'large';

@Component({
    standalone: true,
    selector: 'dxy-workspace-icon',
    templateUrl: './workspace-icon.component.html',
    styleUrls: ['./workspace-icon.component.scss'],
    imports: [NgClass],
})
export class WorkspaceIconComponent {
    public workspace = input.required<IWorkspaceIcon>();
    public size = input<WorkspaceIconSize>('medium');

    @HostBinding('class')
    private get sizeClass() {
        return this.size();
    }

    protected get trigramColorClass() {
        return UiUtil.getColorClassFromString(this.workspace().trigram);
    }
}
