import {
    deserializeAs,
    inheritSerialization,
    SerializableEnumeration,
    serialize,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { UserPublicData } from './public-user';

export enum LicenseLevel {
    Reader = 0,
    Explorer = 1,
    Steward = 2,
}
SerializableEnumeration(LicenseLevel);

@inheritSerialization(BaseServiceParameter)
export class BaseUserListParameter extends BaseServiceParameter {
    @serialize UserIds?: string[];
    @serialize Emails?: string[];
    @serialize IncludedLicenseLevels?: LicenseLevel[];
    @serialize StartIndex?: number;
    @serialize Size?: number;
    @serialize ExcludeDeleted?: boolean;
}

@inheritSerialization(BaseUserListParameter)
export class LoadKnownUsersParameter extends BaseUserListParameter {
    @serialize IncludedSpaceGuids?: string[];
}

@inheritSerialization(BaseServiceResult)
export class LoadKnownUsersResult extends BaseServiceResult {
    @deserializeAs(UserPublicData) Users!: UserPublicData[];
}
