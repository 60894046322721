import { patchState, signalStore, withHooks, withMethods } from '@ngrx/signals';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';
import { computed, inject } from '@angular/core';
import { Technology } from '@datagalaxy/technology/domain';
import { TechnologyService } from './technology.service';
import {
    setFulfilled,
    setPending,
    withRequestStatus,
} from '@datagalaxy/shared/signal-store-common';

export const TechnologyStore = signalStore(
    { providedIn: 'root' },
    withEntities<Technology>(),
    withRequestStatus(),
    withMethods((store) => ({
        get(technologyId: string) {
            return computed(() =>
                store
                    .entities()
                    .find((technology) => technology.id === technologyId),
            );
        },
    })),
    withHooks({
        async onInit(store, technologyService = inject(TechnologyService)) {
            patchState(store, setPending());
            const technologies = await technologyService.getTechnologies();
            patchState(store, setAllEntities(technologies), setFulfilled());
        },
    }),
);
