import { BaseService } from '@datagalaxy/core-ui';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { Injectable } from '@angular/core';
import {
    IEntityLinkIdentifier,
    ILinkedEntityInfo,
    ILinkedObject,
    IObjectLinkIdentifier,
} from '../shared/entity/linked-object.types';
import {
    EntityLinkTypeKind,
    EntityType,
    EntityTypeUtil,
    IEntityIdentifier,
    ObjectLinkType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { EntityService } from '../shared/entity/services/entity.service';
import {
    EntityLinkedObjectMode,
    ILinkedObjectModalInput,
    ILinkedObjectModalInputOptions,
    ILinkedObjectModalOutput,
} from '../shared/entity/linked-object-modal/linked-object-modal.types';
import { ToasterService } from '../services/toaster.service';
import { DataUtil } from '../shared/util/DataUtil';
import { AttributeDataService } from '../shared/attribute/attribute-data.service';
import { LinkedObjectModalComponent } from '../shared/entity/linked-object-modal/linked-object-modal.component';
import { DxyModalService } from '../shared/dialogs/DxyModalService';
import { ModelerDataUtil } from '../shared/util/ModelerDataUtil';
import { isApiError } from '@datagalaxy/data-access';
import {
    AddLinkAction,
    AddLinkedEntitiesParameter,
    AddLinkedEntitiesResult,
    DeleteLinkedEntitiesParameter,
    DeleteLinkedEntitiesResult,
    EntityLinkApiService,
    UpdateLinkAction,
} from '@datagalaxy/webclient/entity/data-access';
import {
    EntityIdentifier,
    EntityLinkUtils,
    EntityServerTypeUtils,
} from '@datagalaxy/webclient/entity/utils';
import { getLocalId } from '@datagalaxy/utils';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import {
    AttributeMetaInfo,
    AttributeMetaType,
} from '@datagalaxy/webclient/attribute/domain';
import { DgZone } from '@datagalaxy/domain';
import {
    EntityItem,
    GoldenLinkDto,
    LinkedDataGroup,
    LinkedDataItem,
} from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

@Injectable({ providedIn: 'root' })
export class EntityLinkService extends BaseService {
    //#region static

    public static isReverseObjectLinkType(olt: ObjectLinkType) {
        return olt < 1000 || olt > 2999
            ? undefined
            : olt >= 2000 && ObjectLinkType[olt] != undefined;
    }

    public static makeLinkedObjects(
        localEntityIdr: IEntityIdentifier,
        ldgs: LinkedDataGroup[],
    ) {
        return CollectionsHelper.flattenGroups(ldgs, (ldg) =>
            ldg.Items.map((ldi) =>
                EntityLinkService.makeLinkedObject(
                    localEntityIdr,
                    ldi,
                    ldg.UniversalObjectLinkType,
                ),
            ),
        );
    }
    public static makeLinkedObject(
        localEntity: IEntityIdentifier,
        ldi: LinkedDataItem,
        linkType: ObjectLinkType,
    ): ILinkedObject {
        const localEntityIdr = EntityIdentifier.from(localEntity);
        const {
            LinkedData: hd,
            LinkEntityData: {
                DataReferenceId: linkReferenceId,
                IsGoldenLink: isGoldenLink,
            },
        } = ldi;
        const linkedObjectIdr = EntityIdentifier.fromHdd(hd.Data);
        return {
            //#region linked object
            linkedObjectIdr,
            dgModule: DataUtil.getModuleFromServerType(hd.DataServerType),
            //#region IHasHddData
            HddData: hd,
            //#endregion
            //#region IHasTechnicalName
            DisplayName: hd.DisplayName,
            TechnicalName: hd.TechnicalName,
            //#endregion
            //#endregion - linked object

            linkType,
            linkReferenceId,
            isGoldenLink,
            linkIdr: {
                source: localEntityIdr,
                target: linkedObjectIdr,
                linkType,
                linkReferenceId,
            },

            localEntityIdr,
            flow: EntityLinkUtils.getLinkTypeFlow(linkType),
        };
    }

    //#endregion - static

    public constructor(
        private toasterService: ToasterService,
        private entityService: EntityService,
        private dxyModalService: DxyModalService,
        private entityLinkApi: EntityLinkApiService,
    ) {
        super();
    }

    public async getEntitiesGoldenLinks(
        spaceIdr: IWorkspaceIdentifier,
        referenceIds: string[],
    ): Promise<GoldenLinkDto[]> {
        const res = await this.entityLinkApi.getGoldenLinks(spaceIdr, {
            SourceGuids: referenceIds.map((referenceId) =>
                getLocalId(referenceId),
            ),
        });

        return res.goldenLinks;
    }

    public getEntityTypesAndLinkTypeKind(
        attributeMeta: AttributeMetaInfo,
        entityItem: EntityItem,
    ) {
        return this.getEntityTypesAndLinkTypeKindInternal(
            attributeMeta,
            entityItem,
        );
    }
    public getEntityTypesForFilterCriteria(
        attributeMeta: AttributeMetaInfo,
        dgZone: DgZone,
        dgModule: DgModule,
    ) {
        const { entityTypes } = this.getEntityTypesAndLinkTypeKindInternal(
            attributeMeta,
            undefined,
            { dgZone, dgModule },
        );
        this.debug &&
            this.log(
                'getEntityTypesForFilterCriteria-result',
                entityTypes?.map((et) => EntityType[et]),
            );
        return entityTypes;
    }

    public getEntityLinkTypeTranslateKey(
        entityLinkTypeValue: number,
        isReversed = false,
    ) {
        const olt = this.getUniversalObjectLinkType(
            entityLinkTypeValue,
            isReversed,
        );
        return olt ? `DgServerTypes.ObjectLinkType.${ObjectLinkType[olt]}` : '';
    }
    public getUniversalObjectLinkType(
        kind: EntityLinkTypeKind,
        isReversed = false,
    ) {
        return EntityTypeUtil.getUniversalObjectLinkType(kind, isReversed);
    }

    private getEntityTypesAndLinkTypeKindInternal(
        attributeMeta: AttributeMetaInfo,
        entityItem?: EntityItem,
        forFilterCriteria?: { dgZone: DgZone; dgModule: DgModule },
    ): { entityTypes: EntityType[]; kind?: EntityLinkTypeKind } {
        const attributeType = attributeMeta.AttributeType;

        if (attributeType == AttributeMetaType.EntityLinkShortcut) {
            this.log('getEntityTypesAndLinkTypeKind-EntityLinkShortcut');

            if (attributeMeta.ShortcutRestrictionRules?.length == 1) {
                this.log(
                    'getEntityTypesAndLinkTypeKind-EntityLinkShortcut-ShortcutRestrictionRules',
                );
                const singleRule = attributeMeta.ShortcutRestrictionRules[0];
                const universalType = this.getUniversalObjectLinkType(
                    singleRule.Kind,
                    false,
                );
                const entityTypes =
                    universalType == singleRule.UniversalObjectLinkType
                        ? singleRule.AllowedTargetTypes
                        : singleRule.AllowedSourceTypes;
                const kind = singleRule.Kind;
                return { entityTypes, kind };
            }

            if (!entityItem) {
                this.warn('no entityItem');
                return;
            }
            const mapping = EntityTypeUtil.getMapping(entityItem.EntityType);
            const entityTypeKinds = mapping.NonHierarchicalLinkRules.map(
                (a) => a.EntityLinkTypeKind,
            ).filter((a) => a != EntityLinkTypeKind.Unknown);
            const kind = attributeMeta.EntityLinkTypeKinds.find((k) =>
                entityTypeKinds.includes(k),
            );

            const isReversed = attributeMeta.IsReversedEntityLink;
            const tldi = EntityTypeUtil.getLinkTypeInfosForLinkTypeKind(
                kind,
            ).find(
                (d) =>
                    d.EntityLinkTypeKind === kind &&
                    d.IsReverseEntityLink === isReversed,
            );
            const entityTypes = tldi.TargetEntityTypes;

            return { entityTypes, kind };
        }

        if (forFilterCriteria) {
            return this.getEntityTypesForFilterCriteriaInternal(
                attributeMeta,
                forFilterCriteria.dgZone,
                forFilterCriteria.dgModule,
            );
        }

        this.log('getEntityTypesAndLinkTypeKind-default');
        return { entityTypes: [] };
    }

    private getEntityTypesForFilterCriteriaInternal(
        attributeMeta: AttributeMetaInfo,
        dgZone?: DgZone,
        dgModule?: DgModule,
    ) {
        const { serverType, AttributeType: attributeType } = attributeMeta;

        const log = (logId: string, ...args: unknown[]) =>
            this.log('getEntityTypesForFilterCriteriaInternal', logId, ...args);

        this.debug &&
            log('in', {
                dgZone,
                dgZone_s: DgZone[dgZone],
                dgModule,
                dgModule_s: DgModule[dgModule],
                serverType,
                serverType_s: ServerType[serverType],
                attributeType,
                attributeType_s: AttributeMetaType[attributeType],
                IsAllTypes: attributeMeta.IsAllTypes,
                attributeMeta,
            });

        let serverTypes = [serverType];

        const result = (
            entityTypes: EntityType[],
            logId: string,
            ...args: unknown[]
        ) => {
            if (this.debug) {
                log(`out-${logId}`, ...args, {
                    entityTypes,
                    entityTypes_s: entityTypes?.map((et) => EntityType[et]),
                    serverTypes,
                    serverTypes_s: serverTypes?.map((st) => ServerType[st]),
                });
            }
            return { entityTypes };
        };

        if (dgZone != undefined || dgModule != undefined) {
            if (dgZone == DgZone.Search) {
                serverTypes = EntityServerTypeUtils.firstClassEntityServerTypes;
            } else if (attributeMeta.IsAllTypes && dgModule) {
                serverTypes =
                    dgModule == DgModule.Catalog
                        ? ModelerDataUtil.modelerServerTypes
                        : [DataUtil.getDefaultServerTypeFromModule(dgModule)];
            } else if (serverType == ServerType.Model) {
                serverTypes = ModelerDataUtil.modelerServerTypes;
            } else {
                log('else');
            }

            if (attributeType == AttributeMetaType.ObjectLink) {
                const objectLinkType = attributeMeta.ObjectLinkType;
                const entityTypes =
                    EntityTypeUtil.getTargetEntityTypesFromObjectLinkTypeAndServerType(
                        objectLinkType,
                        serverTypes,
                        this.debug,
                    );
                return result(
                    entityTypes,
                    'ObjectLink',
                    ObjectLinkType[objectLinkType],
                );
            }

            if (attributeType == AttributeMetaType.AllLinkedData) {
                const entityTypes =
                    EntityTypeUtil.getTargetEntityTypesFromAllObjectLinkTypeAndServerType(
                        serverTypes,
                        this.debug,
                    );
                return result(entityTypes, 'AllLinkedData');
            }
        }

        if (
            AttributeDataService.isLogicalParentAttributeKey(
                attributeMeta.AttributeKey,
            )
        ) {
            const entityTypes =
                EntityTypeUtil.getAllowedParentEntityTypesFromServerType(
                    serverTypes,
                    this.debug,
                );
            return result(
                entityTypes,
                'isLogicalParentAttributeKey',
                attributeMeta.AttributeKey,
            );
        }

        if (
            attributeMeta.AttributePath ==
            ServerConstants.PropertyName.FilterDescendentId
        ) {
            const entityTypes = EntityTypeUtil.getEntityTypes(serverTypes);
            return result(entityTypes, 'FilterDescendentId');
        }

        return result([], 'default');
    }

    public getAvailableLinkTypes(entityData: { EntityType: EntityType }) {
        return EntityLinkService.getLinkInfos(entityData.EntityType, false);
    }

    public async getAvailableLinkTypesForEntities(entities: EntityItem[]) {
        const targetTypes = entities.map((a) => a.EntityType);
        const targetIds = entities.map((a) => a.DataReferenceId);
        const result = await this.entityService.getAvailableLinkTypes(
            null,
            targetTypes,
            targetIds,
        );
        return result.filter(
            (link) => !this.isDataProcessingLink(link.ObjectLinkType),
        );
    }

    /** Uses only ObjectLinkTypes (no EntityLink) */
    public getAvailableObjectLinkTypeByEntityType(entityType: EntityType) {
        return EntityLinkService.getLinkInfos(entityType, true);
    }

    public getAvailableLinkTypesForEdit(
        entityData: EntityItem,
        linkedDataGroups: LinkedDataGroup[],
        excludeEntityLinks?: boolean,
        onlyUniversalLinkTypes?: ObjectLinkType[],
    ) {
        let availableLinkTypes = CoreUtil.cloneDeep(
            EntityLinkService.getLinkInfos(
                entityData.EntityType,
                excludeEntityLinks,
            ),
        );
        if (onlyUniversalLinkTypes) {
            availableLinkTypes = availableLinkTypes.filter((lt) =>
                onlyUniversalLinkTypes.includes(lt.UniversalObjectLinkType),
            );
        }
        availableLinkTypes.forEach(
            (lt) =>
                (lt.ExcludedEntitiesIds =
                    linkedDataGroups
                        ?.find(
                            (ldg) =>
                                ldg.ObjectLinkType == lt.ObjectLinkType &&
                                ldg.EntityLinkType == lt.EntityLinkType,
                        )
                        ?.Items.map((ldi) => ldi.DataReferenceId) ?? []),
        );
        return availableLinkTypes;
    }

    public async openChangeLinkTypeModal(
        source: EntityItem,
        target: EntityItem,
        linkToUpdate: IObjectLinkIdentifier,
    ): Promise<IObjectLinkIdentifier> {
        const res = await this.openLinkCreationModal(source, undefined, {
            allowExistingLinks: true,
            includeEntityLinks: true,
            targetEntityDataList: [target],
            modalTitleTranslateKey: 'UI.DiagramEdge.changeLinkType',
            linkToUpdate,
        });
        return res?.linkIdrs?.[0];
    }

    public async openLinkCreationModal(
        entityData: EntityItem,
        linkedDataResult?: LinkedDataGroup[],
        options?: ILinkedObjectModalInputOptions & {
            allowExistingLinks?: boolean;
            includeEntityLinks?: boolean;
            onlyUniversalLinkTypes?: ObjectLinkType[];
            excludedUniversalLinkTypes?: ObjectLinkType[];
        },
    ): Promise<ILinkedObjectModalOutput> {
        this.log('openLinkCreationModal', entityData, linkedDataResult);

        const linkedDataGroups =
            linkedDataResult ?? (await this.getLinkedDataGroups(entityData));
        const availableLinkTypes = this.getAvailableLinkTypesForEdit(
            entityData,
            linkedDataGroups,
            !options?.includeEntityLinks,
            options?.onlyUniversalLinkTypes,
        );

        return await this.dxyModalService.open<
            LinkedObjectModalComponent,
            ILinkedObjectModalInput,
            ILinkedObjectModalOutput
        >({
            loadComponent: () =>
                import(
                    '../shared/entity/linked-object-modal/linked-object-modal.component'
                ).then((m) => m.LinkedObjectModalComponent),
            data: {
                entityDataList: [entityData],
                mode: options?.allowExistingLinks
                    ? EntityLinkedObjectMode.CreateOrGetExisting
                    : EntityLinkedObjectMode.Create,
                availableLinkTypes,
                modalTitleTranslateKey: options?.modalTitleTranslateKey,
                targetEntityDataList: options?.targetEntityDataList,
                linkToUpdate: options?.linkToUpdate,
                noCheckAccessRights: options?.noCheckAccessRights,
                onlyExistingLinks: options?.onlyExistingLinks,
            },
        });
    }

    public async getLinkIdentifiers(
        entityIdr: IEntityIdentifier,
    ): Promise<ILinkedEntityInfo> {
        entityIdr = EntityIdentifier.from(entityIdr);
        const groups = await this.getLinkedDataGroups(entityIdr);
        const entityId = entityIdr.ReferenceId;
        return {
            entity: entityIdr,
            asSource: CollectionsHelper.flattenGroups(groups, (ldg) =>
                ldg.Items.filter(
                    (ldi) =>
                        ldi.LinkEntityData.Source.DataReferenceId == entityId,
                ).map(
                    (ldi) =>
                        ({
                            source: entityIdr,
                            target: EntityIdentifier.fromIHierarchicalData(
                                ldi.LinkedData,
                            ),
                            linkType: ldg.UniversalObjectLinkType,
                            linkReferenceId: ldi.LinkEntityData.DataReferenceId,
                        }) as IEntityLinkIdentifier,
                ),
            ),
            asTarget: CollectionsHelper.flattenGroups(groups, (ldg) =>
                ldg.Items.filter(
                    (ldi) =>
                        ldi.LinkEntityData.Target.DataReferenceId == entityId,
                ).map(
                    (ldi) =>
                        ({
                            target: entityIdr,
                            source: EntityIdentifier.fromIHierarchicalData(
                                ldi.LinkedData,
                            ),
                            linkType: ldg.UniversalObjectLinkType,
                            linkReferenceId: ldi.LinkEntityData.DataReferenceId,
                        }) as IEntityLinkIdentifier,
                ),
            ),
        };
    }

    // #Archi-optim-back-end
    /** Returns true if any link exists between the given entities.
     * Note: IEntityIdentifier.EntityType is not used, so any value is OK */
    public async isAnyExistingEntityLink(
        entity: IEntityIdentifier,
        other: IEntityIdentifier,
    ) {
        const res = await this.entityService.getEntityLinks(entity);
        if (!res.Groups?.length) {
            return false;
        }
        const entityId = entity.ReferenceId;
        const otherId = other.ReferenceId;
        return res.Groups.some((g) =>
            g.Items.some(
                (it) =>
                    (it.LinkEntityData.Source.DataReferenceId == entityId &&
                        it.LinkEntityData.Target.DataReferenceId == otherId) ||
                    (it.LinkEntityData.Source.DataReferenceId == otherId &&
                        it.LinkEntityData.Target.DataReferenceId == entityId),
            ),
        );
    }

    /** Note: entityIdentifier.EntityType is not used, so any value is OK */
    public async getLinkedDataGroups(entityIdentifier: IEntityIdentifier) {
        const res = await this.entityService.getEntityLinks(entityIdentifier);
        return res.Groups;
    }

    public async getLinkedData(entityIdentifier: IEntityIdentifier) {
        return await this.entityService.getEntityLinks(
            entityIdentifier,
            null,
            true,
        );
    }

    public async removeGoldenLink(entityLinkId: string) {
        return this.entityService.updateEntityLink(
            entityLinkId,
            UpdateLinkAction.RemoveGoldenLink,
        );
    }
    public async updateLinkType(
        entityLinkId: string,
        objectLinkType: ObjectLinkType,
    ) {
        return this.entityService.updateEntityLink(
            entityLinkId,
            UpdateLinkAction.UpdateObjectLinkType,
            objectLinkType,
        );
    }

    public async linkData(
        linkType: ObjectLinkType,
        sourceEntitiesIds: string[],
        targetEntitiesIds: string[],
        versionId: string,
        addLinkAction: AddLinkAction = AddLinkAction.Append,
        linkedEntityAttributes?: string[],
        updatedEntityIncludedAttributesFilter?: string[],
    ): Promise<AddLinkedEntitiesResult> {
        const parameter = AddLinkedEntitiesParameter.createModern(
            sourceEntitiesIds,
            linkType,
            targetEntitiesIds,
            null,
            linkedEntityAttributes,
            updatedEntityIncludedAttributesFilter,
            addLinkAction,
        );
        parameter.VersionId = versionId;

        try {
            return await this.entityService.addEntityReferencesGeneric(
                parameter,
                true,
            );
        } catch (e) {
            if (isApiError(e) && e.error?.ErrorDetails) {
                this.toasterService.warningToast({
                    messageKey: e.error.ErrorDetails,
                });
                return;
            }
            throw e;
        }
    }

    public async bulkUnlinkData(
        universalObjectLinkType: ObjectLinkType,
        sourceEntitiesIds: string[],
        sourceServerType: ServerType,
        targetEntitiesIds: string[],
        versionId: string,
    ): Promise<DeleteLinkedEntitiesResult> {
        const parameter = DeleteLinkedEntitiesParameter.createModern(
            sourceEntitiesIds,
            universalObjectLinkType,
            targetEntitiesIds,
        );
        parameter.VersionId = versionId;

        try {
            return await this.entityService.deleteEntityReferencesGeneric(
                parameter,
            );
        } catch (result) {
            this.toasterService.warningToast({
                messageKey: result.data?.errorDetailsKey,
            });
            return null;
        }
    }

    private static getLinkInfos(
        entityType: EntityType,
        excludeEntityLink: boolean,
    ) {
        const mapping = EntityTypeUtil.getMapping(entityType);
        return mapping.NonHierarchicalLinkRules.filter(
            (link) =>
                !excludeEntityLink ||
                (excludeEntityLink &&
                    link.ObjectLinkType != ObjectLinkType.EntityLink),
        );
    }
    private isDataProcessingLink(linkType: ObjectLinkType) {
        return [
            ObjectLinkType.HasInput,
            ObjectLinkType.HasOutput,
            ObjectLinkType.IsOutputOf,
            ObjectLinkType.IsInputOf,
        ].includes(linkType);
    }

    public async unlinkData(
        sourceEntity: IEntityIdentifier,
        linkItem: { linkReferenceId: string; linkType: ObjectLinkType },
    ) {
        try {
            await this.entityService.deleteEntityLink(
                sourceEntity.ReferenceId,
                linkItem.linkReferenceId,
                sourceEntity.VersionId,
                linkItem.linkType,
            );
        } catch (error) {
            this.toasterService.warningToast({
                messageKey: error.data?.errorDetailsKey,
            });
        }
    }
    public async deleteLink(
        sourceEntity: IEntityIdentifier,
        targetEntity: IEntityIdentifier,
        objectLinkType: ObjectLinkType,
    ) {
        return this.entityService.deleteEntityLink(
            sourceEntity.ReferenceId,
            targetEntity.ReferenceId,
            sourceEntity.VersionId,
            objectLinkType,
        );
    }

    /** returns true if the given entity is linked to any other entity */
    public async isEntityLinked(entityIdr: IEntityIdentifier) {
        return this.entityService.isEntityLinked(entityIdr);
    }
}
