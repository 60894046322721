import {
    BaseMetaAttributeValue,
    MultiSelectAttributeValue,
} from '@datagalaxy/meta-attribute-value-domain';

export function buildMultiSelectValue(
    baseMetaAttributeValue: BaseMetaAttributeValue,
    choiceIds: string[],
): MultiSelectAttributeValue {
    return {
        ...baseMetaAttributeValue,
        choiceIds,
    };
}
