import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FunnelStateService } from '../store/funnel-state.service';
import { FunnelStepperComponent } from '../funnel-stepper/funnel-stepper.component';
import { Observable, of, takeUntil } from 'rxjs';
import { CurrentFunnelStep } from '../store/current-funnel-step';
import { FunnelModalActionDirective } from './funnel-modal-action.directive';
import { ValidatedFunnelStepComponent } from '../funnel-step';
import { FunnelAction } from '../funnel-action';
import { MatDialogModule } from '@angular/material/dialog';
import {
    DxyButtonDirective,
    DxyIconButtonDirective,
} from '@datagalaxy/ui/buttons';

@Component({
    selector: 'dxy-funnel-modal',
    standalone: true,
    imports: [
        CommonModule,
        FunnelStepperComponent,
        FunnelModalActionDirective,
        MatDialogModule,
        DxyButtonDirective,
        DxyIconButtonDirective,
    ],
    templateUrl: './funnel-modal.component.html',
    styleUrls: ['./funnel-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunnelModalComponent implements AfterViewInit {
    private funnelStateService = inject(FunnelStateService);
    private cd = inject(ChangeDetectorRef);

    protected currentStep$ = this.funnelStateService.selectCurrentStep();
    protected currentStepValid$?: Observable<boolean>;
    protected actions: FunnelAction[] = [];
    private invisible$ = this.funnelStateService.selectClosed();

    @ViewChild('stepContent', { read: ViewContainerRef })
    private stepContent?: ViewContainerRef;

    ngAfterViewInit() {
        this.currentStep$
            .pipe(takeUntil(this.invisible$))
            .subscribe((currentStep) => this.updateModalContent(currentStep));
    }

    protected closeModal() {
        this.funnelStateService.stop();
    }

    private updateModalContent(currentStep: CurrentFunnelStep) {
        const componentType = currentStep.step?.componentType;
        if (!componentType) {
            return;
        }
        this.stepContent?.clear();
        const stepComponentRef =
            this.stepContent?.createComponent(componentType);
        const instance = stepComponentRef?.instance;
        if (!instance) {
            return;
        }
        this.currentStepValid$ =
            (instance as unknown as ValidatedFunnelStepComponent)?.valid$ ??
            of(true);
        this.actions = instance.actions;
        this.cd.detectChanges();
    }
}
