import { inject, Injectable } from '@angular/core';
import { UserSettingValueService } from '@datagalaxy/users/data-access';
import {
    EntityCardDisplayMode,
    MetaObjectCardDisplayMode,
} from './meta-object-type-card-display.types';

@Injectable({
    providedIn: 'root',
})
export class MetaObjectTypeCardDisplayService {
    private userSettingsValueService = inject(UserSettingValueService);

    public async getUserObjectCardDisplayMode(): Promise<MetaObjectCardDisplayMode> {
        const res = await this.userSettingsValueService.getUserSettingValue(
            'display',
            'entity-card',
        );

        return this.legacyToNewAdapter(
            EntityCardDisplayMode[res as keyof typeof EntityCardDisplayMode],
        );
    }

    public async setUserObjectCardDisplayMode(
        displayMode?: MetaObjectCardDisplayMode,
    ): Promise<void> {
        const mode = this.newToLegacyAdapter(displayMode);

        await this.userSettingsValueService.setUserSettingValue(
            'display',
            'entity-card',
            EntityCardDisplayMode[mode],
        );
    }

    private legacyToNewAdapter(
        value?: EntityCardDisplayMode,
    ): MetaObjectCardDisplayMode {
        switch (value) {
            case EntityCardDisplayMode.GlyphWithChip:
                return 'glyph-with-chip';
            case EntityCardDisplayMode.GlyphOnly:
                return 'glyph-only';
            case EntityCardDisplayMode.TechnoOnly:
                return 'techno-only';
            case EntityCardDisplayMode.TechnoWithChip:
                return 'techno-with-chip';
            default:
                return 'techno-with-chip';
        }
    }

    private newToLegacyAdapter(
        value?: MetaObjectCardDisplayMode,
    ): EntityCardDisplayMode {
        switch (value) {
            case 'glyph-with-chip':
                return EntityCardDisplayMode.GlyphWithChip;
            case 'glyph-only':
                return EntityCardDisplayMode.GlyphOnly;
            case 'techno-only':
                return EntityCardDisplayMode.TechnoOnly;
            case 'techno-with-chip':
                return EntityCardDisplayMode.TechnoWithChip;
            default:
                return EntityCardDisplayMode.TechnoWithChip;
        }
    }
}

export function createMetaObjectTypeCardDisplayServiceMockProvider(options?: {
    delay?: number;
}) {
    const delay = options?.delay ?? 500;

    return {
        provide: MetaObjectTypeCardDisplayService,
        useValue: {
            getUserObjectCardDisplayMode() {
                return new Promise((resolve) => {
                    setTimeout(() => resolve('glyph-with-chip'), delay);
                });
            },
        },
    };
}
