import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { CurrentSearch } from '../../../../search/models/CurrentSearch';
import { TranslateService } from '@ngx-translate/core';
import { AttributeFilterModel } from '../attribute-filter/attributeFilterModel';
import { AttributeFilterService } from '../attribute-filter.service';
import { FilterUtil } from '../../../util/FilterUtil';
import { AttributeFilterCarouselComponent } from '../attribute-filter-carousel/attribute-filter-carousel.component';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { Filter } from '@datagalaxy/filter-domain';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-attribute-quick-filters',
    templateUrl: './attribute-quick-filters.component.html',
    styleUrls: ['./attribute-quick-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, AttributeFilterCarouselComponent, SpinnerComponent],
})
export class AttributeQuickFiltersComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() currentSearch: CurrentSearch;
    @Input() quickFilters: Filter[];
    @Input() isLoading: boolean;
    @Input() dataTrackerId?: string;
    @Input() hideLookingFor: boolean;
    @Input() displayMode: 'default' | 'horizontal' = 'default';
    @Output() onSelectFilter = new EventEmitter<AttributeFilterModel>();

    @ViewChild(AttributeFilterCarouselComponent)
    filterCarousel: AttributeFilterCarouselComponent;

    @HostBinding('class.horizontal') get horizontal() {
        return this.displayMode == 'horizontal';
    }
    public get isSearchEmpty() {
        return this.currentSearch.isSearchEmpty;
    }
    public get showCarousel() {
        return !this.isLoading && !!this.filters?.length;
    }
    public get placeholderText() {
        return this.translate.instant(
            this.isSearchEmpty
                ? 'UI.QuickFilters.lookingFor'
                : 'UI.QuickFilters.narrowSearch',
        );
    }

    public filters: AttributeFilterModel[];

    constructor(
        private translate: TranslateService,
        private attributeFilterService: AttributeFilterService,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(changes, ['currentSearch', 'quickFilters'], () =>
            this.setupFilters(),
        );
        super.onChange(changes, 'isLoading', () => this.onLoading());
    }

    ngOnInit() {
        this.setupFilters().then();
    }

    //#region API
    public refresh() {
        this.filterCarousel.refresh();
    }
    //#endregion API

    public filterHasValue(afm: AttributeFilterModel) {
        return (
            !!afm.getValuesAsArray()?.length ||
            FilterUtil.isValuelessOperator(afm.operator)
        );
    }

    public onSelectFilterItem(filter: AttributeFilterModel) {
        if (!this.filterHasValue(filter)) {
            filter.isFormOpen = true;
        }
        filter.isFromQuickFilter = true;
        this.onSelectFilter.emit(filter);
    }

    public async setupFilters() {
        await this.attributeFilterService.loadServerDataForFilters(
            this.currentSearch,
            this.quickFilters,
        );
        this.filters = AttributeFilterService.getFilterItemsFromFilters(
            this.currentSearch,
            this.quickFilters,
            false,
        );
        this.cd.detectChanges();
    }

    private onLoading() {
        if (this.isLoading) {
            this.filters = null;
        }
    }
}
