import { BaseMetaAttributeValue } from '@datagalaxy/meta-attribute-value-domain';

export function buildGenericValue<
    TAttributeValue extends BaseMetaAttributeValue & { value: T },
    T = unknown,
>(baseMetaAttributeValue: BaseMetaAttributeValue, value: T): TAttributeValue {
    return {
        ...baseMetaAttributeValue,
        value,
    } as TAttributeValue;
}
