import { inject, Injectable } from '@angular/core';
import { TechnologyDto } from './technology.dto';
import { LanguageService } from '@datagalaxy/translate';
import { Technology } from '@datagalaxy/technology/domain';
import { TechnologyUrlService } from './technology-url.service';

@Injectable({
    providedIn: 'root',
})
export class TechnologyAdapter {
    private languageService = inject(LanguageService);
    private technologyUrlService = inject(TechnologyUrlService);

    public mapDtoToTechnology(dto: TechnologyDto): Technology {
        const currentLanguage = this.languageService.getLanguage();
        const description =
            currentLanguage === 'fr' ? dto.DescriptionFr : dto.DescriptionEn;

        const hash = dto.ImageHash ?? dto.SvgImageHash;
        const imageUrl = hash
            ? this.technologyUrlService.getImageUrl(hash)
            : undefined;

        return {
            displayName: dto.DisplayName,
            id: dto.TechnologyCode,
            modules: dto.Modules,
            description,
            imageUrl,
            creationTime: dto.CreationTime,
            creationUserId: dto.CreationUserId,
            lastModificationTime: dto.LastModificationTime,
            lastModificationUserId: dto.LastModificationUserId,
            lastUsageTime: dto.LastUsageTime,
            lastUsageUserId: dto.LastUsageUserId,
        };
    }
}
