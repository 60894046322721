import { Injectable } from '@angular/core';
import { ClientLoginResult } from '@datagalaxy/webclient/client/data-access';
import { BaseService } from '@datagalaxy/core-ui';
import { RealTimeCommService } from '../services/realTimeComm.service';
import { ClientStorageService } from '../services/clientStorage.service';
import { AppDataService } from '../services/app-data.service';
import { ModelerService } from '../modeler/services/modeler.service';
import { UserService } from '../services/user.service';
import { SecurityService } from '../services/security.service';
import { NotificationService } from '../notifications/notification.service';
import { ClientAdminAttributeService } from '../client-admin-attribute/client-admin-attribute.service';
import { AttributeDataService } from '../shared/attribute/attribute-data.service';
import { TechnologyService } from '../client-admin/services/technology.service';
import { ScreenService } from '../screens/screen.service';
import { SearchService } from '../search/search.service';
import { SuggestionService } from '../suggestions/suggestion.service';
import { ViewTypeService } from '../services/viewType.service';
import { AnalyticService } from '../services/analytic.service';
import { PendoService } from '../services/pendo.service';
import { TeamService } from '../team/team.service';
import {
    DenodoSampleService,
    MasterDataService,
} from '@datagalaxy/webclient/modeler/data-access';
import { Subject } from 'rxjs';
import {
    LoadSystemDataResult,
    SystemApiService,
} from '@datagalaxy/webclient/system/data-access';
import { EntityTypeUtil } from '@datagalaxy/dg-object-model';
import { BaseServiceParameter } from '@datagalaxy/data-access';
import {
    CurrentUser,
    CurrentUserService,
    UserInfoDto,
} from '@datagalaxy/webclient/user/data-access';
import { ModuleStore } from '../module/module.store';
import { OpenFeatureInitializer } from '@datagalaxy/feature-flag';
import { CoreUtil } from '@datagalaxy/core-util';
import { WorkspaceStore } from '@datagalaxy/webclient/workspace/data-access';
import { InactivityLogoutService } from '@datagalaxy/webclient/auth/feature';
import {
    ClientAccessFlags,
    ClientInfoDto,
} from '@datagalaxy/webclient/client/domain';

@Injectable({ providedIn: 'root' })
export class ClientServiceManagerService extends BaseService {
    private cleanupStarted$ = new Subject<void>();
    public doCleanup$ = this.cleanupStarted$.asObservable();

    constructor(
        private realTimeCommService: RealTimeCommService,
        private clientStorageService: ClientStorageService,
        private appDataService: AppDataService,
        private modelerService: ModelerService,
        private userService: UserService,
        private securityService: SecurityService,
        private notificationService: NotificationService,
        private attributeAdminService: ClientAdminAttributeService,
        private attributeDataService: AttributeDataService,
        private technologyApiService: TechnologyService,
        private screenService: ScreenService,
        private searchService: SearchService,
        private suggestionService: SuggestionService,
        private viewTypeService: ViewTypeService,
        private analyticService: AnalyticService,
        private pendoService: PendoService,
        private teamService: TeamService,
        private masterDataService: MasterDataService,
        private currentUserService: CurrentUserService,
        private systemApiService: SystemApiService,
        private openFeatureInitializer: OpenFeatureInitializer,
        private moduleStore: ModuleStore,
        private workspaceStore: WorkspaceStore,
        private inactivityLogoutService: InactivityLogoutService,
        private denodoSample: DenodoSampleService,
    ) {
        super();
    }

    public async initializeServices(
        userInfo: CurrentUser,
        clientInfo: ClientInfoDto,
    ) {
        await this.initSystemData();

        this.attributeAdminService.init();
        this.attributeDataService.init();
        this.screenService.init();

        // Init client security data
        this.securityService.setUserSecurity(
            clientInfo.AccessFlags.map((af) => ClientAccessFlags[af]),
            userInfo.SecurityData,
        );
        this.appDataService.setIsSingleWorkspaceClient(
            this.securityService.isSingleWorkspaceClient(),
        );
        this.appDataService.setForMonitoring({
            isClientAdmin: this.securityService.isCurrentUserClientAdmin(),
            isOnlineConnectorEnabled:
                this.securityService.isOnlineConnectorEnabled(),
            communicationSettings:
                await this.userService.getUserCommunicationSettings(),
        });

        // Set view type
        this.viewTypeService.viewType =
            this.appDataService.clientDefaultViewType;
        await this.viewTypeService.loadPreference();

        // not awaited
        this.realTimeCommService.login({
            currentClientId: clientInfo.ClientId,
            currentClientName: clientInfo.FriendlyName,
            userFullName: userInfo.FullName,
            userId: userInfo.UserId,
            userSessionId: '', // TODO auth: Remove ?
        });

        this.inactivityLogoutService.init(clientInfo.UserSessionTimeoutMinutes);

        this.debug && console.time('post-login');
        try {
            // We need to load user data first. notificationService init needs users to be loaded
            await this.userService.loadClientUserData();
            await Promise.allSettled([
                this.workspaceStore.loadSpacesAsync(),
                this.analyticService
                    .initAsync()
                    .then(() => this.analyticService.login()),
                this.searchService.initAsync(),
                this.suggestionService.initAsync(),
                this.technologyApiService.initAsync(),
                this.notificationService.initAsync(
                    clientInfo.NotificationRealTimeBehavior,
                ),
                this.openFeatureInitializer.init(clientInfo.ClientId),
                this.denodoSample.init(),
            ]);
            this.loadAccessibleModules();
            this.debug && console.timeEnd('post-login');
        } catch (err) {
            CoreUtil.warn('post-login', err);
        }
    }

    private loadAccessibleModules() {
        const currentSpace = this.workspaceStore.currentSpace;
        this.moduleStore.loadAccessibleModules(
            currentSpace.spaceId,
            currentSpace.versionId,
        );
    }

    public async initializeServicesLegacy(
        clientLoginResult: ClientLoginResult,
        currentClientId: string,
    ) {
        this.currentUserService.init({
            userInfo: {
                CurrentClientId: this.appDataService.clientId,
                UserId: this.appDataService.currentUserId,
            } as UserInfoDto,
            clientInfo: {
                DefaultSpaceUid: clientLoginResult.DefaultSpaceUid,
                ClientId: currentClientId,
            } as ClientInfoDto,
        });
        this.appDataService.setConnectionInfos(
            currentClientId,
            clientLoginResult,
        );
        this.viewTypeService.viewType =
            this.appDataService.clientDefaultViewType;

        this.clientStorageService.storeCookieOnLogin(
            this.appDataService.getCookieLoginData(),
        );

        // Init data containers and services
        this.appDataService.init(false);
        this.attributeAdminService.init();
        this.attributeDataService.init();
        this.screenService.init();

        // Init client security data
        this.securityService.setUserSecurity(
            clientLoginResult.ClientAccessFlags,
            clientLoginResult.SecurityData,
        );
        this.appDataService.setIsSingleWorkspaceClient(
            this.securityService.isSingleWorkspaceClient(),
        );
        this.appDataService.setForMonitoring({
            isClientAdmin: this.securityService.isCurrentUserClientAdmin(),
            isOnlineConnectorEnabled:
                this.securityService.isOnlineConnectorEnabled(),
            communicationSettings:
                await this.userService.getUserCommunicationSettings(),
        });

        // Set view type
        await this.viewTypeService.loadPreference();

        // not awaited
        this.realTimeCommService.login(
            this.appDataService.getRealTimeLoginData('login'),
        );

        this.debug && console.time('post-login');
        try {
            // We need to load user data first. notificationService init needs users to be loaded
            await this.userService.loadClientUserData();
            await Promise.allSettled([
                this.workspaceStore.loadSpacesAsync(),
                this.analyticService
                    .initAsync()
                    .then(() => this.analyticService.login()),
                this.searchService.initAsync(),
                this.suggestionService.initAsync(),
                this.technologyApiService.initAsync(),
                this.notificationService.initAsync(
                    clientLoginResult.NotificationRealTimeBehavior,
                ),
                this.openFeatureInitializer.init(currentClientId),
                this.denodoSample.init(),
            ]);
            this.loadAccessibleModules();
            this.debug && console.timeEnd('post-login');
        } catch (err) {
            CoreUtil.warn('post-login', err);
        }
    }

    public async cleanupServices() {
        this.cleanupStarted$.next();
        try {
            await this.realTimeCommService.logout();
        } finally {
            this.pendoService.clearSession();
            this.teamService.clearCache();
            await this.analyticService.reset();
            this.clientStorageService.updateCookieOnLogout();
            this.appDataService.clearUserSessionId();
            this.appDataService.init(true);
            this.masterDataService.clear(false);
        }
    }

    private async initSystemData() {
        const lsdr: LoadSystemDataResult =
            await this.systemApiService.loadSystemData(
                new BaseServiceParameter(),
            );
        this.masterDataService.setJsonTypes(lsdr.JsonTypes);
        this.modelerService.setSystemDataTypeSettings(
            lsdr.SystemDataTypeSettings,
        );
        EntityTypeUtil.initMappings(lsdr.EntityTypeMappings);
        this.userService.setSystemUsersGroups(lsdr.SystemUserGroups);
        this.securityService.setSystemRoles(lsdr.SystemRoles);
    }
}
