<ng-container *ngIf="!isTooManyUsers">
    <div *ngFor="let ud of usersData" [class.disabled]="ud.disabled">
        <dxy-user-badge-cell
            [userData]="ud.userTag"
            [dxyTooltip]="ud.name"
            [mini]="mini"
        ></dxy-user-badge-cell>
    </div>
</ng-container>
<div class="text-container">
    <span
        class="field-name"
        translate
        [translateParams]="{ count: usersCount }"
        >{{ fieldKey }}</span
    >
    <span *ngIf="isUniqueUser" class="user-data">{{ uniqueUserName }}</span>
    <span *ngIf="!isUniqueUser" class="user-data"
        >{{ usersCount }}
        <span translate>UI.EntityHeader.data.lblPersonsCount</span></span
    >
</div>
