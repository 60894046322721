import {
    BaseMetaAttributeValue,
    SelectAttributeValue,
} from '@datagalaxy/meta-attribute-value-domain';

export function buildSelectValue(
    baseMetaAttributeValue: BaseMetaAttributeValue,
    choiceId: string,
): SelectAttributeValue {
    return {
        ...baseMetaAttributeValue,
        choiceId,
    };
}
