import { GraphEdge, GraphEvent, GraphNode, GraphState } from './graph.types';
import { ReplaySubject } from 'rxjs';
import { CollectionsHelper } from '@datagalaxy/core-util';

export class Graph<N, E> {
    private successors = new Map<string, string[]>();
    private predecessors = new Map<string, string[]>();
    private edgeInternalId = new Map<string, string[]>();
    private nodes = new Map<string, GraphNode<N>>();
    private edges = new Map<string, GraphEdge<E>>();

    private readonly events = new ReplaySubject<GraphEvent<GraphState<N, E>>>();

    public get events$() {
        return this.events.asObservable();
    }

    public clear() {
        const removedNodes = Array.from(this.nodes.values());
        const removedEdges = Array.from(this.edges.values());

        this.nodes.clear();
        this.edges.clear();

        const removedNodesAndEdges = {
            nodes: removedNodes,
            edges: removedEdges,
        };

        this.events.next({
            removed: removedNodesAndEdges,
        });

        return removedNodesAndEdges;
    }

    public add(nodes: GraphNode<N>[], edges: GraphEdge<E>[]) {
        const addedNodes = nodes.map((n) => {
            this.nodes.set(n.id, { id: n.id, data: n.data });
            this.predecessors.set(n.id, []);
            this.successors.set(n.id, []);
            return this.nodes.get(n.id);
        });
        const addedEdges = edges
            .map((e) => {
                const sourceNode = this.nodes.get(e.source);
                const targetNode = this.nodes.get(e.target);

                if (!sourceNode || !targetNode) {
                    return;
                }

                const successors = this.successors.get(e.source);
                const predecessors = this.predecessors.get(e.target);
                successors.push(e.target);
                predecessors.push(e.source);

                this.edges.set(e.id, e);
                const internalIdEdges =
                    this.edgeInternalId.get(`${e.source}:${e.target}`) || [];
                internalIdEdges.push(e.id);
                this.edgeInternalId.set(
                    `${e.source}:${e.target}`,
                    internalIdEdges,
                );
                return this.edges.get(e.id);
            })
            .filter((e) => !!e);

        if (!addedNodes.length && !addedEdges.length) {
            return;
        }

        this.events.next({ added: { nodes: addedNodes, edges: addedEdges } });
    }

    public remove(nodesIds: string[], edgesIds: string[]) {
        const removedNodes = nodesIds
            .map((id) => this.nodes.get(id))
            .filter((n) => !!n);
        const removedNodesEdges = this.getNodesEdges(...nodesIds);
        const removedEdges = CollectionsHelper.distinct([
            ...edgesIds.map((id) => this.edges.get(id)),
            ...removedNodesEdges,
        ]).filter((e) => !!e);

        nodesIds.forEach((id) => {
            this.nodes.delete(id);
            this.successors.delete(id);
            this.predecessors.delete(id);
        });

        removedEdges.forEach((edge) => {
            const sourceId = edge.source;
            const targetId = edge.target;

            if (this.successors.has(sourceId)) {
                const successors = this.successors.get(sourceId);
                CollectionsHelper.removeOne(successors, (o) => o === sourceId);
            }

            if (this.predecessors.has(targetId)) {
                const predecessors = this.predecessors.get(targetId);
                CollectionsHelper.removeOne(
                    predecessors,
                    (o) => o === targetId,
                );
            }

            this.edges.delete(edge.id);
            const internalIdEdges = this.edgeInternalId.get(
                `${sourceId}:${targetId}`,
            );
            if (internalIdEdges) {
                this.edgeInternalId.set(
                    `${sourceId}:${targetId}`,
                    internalIdEdges.filter((e) => e !== edge.id),
                );
            }
        });

        if (!removedNodes.length && !removedEdges.length) {
            return;
        }

        this.events.next({
            removed: { nodes: removedNodes, edges: removedEdges },
        });
    }

    public getNodesByIds(ids: string[]): GraphNode<N>[] {
        return ids.map((id) => this.nodes.get(id)).filter((n) => !!n);
    }

    public getNodes(): GraphNode<N>[] {
        return Array.from(this.nodes.values());
    }

    public getNode(id: string): GraphNode<N> {
        return this.nodes.get(id);
    }

    public addNode(id: string, data: N) {
        this.add([{ id, data }], []);
    }

    public updateNode(ids: string[], updater: (nodeData: N) => void | N) {
        const nodes = this.getNodesByIds(ids);
        nodes.forEach((node) => {
            const res = updater(node.data);
            if (res) {
                node.data = res;
            }
        });

        this.events.next({ updated: { nodes, edges: [] } });
    }

    public removeNodes(...ids: string[]) {
        this.remove(ids, []);
    }

    /**
     * Get all edges that are connected to the nodes with the given ids
     */
    public getNodesEdges(...ids: string[]): GraphEdge<E>[] {
        const edgeIds = new Set<GraphEdge<E>>();

        for (const id of ids) {
            const successors = this.successors.get(id);
            const predecessors = this.predecessors.get(id);

            successors?.forEach((successor) => {
                const ids = this.edgeInternalId.get(`${id}:${successor}`);

                ids?.forEach((edgeId) => edgeIds.add(this.edges.get(edgeId)));
            });

            predecessors?.forEach((predecessor) => {
                const ids = this.edgeInternalId.get(`${predecessor}:${id}`);

                ids?.forEach((edgeId) => edgeIds.add(this.edges.get(edgeId)));
            });
        }

        return [...edgeIds];
    }

    /**
     * Get all edges that connect the nodes with the given ids, meaning that
     * the source of the edge is one of the nodes' id and the target is another one
     */
    public getRelatedNodesEdges(nodeIds: string[]) {
        const res: string[] = [];
        nodeIds?.forEach((nodeId) => {
            const ids = nodeIds.filter((n) => n !== nodeId);

            ids.forEach((id) => {
                if (this.edgeInternalId.has(`${nodeId}:${id}`)) {
                    const internalIds = this.edgeInternalId.get(
                        `${nodeId}:${id}`,
                    );
                    if (internalIds) {
                        res.push(...internalIds);
                    }
                }
                if (this.edgeInternalId.has(`${id}:${nodeId}`)) {
                    const internalIds = this.edgeInternalId.get(
                        `${id}:${nodeId}`,
                    );
                    if (internalIds) {
                        res.push(...internalIds);
                    }
                }
            });
        });
        return CollectionsHelper.distinct(res)
            .map((id) => this.edges.get(id))
            .filter((e) => !!e);
    }

    public getSuccessors(id: string): GraphNode<N>[] {
        const successorIds = this.successors.get(id) || [];

        return this.getNodesByIds(successorIds);
    }

    public getPredecessors(id: string): GraphNode<N>[] {
        const predecessorIds = this.predecessors.get(id) || [];

        return this.getNodesByIds(predecessorIds);
    }

    public getRecursiveNodePredecessors(id: string): GraphNode<N>[] {
        const predecessorIds = new Set<string>();

        this.predecessorTraversal(id, (currentId, currentPredecessors) => {
            currentPredecessors.forEach((predecessorId) => {
                if (predecessorId !== id) {
                    predecessorIds.add(predecessorId);
                }
            });
        });

        return this.getNodesByIds(Array.from(predecessorIds));
    }

    public getRecursivePredecessors(id: string): GraphNode<N>[] {
        const predecessorIds = new Set<string>();

        this.predecessorTraversal(id, (currentId, currentPredecessors) => {
            currentPredecessors.forEach((predecessorId) => {
                if (predecessorId !== id) {
                    predecessorIds.add(predecessorId);
                }
            });
        });

        const predecessorIdsArray = Array.from(predecessorIds);

        return this.getNodesByIds(predecessorIdsArray);
    }

    public getRecursiveSuccessors(id: string): GraphNode<N>[] {
        const successorIds = new Set<string>();

        this.successorsTraversal(id, (currentId, currentSuccessors) => {
            currentSuccessors.forEach((successorId) => {
                if (successorId !== id) {
                    successorIds.add(successorId);
                }
            });
        });

        const successorIdsArray = Array.from(successorIds);

        return this.getNodesByIds(successorIdsArray);
    }

    public getRecursiveSuccessorsAndPredecessors(id: string): GraphNode<N>[] {
        const predecessors = this.getRecursivePredecessors(id);
        const successors = this.getRecursiveSuccessors(id);

        return CollectionsHelper.distinct(predecessors.concat(successors));
    }

    public getNeighbours(id: string): GraphState<N, E> {
        const predecessorIds = this.predecessors.get(id) || [];
        const successorIds = this.successors.get(id) || [];

        const uniqueIds = CollectionsHelper.distinct(
            predecessorIds.concat(successorIds),
        );

        const nodes = this.getNodesByIds(uniqueIds);

        return {
            nodes,
            edges: this.getNodesEdges(...uniqueIds),
        };
    }

    //#region public
    public addEdge(id: string, source: string, target: string, data: E) {
        this.add([], [{ id, source, target, data }]);
    }

    public getEdge(id: string): GraphEdge<E> {
        return this.edges.get(id);
    }

    public getEdgesByIds(ids: string[]): GraphEdge<E>[] {
        return ids.map((id) => this.edges.get(id)).filter((e) => !!e);
    }

    public getEdges(): GraphEdge<E>[] {
        return Array.from(this.edges.values());
    }

    public updateEdge(ids: string[], updater: (edgeData: E) => void | E) {
        const edges = this.getEdgesByIds(ids);
        edges.forEach((edge) => {
            const res = updater(edge.data);
            if (res) {
                edge.data = res;
            }
        });

        this.events.next({ updated: { edges, nodes: [] } });
    }

    public removeEdges(...ids: string[]) {
        this.remove([], ids);
    }

    //#endregion

    private breadTraversal(
        startNodeId: string,
        getNeighbors: (nodeId: string) => string[],
        callback: (currentId: string, neighbors: string[]) => void,
    ): void {
        const visited = new Set<string>([]);
        const queue: string[] = [startNodeId];

        while (queue.length !== 0) {
            const currentNode = queue.shift();
            const neighbors = getNeighbors(currentNode)?.filter(
                (n) => !visited.has(n),
            );

            if (!neighbors?.length) {
                continue;
            }

            callback(currentNode, neighbors);

            visited.add(currentNode);
            queue.push(...neighbors);
        }
    }

    private successorsTraversal(
        nodeId: string,
        callback: (currentId: string, successors: string[]) => void,
    ): void {
        this.breadTraversal(nodeId, (id) => this.successors.get(id), callback);
    }

    private predecessorTraversal(
        nodeId: string,
        callback: (currentId: string, predecessors: string[]) => void,
    ): void {
        this.breadTraversal(
            nodeId,
            (id) => this.predecessors.get(id),
            callback,
        );
    }
}
