import { inject, Injectable } from '@angular/core';
import {
    DeleteUserSettingValueParameter,
    GetUserSettingCategoryValuesParameter,
    GetUserSettingValueParameter,
    SetUserSettingValueParameter,
} from './user-setting-value.queries';
import { UserApiService } from '../user-api.service';
import {
    UserSettingCategoryRouteDto,
    UserSettingCategoryValueDto,
} from './user-setting-value.types';

@Injectable({
    providedIn: 'root',
})
export class UserSettingValueService {
    private userApiService = inject(UserApiService);

    public async getUserSettingValue(
        category: string,
        route: string,
        goldenUserId?: string,
    ): Promise<string | undefined> {
        const result = await this.userApiService.getUserSettingValue(
            new GetUserSettingValueParameter(category, route),
        );

        if (result.Value || !goldenUserId) {
            return result.Value;
        }

        const res = await this.userApiService.getUserSettingValue(
            new GetUserSettingValueParameter(category, route, goldenUserId),
        );

        return res.Value;
    }

    public async setUserSettingValue(
        category: string,
        route: string,
        value: string,
        meta?: string,
    ): Promise<void> {
        return await this.userApiService.setUserSettingValue(
            new SetUserSettingValueParameter(category, route, value, meta),
        );
    }

    public async deleteUserSettingValue(
        category: string,
        route: string,
    ): Promise<void> {
        return await this.userApiService.deleteUserSettingValue(
            new DeleteUserSettingValueParameter(category, route),
        );
    }

    public async getUserSettingCategoryValues(
        category: string,
    ): Promise<UserSettingCategoryValueDto[] | undefined> {
        const res = await this.userApiService.getUserSettingCategoryValues(
            new GetUserSettingCategoryValuesParameter(category),
        );

        return res.Values;
    }

    public async getUserSettingCategoryRoutes(
        category: string,
    ): Promise<UserSettingCategoryRouteDto[] | undefined> {
        const res = await this.userApiService.getUserSettingCategoryRoutes(
            new GetUserSettingCategoryValuesParameter(category),
        );

        return res.Routes;
    }
}
