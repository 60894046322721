<div class="task-header">
    <!-- created by, created on -->
    <dxy-profile-avatar
        class="task-avatar"
        [userId]="task.CreationUserId"
        [isMiniIcon]="true"
    ></dxy-profile-avatar>
    <div class="user-data">
        <span class="text-value user-name">{{ userName }}</span>
        <span class="infoDate">{{ modificationDate }}</span>
    </div>
    <div class="task-actions">
        <!-- delete/edit -->
        <button
            *ngIf="isDeleteEnabled"
            dxyIconButton
            variant="tertiary"
            color="destructive"
            (click)="deleteTask()"
            [dxyTooltip]="'UI.TaskContainer.btnDelete' | translate"
            dxyTooltipPosition="below"
        >
            <i class="glyph-delete"></i>
        </button>
        <button
            *ngIf="isEditEnabled"
            dxyIconButton
            variant="tertiary"
            (click)="editTask()"
            [dxyTooltip]="'UI.TaskContainer.btnEdit' | translate"
            dxyTooltipPosition="below"
        >
            <i class="glyph-edit"></i>
        </button>
    </div>
</div>
<div class="task-body">
    <div class="task-line">
        <!-- title -->
        <dxy-field-text
            class="readonly-no-min-width readonly-no-bottom-margin"
            labelKey="UI.TaskContainer.lblPlaceHolderTitle"
            [ngModel]="task.Title"
            [readonly]="true"
        ></dxy-field-text>

        <div class="line-fields-container">
            <!-- type -->
            <div *ngIf="task.Type" class="field-left">
                <dxy-field-select
                    class="readonly-no-min-width readonly-no-bottom-margin no-min-width"
                    labelKey="UI.TaskContainer.lblTaskType"
                    [adapter]="taskTypeSelectAdapter"
                    [readonly]="true"
                ></dxy-field-select>
            </div>
            <!-- status -->
            <div class="field-right">
                <dxy-field-select
                    class="readonly-no-min-width readonly-no-bottom-margin no-min-width"
                    labelKey="UI.TaskContainer.lblTaskStatus"
                    [adapter]="taskStatusSelectAdapter"
                    [readonly]="true"
                ></dxy-field-select>
            </div>
        </div>

        <!-- text -->
        <dxy-rich-text-field
            class="readonly-no-min-width readonly-no-bottom-margin no-min-width"
            labelKey="UI.TaskContainer.lblTaskText"
            [readonly]="true"
            [ngModel]="task.Text"
            [mentionResolvers]="mentionResolvers"
        ></dxy-rich-text-field>
    </div>
    <div class="line-fields-container">
        <!-- assignee -->
        <div class="field-left">
            <div class="task-assignee">
                <div class="task-assignee-info">
                    <dxy-field-select
                        class="readonly-no-min-width readonly-no-bottom-margin no-min-width"
                        labelKey="UI.TaskContainer.lblTaskAssigneeList"
                        [adapter]="taskAssigneeSelectAdapter"
                        [readonly]="true"
                    ></dxy-field-select>
                </div>
            </div>
        </div>
        <!-- duetime -->
        <div *ngIf="dueTime" class="field-right">
            <dxy-field-date
                class="readonly-no-min-width readonly-no-bottom-margin no-min-width"
                labelKey="UI.TaskContainer.lblTaskDueTime"
                [readonly]="true"
                [ngModel]="dueTime"
            ></dxy-field-date>
        </div>
    </div>
    <div *ngIf="isResponseVisible" class="task-line">
        <!-- response -->
        <dxy-rich-text-field
            class="readonly-no-min-width readonly-no-bottom-margin no-min-width"
            labelKey="UI.TaskContainer.lblTaskResponse"
            [readonly]="true"
            [ngModel]="task.Response"
            [mentionResolvers]="mentionResolvers"
        ></dxy-rich-text-field>
    </div>
</div>
