<dxy-collapsible
    class="reflex-shadow big-header body-padding"
    titleKey="UI.EntityDockingPane.Insights.TopContributorLabel"
>
    <ul class="dg5-list-unstyled">
        <li *ngFor="let user of topUsers">
            <dxy-mini-avatars
                class="mini-avatars"
                [usersIds]="user.UserGuid"
                [mini]="false"
            ></dxy-mini-avatars>
            <span
                class="dg5-badge"
                [dxyTooltip]="
                    'UI.EntityDockingPane.Insights.TopContributorCountTooltip'
                        | translate
                "
            >
                {{ getDisplayedCount(user) }}
            </span>
        </li>
    </ul>
</dxy-collapsible>
