import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    input,
} from '@angular/core';

@Component({
    selector: 'dxy-skeleton',
    standalone: true,
    template: '',
    styleUrls: ['./skeleton.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonComponent {
    public type = input<'circle' | 'rectangle'>('rectangle');
    public width = input<number>();
    public height = input<number>();

    @HostBinding('class')
    private get className() {
        return this.type();
    }

    @HostBinding('style.width.px')
    private get hostWidth() {
        return this.width();
    }

    @HostBinding('style.height.px')
    private get hostHeight() {
        return this.height();
    }
}
