import {
    BaseMetaAttributeValue,
    TechnologyAttributeValue,
} from '@datagalaxy/meta-attribute-value-domain';

export function buildTechnologyValue(
    baseMetaAttributeValue: BaseMetaAttributeValue,
    value: string,
): TechnologyAttributeValue {
    return {
        ...baseMetaAttributeValue,
        technologyId: value,
    };
}
