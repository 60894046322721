import {
    ChangeDetectionStrategy,
    Component,
    inject,
    input,
    OnInit,
    viewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DxyRichTextFieldComponent } from '@datagalaxy/core-ui';
import { TranslateModule } from '@ngx-translate/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { TranslationInputActionsComponent } from '@datagalaxy/webclient/multilingual/feature';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { FeatureFlagDirective } from '@datagalaxy/feature-flag';
import { AttributeFieldComponent } from '../../common/attribute-field-component.interface';
import { RichTextAttributeFieldStore } from './rich-text-attribute-field-store';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

@Component({
    selector: 'dxy-rich-text-attribute-field',
    templateUrl: './rich-text-attribute-field.component.html',
    styleUrls: ['./rich-text-attribute-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    providers: [RichTextAttributeFieldStore],
    imports: [
        DxyRichTextFieldComponent,
        FormsModule,
        TranslateModule,
        TranslationInputActionsComponent,
        DxyIconButtonDirective,
        FeatureFlagDirective,
    ],
})
export class RichTextAttributeFieldComponent
    implements OnInit, AttributeFieldComponent
{
    readonly store = inject(RichTextAttributeFieldStore);

    entity = input.required<EntityItem>();
    attributeMetaInfo = input.required<AttributeMetaInfo>();
    readonly = input<boolean>(false);

    field = viewChild(DxyRichTextFieldComponent);

    ngOnInit() {
        this.store.init({
            entity: this.entity(),
            attributePath: this.attributeMetaInfo().AttributePath,
            readonly: this.readonly(),
        });
        this.store.setAttributeMetaInfo(this.attributeMetaInfo());
        this.store.loadTranslations();
    }

    public onValueChange(value: string) {
        this.store.setUpdatedValue(value);
    }
    public async onFieldBlur(_$event: FocusEvent) {
        await this.store.persistUpdatedValue();
    }
    public onFieldFocus() {
        this.store.touch();
    }

    public focusField() {
        this.field()?.doFocus();
    }
}
