import { patchState, signalStore, withMethods } from '@ngrx/signals';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

type PatchedAttributeMetaInfo = AttributeMetaInfo & { AttributePath: string };

export const AttributeMetaInfoStore = signalStore(
    { providedIn: 'root' },
    withEntities<PatchedAttributeMetaInfo>(),
    withMethods((store) => ({
        setAttributeMetaInfos(attributeMetaInfos: AttributeMetaInfo[]) {
            patchState(
                store,
                setAllEntities(
                    attributeMetaInfos as PatchedAttributeMetaInfo[],
                    {
                        idKey: 'AttributePath',
                    },
                ),
            );
        },
        getAttributeMetaInfo(attributeKey: string, dataTypeName: string) {
            const attributesWithKey = store
                .entities()
                .filter(
                    (a) => a.AttributeKey === attributeKey,
                ) as AttributeMetaInfo[];
            return (
                attributesWithKey.find(
                    (a) => a.DataTypeName === dataTypeName,
                ) ??
                attributesWithKey.find((a) => a.DataTypeName === 'AllTypes') ??
                attributesWithKey[0]
            );
        },
    })),
);
