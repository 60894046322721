import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DxyOptionItemComponent } from '@datagalaxy/core-ui';
import { ISuggestion } from '../suggestion.types';
import { TranslateModule } from '@ngx-translate/core';
import { SuggestionChoiceActionsComponent } from '../suggestion-choice-actions/suggestion-choice-actions.component';
import { NgClass, NgIf, PercentPipe } from '@angular/common';
import { TooltipDirective } from '@datagalaxy/ui/tooltip';

/**
 * ## Role
 * Suggestion value with score and user action buttons (accept/decline)
 */
@Component({
    selector: 'app-suggestion-cell',
    templateUrl: 'suggestion-cell.component.html',
    styleUrls: ['suggestion-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        DxyOptionItemComponent,
        SuggestionChoiceActionsComponent,
        NgClass,
        PercentPipe,
        TranslateModule,
        TooltipDirective,
    ],
})
export class SuggestionCellComponent {
    @Input() suggestion: ISuggestion;
    @Input() showGroupDisplayName?: boolean;
    @Input() featureCodePrefix: string;
    @Input() accepted?: boolean;

    protected get acceptedIndicatorVisible() {
        return this.accepted !== undefined;
    }

    protected get actionsVisible() {
        return !this.acceptedIndicatorVisible;
    }

    protected get score() {
        return this.suggestion.score || 0;
    }
}
