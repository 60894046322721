import { deserialize, inheritSerialization, serialize } from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import {
    UserSettingCategoryRouteDto,
    UserSettingCategoryValueDto,
} from './user-setting-value.types';

@inheritSerialization(BaseServiceParameter)
export class GetUserSettingValueParameter extends BaseServiceParameter {
    @serialize public Category: string;
    @serialize public Route?: string;
    @serialize public GoldenSettingsUserLocalId?: string;

    public constructor(
        category: string,
        route: string,
        goldenSettingsUserLocalId?: string,
    ) {
        super();
        this.Category = category;
        this.Route = route;
        this.GoldenSettingsUserLocalId = goldenSettingsUserLocalId;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetUserSettingValueResult extends BaseServiceResult {
    @deserialize public Value?: string;

    public constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceParameter)
export class SetUserSettingValueParameter extends BaseServiceParameter {
    @serialize public Category: string;
    @serialize public Route?: string;
    @serialize public Value: string;
    @serialize public Meta?: string;

    public constructor(
        category: string,
        route: string,
        value: string,
        meta?: string,
    ) {
        super();
        this.Category = category;
        this.Route = route;
        this.Value = value;
        this.Meta = meta;
    }
}

export class GetUserSettingCategoryValuesParameter extends BaseServiceParameter {
    @serialize public Category: string;

    public constructor(category: string) {
        super();
        this.Category = category;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetUserSettingCategoryValuesResult extends BaseServiceResult {
    @deserialize public Values?: UserSettingCategoryValueDto[];

    public constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceResult)
export class GetUserSettingCategoryRoutesResult extends BaseServiceResult {
    @deserialize public Routes?: UserSettingCategoryRouteDto[];

    public constructor() {
        super();
    }
}

@inheritSerialization(BaseServiceParameter)
export class DeleteUserSettingValueParameter extends BaseServiceParameter {
    @serialize public Category: string;
    @serialize public Route?: string;

    public constructor(category: string, route: string) {
        super();
        this.Category = category;
        this.Route = route;
    }
}
