@if (leftButtonVisible) {
    <div class="arrow-button-container left">
        <button
            dxyIconButton
            variant="tertiary"
            size="small"
            type="button"
            (click)="scrollLeft()"
        >
            <i class="glyph-arrow-drop-right"></i>
        </button>
    </div>
}

<div class="filters-container">
    <div
        #filters
        class="filters"
        [style.transform]="'translateX(' + translateX + 'px)'"
    >
        <ng-content></ng-content>
    </div>
</div>

@if (rightButtonVisible) {
    <div class="arrow-button-container right">
        <button
            dxyIconButton
            variant="tertiary"
            size="small"
            type="button"
            (click)="scrollRight()"
        >
            <i class="glyph-arrow-drop-right"></i>
        </button>
    </div>
}
