import { FilteredViewDto } from '@datagalaxy/filter-domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

export enum EntityExportType {
    Unitary,
    List,
    FilteredView,
}

export interface IEntityExportModalInput {
    data: EntityItem | EntityItem[] | FilteredViewDto;
    exportType: EntityExportType;
}
