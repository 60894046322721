import {
    ChangeDetectionStrategy,
    Component,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { BaseAttributeFilter } from '../base-attribute-filter.component';
import { AttributeDateFilterModel } from './AttributeDateFilterModel';
import { AttributeFilterService } from '../attribute-filter.service';
import {
    DateFilterOperator,
    IDateFilterData,
    DxyDateFilterComponent,
} from '@datagalaxy/core-ui/filters';
import { AttributeFilterAction } from '../attribute-filter.types';
import { FilterOperator } from '@datagalaxy/filter-domain';

/**
 * ## Role
 * Display a date attribute filter
 */
@Component({
    selector: 'app-attribute-date-filter',
    templateUrl: './attribute-date-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [DxyDateFilterComponent],
})
export class AttributeDateFilterComponent
    extends BaseAttributeFilter<AttributeDateFilterModel>
    implements OnInit, OnChanges
{
    @ViewChild(DxyDateFilterComponent) filterComponent: DxyDateFilterComponent;

    public filter: IDateFilterData;

    public get operators() {
        return this.filterItemData.operators.map((op) =>
            this.getTypedOperator(op),
        );
    }

    constructor(attributeFilterService: AttributeFilterService) {
        super(attributeFilterService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.init();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'filterItemData', () => this.init());
    }

    public onFilterChange() {
        this.filterItemData.momentValue1 = this.filter.startDate;
        this.filterItemData.momentValue2 = this.filter.endDate;
        this.filterItemData.operator =
            FilterOperator[DateFilterOperator[this.filter.operator]];
        this.onAction.emit(AttributeFilterAction.itemChanged);
    }

    private init() {
        this.filter = {
            startDate: this.filterItemData.momentValue1,
            endDate: this.filterItemData.momentValue2,
            operator: this.getTypedOperator(this.filterItemData.operator),
        };
    }

    private getTypedOperator(filterOperator: FilterOperator) {
        const operatorText = FilterOperator[filterOperator];
        return DateFilterOperator[operatorText];
    }
}
