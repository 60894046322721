import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
} from 'cerialize';
import { BaseServiceResult } from '@datagalaxy/data-access';
import { BaseUserServiceParameter } from '../user';
import { UserSettingsDto } from './user-settings.types';

@inheritSerialization(BaseUserServiceParameter)
export class SetUserSettingsParameter extends BaseUserServiceParameter {
    @serialize public ClientId: string;
    @serialize public NotificationCleanupPeriod?: number;
    @serialize public NotificationRealTimeBehavior?: number;
    @serialize public UserLanguageCode?: string;

    public constructor(clientId: string, userId: string) {
        super(userId);
        this.ClientId = clientId;
    }
}

@inheritSerialization(BaseServiceResult)
export class SetUserSettingsResult extends BaseServiceResult {
    @deserialize IsErrorUnknownClientLocalId!: boolean;
    @deserialize IsErrorUnknownUserLocalId!: boolean;
}

@inheritSerialization(BaseUserServiceParameter)
export class GetUserSettingsParameter extends BaseUserServiceParameter {
    @serialize public ClientId: string;

    public constructor(clientId: string, userId: string) {
        super(userId);
        this.ClientId = clientId;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetUserSettingsResult extends BaseServiceResult {
    @deserialize IsErrorUnknownClientLocalId!: boolean;
    @deserialize IsErrorUnknownUserLocalId!: boolean;
    @deserializeAs(UserSettingsDto) UserSettings!: UserSettingsDto;
}
