import { BaseService } from '@datagalaxy/core-ui';
import { CollectionsHelper, wait } from '@datagalaxy/core-util';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { RealTimeCommService } from '../services/realTimeComm.service';
import { UserService } from '../services/user.service';
import { ITranslatedNotification } from './notification.types';
import { TeamService } from '../team/team.service';
import { UserNotification } from '@datagalaxy/webclient/notification/data-access';
import {
    AcknowledgeNotificationsCommand,
    NotificationApiService,
} from '@datagalaxy/webclient/notification/data-access';
import { ToasterService } from '../services/toaster.service';
import { TypedEventTranslationFactory } from '../dg-event-translation/typed-event-translation-factory';
import { NotificationRealTimeBehavior } from '@datagalaxy/webclient/client/domain';

@Injectable({ providedIn: 'root' })
export class NotificationService extends BaseService {
    public get notifications$() {
        return this.notificationsSubject;
    }
    public get toastNotifications$() {
        return this.toastNotificationsSubject;
    }
    public get newNotification$() {
        return this.newNotification;
    }
    private newNotification = new Subject<ITranslatedNotification>();

    public get isRealTimeNotificationActive() {
        return (
            this.notificationRealTimeBehavior ==
            NotificationRealTimeBehavior.All
        );
    }

    private notificationRealTimeBehavior: NotificationRealTimeBehavior;
    private notificationsSubject = new BehaviorSubject<
        ITranslatedNotification[]
    >([]);
    private toastNotificationsSubject = new BehaviorSubject<
        ITranslatedNotification[]
    >([]);

    constructor(
        private notificationApiService: NotificationApiService,
        private userService: UserService,
        private factory: TypedEventTranslationFactory,
        private realTimeCommService: RealTimeCommService,
        private teamService: TeamService,
        private toasterService: ToasterService,
    ) {
        super();
        this.realTimeCommService.subscribeUserNotification((notification) => {
            // On an API call, notification could be received before the success toast appears
            // Execute with a delay to be sure that toasters and notifications can't collide
            setTimeout(() => this.onUserNotification(notification), 500);
        });
    }

    public async initAsync(realTimeBehavior: NotificationRealTimeBehavior) {
        this.notificationRealTimeBehavior = realTimeBehavior;
        await this.teamService.getTeamsPublicData();
        await this.loadNotifications();
    }

    public async setRealTimeNotificationBehavior(isRealTimeDisabled: boolean) {
        const newBehavior = isRealTimeDisabled
            ? NotificationRealTimeBehavior.None
            : NotificationRealTimeBehavior.All;
        this.notificationRealTimeBehavior = newBehavior;
        await this.userService.setNotificationRealTimeBehavior(newBehavior);
    }

    public async acknowledgeNotification(
        notif: ITranslatedNotification,
        acknowledged: boolean,
    ) {
        await this.acknowledgeNotificationList([notif], acknowledged);
    }

    public async acknowledgeNotificationList(
        notifs: ITranslatedNotification[],
        acknowledged: boolean,
    ) {
        const timePlaceholder = Date.now().toString();
        const param = {
            Guids: notifs.map((n) => n.source.Guid),
            Acknowledged: acknowledged,
        } as AcknowledgeNotificationsCommand;
        const updatedNotifications = notifs.map((notif) => ({
            ...notif,
            source: Object.assign(new UserNotification(), notif.source, {
                AcknowledgementTime: acknowledged ? timePlaceholder : null,
                ReadTime: notif.source.ReadTime ?? timePlaceholder,
            }),
        }));
        try {
            await this.notificationApiService.acknowledgeNotifications(param);
            this.notifyUpdateNotifications(updatedNotifications);
        } catch (error) {
            this.warn('Error while acknowledging notifications', notifs);
        }
    }

    public async buildNotificationTranslation(notification: UserNotification) {
        return await this.factory.translateEvent(
            notification.Event,
            notification.IsUserMention,
            notification.MentionedTeamGuid,
        );
    }

    public notifyToasterClose(notificationId: string) {
        this.onCloseNotificationToaster(notificationId);
    }

    public async loadNotifications() {
        const notifications =
            await this.notificationApiService.getUserNotifications();
        if (!notifications?.length) {
            return [];
        }
        let orderedNotifications = await Promise.all(
            CollectionsHelper.orderBy(
                notifications ?? [],
                (n) => n.CreationTime,
                'desc',
            ).map((n) => this.mapToTranslatedNotification(n)),
        );
        orderedNotifications = orderedNotifications.filter((n) => n?.innerHTML);

        this.notificationsSubject.next(orderedNotifications);
    }

    private notifyUpdateNotifications(
        notifications: ITranslatedNotification[],
    ) {
        const newNotifications = this.notificationsSubject.value.map(
            (notification) => {
                const updatedNotification = notifications.find(
                    (n) => n.source.Guid === notification.source.Guid,
                );
                return updatedNotification || notification;
            },
        );
        this.notificationsSubject.next(newNotifications);
    }

    private onCloseNotificationToaster(notificationId: string) {
        const updatedNotifications =
            this.toastNotificationsSubject.value.filter(
                (n) => n.source.Guid !== notificationId,
            );
        this.toastNotificationsSubject.next(updatedNotifications);
    }

    private async onUserNotification(notification: UserNotification) {
        await this.ensureNotCollideWithAToast();
        const translatedNotif =
            await this.mapToTranslatedNotification(notification);
        if (!translatedNotif) {
            return;
        }

        this.notificationsSubject.next([
            translatedNotif,
            ...this.notificationsSubject.value,
        ]);
        this.newNotification.next(translatedNotif);

        if (!this.isRealTimeNotificationActive) {
            return;
        }
        this.toastNotificationsSubject.next([
            translatedNotif,
            ...this.toastNotificationsSubject.value,
        ]);
    }

    private async ensureNotCollideWithAToast() {
        await wait(500);
        await this.toasterService.waitAllToastersAreClosed();
    }

    private async mapToTranslatedNotification(
        notification: UserNotification,
    ): Promise<ITranslatedNotification> {
        try {
            const translation =
                await this.buildNotificationTranslation(notification);
            if (!translation) {
                return;
            }
            return {
                ...translation,
                source: notification,
            } as ITranslatedNotification;
        } catch (error) {
            this.warn(
                'Impossible to translate a notification',
                notification,
                error,
            );
            return undefined;
        }
    }
}
