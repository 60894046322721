import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import {
    FunnelAction,
    ValidatedFunnelStepComponent,
} from '@datagalaxy/ui/funnel';
import { map, Observable, startWith } from 'rxjs';
import { MultilingualFunnelStateService } from '../multilingual-funnel-state.service';
import { MultilingualFunnelStepComponent } from '../multilingual-funnel-step.component';
import { LanguageForm } from '../../../languages';
import { TargetLanguageSelectorComponent } from '../../target-language-selector/target-language-selector.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'dxy-target-language-step',
    templateUrl: './target-language-step.component.html',
    styleUrls: ['../funnel-step.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        TargetLanguageSelectorComponent,
    ],
})
export class TargetLanguageStepComponent
    extends MultilingualFunnelStepComponent
    implements ValidatedFunnelStepComponent
{
    private formBuilder = inject(FormBuilder);
    private stateService = inject(MultilingualFunnelStateService);

    public override actions: FunnelAction[] = [
        this.previousAction,
        this.nextAction,
    ];
    public valid$: Observable<boolean>;

    protected form: FormGroup<LanguageForm>;

    protected exludedLanguageCodes: string[] = [
        this.stateService.getSourceLanguage()?.languageCode ?? '',
    ];

    public constructor() {
        super();
        const defaultValue =
            this.stateService.getSelectedTargetLanguage() ?? null;
        this.form = this.formBuilder.group<LanguageForm>({
            language: new FormControl(defaultValue, [Validators.required]),
        });
        this.valid$ = this.form.statusChanges.pipe(
            startWith(this.form.status),
            map((status) => status === 'VALID'),
        );
        this.form.controls.language.valueChanges.subscribe((value) => {
            if (!value) {
                return;
            }
            this.stateService.setSelectedTargetLanguage(value);
        });
    }
}
