import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
} from '@angular/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { combineLatest, ReplaySubject, switchMap } from 'rxjs';
import { AttributeValueTranslationService } from '../../translations';
import { AsyncPipe, NgIf } from '@angular/common';
import { EllipsisTooltipDirective, RichTextPipe } from '@datagalaxy/core-ui';

@Component({
    selector: 'dxy-translation-text-attribute-value',
    styleUrls: ['./translation-text-attribute-value.component.scss'],
    templateUrl: './translation-text-attribute-value.component.html',
    imports: [AsyncPipe, NgIf, RichTextPipe, EllipsisTooltipDirective],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslatedTextAttributeValueComponent {
    @Input() set entity(e: EntityItem) {
        this.entity$.next(e);
    }
    @Input() set attributePath(a: string) {
        this.attributePath$.next(a);
    }

    private readonly entity$ = new ReplaySubject<EntityItem>();
    private readonly attributePath$ = new ReplaySubject<string>();
    private attributeValueTranslationService = inject(
        AttributeValueTranslationService,
    );

    protected translatedText$ = combineLatest([
        this.entity$,
        this.attributePath$,
    ]).pipe(
        switchMap(([entity, attributePath]) =>
            this.attributeValueTranslationService.getAttributeValueTranslationResultFromEntityItem$(
                entity,
                attributePath ?? '',
            ),
        ),
    );
}
