import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    CanMatchFn,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
} from '@angular/router';
import { inject } from '@angular/core';
import { SlackRedirectionQueryParams } from '../notification-channels/slack-redirection.types';
import { SlackRedirectionHandler } from '../notification-channels/slack-redirection-handler';
import { QueryParamRemover } from '../notification-channels/query-param-remover';
import { ClientService } from './client.service';
import { filter, take } from 'rxjs/operators';
import { switchMap } from 'rxjs';
import { ClientServiceManagerService } from './client-service-manager.service';
import { CurrentUserService } from '@datagalaxy/webclient/user/data-access';
import { AuthenticationService } from '@datagalaxy/webclient/auth/feature';

/**
 * Remove the hash /client/:id from the URL
 * This guard ensure retro-compatibility with old URLs (Auth v1)
 */
export const ClientBackwardCompatGuard: CanMatchFn = (
    _route: Route,
    segments: UrlSegment[],
) => {
    const router = inject(Router);

    const regex = /^client\/[^/]+/; // Matches "/client/:id"
    const path = segments.join('/');
    const match = regex.test(path);

    if (match) {
        return router.parseUrl(path.replace(regex, ''));
    }

    return true;
};

export const ClientQueryParamsGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
) => {
    const slackRedirectionHandler = inject(SlackRedirectionHandler);
    const queryParamRemover = inject(QueryParamRemover);
    const params = route.queryParams;

    const callbackIdentifier =
        params[SlackRedirectionQueryParams.callbackIdentifier];
    const code = params[SlackRedirectionQueryParams.code];
    const from = params.from;

    if (callbackIdentifier && code) {
        setTimeout(
            () =>
                slackRedirectionHandler.handleRedirection(
                    callbackIdentifier,
                    code,
                ),
            10,
        );
    }

    if (from) {
        setTimeout(() => queryParamRemover.remove(['from']), 10);
    }

    return true;
};

export const ClientGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const currentUserService = inject(CurrentUserService);
    const clientServiceManager = inject(ClientServiceManagerService);
    const authenticationService = inject(AuthenticationService);
    const clientService = inject(ClientService);
    const router = inject(Router);

    return authenticationService.authenticated$.pipe(
        filter((authenticated) => authenticated),
        take(1),
        switchMap(() =>
            currentUserService.selectUserInfo().pipe(
                filter((userInfo) => !!userInfo),
                take(1),
                switchMap(async (userInfo) => {
                    try {
                        await clientServiceManager.initializeServices(
                            userInfo,
                            currentUserService.clientInfo,
                        );

                        // Could be move in angular 18 to children path '' with redirectTo
                        // function
                        const pathWithoutQueryParams =
                            state.url?.split('?')?.[0];
                        if (pathWithoutQueryParams === '/') {
                            return router.createUrlTree(
                                await clientService.getLandingPageRouteCommands(
                                    currentUserService.clientId,
                                ),
                            );
                        }

                        return true;
                    } catch (error) {
                        return router.createUrlTree(['/error']);
                    }
                }),
            ),
        ),
    );
};
