import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    HostBinding,
    Output,
    computed,
    input,
    model,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgClass } from '@angular/common';
import { CheckboxState } from './checkbox-selected';

@Component({
    selector: 'dxy-checkbox',
    standalone: true,
    imports: [NgClass],
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent<ValueType = unknown>
    implements ControlValueAccessor
{
    checked = model<CheckboxState>(false);

    name = input<string | undefined>(undefined);
    value = input<ValueType | undefined>(undefined);
    label = input<string | undefined>(undefined);

    size = input<'default' | 'small'>('default');
    disabled = model<boolean>(false);
    indeterminate = input<boolean>(false);

    @Output() checkedChange = new EventEmitter<CheckboxState>();

    checkboxIcon = computed(() => {
        switch (this.checked()) {
            case true:
                return 'glyph-checkbox-checked';
            case 'indeterminate':
                return 'glyph-checkbox-indeterminate';
            default:
                return 'glyph-checkbox-unchecked';
        }
    });

    checkboxState = computed(() => {
        switch (this.checked()) {
            case true:
                return 'checked';
            case 'indeterminate':
                return 'indeterminate';
            default:
                return 'unchecked';
        }
    });

    checkboxDisabled = computed(() => (this.disabled() ? 'disabled' : ''));

    private onChange: (state: CheckboxState) => void = () => {};
    private onTouched: () => void = () => {};

    writeValue(state: CheckboxState): void {
        this.checked.set(state);
    }

    registerOnChange(fn: (state: CheckboxState) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled.set(isDisabled);
    }

    @HostBinding('class') get classes(): string {
        return `${this.size()} ${this.checkboxDisabled()} ${this.checkboxState()}`;
    }

    protected onValueChange(): void {
        if (this.disabled()) return;

        const currentState = this.checked();
        let nextState: CheckboxState;

        if (this.indeterminate()) {
            if (currentState === false) {
                nextState = true;
            } else if (currentState === true) {
                nextState = 'indeterminate';
            } else {
                nextState = false;
            }
        } else {
            nextState = currentState === false ? true : false;
        }

        this.checked.set(nextState);
        this.onChange(nextState);
        this.checkedChange.emit(nextState);
    }
}
