import {
    FlexibleConnectedPositionStrategy,
    Overlay,
    OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    computed,
    ElementRef,
    inject,
    Injectable,
    signal,
    ViewContainerRef,
    OnDestroy,
} from '@angular/core';
import { getMenuPosition, MenuAlignment } from './menu/menu-alignment';
import { MenuOpeningParameters } from './menu-opening-parameters';

const menuViewportMarginInPx = 16;
const menuMinHeightInPx = 20;

@Injectable()
export class MenuOpener implements OnDestroy {
    public isOpen = signal(false);

    private overlayRef = signal<OverlayRef | undefined>(undefined);
    private overlay = inject(Overlay);
    private viewContainerRef = inject(ViewContainerRef);
    private positionStrategy: FlexibleConnectedPositionStrategy | undefined;

    public overlayElement = computed(() => {
        return this.overlayRef()?.overlayElement;
    });

    public ngOnDestroy() {
        this.removeScrollEventListener();
    }

    public open(openingParameters: MenuOpeningParameters) {
        const { alignElementRef, hasBackdrop, menuTemplate, alignment } =
            openingParameters;

        this.positionStrategy = this.getPositionStrategy(
            alignElementRef,
            alignment,
        );
        this.overlayRef.set(
            this.overlay.create({
                positionStrategy: this.positionStrategy,
                hasBackdrop: hasBackdrop,
                backdropClass: 'cdk-overlay-transparent-backdrop',
                scrollStrategy: this.overlay.scrollStrategies.reposition(),
            }),
        );

        const overlayRef = this.overlayRef();
        if (!overlayRef) {
            return;
        }

        this.adjustMenuPosition(alignElementRef);
        this.addScrollEventListener();

        const portal = new TemplatePortal(menuTemplate, this.viewContainerRef);
        overlayRef.attach(portal);

        this.adjustMenuListWidthWhenAlignBoth(
            alignment,
            overlayRef,
            alignElementRef,
        );

        overlayRef.backdropClick().subscribe(() => {
            this.close();
        });
        this.isOpen.set(true);
    }

    public close() {
        if (!this.isOpen()) {
            return;
        }
        this.removeScrollEventListener();
        this.overlayRef()?.dispose();
        this.overlayRef.set(undefined);
        this.isOpen.set(false);
    }

    public refreshPosition() {
        this.positionStrategy?.apply();
    }

    private addScrollEventListener() {
        document.addEventListener('scroll', this.onScroll, true);
    }

    private removeScrollEventListener() {
        document.removeEventListener('scroll', this.onScroll, true);
    }

    private onScroll = () => {
        this.refreshPosition();
    };

    private adjustMenuPosition(trigger: ElementRef<HTMLElement>) {
        if (!this.positionStrategy) {
            return;
        }
        let isRepositioning = false;
        this.positionStrategy.positionChanges.subscribe((change) => {
            if (isRepositioning || !this.overlayRef) {
                return;
            }
            const overlayRef = this.overlayRef();
            if (!overlayRef) {
                return;
            }

            const overlayPane = overlayRef.overlayElement;
            const triggerRect = trigger.nativeElement.getBoundingClientRect();
            const viewportHeight = document.documentElement.clientHeight;

            if (change.connectionPair.overlayY === 'top') {
                const availableSpace =
                    viewportHeight -
                    Math.max(triggerRect.bottom, 0) -
                    menuViewportMarginInPx;
                const minHeight = Math.max(availableSpace, menuMinHeightInPx);
                overlayPane.style.maxHeight = `${minHeight}px`;
            } else {
                const availableSpace = triggerRect.top - menuViewportMarginInPx;
                const minHeight = Math.max(availableSpace, menuMinHeightInPx);
                overlayPane.style.maxHeight = `${minHeight}px`;
            }
            isRepositioning = true;
            this.refreshPosition();
            isRepositioning = false;
        });
    }

    private getPositionStrategy(trigger: ElementRef, alignment: MenuAlignment) {
        return this.overlay
            .position()
            .flexibleConnectedTo(trigger)
            .withFlexibleDimensions(false)
            .withPush(true)
            .withPositions(getMenuPosition(alignment));
    }

    private adjustMenuListWidthWhenAlignBoth(
        alignment: MenuAlignment,
        overlayRef: OverlayRef,
        alignElementRef: ElementRef<HTMLElement>,
    ) {
        if (alignment !== 'both') {
            return;
        }
        const menuList = overlayRef.overlayElement.querySelector(
            '.dxy-menu-list',
        ) as HTMLElement;
        if (!menuList) {
            return;
        }
        const alignElementWidth = alignElementRef.nativeElement.offsetWidth;
        menuList.style.width = `${alignElementWidth}px`;
    }
}
