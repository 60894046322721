import { deserialize, inheritSerialization } from 'cerialize';
import { BaseDTO } from '@datagalaxy/dg-object-model';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

@inheritSerialization(BaseDTO)
export class TechnologyDto {
    @deserialize public DisplayName!: string;
    @deserialize public TechnologyCode!: string;
    @deserialize public Modules: Array<string> = [];
    @deserialize public DescriptionFr?: string;
    @deserialize public DescriptionEn?: string;
    @deserialize public ImageHash?: string;
    @deserialize public SvgImageHash?: string;
    @deserialize public CreationTime?: string;
    @deserialize public CreationUserId?: string;
    @deserialize public LastModificationTime?: string;
    @deserialize public LastModificationUserId?: string;
    @deserialize public LastUsageTime?: string;
    @deserialize public LastUsageUserId?: string;

    public dgModules!: DgModule[];
}
