import {
    Directive,
    ElementRef,
    Renderer2,
    AfterViewInit,
    inject,
} from '@angular/core';

@Directive({
    selector: '[dxyFilterCarouselItem]',
    standalone: true,
})
export class FilterCarouselItemDirective implements AfterViewInit {
    private el = inject(ElementRef);
    private renderer = inject(Renderer2);

    ngAfterViewInit() {
        this.renderer.setStyle(this.el.nativeElement, 'flex', '0 0 auto');
    }

    public getWidth() {
        return this.el.nativeElement.offsetWidth;
    }
}
