import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { BaseAttributeFilter } from '../base-attribute-filter.component';
import { AttributeEntityFilterModel } from './AttributeEntityFilterModel';
import { AttributeFilterService } from '../attribute-filter.service';
import {
    DxyValueListFilterComponent,
    IValueListFilterConfig,
    IValueListFilterData,
    ValueListFilterOperator,
} from '@datagalaxy/core-ui/filters';
import { EntityType } from '@datagalaxy/dg-object-model';
import { IOptionAdapter, UiOptionSelectDataType } from '@datagalaxy/core-ui';
import { ICellRenderData } from '@datagalaxy/core-ui/cell-components';
import { EntityCardCellComponent } from '../../../entityCard/entity-card/entity-card-cell.component';
import { multiSelectEntityCardParams } from '../../../entityCard/entity-card/entity-card-cell.types';
import { CollectionsHelper, StringUtil } from '@datagalaxy/core-util';
import { SearchUsage } from '../../../util/DgServerTypesApi';
import { CurrentSearch } from '../../../../search/models/CurrentSearch';
import { AttributeFilterModel } from '../attribute-filter/attributeFilterModel';
import { EntityLinkService } from '../../../../entity-links/entity-link.service';
import { SearchService } from '../../../../search/search.service';
import { ViewTypeService } from '../../../../services/viewType.service';
import { AttributeFilterAction } from '../attribute-filter.types';
import { FilteredEntityItem } from '@datagalaxy/webclient/search/data-access';
import { EntityTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { getAttributeTypeGlyphClass } from '@datagalaxy/webclient/attribute/feature';
import { Filter, FilterOperator } from '@datagalaxy/filter-domain';
import { AttributeMetaValue } from '@datagalaxy/webclient/attribute/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import PropertyName = ServerConstants.PropertyName;
import { ModuleStore } from '../../../../module/module.store';
import { DataUtil } from '../../../util/DataUtil';
import { AttributeQuickFiltersComponent } from '../attribute-quick-filters/attribute-quick-filters.component';
import { AttributeFilterComponent } from '../attribute-filter/attribute-filter.component';
import { NgIf, NgFor } from '@angular/common';

/**
 * ## Role
 * Display an entity attribute filter
 */
@Component({
    selector: 'app-attribute-entity-filter',
    templateUrl: './attribute-entity-filter.component.html',
    styleUrls: ['./attribute-entity-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        DxyValueListFilterComponent,
        NgIf,
        NgFor,
        forwardRef(() => AttributeFilterComponent),
        AttributeQuickFiltersComponent,
    ],
})
export class AttributeEntityFilterComponent
    extends BaseAttributeFilter<AttributeEntityFilterModel>
    implements OnInit, OnChanges
{
    // As decided by Func Team
    private static readonly MaxResultCountNoTerm = 50;
    private static readonly MaxResultCountWithTerm = 50;

    @ViewChild(DxyValueListFilterComponent)
    filterComponent: DxyValueListFilterComponent<AttributeMetaValue>;

    public quickFilters: Filter[] = [];
    public currentSearch: CurrentSearch;
    public searchFilters: AttributeFilterModel[] = [];

    public filter: IValueListFilterData<AttributeMetaValue>;
    public filterOptions: AttributeMetaValue[];
    public filterAdapter: IOptionAdapter<AttributeMetaValue> = {
        getIconUrl: (amv) => amv?.iconUrl,
        getId: (amv) => amv.Key,
        getText: (amv) => amv.translatedDisplayName,
        getRenderData: (amv) => this.getRenderData(amv),
        getGlyphClass: (amv) => amv?.glyphClass,
    };
    public filterConfig: IValueListFilterConfig = {
        hasSearch: true,
        dataType: UiOptionSelectDataType.entityReference,
        sortOptions: (options) => this.sortOptions(options),
        onSearch: (searchTerm) => this.onSearch(searchTerm),
    };
    public dataType = UiOptionSelectDataType.entityReference;

    private entities?: FilteredEntityItem[];

    public get hasQuickFilters() {
        return this.quickFilters?.length;
    }
    public get hasSearchFilters() {
        return this.searchFilters?.length;
    }
    public get iconClass() {
        return getAttributeTypeGlyphClass(this.attributeType);
    }
    public get operators() {
        return this.filterItemData.operators.map((op) =>
            this.getTypedOperator(op),
        );
    }

    private get isTechnicalView() {
        return this.viewTypeService.isTechnicalView;
    }

    constructor(
        attributeFilterService: AttributeFilterService,
        private linkedObjectService: EntityLinkService,
        private searchService: SearchService,
        private viewTypeService: ViewTypeService,
        private moduleStore: ModuleStore,
        private cd: ChangeDetectorRef,
    ) {
        super(attributeFilterService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.initAsync();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'filterItemData', () => this.initAsync());
    }

    public onFilterChange() {
        this.filterItemData.operator =
            FilterOperator[ValueListFilterOperator[this.filter.operator]];
        this.filterItemData.values = this.filter.values;
        this.onAction.emit(AttributeFilterAction.itemChanged);
    }

    public async selectQuickFilterEvent(afm: AttributeFilterModel) {
        this.currentSearch.addFilterItem(afm);
        this.setFilters();
        await this.filterComponent.triggerSearch();
    }

    public async onFilterItemAction(
        afm: AttributeFilterModel,
        action: AttributeFilterAction,
    ) {
        switch (action) {
            case AttributeFilterAction.itemRemoved:
                await this.onFilterRemove(afm);
                break;
        }
    }

    /**
     * Separate selected options from unselected and order selected options
     * with alphaNumeric order, keep unselected options ordered by score from request response
     */
    private sortOptions(options: AttributeMetaValue[]): AttributeMetaValue[] {
        const selectedIds = this.filter.values?.map((item) => item.Key);
        return CollectionsHelper.orderBy(options, [
            (o) => (selectedIds?.includes(o.Key) ? 1 : 2),
            (o) =>
                selectedIds?.includes(o.Key)
                    ? StringUtil.normalizeForSearch(o.translatedDisplayName)
                    : null,
        ]);
    }

    private async onSearch(searchTerm: string) {
        const afm = this.filterItemData,
            ami = afm.attributeMeta;
        const maxCount = searchTerm
            ? AttributeEntityFilterComponent.MaxResultCountWithTerm
            : AttributeEntityFilterComponent.MaxResultCountNoTerm;
        this.currentSearch.searchTerm = searchTerm;
        const res = await this.searchService.searchPreview(this.currentSearch, {
            searchUsage: SearchUsage.FilterEntity,
            maxCount,
            excludedIds: this.filter.values?.map((amv) => amv.Key),
            includedAttributesFilter: [PropertyName.EntityStatus],
        });
        this.entities = res?.allItems;
        return res?.allItems.map((ei) =>
            AttributeMetaValue.fromHierarchicalData(
                ei.HddData,
                ami,
                EntityTypeUtils.getColoredGlyphClass(ei.HddData.entityType),
                this.isTechnicalView,
            ),
        );
    }

    private async initAsync() {
        const selectedItems = this.filterItemData.values;
        this.filter = {
            operator: this.getTypedOperator(this.filterItemData.operator),
            isRestricted: (amv) => !(amv?.hddData?.Data?.HasReadAccess ?? true),
            values: selectedItems,
        };

        const afm = this.filterItemData,
            ami = afm.attributeMeta;
        const includedEntityTypes =
            this.linkedObjectService.getEntityTypesForFilterCriteria(
                ami,
                afm.dgZone,
                afm.dgModule,
            );

        const filters = [
            new Filter(
                ServerConstants.Search.EntityTypeFilterKey,
                FilterOperator.ListContains,
                includedEntityTypes.map((et) => EntityType[et]),
            ),
        ];
        this.currentSearch =
            await this.searchService.getCurrentSearchFromFilters(
                filters,
                afm.spaceIdr?.spaceId,
                afm.spaceIdr?.versionId,
            );
        this.setQuickFilters();

        this.cd.detectChanges();
        setTimeout(() => this.filterComponent.refreshUi());
    }

    private getTypedOperator(filterOperator: FilterOperator) {
        const operatorText = FilterOperator[filterOperator];
        return ValueListFilterOperator[operatorText];
    }

    private async onFilterRemove(afm: AttributeFilterModel) {
        this.currentSearch.removeFilterItem(afm);
        this.setFilters();
        await this.filterComponent.triggerSearch();
    }

    public setQuickFilters() {
        const afm = this.filterItemData,
            ami = afm.attributeMeta;
        const entityTypeFilters = this.currentSearch.filterItems?.filter(
            (filter) =>
                filter.attributePath ===
                ServerConstants.Search.EntityTypeFilterKey,
        );
        if (entityTypeFilters?.length > 1) {
            this.quickFilters = [];
            return;
        }
        const includedEntityTypes = this.linkedObjectService
            .getEntityTypesForFilterCriteria(ami, afm.dgZone, afm.dgModule)
            .filter((et) => {
                const module = DataUtil.getModuleFromEntityType(et);
                return this.moduleStore.hasAccess(module);
            });
        if (includedEntityTypes?.length <= 1) {
            return;
        }
        this.quickFilters = includedEntityTypes.map(
            (type) =>
                new Filter(
                    ServerConstants.Search.EntityTypeFilterKey,
                    FilterOperator.ListContains,
                    [EntityType[type]],
                ),
        );
    }

    private setFilters() {
        this.searchFilters = this.currentSearch?.filterItems.filter(
            (afm) =>
                afm.attributeKey !=
                    ServerConstants.Search.SearchTermFilterKey &&
                (afm.attributeKey !==
                    ServerConstants.Search.EntityTypeFilterKey ||
                    afm.getValuesAsArray()?.length == 1),
        );
        this.setQuickFilters();
    }

    private getRenderData(amv: AttributeMetaValue): ICellRenderData {
        return {
            renderer: EntityCardCellComponent,
            param: {
                ...multiSelectEntityCardParams,
                inputs: {
                    ...multiSelectEntityCardParams.inputs,
                    getAttributes: (amv: AttributeMetaValue) =>
                        this.entities?.find(
                            (entity) =>
                                entity.ReferenceId === amv.hddData.ReferenceId,
                        )?.Attributes,
                },
                data: amv,
            },
        };
    }
}
