import { inject, Injectable } from '@angular/core';
import { Technology } from '@datagalaxy/technology/domain';
import { BaseServiceParameter } from '@datagalaxy/data-access';
import { TechnologyAdapter } from './technology-adapter.service';
import { TechnologyApiService } from './technology-api.service';

@Injectable({
    providedIn: 'root',
})
export class TechnologyService {
    private technologyApiService = inject(TechnologyApiService);
    private technologyMapperService = inject(TechnologyAdapter);

    public async getTechnologies(): Promise<Technology[]> {
        const result = await this.technologyApiService.getTechnologies(
            new BaseServiceParameter(),
        );

        return result.Technologies.map((dto) =>
            this.technologyMapperService.mapDtoToTechnology(dto),
        );
    }
}
