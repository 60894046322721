import { Injectable } from '@angular/core';
import {
    IEntityToolParams,
    IEntityToolState,
} from '../interfaces/entity-panel.interface';
import { EntityService } from './entity.service';
import { IDragDropConfig, KeyboardEventService } from '@datagalaxy/core-ui';
import { map, ReplaySubject } from 'rxjs';
import {
    EntityType,
    EntityTypeUtil,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { PreviewPanelService } from '../../shared-ui/preview-panel.service';
import { DxyModalService } from '../../dialogs/DxyModalService';
import { DxyEntityPreviewPanelContentComponent } from '../../../entity-panels/dxy-entity-preview-panel-content/dxy-entity-preview-panel-content.component';
import { ScreenService } from '../../../screens/screen.service';
import { BaseEntityToolService } from '../../../entity-panels/BaseEntityToolService';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';
import { DataProcessingUiService } from '../../../data-processing/services/data-processing-ui.service';
import { WorkspaceService } from '@datagalaxy/webclient/workspace/data-access';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** ## Role:
 * Manage the entity preview panel content */
@Injectable({ providedIn: 'root' })
export class EntityPreviewPanelService extends BaseEntityToolService<
    IEntityToolParams,
    IEntityToolState
> {
    public static isPanelAvailable(entityType: EntityType) {
        switch (EntityTypeUtil.getServerType(entityType)) {
            case ServerType.Model:
            case ServerType.Container:
            case ServerType.Table:
            case ServerType.Column:
            case ServerType.Property:
            case ServerType.SoftwareElement:
            case ServerType.DataProcessing:
            case ServerType.Diagram:
                return true;
            default:
                return false;
        }
    }

    public get hidden$() {
        return this.previewPanelService.visible$.pipe(
            map((visible) => !visible),
        );
    }
    public get isPanelHidden() {
        return this.previewPanelService.isPanelHidden;
    }
    public get isPanelVisible() {
        return !this.isPanelHidden;
    }

    public get dragDropConfig$() {
        return this.dragDropConfig.asObservable();
    }

    private dragDropConfig = new ReplaySubject<IDragDropConfig | null>();

    constructor(
        screenService: ScreenService,
        entityService: EntityService,
        dxyModalService: DxyModalService,
        workspaceService: WorkspaceService,
        private previewPanelService: PreviewPanelService,
        private modalService: DxyModalService,
        private dataProcessingUiService: DataProcessingUiService,
        keyboardEvent: KeyboardEventService,
    ) {
        super(screenService, entityService, workspaceService);

        keyboardEvent
            .onEscape()
            .pipe(takeUntilDestroyed())
            .subscribe(() => {
                if (!dxyModalService.isActiveModal) {
                    this.hidePanel();
                }
            });
    }

    public setupDragDrop(dragDropConfig: IDragDropConfig) {
        this.dragDropConfig.next(dragDropConfig);
    }
    public clearDragDrop() {
        this.dragDropConfig.next(null);
    }

    public hidePanel() {
        this.previewPanelService.hidePanel();
    }

    /** called by any component or service to show the panel */
    public async setupPanel(params: IEntityToolParams) {
        this.log('setupPanel', params);
        const previousEntityIdr = this.parameters?.entityIdr;
        super.setupPanel(params);
        this.parameters.enableToolDetails ??= true;

        const entityIdr = params.entityIdr;
        const sameEntityRequested = EntityIdentifier.areSame(
            entityIdr,
            previousEntityIdr,
        );

        const readOnly = params.readOnly || this.modalService.isActiveModal;
        const noNavLink = params.noNavLink || this.modalService.isActiveModal;

        if (readOnly) {
            this.dragDropConfig.next(undefined);
        }

        if (entityIdr.entityType === EntityType.DataProcessingItem) {
            return this.dataProcessingUiService.showDpiEditModalFromEntityIdr(
                entityIdr,
            );
        }

        return this.previewPanelService.setupPanel({
            component: DxyEntityPreviewPanelContentComponent,
            inputs: {
                entityIdr,
                preferredTool: this.activeTool,
                readOnly,
                noNavLink,
            },
            isSameInput: sameEntityRequested,
        });
    }
}
