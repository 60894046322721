import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CurrentObjectResolver {
    private router: Router = inject(Router);

    public getObjectId() {
        return this.searchEntityId(this.router.routerState.root.children);
    }

    private searchEntityId(children: ActivatedRoute[]): string | undefined {
        if (children.length === 0) {
            return;
        }

        const entityId = children[0].snapshot.params['entityId'];
        if (entityId) {
            return entityId;
        }

        return this.searchEntityId(children[0].children);
    }
}
