import { Component, ElementRef, forwardRef, OnInit } from '@angular/core';
import { DxyAttributeBaseInput } from '../DxyAttributeBaseInput';
import { TranslateService } from '@ngx-translate/core';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { EntityEventService } from '../../../entity/services/entity-event.service';
import { DxyNavBreadcrumbComponent } from '../../../../navigation/dxy-nav-breadcrumb/dxy-nav-breadcrumb.component';
import { NgIf } from '@angular/common';
import { TooltipDirective } from '@datagalaxy/ui/tooltip';

@Component({
    selector: 'dxy-attribute-logical-path-input',
    templateUrl: 'dxy-attribute-logical-path-input.component.html',
    standalone: true,
    imports: [
        NgIf,
        forwardRef(() => DxyNavBreadcrumbComponent),
        TooltipDirective,
    ],
})
export class DxyAttributeLogicalPathInputComponent
    extends DxyAttributeBaseInput<void>
    implements OnInit
{
    public hierarchicalData: HierarchicalData;

    constructor(
        private entityEventService: EntityEventService,
        elementRef: ElementRef<HTMLElement>,
        translate: TranslateService,
    ) {
        super(elementRef, translate);
    }

    ngOnInit() {
        const entity = this.getEntityData();
        if (entity) {
            this.hierarchicalData = entity.HddData;
            super.registerSubscription(
                this.entityEventService.subscribeEntityUpdate(
                    entity.ServerType,
                    (data) => {
                        if (data.DataReferenceId == entity.DataReferenceId) {
                            this.hierarchicalData = entity.HddData;
                        }
                    },
                ),
            );
        }
        super.ngOnInit();
    }

    //#region IAttributeBaseInputOverride
    public isEmpty() {
        return false;
    }

    //#endregion
}
