import { AppDataService } from '../../services/app-data.service';
import { ServerTimeService } from '../../services/serverTime.service';
import { IApiConfig } from '@datagalaxy/data-access';
import { AuthenticationService } from '@datagalaxy/webclient/auth/feature';
import { AppConfigService } from '@datagalaxy/webclient/config';

export function backendApiFactory(
    appConfigService: AppConfigService,
    appDataService: AppDataService,
    serverTimeService: ServerTimeService,
    authenticationService: AuthenticationService,
    apiBaseUrl: string,
): IApiConfig {
    return {
        baseUrl: apiBaseUrl,
        getUserSessionId: () => appDataService.userSessionId,
        getJWT: async () =>
            appConfigService.ENABLE_AUTH_V2
                ? await authenticationService.getAccessTokenFromStorage()
                : appDataService.accessToken,
        getServerTimeString: () => serverTimeService.getServerNowTimeString(),
        clientVersion: appConfigService.clientAppHttpHeaderValue,
    };
}
