import { RichTextContent } from '@datagalaxy/rich-text-utils';
import { IQuillContent } from './editor/rich-text-editor-quill.types';
import { IRichTextContentAdapter } from './editor/rich-text-editor.types';

/** Adapter for RichTextEditor and DxyRichTextFieldControl (which use Quill editor) to use RichTextContent)
 *
 * Note that it does not implement mention management. */
export class SimpleQuillContentAdapter implements IRichTextContentAdapter {
    deserialize(rawTextOrJsonString: string): IQuillContent {
        return RichTextContent.fromJsonOrRawText(
            rawTextOrJsonString,
        ) as IQuillContent;
    }
    serialize(doc: IQuillContent): string {
        return doc instanceof RichTextContent ? doc.toJsonString() : undefined;
    }
    isEmpty(doc: IQuillContent): boolean {
        return doc instanceof RichTextContent
            ? doc.isEmpty()
            : doc == null
              ? true
              : undefined;
    }
}
