import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { FirstAccessService } from './first-access.service';
import { ClientRouterService } from '@datagalaxy/webclient/client/feature';
import { AppConfigService } from '@datagalaxy/webclient/config';

export const FirstAccessGuard: CanActivateFn = async () => {
    const firstAccessService = inject(FirstAccessService);
    const router = inject(Router);

    const isFirstAccessCompleted = await firstAccessService.isCompleted();

    if (isFirstAccessCompleted) {
        return router.createUrlTree(['/']);
    }

    return true;
};

export const ClientFirstAccessGuard: CanActivateFn = async (
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const firstAccessService = inject(FirstAccessService);
    const appConfifService = inject(AppConfigService);
    const clientRouter = inject(ClientRouterService);
    const router = inject(Router);

    const isFirstAccessCompleted = await firstAccessService.isCompleted();

    if (isFirstAccessCompleted) {
        return true;
    }

    firstAccessService.saveUrl(state.url);

    if (appConfifService.ENABLE_AUTH_V2) {
        return router.navigate(['/first-access']);
    }

    const clientUrlCommands = clientRouter.getClientUrlCommands();
    return router.navigate([...clientUrlCommands, 'first-access']);
};
