import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { EntityCommentaryDTO } from '@datagalaxy/dg-object-model';
import { CommentaryService } from '../commentary.service';
import { RichTextMentionService } from '../../shared/richText/mentions/rich-text-mention.service';
import { UserService } from '../../services/user.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { IMiniEntityContent } from '@datagalaxy/webclient/entity/domain';
import { EntityFunctionalLogService } from '@datagalaxy/webclient/entity/data-access';
import { DxyLogFunctionalDirective } from '../../directives/dxy-log-functional.directive';
import { TranslateModule } from '@ngx-translate/core';
import { DxyRichTextFieldComponent } from '@datagalaxy/core-ui/rich-text';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { DxyProfileAvatarComponent } from '../../shared/shared-ui/dxy-profile-avatar/dxy-profile-avatar.component';
import {
    DxyButtonDirective,
    DxyIconButtonDirective,
} from '@datagalaxy/ui/buttons';
import { DataUtil } from '../../shared/util/DataUtil';
import { TooltipDirective } from '@datagalaxy/ui/tooltip';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-commentary-item',
    templateUrl: 'dxy-commentary-item.component.html',
    styleUrls: ['dxy-commentary-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        DxyProfileAvatarComponent,
        NgIf,
        FormsModule,
        DxyRichTextFieldComponent,
        TranslateModule,
        DxyLogFunctionalDirective,
        DxyButtonDirective,
        DxyIconButtonDirective,
        TooltipDirective,
    ],
})
export class DxyCommentaryItemComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() entityData: IMiniEntityContent;
    @Input() commentary: EntityCommentaryDTO;
    @Input() isEditActive = false;
    @Input() readOnly: boolean;
    @Output() readonly onAfterFormAction = new EventEmitter<void>();
    @Output() readonly onEditCommentary = new EventEmitter<void>();
    @Output() readonly onDeleteCommentary = new EventEmitter<void>();

    public get userName() {
        return this.userService.getUserName(this.commentary.CreationUserId);
    }
    public get mentionResolvers() {
        const module = DataUtil.getModuleFromServerType(
            this.entityData.ServerType,
        );
        return this.richTextMentionService.getDefaultMentionResolvers(
            this.entityData.ReferenceId,
            this.entityData.VersionId,
            module,
        );
    }
    public get featureCode() {
        return EntityFunctionalLogService.getFeatureCode(
            this.entityData,
            'SOCIAL_COMMENT',
            'U',
        );
    }
    public get isDeleteEnabled() {
        return this.isEditEnabled;
    }
    public get modificationDate() {
        return this.commentaryService.getCreationDate(this.commentary);
    }
    public get isEditEnabled() {
        return (
            !this.readOnly &&
            !this.isEditActive &&
            (!!this.entityData?.SecurityData?.HasAdministratorAccess ||
                this.commentaryService.isCurrentUserCommentaryOwner(
                    this.commentary,
                ))
        );
    }
    public get isSubmitUpdateCommentaryDisabled() {
        return (
            this.isUpdating ||
            this.commentary.Text === this.commentaryTextCopy ||
            !this.commentary.Text
        );
    }

    private commentaryTextCopy: string;
    private isUpdating = false;

    constructor(
        private commentaryService: CommentaryService,
        private richTextMentionService: RichTextMentionService,
        private userService: UserService,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onChange(
            changes,
            'isEditActive',
            () => (this.commentaryTextCopy = this.commentary.Text),
        );
    }

    ngOnInit() {
        this.commentaryTextCopy = this.commentary.Text;
    }

    public deleteCommentary() {
        if (this.readOnly) {
            return;
        }
        this.onDeleteCommentary.emit();
    }

    public editCommentary() {
        if (this.readOnly) {
            return;
        }
        this.onEditCommentary.emit();
    }

    public async cancelUpdateCommentary() {
        if (this.commentary.Text !== this.commentaryTextCopy) {
            const confirmed = await this.commentaryService.confirmCancelEdit();
            if (!confirmed) {
                return;
            }
        }
        this.commentary.Text = this.commentaryTextCopy;
        this.onAfterFormAction.emit();
    }

    public async submitUpdateCommentary() {
        if (this.readOnly || !this.entityData) {
            return;
        }
        this.isUpdating = true;
        try {
            await this.commentaryService.updateCommentary(
                this.entityData,
                this.commentary.ReferenceId,
                this.commentary.Text,
            );
        } finally {
            this.isUpdating = false;
            this.onAfterFormAction.emit();
        }
    }
}
