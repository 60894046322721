import { Injectable } from '@angular/core';
import { CoreUtil } from '@datagalaxy/core-util';
import { IEntityIdentifier, ServerType } from '@datagalaxy/dg-object-model';
import {
    EntityApiService,
    GetLinkedDataParameter,
    LoadEntityParameter,
    LoadMultiEntityParameter,
} from '@datagalaxy/webclient/entity/data-access';
import { Subject } from 'rxjs';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { Filter } from '@datagalaxy/filter-domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

@Injectable({ providedIn: 'root' })
export class EntityLoader {
    private entityLoadedSubject = new Subject<EntityItem>();
    public entityLoaded$ = this.entityLoadedSubject.asObservable();

    private entitiesLoadedSubject = new Subject<EntityItem[]>();
    public entitiesLoaded$ = this.entitiesLoadedSubject.asObservable();

    constructor(private entityApiService: EntityApiService) {}

    public async loadEntity(
        identifier: IEntityIdentifier,
        opt: {
            includeHddSecurityAndSocialData?: boolean;
            includeHdd?: boolean;
            includeSecurity?: boolean;
            includeSocialData?: boolean;
            includedAttributesFilter?: string[];
        } = {},
    ): Promise<EntityItem> {
        const entityId = identifier.ReferenceId;

        const parameter = new LoadEntityParameter(
            entityId,
            identifier.ServerType,
            identifier.VersionId,
            opt.includeHddSecurityAndSocialData,
            opt.includedAttributesFilter,
        );
        if (!opt.includeHddSecurityAndSocialData) {
            parameter.IncludeHdd = opt.includeHdd;
            parameter.IncludeSecurity = opt.includeSecurity;
            parameter.IncludeSocialData = opt.includeSocialData;
        }
        const result = await this.entityApiService.loadEntity(parameter);
        const entity = result.Entity;
        this.entityLoadedSubject.next(entity);
        return entity;
    }

    /** NOTE: Specifying Size = 0 will only return Count, and no entity */
    public async getEntities(
        spaceIdr: IWorkspaceIdentifier,
        serverType: ServerType | ServerType[],
        includeHdd: boolean,
        startIndex: number,
        size = 20,
        filters: Filter[] = [],
        includedAttributesFilter: string[] = [],
        primaryName: string,
        sortKey: string,
        isLogicalHierarchyMode = false,
        maxHierarchicalLevel = 0,
    ) {
        if (sortKey == undefined) {
            sortKey = primaryName;
        }
        const searchColumns = primaryName;

        const localFilters = (filters && CoreUtil.cloneDeep(filters)) || [];
        localFilters
            .filter((f) => !f.SearchValues)
            .forEach((f) => (f.SearchValues = ['']));

        const parameter = new LoadMultiEntityParameter(
            spaceIdr?.spaceId || '',
            serverType,
            includeHdd,
            startIndex,
            size,
            '',
            searchColumns,
            sortKey,
            includedAttributesFilter,
            localFilters,
            undefined,
            isLogicalHierarchyMode,
            maxHierarchicalLevel,
        );

        parameter.setVersionId(spaceIdr?.versionId ?? '');
        const result = await this.entityApiService.loadMultiEntity(parameter);
        const entities = result.Entities;
        this.entitiesLoadedSubject.next(entities);
        return result;
    }

    public async getLinkedEntities(parameter: GetLinkedDataParameter) {
        const result = await this.entityApiService.getLinkedData(parameter);
        const entities = result.Entities;
        this.entitiesLoadedSubject.next(entities);

        return result;
    }
}
