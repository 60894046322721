import { deburr } from 'lodash';

export class StringUtils {
    /**
     * Normalize text for comparison, removing accents, lowercasing and trimming
     * @param text
     * @returns string
     */
    public static normalize(text: string | undefined): string {
        return deburr(text).toLowerCase().trim();
    }

    public static unCapitalize(text?: string): string {
        return text ? text.charAt(0).toLowerCase() + text.slice(1) : '';
    }
}
