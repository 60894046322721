import { Directive, HostBinding, input } from '@angular/core';
import { FreshDeskLinkResolver } from './freshdesk-link-resolver';

@Directive({
    selector: '[dxyFreshdeskHelpLink]',
    standalone: true,
})
export class DxyFreshdeskHelpLinkDirective {
    freshDeskArticleId = input.required<string>({
        alias: 'dxyFreshdeskHelpLink',
    });

    constructor(protected freshDeskLinkResolver: FreshDeskLinkResolver) {}

    @HostBinding('attr.href') get getHref() {
        return this.freshDeskLinkResolver.getFreshdeskUrlFromArticleId(
            this.freshDeskArticleId(),
        );
    }

    @HostBinding('attr.target') get getTarget() {
        return '_blank';
    }
}
