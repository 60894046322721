import { Component, computed, HostBinding, input } from '@angular/core';
import {
    EllipsisTooltipDirective,
    TooltipDirective,
} from '@datagalaxy/ui/tooltip';
import { IconComponent, IconType } from '../icon/icon.component';

export type IconCardSize = 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'xxl';

@Component({
    standalone: true,
    selector: 'dxy-icon-card',
    templateUrl: './icon-card.component.html',
    imports: [EllipsisTooltipDirective, IconComponent, TooltipDirective],
    styleUrls: ['./icon-card.component.scss'],
})
export class IconCardComponent {
    public icon = input.required<string>();
    public size = input<IconCardSize>('medium');
    public iconType = input<IconType>('glyph');
    public iconTooltip = input<string>('');
    public iconTooltipDisabled = input<boolean>(false);
    public text = input<string>('');

    @HostBinding('class')
    private get sizeClass(): string {
        return this.size();
    }

    protected iconSize = computed(() => {
        switch (this.size()) {
            case 'xs':
                return 'medium';
            case 'small':
                return 'large';
            case 'medium':
                return 'xl';
            case 'large':
                return 'xxl';
            case 'xl':
                return 'xxl';
            case 'xxl':
                return 'xxl';
        }
    });

    protected displayText = computed(() => {
        if (this.size() === 'xs') {
            return false;
        }

        return this.text();
    });

    protected displaySeparator = computed(() => {
        return this.size() === 'xxl' && this.text();
    });
}
