import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import {
    BaseMetaAttributeValue,
    ModelTypeAttributeValue,
} from '@datagalaxy/meta-attribute-value-domain';

export function buildDataModelTypeValue(
    entity: EntityItem,
    baseMetaAttributeValue: BaseMetaAttributeValue,
    typeId: string,
): ModelTypeAttributeValue {
    return {
        ...baseMetaAttributeValue,
        typeId,
        precision: entity.getAttributeValue<number>('Precision') as number,
        size: entity.getAttributeValue<number>('Size') as number,
    };
}

export function serializeModelType(value: ModelTypeAttributeValue) {
    return {
        DataTypeId: value.typeId,
        Precision: value.precision,
        Size: value.size,
    };
}
