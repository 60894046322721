import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostBinding,
    inject,
    input,
} from '@angular/core';
import { MenuDisplayMode } from '../menu/menu-display-mode';

@Directive({
    selector: '[dxyMenuList]',
    standalone: true,
})
export class MenuListDirective implements AfterViewInit {
    displayMode = input<MenuDisplayMode>('default');

    @HostBinding('class.hidden') get hidden() {
        return this.element.children.length === 0;
    }

    private get element() {
        return this.elementRef.nativeElement as HTMLElement;
    }

    private elementRef = inject(ElementRef<HTMLElement>);

    ngAfterViewInit() {
        this.appendCssClass();
    }

    private appendCssClass() {
        this.element.classList.add('dxy-menu-list');
        if (this.displayMode() === 'bordered') {
            this.element.classList.add('bordered');
        }
    }
}
