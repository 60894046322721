import { BaseService } from '@datagalaxy/core-ui';
import { DomUtil, StringUtil } from '@datagalaxy/core-util';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClientErrorService } from './clientError.service';
import { ApiServiceError, ProblemDetails } from '@datagalaxy/data-access';
import { LoggingHttpService } from '../core/api/backend-http-interceptors/logging-http.service';
import { AppConfigService } from '@datagalaxy/webclient/config';
import {
    ToastActionBtn,
    ToastType,
    LegacyToaster,
} from '@datagalaxy/ui/toaster';

/**
 * IMPORTANT: This is a UI service.
 * It must not be referenced by any API service
 *
 */

@Injectable({ providedIn: 'root' })
export class ToasterService extends BaseService {
    constructor(
        private toaster: LegacyToaster,
        private translate: TranslateService,
        private appConfigService: AppConfigService,
        private clientErrorService: ClientErrorService,
        loggingHttpService: LoggingHttpService,
    ) {
        super();
        loggingHttpService.genericErrorForToaster$.subscribe((apiError) => {
            this.showApiErrorGeneric(apiError);
        });
        loggingHttpService.problemDetailsForToaster$.subscribe(
            (problemDetails) => {
                this.showProblemDetails(problemDetails);
            },
        );
    }

    /** @deprecated use Toaster from libs/ui/toaster instead */
    public successToast(options: WebclientShowToasterOptions) {
        this.showToast(ToastType.Success, options);
    }

    /** @deprecated use Toaster from libs/ui/toaster instead */
    public errorToast(options: WebclientShowToasterOptions) {
        this.showToast(ToastType.Error, options);
    }

    /** @deprecated use Toaster from libs/ui/toaster instead */
    public warningToast(options: WebclientShowToasterOptions) {
        this.showToast(ToastType.Warning, options);
    }

    /** @deprecated use Toaster from libs/ui/toaster instead */
    public infoToast(options: WebclientShowToasterOptions) {
        this.showToast(ToastType.Info, options);
    }

    public showRequestBlockedError() {
        this.infoToast({
            titleKey: 'UI.Notification.RequestBlockedByClientError.title',
            messageKey: 'UI.Notification.RequestBlockedByClientError.msg',
        });
    }

    public showRequestTimeoutError() {
        this.warningToast({
            titleKey: 'UI.Notification.RequestTimeoutByClientError.title',
            messageKey: 'UI.Notification.RequestTimeoutByClientError.msg',
        });
    }

    public showToast(
        toastType: ToastType,
        options: WebclientShowToasterOptions,
    ) {
        const title = options.titleKey
            ? this.translate.instant(options.titleKey)
            : '';
        let message = options.messageKey
            ? this.translate.instant(options.messageKey, options.messageParams)
            : '';

        if (toastType == ToastType.Error) {
            if (!options.apiServiceError && options.additionalMessageContent) {
                message += this.getRawText(options.additionalMessageContent);
            }
            const error = options.apiServiceError
                ? options.apiServiceError
                : new Error(message);
            this.clientErrorService.notify(error, '', 'toaster');
        }

        if (
            this.appConfigService.showServerErrorDetails &&
            options.additionalMessageContent
        ) {
            message += `\r\n${options.additionalMessageContent}`;
        }

        this.toaster.showToast(toastType, {
            title,
            message,
            timeOut: options.timeOut || 5000,
            actionsBtn: options.actionsBtn,
        });
    }

    public showApiErrorGeneric(apiServiceError: ApiServiceError) {
        let finalErrorMessage = '';

        finalErrorMessage += '<br />';
        finalErrorMessage += `<b>${apiServiceError.route}</b><br/>`;
        finalErrorMessage += `${apiServiceError.message}<br/>`;

        if (apiServiceError.error) {
            for (const key in apiServiceError.error) {
                if (StringUtil.startsWith(key, 'IsError')) {
                    if (apiServiceError.error[key]) {
                        finalErrorMessage += `<br />${key}`;
                    }
                }
            }
        }

        finalErrorMessage +=
            '\n\nParameter: ' + JSON.stringify(apiServiceError.parameter);

        const copyErrorBtn = <ToastActionBtn>{
            text: this.translate.instant('UI.Global.btnCopyError'),
            callback: () => DomUtil.copyToClipboard(finalErrorMessage),
            glyphClass: 'glyph-file-copy',
        };

        this.errorToast({
            titleKey: 'UI.Notification.ApiErrorGeneric.title',
            messageKey: 'UI.Notification.ApiErrorGeneric.msg',
            additionalMessageContent: finalErrorMessage,
            apiServiceError,
            actionsBtn: [copyErrorBtn],
        });
    }

    public async waitAllToastersAreClosed() {
        await this.toaster.waitAllToastersAreClosed();
    }

    private getRawText(html: string) {
        const tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    }

    private showProblemDetails(problemDetails: ProblemDetails) {
        const titleKey = `ProblemDetails.${problemDetails.title}.title`;
        const messageKey = `ProblemDetails.${problemDetails.title}.message`;
        const translations = this.translate.instant([titleKey, messageKey]);
        this.errorToast({
            titleKey:
                translations[titleKey] === titleKey
                    ? 'UI.Notification.ApiErrorGeneric.title'
                    : titleKey,
            messageKey:
                translations[messageKey] === messageKey
                    ? 'UI.Notification.ApiErrorGeneric.msg'
                    : messageKey,
        });
    }
}

export interface WebclientShowToasterOptions {
    titleKey?: string;
    messageKey?: string;
    messageParams?: object;
    timeOut?: number;
    actionsBtn?: ToastActionBtn[];
    additionalMessageContent?: string;
    apiServiceError?: ApiServiceError;
}
