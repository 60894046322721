import { inject, Injectable } from '@angular/core';
import { GetRecentSearchesResult } from '@datagalaxy/webclient/search/data-access';
import {
    BackendApiService,
    BaseServiceParameter,
} from '@datagalaxy/data-access';
import { inheritSerialization, serialize } from 'cerialize';

@inheritSerialization(BaseServiceParameter)
export class GetRecentSearchesParameter extends BaseServiceParameter {
    @serialize public Size?: number;
}

@Injectable({ providedIn: 'root' })
export class RecentSearchApiService {
    private backendApiService = inject(BackendApiService);

    public async getLastSearchFilters() {
        const parameters = new GetRecentSearchesParameter();
        parameters.Size = 10;
        const searches = await this.getLastSearches(parameters);
        return searches.RecentSearches.map((search) => search.ListFilter);
    }

    public async getLastSearches(param: GetRecentSearchesParameter) {
        return await this.backendApiService.postPromise(
            'SearchHistory/GetRecentSearches',
            param,
            GetRecentSearchesResult,
        );
    }
}
