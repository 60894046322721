import { Injectable } from '@angular/core';
import { SecurityService } from './security.service';
import {
    CommunicationSetting,
    CommunicationType,
    CurrentUserService,
} from '@datagalaxy/webclient/user/data-access';
import { LanguageService } from '@datagalaxy/translate';

@Injectable({ providedIn: 'root' })
export class AnalyticLoginBuilder {
    constructor(
        private currentUserService: CurrentUserService,
        private securityService: SecurityService,
        private languageService: LanguageService,
    ) {}

    public build() {
        const clientInfo = this.currentUserService.clientInfo;
        const userInfo = this.currentUserService.userInfo;
        const communicationSettings =
            this.currentUserService.communicationSettings;
        return {
            privateData: {
                isAuthenticated: true,
                userId: clientInfo.UserId,
                currentClientName: clientInfo.FriendlyName,
                currentClientId: clientInfo.ClientId,
            },
            publicData: {
                firstname: userInfo.FirstName,
                name: userInfo.LastName,
                fullname: userInfo.FullName,
                email: userInfo.Email,
                language: this.languageService.getLanguage(),
                title: userInfo.Title,
                service: userInfo.Service,
                role: userInfo.Role,
                clientid: clientInfo.ClientId,
                firstconnection: clientInfo.FirstConnectionTime,
                lastconnection: clientInfo.LastConnectionTime,
                isinternal:
                    !clientInfo.IsExternalLogin || !clientInfo.IsSsoEnabled,
                isexternal:
                    clientInfo.IsExternalLogin || clientInfo.IsSsoEnabled,
                numberofclient: userInfo.AuthorizedClients?.length,
                category: clientInfo.Category,
                Status: clientInfo.Status,
                UserEngagementLifeCycle: userInfo.EngagementLifeCycle,
                ClientEngagementLifeCycle: clientInfo.EngagementLifeCycle,
                POVStartTime: clientInfo.PovStartTime,
                POVEndTime: clientInfo.PovEndTime,
                TacitAgreementDate: clientInfo.TacitAgreementDate,
                EndOfContractNotificationDate:
                    clientInfo.EndOfContractNotificationDate,
                licenselevel: userInfo.SecurityData.LicenseLevel,
                isSingleWsClient:
                    this.securityService.isSingleWorkspaceClient(),
                groupId: clientInfo.ClientId,
                isUserClientAdmin:
                    this.securityService.isCurrentUserClientAdmin(),
                isOnlineConnectorEnabled:
                    this.securityService.isOnlineConnectorEnabled(),
                clientOfferName: clientInfo.OfferName,
                clientOfferPlan: clientInfo.OfferPlan,
                ...this.getCommunicationSettingsObject(communicationSettings),
            },
        };
    }
    private getCommunicationSettingsObject(
        communicationSettings: CommunicationSetting[],
    ) {
        const data: { [key: string]: boolean } = {};
        communicationSettings.forEach(
            (cs) =>
                (data[`Com_${CommunicationType[cs.CommunicationType]}`] =
                    !!cs.EnableInApp),
        );
        return data;
    }
}
