import { Inject, Injectable } from '@angular/core';
import {
    BACKEND_API_CONFIG,
    BackendApiService,
    IApiConfig,
} from '@datagalaxy/data-access';
import {
    LoadKnownUsersParameter,
    LoadKnownUsersResult,
} from './user-api.types';
import { Location } from '@angular/common';
import {
    DeleteUserSettingValueParameter,
    GetUserSettingCategoryRoutesResult,
    GetUserSettingCategoryValuesParameter,
    GetUserSettingCategoryValuesResult,
    GetUserSettingValueParameter,
    GetUserSettingValueResult,
    SetUserSettingValueParameter,
} from './user-setting-value/user-setting-value.queries';

@Injectable({ providedIn: 'root' })
export class UserApiService {
    public constructor(
        @Inject(BACKEND_API_CONFIG) private config: IApiConfig,
        private backendApiService: BackendApiService,
    ) {}

    public async loadKnownUsers(param: LoadKnownUsersParameter) {
        return await this.backendApiService.postPromise(
            'User/LoadKnownUsers',
            param,
            LoadKnownUsersResult,
        );
    }

    public async getUserSettingValue(param: GetUserSettingValueParameter) {
        return await this.backendApiService.postPromise(
            'User/GetUserSettingValue',
            param,
            GetUserSettingValueResult,
        );
    }

    public async setUserSettingValue(param: SetUserSettingValueParameter) {
        await this.backendApiService.postPromise(
            'User/SetUserSettingValue',
            param,
        );
    }

    public async deleteUserSettingValue(
        param: DeleteUserSettingValueParameter,
    ) {
        await this.backendApiService.postPromise(
            'User/DeleteUserSettingValue',
            param,
        );
    }

    public async getUserSettingCategoryValues(
        param: GetUserSettingCategoryValuesParameter,
    ) {
        return await this.backendApiService.postPromise(
            'User/GetUserSettingCategoryValues',
            param,
            GetUserSettingCategoryValuesResult,
        );
    }

    public async getUserSettingCategoryRoutes(
        param: GetUserSettingCategoryValuesParameter,
    ) {
        return await this.backendApiService.postPromise(
            'User/GetUserSettingCategoryRoutes',
            param,
            GetUserSettingCategoryRoutesResult,
        );
    }

    public getUserImage(imageHash: string) {
        return !imageHash
            ? ''
            : Location.joinWithSlash(
                  this.config.baseUrl,
                  `api/User/GetImage?hash=${encodeURIComponent(imageHash)}`,
              );
    }
}
