export type MetaObjectCardDisplayMode =
    | 'techno-with-chip'
    | 'glyph-with-chip'
    | 'glyph-only'
    | 'techno-only';

/**
 * Important: the display mode is saved with the string value of this enum
 * so we have to make adapter/converter to keep the saved data untouched
 *
 * @deprecated use MetaObjectCardDisplayMode instead
 */
export enum EntityCardDisplayMode {
    TechnoWithChip,
    GlyphWithChip,
    GlyphOnly,
    TechnoOnly,
}
