import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
    serializeAs,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { AttributeFilter } from '@datagalaxy/webclient/attribute/data-access';
import { FilteredEntityItem } from './search';
import { Filter } from '@datagalaxy/filter-domain';

@inheritSerialization(BaseServiceParameter)
export class GetFilteredEntitiesParameter extends BaseServiceParameter {
    @serializeAs(Filter) public Filters: Filter[];
    @serialize public SearchTerm?: string;
    @serialize public MaxCount = 500;
    @serialize public ParentReferenceId?: string;
    /** attribute keys of attributes to be populated in each result entity */
    @serialize public IncludedAttributesFilter: string[];
    @serialize public IncludeSecurityData?: boolean;
    @serialize public IncludeResultFilterData: boolean;
    @serialize public IncludeSearchPreferences?: boolean;
    /** attribute keys of facet filters to be computed */
    @serialize public IncludedResultAttributesFilter: string[];
    /** max number of values for each facet filters to be computed */
    @serialize public AttributeFilterValuesMaxCount = 100;
    @serialize public SortKey?: string;
    @serialize public IncludeQuickFilters?: boolean;
    @serialize public SearchUsage?: string;
    @serialize public ExcludedIds?: string[];
    @serialize public DataReferenceIdList?: string[];

    constructor() {
        super();
        this.Filters = new Array<Filter>();
        this.IncludedAttributesFilter = new Array<string>();
        this.IncludeResultFilterData = false;
        this.IncludedResultAttributesFilter = new Array<string>();
    }
}

@inheritSerialization(BaseServiceResult)
export class GetFilteredEntitiesResult extends BaseServiceResult {
    @deserializeAs(FilteredEntityItem)
    public Entities!: Array<FilteredEntityItem>;
    @deserializeAs(AttributeFilter)
    public AttributeFilters!: Array<AttributeFilter>;
    @deserializeAs(Filter) public QuickFilters!: Array<Filter>;
    @deserializeAs(Filter) public InputFilters!: Filter[];
    @deserialize public TotalCount!: number;
    @deserialize public HasMoreResults!: boolean;
}
