import { Subscription } from 'rxjs';
import { CoreUtil } from '@datagalaxy/core-util';
import { EntityService } from './services/entity.service';
import { EntityType, ServerType } from '@datagalaxy/dg-object-model';
import { SearchUsage } from '../util/DgServerTypesApi';
import { EntityEventService } from './services/entity-event.service';
import {
    GetFilteredEntitiesParameter,
    SearchApiService,
} from '@datagalaxy/webclient/search/data-access';
import { Filter, FilterOperator } from '@datagalaxy/filter-domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import PropertyName = ServerConstants.PropertyName;

/** base class for a sub component managing a list of entities, updated on external events */
export abstract class EntityStoreBase {
    public isLoading: boolean;

    protected get size() {
        return this.entities.size;
    }

    private readonly entities = new Map<string, EntityItem>();
    private subscription: Subscription;
    private options: IGetEntitiesOptions;

    constructor(
        protected searchApiService: SearchApiService,
        protected entityEventService: EntityEventService,
        protected entityService: EntityService,
        protected onChange?: () => Promise<void>,
        public debug = false,
        public logId?: string,
    ) {}

    public dispose() {
        this.subscription?.unsubscribe();
        this.entities.clear();
    }

    protected abstract isManagedEntity(
        entity: EntityItem,
        isInit: boolean,
    ): boolean;

    /** Note: serverTypes is used for event subscription, entityTypes for getEntities */
    protected async initInternal(
        serverTypes: ServerType[],
        entityTypes: EntityType[],
        parentId: string,
        versionId: string,
        searchUsage: SearchUsage,
        opt?: IGetEntitiesOptions,
    ) {
        this.options = opt;
        this.entities.clear();
        this.subscribeEvents(serverTypes);
        this.isLoading = true;
        try {
            const entities = await this.getEntities(
                entityTypes,
                parentId,
                versionId,
                searchUsage,
                opt,
            );
            entities.forEach((ei) => this.onAddOrUpdate(ei, true));
        } finally {
            this.isLoading = false;
            this.debug &&
                this.log('initInternal', {
                    serverTypes: serverTypes?.map((st) => ServerType[st]),
                    entityTypes: entityTypes?.map((et) => EntityType[et]),
                    parentId,
                    versionId,
                    searchUsage,
                    opt,
                    size: this.entities.size,
                });
        }
    }

    protected getAll() {
        return Array.from(this.entities.values());
    }

    private subscribeEvents(serverTypes: ServerType[]) {
        this.subscription?.unsubscribe();
        this.subscription = new Subscription();
        serverTypes.forEach((serverType) => {
            this.subscription.add(
                this.entityEventService.subscribeEntityCreation(
                    serverType,
                    (e) => this.onAddOrUpdate(e.entity),
                ),
            );
            this.subscription.add(
                this.entityEventService.subscribeEntityUpdate(serverType, (e) =>
                    this.onAddOrUpdate(e),
                ),
            );
            this.subscription.add(
                this.entityEventService.subscribeEntityDelete(serverType, (e) =>
                    this.onDeleted(e.deletedIds),
                ),
            );
        });
    }

    /** If entity already in dictionary =>
     * merge Attributes & SecurityData values
     * from http request result to entity.
     * Prevent Attribute values loss */
    private async onAddOrUpdate(entity: EntityItem, isInit = false) {
        const isManaged = this.isManagedEntity(entity, isInit);
        this.log('onAddOrUpdate', isManaged);
        if (!isManaged) {
            return;
        }

        if (isInit) {
            this.entities.set(entity.ReferenceId, entity);
            return;
        }

        const existingEntity = this.entities.get(entity.ReferenceId);
        if (existingEntity) {
            CoreUtil.merge(existingEntity.Attributes, entity.Attributes);
            CoreUtil.merge(existingEntity.SecurityData, entity.SecurityData);
            CoreUtil.merge(existingEntity.HddData, entity.HddData);
        } else {
            this.entities.set(entity.ReferenceId, entity);
        }

        if (entity.EntityType && !entity.Attributes[PropertyName.EntityType]) {
            // EntityType as an attribute is needed at least for the diagrams-list.
            // But when cloning a diagram, this attribute is not set (coming from updateEntity)
            // So, since we have its value, we set it.
            entity.setAttributeValue(
                PropertyName.EntityType,
                entity.EntityType,
            );
        }

        const opt = this.options;
        if (opt?.reloadEntityOnMissingPropertyOrAttribute) {
            const missingHdd = !entity.HddData;
            const missingSecurity =
                opt?.includeSecurityData && !entity.SecurityData;
            const missingAttributes = opt?.specificAttributeKeys?.filter(
                (ak) => !(ak in entity.Attributes),
            );
            if (missingHdd || missingSecurity || missingAttributes?.length) {
                this.log('getting full entity', {
                    entity,
                    missingHdd,
                    missingSecurity,
                    missingAttributes,
                });
                entity = await this.entityService.getEntity(entity, {
                    includeHdd: true,
                    includeSecurity: opt.includeSecurityData,
                    includedAttributesFilter: this.getAttributeKeys(opt),
                });
            }
        }

        this.onChange?.();
    }

    private onDeleted(entityIds: string[]) {
        const managedEntityIds = entityIds?.filter((id) =>
            this.entities.has(id),
        );
        this.log('onDeleted', managedEntityIds);
        if (!managedEntityIds.length) {
            return;
        }
        managedEntityIds.forEach((id) => this.entities.delete(id));
        this.onChange?.();
    }

    protected log(...args: any[]) {
        if (this.debug) {
            console.log(this.constructor.name, this.logId ?? '', ...args);
        }
    }

    private async getEntities(
        entityTypes: EntityType[],
        parentId: string,
        versionId: string,
        searchUsage: SearchUsage,
        opt: IGetEntitiesOptions,
    ) {
        const params = new GetFilteredEntitiesParameter();
        params.Filters = [
            new Filter(
                ServerConstants.Search.EntityTypeFilterKey,
                FilterOperator.ListContains,
                entityTypes.map((et) => EntityType[et]),
            ),
        ];
        params.IncludedAttributesFilter = this.getAttributeKeys(opt);
        params.IncludeSecurityData = opt.includeSecurityData;
        params.SortKey = PropertyName.DisplayName;
        params.SearchUsage = searchUsage;
        params.ParentReferenceId = parentId;
        params.VersionId = versionId;

        if (opt?.inDiagramEntityId) {
            params.Filters.push(
                new Filter(
                    ServerConstants.Diagram.DiagramEntityIds,
                    FilterOperator.ListContains,
                    [opt.inDiagramEntityId],
                ),
            );
        }

        this.log('getEntities', params);
        const result = await this.searchApiService.getFilteredEntities(params);
        return result.Entities;
    }

    private getAttributeKeys(opt: IGetEntitiesOptions) {
        return [
            'ReferenceId',
            'VersionId',
            PropertyName.DisplayName,
            ...(opt?.specificAttributeKeys ?? []),
        ];
    }
}

interface IGetEntitiesOptions {
    specificAttributeKeys?: string[];
    includeSecurityData?: boolean;
    /** id of an entity, to search for diagram entities that contain it */
    inDiagramEntityId?: string;
    reloadEntityOnMissingPropertyOrAttribute?: boolean;
}
