import { BaseGraphicalManager } from '../../BaseGraphicalManager';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { selectAll } from 'd3-selection';
import { GraphManager } from '../../graph-manager';
import { ManagedItem } from '../../node/managed-item';
import { Connector } from '../../connector';
import { IDiagNode } from '../../../diagramming';
import { ISelection, ISelectionOptions } from './selection.types';
import { IGraphEvent, TGraphItem } from '../../graph-manager.types';
import { LayerManager } from '../../layer';
import { TDiagEdge } from '../../edge';
import { TDomElement } from '@datagalaxy/core-2d-util';

export class SelectionTool<NodeData = unknown, EdgeData = unknown>
    extends BaseGraphicalManager
    implements ISelection<NodeData, EdgeData>
{
    private selectionChange = new BehaviorSubject<
        TGraphItem<NodeData, EdgeData>[]
    >([]);
    private selectionUpdate = new BehaviorSubject<
        TGraphItem<NodeData, EdgeData>[]
    >([]);
    private readonly modeChanged = new BehaviorSubject<'pan' | 'select'>('pan');

    public get selectionCount() {
        return this.selection?.length;
    }
    public get selection() {
        return this.selectionChange.value;
    }
    public get selection$() {
        return this.selectionChange.asObservable();
    }
    public get selectionUpdated$() {
        return this.selectionUpdate.asObservable();
    }
    public get isSelectMode() {
        return this.modeChanged.value === 'select';
    }
    public get modeChanged$() {
        return this.modeChanged.asObservable();
    }

    public get nodeSelection$() {
        return this.selection$.pipe(map((items) => items.filter(this.isNode)));
    }

    public get edgeSelection$() {
        return this.selection$.pipe(
            map((items) => items.filter(this.isConnector)),
        );
    }

    public get nodeSelection(): ManagedItem<NodeData>[] {
        return this.selection.filter(this.isNode);
    }

    public get connectorSelection(): Connector<NodeData, EdgeData>[] {
        return this.selection.filter(this.isConnector);
    }

    public get selectedNodes$(): Observable<IDiagNode<NodeData>[]> {
        return this.nodeSelection$.pipe(
            map((nodes) =>
                nodes?.map((n) => this.graphManager.getNodeById(n.id)),
            ),
        );
    }

    public get selectedNodes(): IDiagNode<NodeData>[] {
        return this.nodeSelection.map((it) =>
            this.graphManager.getNodeById(it.id),
        );
    }

    public get selectedEdges(): TDiagEdge<NodeData, EdgeData>[] {
        return this.connectorSelection.map((c) =>
            this.graphManager.getEdgeFromConnector(c),
        );
    }

    private get selectedClass() {
        return /*this.params?.selectedClass ?? */ 'selected';
    }

    constructor(
        private graphManager: GraphManager<NodeData, EdgeData>,
        private layerTool: LayerManager,
        private options: ISelectionOptions<TDomElement, NodeData>,
    ) {
        super();

        super.subscribe(graphManager.items$, (event) =>
            this.onGraphChange(event),
        );
    }

    /** activates or deactivates the selection mode */
    public setSelectMode(isSelect: boolean): void {
        if (isSelect) {
            this.toSelectMode();
        } else {
            this.toPanMode();
        }
    }

    public detach() {}

    public select(...items: TGraphItem<NodeData, EdgeData>[]) {
        this.updateSelection(items);
    }

    public add(item: TGraphItem<NodeData, EdgeData>) {
        this.updateSelection([...this.selection, item]);
    }

    public addToSelection(...ids: string[]): void {
        const managedItems = ids
            ?.map((id) => this.graphManager.getNodeById(id))
            .map((node) => node.data);

        this.updateSelection(
            CollectionsHelper.distinct([...this.selection, ...managedItems]),
        );
    }

    public unselect(...ids: string[]): void {
        const managedItems = ids
            ?.map((id) => this.graphManager.getNodeById(id))
            .map((node) => node.data);
        this.remove(...managedItems);
    }

    public remove(...items: TGraphItem<NodeData, EdgeData>[]): void {
        const res = this.selection.filter((it) => !items.includes(it));
        this.updateSelection(res);
    }

    public unselectAll() {
        this.updateSelection([]);
    }

    public selectAll(): void {
        this.updateSelection(this.graphManager.items);
    }

    public isSelected(id: string): boolean {
        return !!(
            this.graphManager.getNodeById(id) ||
            this.graphManager.getEdgeById(id)
        );
    }

    private updateSelection(items: TGraphItem<NodeData, EdgeData>[]) {
        if (this.options?.disabled) {
            return;
        }
        const removedItems = CollectionsHelper.difference(
            this.selection,
            items,
        );
        this.setSelectedClass(removedItems, false);
        this.setSelectedClass(items, true);
        items.forEach((item) => {
            this.layerTool.bringToFront(item);
            item.updateProps({ selected: true });
        });
        removedItems.forEach((item) => {
            if (!item?.el) {
                return;
            }
            this.layerTool.bringBackToInitial(item);
            item.updateProps({ selected: false });
        });
        this.selectionChange.next(items);
    }

    private onGraphChange(event: IGraphEvent) {
        const removedItems = event.removed;
        const updatedItems = event.updated;

        if (
            updatedItems?.some((it) =>
                this.selection.includes(it as TGraphItem<NodeData, EdgeData>),
            )
        ) {
            this.selectionUpdate.next(this.selection);
        }
        if (!removedItems?.length) {
            return;
        }
        this.remove(...(removedItems as TGraphItem<NodeData, EdgeData>[]));
    }

    private setSelectedClass(
        items: TGraphItem<NodeData, EdgeData>[],
        selected: boolean,
    ) {
        const elements = items.map((it) => it.el);
        selectAll(elements).classed(this.selectedClass, selected);
    }

    private toPanMode() {
        if (!this.isSelectMode) {
            return;
        }
        this.modeChanged.next('pan');
    }

    private toSelectMode() {
        if (this.isSelectMode) {
            return;
        }
        this.modeChanged.next('select');
    }

    private isNode(
        item: TGraphItem<NodeData, EdgeData>,
    ): item is ManagedItem<NodeData> {
        return item.graphType === 'node';
    }

    private isConnector(
        item: TGraphItem<NodeData, EdgeData>,
    ): item is Connector<NodeData, EdgeData> {
        return item.graphType === 'edge';
    }
}
