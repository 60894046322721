import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    input,
} from '@angular/core';

export type IconSize = 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'xxl';
export type IconType = 'glyph' | 'url';

/**
 * Display a glyph icon or an image url
 */
@Component({
    standalone: true,
    selector: 'dxy-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass],
})
export class IconComponent {
    public icon = input.required<string>();
    public size = input<IconSize>('medium');
    public type = input<IconType>('glyph');

    @HostBinding('class')
    private get sizeClass() {
        return this.size();
    }
}
