import { Injectable } from '@angular/core';
import { WorkspaceSignalrService } from './workspace-signalr.service';
import { DataIdentifier, ServerType } from '@datagalaxy/dg-object-model';
import { WorkspaceService } from './workspace.service';
import { Subject } from 'rxjs';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { TranslateService } from '@ngx-translate/core';
import { BaseStateService } from '@datagalaxy/utils';
import {
    IWorkspaceIdentifier,
    Workspace,
    WorkspaceDetails,
} from '@datagalaxy/webclient/workspace/domain';

export interface WorkspaceStoreState {
    workspaces: Workspace[];
    currentSpace?: WorkspaceDetails;
    loading: boolean;
}

@Injectable({ providedIn: 'root' })
export class WorkspaceStore extends BaseStateService<WorkspaceStoreState> {
    private allSpaces!: Workspace;
    private deleteCurrentSpaceSubject = new Subject<void>();

    public get currentSpaceDeleted$() {
        return this.deleteCurrentSpaceSubject.asObservable();
    }

    public get currentSpace() {
        return this.state.currentSpace;
    }

    public get hasCurrentSpace() {
        return !!this.state.currentSpace;
    }

    public constructor(
        workspaceSignalR: WorkspaceSignalrService,
        private workspaceService: WorkspaceService,
        private translate: TranslateService,
    ) {
        super({
            workspaces: [],
            loading: false,
        });

        this.subscribeSignalREvents(workspaceSignalR);
    }

    public selectSpaceCount() {
        return this.select((state) => state.workspaces.length);
    }

    public selectCurrentSpace() {
        return this.select((state) => state.currentSpace);
    }

    public selectSpaces(opt?: {
        includeAllSpaces?: boolean;
        projectsOnly?: boolean;
        onlyImportable?: boolean;
        onlyImportableCatalog?: boolean;
    }) {
        return this.select((state) => {
            const spaces = state.workspaces.filter((space) => {
                const project =
                    !opt?.projectsOnly || space.Type === ServerType.Project;
                const importable =
                    !opt?.onlyImportable || space.CanImportEntities;
                const importableCatalog =
                    !opt?.onlyImportableCatalog ||
                    space.CanImportEntitiesOnCatalog;
                return project && importable && importableCatalog;
            });

            if (opt?.includeAllSpaces) {
                return [this.allSpaces, ...spaces];
            }

            return spaces;
        });
    }

    public selectUserDefaultNavSpace() {
        return this.select((state) => {
            const spaces = state.workspaces;
            return spaces.find((ns) => ns.IsDefaultSpace) ?? spaces[0];
        });
    }

    public selectLoading() {
        return this.select((state) => state.loading);
    }

    public setCurrentSpace(space: WorkspaceDetails) {
        this.workspaceService.rememberSpace(space);

        this.setState({
            currentSpace: space,
            loading: false,
        });
    }

    public setCurrentSpaceIdentifier(spaceIdr: IWorkspaceIdentifier) {
        this.setState({ loading: true });

        void this.loadCurrentSpace(spaceIdr);
    }

    public updateSpaceIconHash(hash: string) {
        const currentSpace = this.state.currentSpace;
        if (!currentSpace) return;

        currentSpace.IconHash = hash;

        this.setState({
            currentSpace: CoreUtil.clone(this.state.currentSpace),
            workspaces: this.state.workspaces.map((space) => {
                if (space.spaceId === currentSpace.spaceId) {
                    space.IconHash = hash;
                    return CoreUtil.clone(space);
                }
                return space;
            }),
        });
    }

    public deleteSpace(space: WorkspaceDetails) {
        this.setState({ loading: true });

        void this._deleteSpace(space);
    }

    public updateDefaultSpace(
        spaceIdr: IWorkspaceIdentifier,
        isDefault: boolean,
    ) {
        this.setState({ loading: true });

        void this.updateDefaultSpaceAsync(spaceIdr, isDefault);
    }

    public async loadSpacesAsync() {
        this.setState({
            loading: true,
        });
        const res = await this.workspaceService.getSpaces();
        const spaces = this.sortSpaces(res.Projects);
        const currentSpace = await this.getDefaultCurrentSpace(spaces);

        const allSpaces = (this.allSpaces = new Workspace());

        allSpaces.DisplayName = this.translate.instant(
            'UI.SpaceVersionSelector.allSpaces',
        );

        this.setState({
            workspaces: spaces,
            currentSpace: currentSpace,
            loading: false,
        });
    }

    public getNavSpace(spaceId: string) {
        return this.state.workspaces.find(
            (space) => space.ReferenceId === spaceId,
        );
    }

    public getNavSpaces() {
        return this.state.workspaces;
    }

    private async loadCurrentSpace(spaceIdr: IWorkspaceIdentifier) {
        const space = await this.workspaceService.getWorkspace(spaceIdr);

        this.setCurrentSpace(space);
    }

    private async updateDefaultSpaceAsync(
        spaceIdr: IWorkspaceIdentifier,
        isDefault: boolean,
    ) {
        await this.workspaceService.updateDefaultSpace(spaceIdr, isDefault);

        this.setState({
            workspaces: this.state.workspaces.map((space) => {
                if (space.spaceId === spaceIdr.spaceId) {
                    space.IsDefaultSpace = isDefault;
                } else {
                    space.IsDefaultSpace = false;
                }
                return CoreUtil.cloneDeep(space);
            }),
            loading: false,
        });
    }

    private async onWorkspaceDeleted(data: DataIdentifier[]) {
        const currentDeleted = data.some(
            (d) => this.state.currentSpace?.ReferenceId === d.DataReferenceId,
        );
        const workspaces = this.state.workspaces.filter(
            (s) => !data.some((d) => s.ReferenceId === d.DataReferenceId),
        );
        const currentWorkspace = currentDeleted
            ? await this.getDefaultWorkspace(workspaces)
            : this.state.currentSpace;

        this.setState({
            currentSpace: currentWorkspace,
            workspaces,
            loading: false,
        });

        if (currentDeleted) {
            this.deleteCurrentSpaceSubject.next();
        }
    }

    private async onWorkspaceCreated(_space: Workspace) {
        /**
         * The returned workspace is not complete and some properties are missing.
         * So instead of updating the state with the returned workspace, we reload all spaces.
         */
        await this.loadSpacesAsync();
    }

    private async onWorkspaceUpdated(space: Workspace) {
        /**
         * The returned workspace is not complete and some properties are missing.
         * So instead of updating the state with the returned workspace, we reload all spaces.
         */
        const spaces = await this.workspaceService.getSpaces();
        const currentSpace =
            space.spaceId === this.currentSpace?.spaceId
                ? await this.workspaceService.getWorkspace(this.currentSpace)
                : this.currentSpace;

        this.setState({
            workspaces: this.sortSpaces(spaces.Projects),
            currentSpace: currentSpace,
        });
    }

    private async onWorkspaceSecurityUpdate() {
        const spaces = await this.workspaceService.getSpaces();
        const currentSpace = this.currentSpace
            ? await this.workspaceService.getWorkspace(this.currentSpace)
            : this.currentSpace;

        this.setState({
            workspaces: this.sortSpaces(spaces.Projects),
            currentSpace: currentSpace,
        });
    }

    private async _deleteSpace(space: WorkspaceDetails) {
        const res = await this.workspaceService.deleteWorkspace(space);

        await this.onWorkspaceDeleted(res);
    }

    private sortSpaces(spaces: Workspace[]) {
        return CollectionsHelper.orderByText(
            spaces,
            (workspace) => workspace.DisplayName,
        );
    }

    private async getDefaultCurrentSpace(
        spaces: Workspace[],
    ): Promise<WorkspaceDetails> {
        const savedSpaceIdr = this.workspaceService.loadRememberedSpace();

        if (savedSpaceIdr) {
            try {
                return await this.workspaceService.getWorkspace(savedSpaceIdr);
            } catch (e) {
                return this.getDefaultWorkspace(spaces);
            }
        }

        return this.getDefaultWorkspace(spaces);
    }

    private async getDefaultWorkspace(
        spaces: Workspace[],
    ): Promise<WorkspaceDetails> {
        const defaultOrFirstSpaceIdr =
            spaces.find((ns) => ns.IsDefaultSpace) ?? spaces[0];

        return await this.workspaceService.getWorkspace(defaultOrFirstSpaceIdr);
    }

    private subscribeSignalREvents(workspaceSignalR: WorkspaceSignalrService) {
        /**
         * Be aware that most of those events are emitted back to the current
         * user, so there is no need to manually update the state.
         */
        workspaceSignalR.deleted$.subscribe((event) =>
            this.onWorkspaceDeleted([event.data]),
        );

        workspaceSignalR.projectCreated$.subscribe((event) =>
            this.onWorkspaceCreated(event.data),
        );

        workspaceSignalR.projectUpdated$.subscribe((event) =>
            this.onWorkspaceUpdated(event),
        );

        workspaceSignalR.securityUpdated$.subscribe(() =>
            this.onWorkspaceSecurityUpdate(),
        );
    }
}
