<div class="table-header">
    <button
        *ngIf="canCreateRule"
        dxyIconButton
        [dxyTooltip]="'UI.DataQualityRules.addRuleBtnTooltip' | translate"
        dxyTooltipPosition="left"
        (click)="createRule()"
    >
        <span class="glyph-add"></span>
    </button>
    <dxy-grid-column-selector
        *ngIf="grid"
        [forGrid]="grid"
    ></dxy-grid-column-selector>
</div>
<div *ngIf="isEmptyGrid" class="dxy-placeholder">
    <img
        src="/images/placeholders/data-quality.svg"
        class="dxy-placeholder-img"
        alt="data quality"
    />
    <div class="dxy-placeholder-text" translate>
        UI.Modeler.DataQuality.noDataQuality
    </div>
</div>
<dxy-grid
    *ngIf="!isEmptyGrid"
    class="data-quality-grid"
    [config]="gridConfig"
    [columns]="cols"
    [items]="data"
    [dxyGridUserSettings]="
        isFullColumnDisplay ? 'quality-rules-list-full' : 'quality-rules-list'
    "
    (rowClick)="onRowClick($event)"
></dxy-grid>
<dxy-spinner
    *ngIf="loading$ | async"
    class="xl absolute-centered"
></dxy-spinner>
