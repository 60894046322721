import {
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CollectionsHelper, StringUtil } from '@datagalaxy/core-util';
import { TeamMembersBurgerMenuActionProvider } from './TeamMembersBurgerMenuActionProvider';
import { TeamService } from '../team.service';
import {
    IUserGridCellData,
    UserCellComponent,
} from '@datagalaxy/webclient/user/ui';
import { TeamDto, TeamMemberDto } from '@datagalaxy/webclient/team/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import {
    DxyGridColumnSelectorComponent,
    GridCellType,
    GridComponent,
    GridConfig,
    TColDef,
} from '@datagalaxy/ui/grid';
import * as moment from 'moment';
import { DxyDgDateTimeCellComponent } from '@datagalaxy/core-ui';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { DxyLogFunctionalDirective } from '../../directives/dxy-log-functional.directive';
import { SearchInputComponent } from '@datagalaxy/ui/search';
import { NgIf } from '@angular/common';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { TooltipDirective } from '@datagalaxy/ui/tooltip';
import { UserGridSettingsDirective } from '@datagalaxy/webclient/user/feature/current-user';

@Component({
    selector: 'app-team-members',
    templateUrl: 'team-members.component.html',
    styleUrls: ['team-members.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        SearchInputComponent,
        DxyLogFunctionalDirective,
        DxyGridColumnSelectorComponent,
        MatLegacyMenuModule,
        TranslateModule,
        GridComponent,
        UserGridSettingsDirective,
        DxyIconButtonDirective,
        TooltipDirective,
    ],
})
export class TeamMembersComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() teamData: TeamDto;
    @Input() teamMembers: TeamMemberDto[];
    @Input() hasEditRights: boolean;

    @ViewChild(GridComponent) gridComponent: GridComponent<TeamMemberDto>;

    public get hasSelectedRows() {
        return !!this.gridComponent?.selection?.length;
    }

    public get hasTeamMembers() {
        return !!this.teamMembers?.length;
    }

    protected columns: TColDef<TeamMemberDto>[];
    protected gridConfig: GridConfig<TeamMemberDto> = {
        getItemId: (teamMember) => teamMember.ReferenceId,
        sorting: {
            activeSort: {
                columnId: 'FullName',
            },
        },
    };
    protected filteredTeamMembers: TeamMemberDto[];
    protected searchString: string;

    constructor(
        private translate: TranslateService,
        protected changeDetector: ChangeDetectorRef,
        private teamService: TeamService,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(
            changes,
            'teamMembers',
            async () => await this.onTeamMembersChanged(),
        );
    }

    ngOnInit() {
        this.gridConfig.multiSelect = this.hasEditRights;
        this.initColumns();
        this.getFilteredRows();
    }

    protected onSearchTermChange() {
        this.getFilteredRows();
    }

    public async onClickCreateBtn() {
        const result = await this.teamService.openAddTeamMemberModal(
            this.teamData,
            this.teamMembers,
        );
        if (!result.TeamMembers) {
            return;
        }
        this.teamMembers = result.TeamMembers;
        await this.onTeamMembersChanged();
    }

    public async onClickImportBtn() {
        await this.teamService.importTeams('ADMIN_CS_TEAM');
    }

    public async onClickDeleteBtn(event: MouseEvent) {
        if (!this.hasSelectedRows) {
            event.stopPropagation();
            return;
        }
        const selectedMembers = this.gridComponent.selection;
        const res = await this.teamService.openDeleteTeamMembersModal(
            this.teamData,
            selectedMembers,
        );

        if (!res) {
            return;
        }
        CollectionsHelper.remove(this.teamMembers, (value) =>
            selectedMembers.some(
                (member: TeamMemberDto) => member.UserId == value.UserId,
            ),
        );

        this.getFilteredRows();
    }

    public async onClickSetAdminBtn(event: MouseEvent) {
        if (!this.hasSelectedRows) {
            event.stopPropagation();
            return;
        }
        const selectedMembers: TeamMemberDto[] = this.gridComponent.selection;

        const updatedTeam = new TeamDto();
        updatedTeam.TeamUid = this.teamData.TeamUid;
        updatedTeam.TeamOwners = this.teamData.TeamOwners.slice();
        CollectionsHelper.appendIfNotExist(
            updatedTeam.TeamOwners,
            selectedMembers,
            (m, d) => m.UserId == d.UserId,
        );
        await this.teamService.updateTeam(updatedTeam);
    }

    public getFilteredRows() {
        this.filteredTeamMembers = StringUtil.filterSearched(
            this.searchString,
            this.teamMembers,
            (member) => member.FullName,
        );
    }

    private async onTeamMembersChanged() {
        this.getFilteredRows();
    }

    private initColumns() {
        this.columns = [
            {
                id: 'FullName',
                headerLabel: this.translate.instant(
                    'UI.Teams.Members.nameColumn',
                ),
                customCellComponent: UserCellComponent,
                sortable: true,
                width: 200,
                getSortableValue: (teamMember) => teamMember.FullName,
                getInputs: (teamMember) => {
                    return {
                        userId: teamMember.UserId,
                        badgeData: {
                            enableBadgeDisplay: true,
                            glyphClass: 'glyph-team-owner',
                            isBadgeVisible: () => teamMember.IsTeamOwner,
                            tooltipText: this.translate.instant(
                                'UI.Teams.teamOwnersLbl',
                            ),
                        },
                        actionData: teamMember,
                        actions: [
                            {
                                burgerMenuProvider:
                                    new TeamMembersBurgerMenuActionProvider(
                                        this.teamService,
                                        this.teamData,
                                        this.hasEditRights,
                                    ),
                                filter: () => this.hasEditRights,
                            },
                        ],
                    } as IUserGridCellData;
                },
            },
            {
                id: 'TeamRole',
                headerLabel: this.translate.instant(
                    'UI.Teams.Members.teamRoleColumn',
                ),
                sortable: true,
                width: 125,
                type: GridCellType.text,
                getValue: (teamMember) => {
                    const translateKey = teamMember.IsTeamOwner
                        ? 'OwnerRole'
                        : 'MemberRole';
                    return this.translate.instant(
                        `UI.Teams.Members.${translateKey}`,
                    );
                },
            },
            {
                id: 'Service',
                hidden: true,
                sortable: true,
                type: GridCellType.text,
                headerLabel: this.translate.instant(
                    'UI.Teams.Members.serviceColumn',
                ),
            },
            {
                id: 'Role',
                hidden: true,
                sortable: true,
                type: GridCellType.text,
                headerLabel: this.translate.instant(
                    'UI.Teams.Members.roleColumn',
                ),
            },
            {
                id: 'Title',
                hidden: true,
                sortable: true,
                type: GridCellType.text,
                headerLabel: this.translate.instant(
                    'UI.Teams.Members.titleColumn',
                ),
            },
            {
                id: 'AddTime',
                hidden: true,
                sortable: true,
                headerLabel: this.translate.instant(
                    'UI.Teams.Members.dateColumn',
                ),
                customCellComponent: DxyDgDateTimeCellComponent,
                getInputs: (teamMember) => ({
                    dgDate: moment(teamMember.CreationTime),
                    noTime: true,
                }),
            },
        ];
    }
}
