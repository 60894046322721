import { SerializableEnumeration } from 'cerialize';
//REWITE
export enum FilterOperator {
    TextContains,
    TextStartsWith,
    TextEndsWith,
    TextEquals,
    /** used as a OR logical operator */
    ListContains,
    ListExcludes,
    RangeContains,
    Equals,
    NotEquals,
    Lower,
    LowerEqual,
    Greater,
    GreaterEqual,

    DateTimePastHour,
    DateTimeToday,
    DateTimeYesterday,
    DateTimeCurrentWeek,
    DateTimePastWeek,
    DateTimeBeforeCurrentWeek,
    DateTimeBeforePastWeek,
    DateTimeCurrentMonth,
    DateTimePastMonth,
    DateTimeBeforeCurrentMonth,
    DateTimeBeforePastMonth,
    DateTimeBeforeToday,
    DateTimeCurrentYear,
    DateTimeLast365Days,

    FieldIsEmpty,
    FieldHasValue,

    /** used as a AND logical operator */
    ListMatchAll,
}
SerializableEnumeration(FilterOperator);
