<dxy-field-multi-select
    *ngIf="isMultiValue"
    [readonly]="readonly"
    [(ngModel)]="value"
    [logId]="logId"
    [labelTooltipText]="labelTooltip"
    [labelText]="label"
    [fieldAppearance]="fieldAppearance"
    [compact]="compact"
    [mandatory]="mandatory"
    [openMenuOnFocus]="openMenuOnFocus"
    [menuTakeFullWidth]="menuTakeFullWidth"
    [maxDisplayedOptions]="maxDisplayedOptions"
    [data]="multiSelectData"
    [required]="required"
    [hint]="hint"
    [errorMessageText]="errorMessage"
    (openClose)="onMultiSelectOpenClose($event)"
    [class.mini]="mini"
    [ngClass]="fieldClass"
    [menuHasDynamicHeight]="true"
    (selectedItemClick)="onSelectedItemClick($event)"
>
    <div
        *ngIf="mini"
        selectedValueContent
        class="mini-value-content"
        [dxyTooltip]="miniValueTooltipText"
    >
        <span *ngIf="values?.length" [ngClass]="miniValueGlyphClass"></span>
        <span *ngIf="values?.length == 1" class="value-text">
            {{ values[0].HddData.Data.DisplayName }}
        </span>
        <span *ngIf="values?.length > 1" class="value-text">
            {{ values?.length }} {{ propertyTypeNameKey | translate }}
        </span>
    </div>

    <ng-container
        ngProjectAs="[headerBeforeSearch]"
        [ngTemplateOutlet]="searchFiltersTemplate"
    ></ng-container>
    <ng-container
        ngProjectAs="[headerAfterSearch]"
        [ngTemplateOutlet]="quickFiltersTemplate"
    ></ng-container>
</dxy-field-multi-select>

<dxy-field-select
    *ngIf="!isMultiValue"
    [readonly]="readonly"
    [adapter]="selectAdapter"
    [canRemove]="!noRemove"
    [options]="selectOptions"
    [(ngModel)]="value"
    [logId]="logId"
    [openMenuOnFocus]="openMenuOnFocus"
    [labelTooltipText]="labelTooltip"
    [labelText]="label"
    [search]="true"
    [fieldAppearance]="fieldAppearance"
    [compact]="compact"
    [mandatory]="mandatory"
    [required]="required"
    [hint]="hint"
    [errorMessageText]="errorMessage"
    [ngClass]="fieldClass"
    [noSearchPlaceholder]="true"
    (onSearchTerm)="onSelectSearch($event)"
    (openClose)="openClose.emit($event)"
    [class.mini]="mini"
    (selectedItemClick)="onSelectedItemClick($event)"
>
    <ng-container
        ngProjectAs="[headerBeforeSearch]"
        [ngTemplateOutlet]="searchFiltersTemplate"
    ></ng-container>
    <ng-container
        ngProjectAs="[headerAfterSearch]"
        [ngTemplateOutlet]="quickFiltersTemplate"
    ></ng-container>
</dxy-field-select>

<ng-template #searchFiltersTemplate>
    <div *ngIf="hasSearchFilters" class="filters-wrappers">
        <app-attribute-filter
            *ngFor="let afm of searchFilters"
            [filterItemData]="afm"
            [isMini]="true"
            [hasRemove]="true"
            (onAction)="onFilterItemAction(afm, $event)"
            class="filter"
        ></app-attribute-filter>
    </div>
</ng-template>

<ng-template #quickFiltersTemplate>
    <app-attribute-quick-filters
        *ngIf="hasQuickFilters"
        [currentSearch]="currentSearch"
        [quickFilters]="quickFilters"
        [hideLookingFor]="true"
        (onSelectFilter)="selectQuickFilterEvent($event)"
    ></app-attribute-quick-filters>
</ng-template>
