<ng-container *ngIf="!isEditingPrimaryName">
    <div class="tab-header-displayName">
        <app-entity-display-name
            [entity]="entity"
            [readOnly]="readOnly"
            [displayNameTooltip]="obsoleteTooltipKey | translate"
            [class.is-obsolete]="isObsolete"
            (titleClicked)="onPrimaryNameEdit()"
        ></app-entity-display-name>
        <button
            *ngIf="editButtonVisible"
            dxyIconButton
            variant="tertiary"
            class="btn-edit"
            (click)="onPrimaryNameEdit()"
            [dxyTooltip]="'UI.EntityHeader.actions.editNameTooltip' | translate"
        >
            <i class="glyph glyph-edit"></i>
        </button>
    </div>
</ng-container>
<form
    *ngIf="isEditingPrimaryName"
    #form="ngForm"
    (ngSubmit)="onSubmitNewPrimaryName()"
>
    <div class="tab-header-displayName">
        <input
            type="text"
            name="propertyTitle"
            class="displayName-container"
            [(ngModel)]="updatedEntityPrimaryName"
            [maxLength]="textMaxLength"
            [required]="true"
        />
        <span class="remaining-digits">{{ remainingTitleDigits }}</span>
        <span class="input-group-btn dg_pane-header-actions">
            <button
                dxyIconButton
                type="button"
                [class.disabled]="form.invalid"
                (click)="onSubmitNewPrimaryName()"
                class="btn-validate"
            >
                <span class="glyph glyph-check"></span>
            </button>
            <button
                dxyIconButton
                type="button"
                variant="tertiary"
                (click)="onClearPrimaryName()"
            >
                <i class="glyph glyph-cancelsearch"></i>
            </button>
        </span>
    </div>
</form>
