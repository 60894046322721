import { CoreUtil } from '@datagalaxy/core-util';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

export class EntityUtils {
    public static mergeEntity(
        entity: EntityItem,
        newEntity: EntityItem,
        mergeHddData = false,
    ) {
        CoreUtil.mergeWith(
            entity.Attributes,
            newEntity.Attributes,
            (objValue, srcValue) => srcValue,
        );
        const hdd = entity.HddData && entity.HddData.Data;
        if (hdd) {
            hdd.DisplayName = entity.DisplayName;
            hdd.TechnicalName = entity.TechnicalName;
        }

        // If HddData has been updated, update entity's HddData.Parents and HddData.Data
        const hasHddDataChanged =
            !CoreUtil.areEqual(
                entity?.HddData?.Data,
                newEntity?.HddData?.Data,
            ) ||
            !CoreUtil.areEqual(
                entity?.HddData?.Parents,
                newEntity?.HddData?.Parents,
            );

        if (hasHddDataChanged && newEntity?.HddData?.Data && mergeHddData) {
            CoreUtil.mergeWith(
                entity.HddData,
                newEntity.HddData,
                (objValue, srcValue) => srcValue,
            );
        }

        const technologyCode =
            newEntity.HddData?.TechnologyCode ||
            newEntity.getAttributeValue<string>('TechnologyCode');

        if (entity.HddData && technologyCode) {
            entity.HddData.TechnologyCode = technologyCode;
        }

        // If entity Security Data has been updated
        const hasSecurityDataChanged = !CoreUtil.areEqual(
            entity?.SecurityData,
            newEntity?.SecurityData,
        );

        if (hasSecurityDataChanged) {
            CoreUtil.mergeWith(
                entity.SecurityData,
                newEntity.SecurityData,
                (objValue, srcValue) => srcValue,
            );
        }
    }

    public static getModifiedAttributesAndValue(
        attributes: AttributeMetaInfo[],
        entity: EntityItem,
        updatedEntity: EntityItem,
    ) {
        const attributeUpdateData: IUpdatedAttributeData[] = [];
        attributes.forEach((attribute) => {
            const attributeKey = attribute.AttributeKey;
            const entityAttributeValue = entity.getAttributeValue(attributeKey);
            const updatedEntityAttributeValue =
                updatedEntity.getAttributeValue(attributeKey);
            if (
                updatedEntityAttributeValue != undefined &&
                entityAttributeValue != updatedEntityAttributeValue
            ) {
                attributeUpdateData.push({
                    newValue: updatedEntityAttributeValue,
                    attribute,
                });
            }
        });
        return attributeUpdateData;
    }

    public static getAttributesReadOnlyClones(attributes: AttributeMetaInfo[]) {
        return attributes.map((a) => {
            const clone = CoreUtil.clone(a);
            clone.IsReadOnly = true;
            return clone;
        });
    }
}

export interface IUpdatedAttributeData {
    newValue: any;
    attribute: AttributeMetaInfo;
}
