import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { AttributeFilterModel } from '../attribute-filter/attributeFilterModel';
import { IAttributeFilterCarouselFilterChange } from './attribute-filter-carousel.types';
import { AttributeFilterAction } from '../attribute-filter.types';
import {
    FilterCarouselComponent,
    FilterCarouselDisplayMode,
} from '@datagalaxy/ui/filters';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { AttributeFilterComponent } from '../attribute-filter/attribute-filter.component';
import { FilterCarouselItemDirective } from '@datagalaxy/ui/filters';
import { NgFor } from '@angular/common';

/**
 * ## Role
 * Carousel of attribute filter items
 * Based on FilterCarouselComponent
 */
@Component({
    selector: 'app-attribute-filter-carousel',
    templateUrl: './attribute-filter-carousel.component.html',
    styleUrls: ['./attribute-filter-carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FilterCarouselComponent,
        NgFor,
        FilterCarouselItemDirective,
        forwardRef(() => AttributeFilterComponent),
    ],
})
export class AttributeFilterCarouselComponent
    extends DxyBaseComponent
    implements OnChanges, AfterViewInit
{
    @Input() filterItems: AttributeFilterModel[];
    @Input() displayMode: FilterCarouselDisplayMode = 'default';
    /**
     * If true, filters are readonly and we emit an event on filterItem click
     */
    @Input() readonly: boolean;
    /**
     * If true the carousel will start at the end, positioned on the last element
     */
    @Input() startAtEnd: boolean;

    @Output() select = new EventEmitter<AttributeFilterModel>();
    @Output() filterChange =
        new EventEmitter<IAttributeFilterCarouselFilterChange>();
    @Output() scrollPrevious = new EventEmitter();
    @Output() scrollNext = new EventEmitter();

    @ViewChild(FilterCarouselComponent) carousel: FilterCarouselComponent;

    constructor() {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'filterItems', () =>
            setTimeout(() => this.initCarousel()),
        );
    }

    ngAfterViewInit() {
        setTimeout(() => this.initCarousel());
    }

    public refresh() {
        setTimeout(() => this.initCarousel());
    }

    public onFilterItemClick(afm: AttributeFilterModel) {
        if (this.readonly) {
            this.select.emit(afm);
        }
    }

    public onFilterItemAction(
        filterItem: AttributeFilterModel,
        action: AttributeFilterAction,
    ) {
        this.filterChange.emit({ filterItem, action });
        /** The filterItem may have grown in size, so we need to ensure that scroll is at the end */
        if (this.startAtEnd) {
            this.scrollToEnd();
        }
    }

    private initCarousel() {
        if (this.startAtEnd) {
            this.scrollToEnd();
        }
    }

    private scrollToEnd() {
        this.carousel.scrollToEnd();
    }
}
