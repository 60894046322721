import { Injectable } from '@angular/core';
import { WorkSpaceApiService } from './workspace-api.service';
import {
    CheckSpaceTrigramValidityParameter,
    CreateSpaceParameter,
    DeleteProjectParameter,
    LoadProjectParameter,
    UpdateSpaceParameter,
} from './workspace';
import { DataIdentifier, ServerType } from '@datagalaxy/dg-object-model';
import { getContextId, getReferenceId } from '@datagalaxy/utils';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import {
    ClientApiService,
    ImageCategory,
    SetImageParameter,
} from '@datagalaxy/webclient/client/data-access';
import {
    GetSpaceAdministrationUsersParameter,
    GetSpaceUsersParameter,
    UpdateSpaceGovernanceUserParameter,
} from './user';
import {
    CurrentUserService,
    UserAdminData,
} from '@datagalaxy/webclient/user/data-access';
import {
    IHasSpaceIconData,
    IWorkspaceIdentifier,
    Workspace,
    SpaceGovernanceUserDto,
    SpaceSecurityProfileType,
    WorkspaceDetails,
} from '@datagalaxy/webclient/workspace/domain';

@Injectable({ providedIn: 'root' })
export class WorkspaceService {
    private static REMEMBERED_SPACE_STORAGEKEY = 'remembered_spaceIdr';

    public constructor(
        private clientApiService: ClientApiService,
        private workspaceApiService: WorkSpaceApiService,
        private currentUser: CurrentUserService,
    ) {}

    public async getWorkspace(
        spaceIdr: IWorkspaceIdentifier,
    ): Promise<WorkspaceDetails> {
        return await this.getProject(spaceIdr);
    }

    public async deleteWorkspace(
        space: WorkspaceDetails,
    ): Promise<DataIdentifier[]> {
        const res = await this.deleteProject(
            space.ParentOrganizationReferenceId,
            space.ReferenceId,
        );
        return [res];
    }

    public async getSpaces() {
        const res = await this.workspaceApiService.loadSpaces();

        const defaultSpaceUid = this.currentUser.clientInfo?.DefaultSpaceUid;
        const defaultSpaceId =
            defaultSpaceUid && getReferenceId(defaultSpaceUid, defaultSpaceUid);

        if (defaultSpaceId) {
            CollectionsHelper.withFirstFound(
                res.Projects,
                (ns) => ns.ReferenceId == defaultSpaceId,
                (ns) => (ns.IsDefaultSpace = true),
            );
        }

        return res;
    }

    public async updateDefaultSpace(
        spaceIdr: IWorkspaceIdentifier,
        isDefault: boolean,
    ) {
        const param = new UpdateSpaceParameter(
            spaceIdr.spaceId || '',
            null,
            null,
            null,
            isDefault,
        );
        return this.workspaceApiService.updateDefaultSpace(param);
    }

    public async preCreateSpace(
        name: string,
        trigram: string,
        descriptionId: string,
        defaultOwnerId: string,
        defaultStewardId: string,
    ) {
        const createSpaceParameter = new CreateSpaceParameter(
            name,
            trigram,
            descriptionId,
            defaultOwnerId,
            defaultStewardId,
        );
        return await this.workspaceApiService.preCreateSpace(
            createSpaceParameter,
        );
    }

    // TODO : Refactor legacy code
    public async updateProject(
        projectReferenceId: string,
        newDisplayName: string,
        newTrigram: string,
        newDescription: string,
        isDefaultSpace = false,
        spaceSecurityProfileType?: SpaceSecurityProfileType,
    ): Promise<Workspace> {
        const parameter = new UpdateSpaceParameter(
            projectReferenceId,
            newDisplayName,
            newTrigram,
            newDescription,
            isDefaultSpace,
            spaceSecurityProfileType,
        );
        await this.workspaceApiService.updateSpace(parameter);
        const navProject = new Workspace();
        navProject.ReferenceId = projectReferenceId;
        navProject.Trigram = newTrigram;
        navProject.DisplayName = newDisplayName;
        navProject.Description = newDescription;
        navProject.SecurityProfileType = spaceSecurityProfileType;
        return navProject;
    }

    public async createProject(
        projectName: string,
        projectTrigram: string,
        projectDescription: string,
        defaultDataOwnerUserId: string,
        defaultDataStewardUserId: string,
        securityProfileType: SpaceSecurityProfileType,
    ) {
        const param = new CreateSpaceParameter(
            projectName,
            projectTrigram,
            projectDescription,
            defaultDataOwnerUserId,
            defaultDataStewardUserId,
            securityProfileType,
        );
        const result = await this.workspaceApiService.createProject(param);
        return result.Project;
    }

    public async checkTrigramValidity(spaceId: string, trigram: string) {
        return await this.workspaceApiService.CheckSpaceTrigramValidity(
            new CheckSpaceTrigramValidityParameter(spaceId || '', trigram),
        );
    }

    public async deleteSpaceImage(
        spaceId: string,
        imageCategory: ImageCategory,
    ) {
        return this.setSpaceImageInternal(spaceId, imageCategory, true);
    }

    public async setSpaceImage(
        file: File,
        spaceId: string,
        imageCategory: ImageCategory,
    ) {
        return this.setSpaceImageInternal(spaceId, imageCategory, false, file);
    }

    private async setSpaceImageInternal(
        spaceId: string,
        imageCategory: ImageCategory,
        isDelete: boolean,
        file?: File,
    ) {
        let param: SetImageParameter;
        if (isDelete) {
            param = SetImageParameter.forDelete(imageCategory, spaceId);
        } else if (file) {
            const fileContent = await CoreUtil.readAsDataUrl(file);
            param = new SetImageParameter(
                fileContent,
                file.name,
                file.type,
                imageCategory,
                spaceId,
            );
        } else {
            return;
        }
        return await this.clientApiService.setImage(param);
    }

    public getWorkspaceIconUrl(space?: IHasSpaceIconData): string {
        const hash = space?.IconHash;
        if (!hash) {
            return '';
        }
        return this.clientApiService.getImageUrl(hash);
    }

    public async updateSpaceGovernanceUser(
        spaceIdr: IWorkspaceIdentifier,
        addUserList: string[],
        deleteUserList: string[],
        defaultUserId: string,
        attributeKey: string,
    ) {
        const param = new UpdateSpaceGovernanceUserParameter(
            addUserList,
            deleteUserList,
            defaultUserId,
            attributeKey,
            spaceIdr.spaceId,
        );
        param.setVersionId(spaceIdr?.spaceId);
        return this.workspaceApiService.updateSpaceGovernanceUser(param);
    }

    public async getSpaceUsers(
        spaceIdr: IWorkspaceIdentifier,
        includeRoleData = false,
    ) {
        const param = new GetSpaceUsersParameter();
        param.SpaceGuid = getContextId(spaceIdr?.spaceId);
        param.IncludeRoleData = includeRoleData;
        return await this.workspaceApiService.getSpaceUsers(param);
    }

    public async getSpaceAdministrationUsers(
        spaceIdr: IWorkspaceIdentifier,
    ): Promise<UserAdminData[]> {
        const param = new GetSpaceAdministrationUsersParameter();
        param.SpaceGuid = getContextId(spaceIdr?.spaceId);
        const res =
            await this.workspaceApiService.getSpaceAdministrationUsers(param);
        return res?.SpaceUsers;
    }

    /** This is the source list for the Space Governance Users management : All (non-deleted) users are valid candidates */
    public async getUsersDataAsSpaceGovDtos(spaceIdr: IWorkspaceIdentifier) {
        const res = await this.getSpaceAdministrationUsers(spaceIdr);
        return res
            .filter((user) => !user.IsSupport && !user.IsToken)
            ?.map(
                (user) =>
                    new SpaceGovernanceUserDto(
                        user.ReferenceId,

                        user.FirstName,
                        user.LastName,
                        user.Email,
                        user.UserId,
                        false,
                        '',
                        user.ProfileImageHash,
                        user.ProfileThumbnailHash,
                    ),
            );
    }

    /**
     * Store current-space spaceIdentifier in localStorage
     * (Obviously, to be invoked only after navigating to a space related page)
     */
    public rememberSpace(spaceIdr: IWorkspaceIdentifier) {
        const { spaceId, versionId } = spaceIdr;

        if (spaceId) {
            window.localStorage.setItem(
                WorkspaceService.REMEMBERED_SPACE_STORAGEKEY,
                JSON.stringify({ spaceId, versionId }),
            );
        }
    }

    /**
     * Restore retained space (if any, from localStorage)
     * (Meant to be invoked after navigating to a non-workspace page)
     */
    public loadRememberedSpace(): IWorkspaceIdentifier {
        return JSON.parse(
            window.localStorage.getItem(
                WorkspaceService.REMEMBERED_SPACE_STORAGEKEY,
            ) || 'null',
        ) as IWorkspaceIdentifier;
    }

    public deleteRememberedSpace() {
        window.localStorage.removeItem(
            WorkspaceService.REMEMBERED_SPACE_STORAGEKEY,
        );
    }

    private async getProject(
        spaceIdr: IWorkspaceIdentifier,
    ): Promise<WorkspaceDetails> {
        const param = new LoadProjectParameter(
            spaceIdr.spaceId,
            spaceIdr.versionId,
        );
        const res = await this.workspaceApiService.loadProject(param);

        return res.Project;
    }

    private async deleteProject(
        parentId: string,
        projectId: string,
    ): Promise<DataIdentifier> {
        const param = new DeleteProjectParameter(parentId, projectId);
        await this.workspaceApiService.deleteProject(param);
        return new DataIdentifier(projectId, ServerType[ServerType.Project]);
    }
}
