import { IFilterFormModel } from '../attribute-filter-form/IFilterFormModel';
import { BaseFilterListItemModel } from '../models/BaseFilterListItemModel';
import { AttributeFilterModel } from '../attribute-filter/attributeFilterModel';
import { FilterUtil } from '../../../util/FilterUtil';
import { FilterOperator } from '@datagalaxy/filter-domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

export class AttributeReferenceFilterModel extends BaseFilterListItemModel {
    constructor(
        filterFormData: IFilterFormModel,
        attributeMeta: AttributeMetaInfo,
        operators: FilterOperator[],
    ) {
        super(filterFormData, attributeMeta, operators);
        this.operator = this.isSystemDataTypeRefAttribute
            ? FilterOperator.Equals
            : FilterOperator.ListContains;
    }

    public getFilterValueDisplayName() {
        return this.values?.map((v) => v.Value).join(', ');
    }

    copy(filterItem: AttributeReferenceFilterModel) {
        this.operator = filterItem.operator;
        this.values = filterItem.values;
    }

    isValid() {
        return FilterUtil.isValuelessOperator(this.operator) || this.hasValues;
    }

    protected sameTypeAndValue(other: AttributeFilterModel) {
        return (
            other instanceof AttributeReferenceFilterModel &&
            super.sameValuesByKey(other)
        );
    }
}
