/* eslint-disable @typescript-eslint/no-namespace */
export namespace Constants {
    export namespace ActionType {
        export const User = 'User';
        export const Save = 'Save';
        export const State = 'State';
    }

    export const defaultDashboardName = 'Dashboard';

    export namespace FreshdeskUrl {
        export const BaseUrl = 'https://datagalaxy.freshdesk.com/';
        export const BaseApiUrl = BaseUrl + 'api/v2';

        /**
         * @deprecated Replace with dxyFreshdeskHelpLink directive
         */
        export const CsvImportHelpId = '35000132821';
        /**
         * @deprecated Replace with dxyFreshdeskHelpLink directive
         */
        export const OnlineImportHelpId = '35000179788';
        /**
         * @deprecated Replace with dxyFreshdeskHelpLink directive
         */
        export const DesktopImportHelpId = '35000132804';
        /**
         * @deprecated Replace with dxyFreshdeskHelpLink directive
         */
        export const CsvImportSamplesId = '35000139751';
        /**
         * @deprecated Replace with dxyFreshdeskHelpLink directive
         */
        export const OnlineImportTokensId = '35000132471';
        /**
         * @deprecated Replace with dxyFreshdeskHelpLink directive
         */
        export const DesktopImportOperationsId = '35000132850';
    }
}
