import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageService } from '@datagalaxy/translate';

/**
 * ## Role
 * Add User language header to the request
 */
@Injectable()
export class UserLanguageHeaderInterceptor implements HttpInterceptor {
    constructor(private languageService: LanguageService) {}

    intercept(
        req: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        const userLanguage = this.languageService.getLanguage();

        if (userLanguage) {
            req = req.clone({
                setHeaders: {
                    'datagalaxy-user-language': userLanguage,
                },
            });
        }
        return next.handle(req);
    }
}
