import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Injectable, Type } from '@angular/core';
import { BaseService, KeyboardEventService } from '@datagalaxy/core-ui';
import { DxyModalService } from '../dialogs/DxyModalService';
import { CoreUtil } from '@datagalaxy/core-util';
import { IDxyPreviewPaneContent } from './dxy-preview-panel-slider/dxy-preview-panel-slider.types';

/**
 * ## Role
 * Service managing preview panel setup and visibility
 *
 * ## Features
 * - Setup panel with a component implementing IDxyPreviewPaneContent and its input parameters
 * - Show panel
 * - Hide panel
 */
@Injectable({ providedIn: 'root' })
export class PreviewPanelService extends BaseService {
    private static readonly behaviour = { closeOnReopenWithSameInput: true };

    public get visible$() {
        return this.visible.asObservable();
    }
    public get panelParams$() {
        return this.panelParams.asObservable();
    }
    public get reopenParams$() {
        return this.reopenParams.asObservable();
    }

    public get isPanelHidden() {
        return !this.visible.value;
    }

    private visible = new BehaviorSubject<boolean>(false);
    private panelParams = new BehaviorSubject<IPreviewPanelParams>(undefined);
    private reopenParams = new ReplaySubject<IPreviewPanelParams>();

    constructor(
        private keyboardEvent: KeyboardEventService,
        dxyModalService: DxyModalService,
    ) {
        super();
        this.keyboardEvent.onEscape().subscribe(() => {
            if (!dxyModalService.isActiveModal) {
                this.hidePanel();
            }
        });
    }

    public hidePanel() {
        this.setIsHidden(true);
    }

    /** called by any component or service to show the panel */
    public async setupPanel(params: IPreviewPanelParams) {
        this.log('setupPanel', params);

        if (this.isPanelHidden) {
            this.panelParams.next(params);
            this.showPanel();
            return;
        }

        const sameInputsRequested = this.areSameInputs(
            this.panelParams?.value,
            params,
        );

        if (!sameInputsRequested) {
            this.log('setupPanel: close+open for new input');
            await this.reopenPanel(params);
            return;
        }

        if (PreviewPanelService.behaviour.closeOnReopenWithSameInput) {
            this.log('setupPanel: closeOnReopenWithSameInput');
            this.hidePanel();
            return;
        }
    }

    public onCurrentDeactivated() {
        this.log('onCurrentDeactivated');
        this.panelParams.next({
            component: null,
            inputs: null,
        });
        this.hidePanel();
    }

    private showPanel() {
        this.setIsHidden(false);
    }

    private setIsHidden(toHidden: boolean) {
        toHidden = !!toHidden;
        const changing = this.isPanelHidden != toHidden;
        if (!changing) {
            return false;
        }
        this.visible.next(!toHidden);
        return true;
    }

    private async reopenPanel(params: IPreviewPanelParams) {
        this.reopenParams.next(params);
        this.panelParams.next(params);
    }

    private areSameInputs(
        currentParams: IPreviewPanelParams,
        newParams: IPreviewPanelParams,
    ) {
        if (newParams?.component != currentParams?.component) {
            return false;
        }
        if (newParams?.isSameInput != undefined) {
            return newParams.isSameInput;
        }
        return CoreUtil.areEqual(newParams?.inputs, currentParams?.inputs);
    }
}

export interface IPreviewPanelParams<TData = unknown, TInputs = unknown> {
    component: Type<IDxyPreviewPaneContent<TData>>;
    inputs: TInputs;
    isSameInput?: boolean;
}
