import { deserialize } from 'cerialize';

export class UserSettingsDto {
    /** in seconds */
    @deserialize public NotificationCleanupPeriod: number;
    @deserialize public UserLanguageCode: string;

    public constructor(
        notificationCleanupPeriod: number,
        userLanguageCode: string,
    ) {
        this.NotificationCleanupPeriod = notificationCleanupPeriod;
        this.UserLanguageCode = userLanguageCode;
    }
}
