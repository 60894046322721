import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MultilingualFunnelStateService } from '../multilingual-funnel-state.service';
import { MultilingualFunnelStepComponent } from '../multilingual-funnel-step.component';
import { FunnelAction } from '@datagalaxy/ui/funnel';
import {
    MultilingualApiService,
    MultilingualAttributeSetting,
} from '@datagalaxy/webclient/multilingual/data-access';
import { lexiconFileName } from '../../lexicon-upload-form/lexicon.constants';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';
import { AttributeSelectorComponent } from '@datagalaxy/webclient/attribute/feature';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';

@Component({
    selector: 'dxy-multilingual-recap-step',
    templateUrl: './multilingual-recap-step.component.html',
    styleUrls: ['./multilingual-recap-step.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        DxyFieldTextComponent,
        FormsModule,
        NgIf,
        TranslateModule,
        AttributeSelectorComponent,
        AsyncPipe,
    ],
})
export class MultilingualRecapStepComponent extends MultilingualFunnelStepComponent {
    private stateService = inject(MultilingualFunnelStateService);
    private multilingualApiService = inject(MultilingualApiService);

    public override actions: FunnelAction[] = [
        this.previousAction,
        {
            actionType: 'finish',
            label: this.translate.instant(
                'Multilingual.Administration.RecapStep.activate',
            ),
            variant: 'primary',
            callback: () => this.saveSettings(),
        },
    ];

    protected lexiconFileName = lexiconFileName;

    protected get sourceLanguageName(): string {
        return (
            this.stateService.state.selectedSourceLanguage?.languageName ?? ''
        );
    }
    protected get targetLanguageName(): string {
        return (
            this.stateService.state.selectedTargetLanguage?.languageName ?? ''
        );
    }

    protected get attributes(): AttributeMetaInfo[] {
        return this.stateService.state.selectedAttributes ?? [];
    }

    protected lexiconStatistics$ =
        this.multilingualApiService.getLexiconStatistics();

    private async saveSettings() {
        try {
            await this.saveTargetLanguage();
            await this.saveAttributes();
        } catch (error) {
            console.warn('Error while saving multilingual settings', error);
            throw error;
        } finally {
            await this.enable();
        }
    }

    private async saveTargetLanguage() {
        const targetLanguage = this.stateService.state.selectedTargetLanguage;
        if (!targetLanguage) {
            return;
        }
        await this.multilingualApiService.addTargetLanguage({
            languageCode: targetLanguage.languageCode,
        });
    }

    private async saveAttributes() {
        const attributes = this.stateService.state.selectedAttributes;
        if (!attributes?.length) {
            return;
        }
        const attributeSettings = attributes.map(
            (a) =>
                ({
                    attributePath: a.AttributePath,
                    enabled: true,
                }) as MultilingualAttributeSetting,
        );
        await this.multilingualApiService.addAttributeSettings(
            attributeSettings,
        );
    }

    private async enable() {
        const sourceLanguageCode =
            this.stateService.state.selectedSourceLanguage?.languageCode;
        if (!sourceLanguageCode) {
            return;
        }
        await this.multilingualApiService.updateSettings({
            sourceLanguageCode: sourceLanguageCode,
            enabled: true,
        });
    }
}
