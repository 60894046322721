<div class="dxy-field" [class.mini]="mini">
    <div class="read-only-field">
        <label *ngIf="isLabelDisplayed">
            <span [dxyTooltip]="description">{{ labelText }}</span>
        </label>
        <dxy-nav-breadcrumb
            [class.multi-lines]="!mini"
            [hierarchicalData]="hierarchicalData"
            [includeSelf]="true"
        ></dxy-nav-breadcrumb>
    </div>
</div>
