import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostListener,
    inject,
    input,
} from '@angular/core';
import { MenuComponent } from '../menu/menu.component';

@Directive({
    selector: '[dxyMenuItem]',
    standalone: true,
})
export class MenuItemDirective implements AfterViewInit {
    doNotCloseMenuOnClick = input(false);

    private el = inject(ElementRef<HTMLElement>);
    private menu = inject(MenuComponent);
    ngAfterViewInit() {
        this.appendCssClass();
    }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        if (!this.menu || this.doNotCloseMenuOnClick()) {
            event.stopPropagation();
            event.preventDefault();
            return;
        }
        this.menu.close.emit();
    }

    private appendCssClass() {
        this.el.nativeElement.classList.add('dxy-menu-item');
    }
}
