import { Component, Input } from '@angular/core';
import { IDropdownButtonOption } from '../dropdownButton.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { TranslateModule } from '@ngx-translate/core';
import { DxyLogFunctionalDirective } from '../../../directives/dxy-log-functional.directive';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { TooltipDirective } from '@datagalaxy/ui/tooltip';

/** Behaviour:
 * If no actions were gigen, the button is not displayed.
 * When clicking the button:
 *  - If only one action, its callback is called.
 *  - If several actions, a dropdown is displayed
 */
@Component({
    selector: 'dxy-dropdown-button',
    templateUrl: 'dxy-dropdown-button.component.html',
    standalone: true,
    imports: [
        MatLegacyMenuModule,
        NgIf,
        NgFor,
        DxyLogFunctionalDirective,
        NgClass,
        TranslateModule,
        DxyIconButtonDirective,
        TooltipDirective,
    ],
})
export class DxyDropdownButtonComponent extends DxyBaseComponent {
    @Input() type: 'add' | 'burger';
    @Input() tooltipTranslateKey: string;
    @Input() actions: IDropdownButtonOption[] = [];

    public get isAdd() {
        return this.type == 'add';
    }
    public get isBurger() {
        return this.type == 'burger';
    }
    public get isPrimary() {
        return this.isAdd;
    }

    constructor() {
        super();
    }

    public onButtonClick(event: Event) {
        this.log('onButtonClick', this.actions?.length);
        if (this.actions?.length == 1) {
            event.stopPropagation();
            this.actions[0].callback();
        }
    }
}
