import { deserializeAs, inheritSerialization, serialize } from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { RecentSearchDto } from './recent';
import { Filter } from '@datagalaxy/filter-domain';
@inheritSerialization(BaseServiceResult)
export class GetRecentSearchesResult extends BaseServiceResult {
    @deserializeAs(RecentSearchDto) public RecentSearches!: RecentSearchDto[];
}

@inheritSerialization(BaseServiceParameter)
export class SaveRecentSearchFiltersParameter extends BaseServiceParameter {
    @serialize Filters!: Filter[];
    @serialize SpaceId!: string;
}

@inheritSerialization(BaseServiceParameter)
export class GetRecentEntitiesParameter extends BaseServiceParameter {
    @serialize public Size?: number;
    @serialize public IncludedAttributesFilter?: string[];
}

@inheritSerialization(BaseServiceResult)
export class GetRecentEntitiesResult extends BaseServiceResult {
    @deserializeAs(HierarchicalData) public Entities!: Array<HierarchicalData>;
}
