import { IOmniGridState, PersistedGridState } from '@datagalaxy/ui/grid';
import { Injectable } from '@angular/core';
import { CurrentUserService } from '@datagalaxy/webclient/user/data-access';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';
import { UserSettingValueService } from '@datagalaxy/users/data-access';

export type TGridState = IOmniGridState | PersistedGridState;

@Injectable({
    providedIn: 'root',
})
export class UserGridSettingsService {
    constructor(
        private userSettingsService: UserSettingValueService,
        private currentUser: CurrentUserService,
    ) {}

    public async getGridState(
        gridId: string,
    ): Promise<PersistedGridState | undefined> {
        const clientUiSettingsJson =
            this.currentUser.clientInfo?.UiSettingsJson || '{}';
        const clientUiSettings: { goldenSettingsUserLocalId: string } =
            JSON.parse(clientUiSettingsJson);
        const res = await this.userSettingsService.getUserSettingValue(
            userSettingsValues.omniGrid.category,
            gridId,
            clientUiSettings?.goldenSettingsUserLocalId,
        );

        const gridState: TGridState = res && JSON.parse(res);

        return this.convertGridState(gridState);
    }

    public async saveGridState(
        gridId: string,
        gridState: PersistedGridState,
    ): Promise<void> {
        await this.userSettingsService.setUserSettingValue(
            userSettingsValues.omniGrid.category,
            gridId,
            JSON.stringify(gridState),
        );
    }

    private convertGridState(
        gridState?: TGridState,
    ): PersistedGridState | undefined {
        switch (gridState?.version) {
            case 'omni-grid-1': {
                return {
                    version: 'dxy-grid-v1',
                    columns: gridState.columns.map((col) => ({
                        id: col.colId,
                        hidden: col.hide ?? false,
                        width: col.width ?? 100,
                    })),
                };
            }
            case 'dxy-grid-v1': {
                return gridState as PersistedGridState;
            }
            default: {
                return;
            }
        }
    }
}
