import * as moment from 'moment';
import {
    DateFilterOperator,
    DateFilterUtils,
    DxyDateFilterComponent,
    DxyValueListFilterComponent,
    IDateFilterData,
    IOptionAdapter,
    IValueListFilterData,
    ValueListFilterOperator,
    ValueListFilterUtils,
} from '@datagalaxy/core-ui';
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { CollectionsHelper, DomUtil } from '@datagalaxy/core-util';
import {
    ActivityLogDataOptions,
    ActivityLogEntryElement,
} from '../activityLog.types';
import {
    UserCellComponent,
    UserFieldSelectAdapter,
} from '@datagalaxy/webclient/user/ui';
import { ActivityLogService } from '../activityLog.service';
import { UserService } from '../../services/user.service';
import { getLocalId } from '@datagalaxy/utils';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { catchUnsuccessfulApiErrorOrThrow } from '@datagalaxy/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { UserPublicData } from '@datagalaxy/webclient/user/domain';
import { ActivityLogEntry } from '@datagalaxy/webclient/activity-log/domain';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { DxyActivityLogElementComponent } from '../dxy-activity-log-element/dxy-activity-log-element.component';
import { DxyLogFunctionalDirective } from '../../directives/dxy-log-functional.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf } from '@angular/common';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { TooltipDirective } from '@datagalaxy/ui/tooltip';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-activity-log-container',
    templateUrl: 'dxy-activity-log-container.component.html',
    styleUrls: ['./dxy-activity-log-container.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        TranslateModule,
        DxyLogFunctionalDirective,
        DxyValueListFilterComponent,
        UserCellComponent,
        DxyDateFilterComponent,
        NgFor,
        DxyActivityLogElementComponent,
        SpinnerComponent,
        DxyIconButtonDirective,
        TooltipDirective,
    ],
})
export class DxyActivityLogContainerComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() activityLogDataOptions: ActivityLogDataOptions;
    @Input() showHeader: boolean;
    @Input() disableNavigation?: boolean;

    public activityLogElements: ActivityLogEntryElement[] = [];

    protected userFilter: IValueListFilterData<UserPublicData> = {
        operator: ValueListFilterOperator.Equals,
    };
    protected userFilterOptions: UserPublicData[];
    protected userFilterAdapter: IOptionAdapter<UserPublicData> =
        new UserFieldSelectAdapter();

    protected dateFilter: IDateFilterData = {
        operator: DateFilterOperator.RangeContains,
    };

    protected get hasDateFilter() {
        return DateFilterUtils.isResolved(this.dateFilter);
    }
    protected get hasUserFilter() {
        return ValueListFilterUtils.isResolved(this.userFilter);
    }

    public get isLoadingMessageDisplayed() {
        return this.isLoading;
    }
    public get isErrorMessageDisplayed() {
        return !this.isLoading && this.hasRequestError;
    }
    public get isEmptyMessageDisplayed() {
        return (
            !this.isLoading &&
            !this.hasRequestError &&
            !this.activityLogElements.length
        );
    }
    protected get userId() {
        return this.userFilter?.values?.[0]?.UserId;
    }

    private readonly initialMaxElementsDisplayed = 30;
    private readonly moreDisplayedElementsCount = 20;
    private isLoading = true;
    private initTimer: number;
    private currentLimit: number;
    private hasRequestError: boolean;
    private activityLogEntries: ActivityLogEntry[] = [];

    constructor(
        private activityLogService: ActivityLogService,
        private functionalLogService: FunctionalLogService,
        private userService: UserService,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onChange(changes, 'activityLogDataOptions', () =>
            this.updateActivityLogEntries('ngOnChanges'),
        );
    }
    ngOnInit() {
        this.log('ngOnInit');
        this.initSelectedUsers().then();
        // Prevents display of previous entity before being updated with the new one (DG-2750)
        this.initTimer = window.setTimeout(
            () => this.updateActivityLogEntries('ngOnInit'),
            500,
        );
    }

    public onScroll(event: Event) {
        if (
            this.isLoading ||
            this.currentLimit > this.activityLogElements.length ||
            !DomUtil.isTargetScrolledToBottom(event)
        ) {
            return;
        }

        setTimeout(() => {
            this.currentLimit += this.moreDisplayedElementsCount;
            this.updateActivityLogElements();
        }, 250);
    }

    public async updateActivityLogEntries(from?: string) {
        this.log('updateActivityLogEntries', from);
        if (this.initTimer) {
            clearTimeout(this.initTimer);
            this.initTimer = null;
        }
        this.isLoading = true;
        try {
            await this.load();
            this.updateActivityLogElements();
        } finally {
            this.isLoading = false;
        }
    }

    protected async onDateFilterChange(filter: IDateFilterData) {
        this.dateFilter = filter;
        await this.updateActivityLogEntries();
    }
    protected async onUserFilterChange(
        filter: IValueListFilterData<UserPublicData>,
    ) {
        this.userFilter = filter;
        await this.updateActivityLogEntries();
    }
    protected async clearUserFilterAndReload() {
        this.userFilter = {
            ...this.userFilter,
            values: [],
        };
        await this.updateActivityLogEntries();
    }
    protected async clearDateFilterAndReload() {
        this.dateFilter.endDate = null;
        this.dateFilter.startDate = null;
        await this.updateActivityLogEntries();
    }

    private async initSelectedUsers() {
        const users = await this.userService.getNonDeletedUsers();
        this.userFilterOptions = CollectionsHelper.alphaSort(users, 'FullName');
    }
    private async load() {
        const galp = this.createGetActivityLogParameter();

        if (this.dateFilter.startDate) {
            this.functionalLogService.logFunctionalAction(
                'SOCIAL_ACTIVITY_LOG_START_DATE_FILTER',
                CrudOperation.R,
            );
            galp.FromTime = this.dateFilter.startDate
                .add(moment().utcOffset(), 'minutes')
                .toISOString();
        }

        if (this.dateFilter.endDate) {
            this.functionalLogService.logFunctionalAction(
                'SOCIAL_ACTIVITY_LOG_END_DATE_FILTER',
                CrudOperation.R,
            );
            // Add 1 day to the end date so the end date is included in the logs
            galp.ToTime = this.dateFilter.endDate
                .add(moment().utcOffset(), 'minutes')
                .add(1, 'days')
                .toISOString();
        }

        const userId = getLocalId(this.userId);
        if (userId) {
            this.functionalLogService.logFunctionalAction(
                'SOCIAL_ACTIVITY_LOG_USER_FILTER',
                CrudOperation.R,
            );
            galp.UserId = userId;
        }

        try {
            const result = await this.activityLogService.requestGetActivityLog(
                galp,
                this.destroyNotifier,
            );
            this.activityLogEntries = result.Entries;
        } catch (e) {
            catchUnsuccessfulApiErrorOrThrow(e, () => {
                this.activityLogEntries = [];
                this.hasRequestError = true;
            });
        }
        this.currentLimit = this.initialMaxElementsDisplayed;
    }
    private createGetActivityLogParameter() {
        const opt = this.activityLogDataOptions;
        if (!opt) {
            return this.activityLogService.createGetActivityLogParameter();
        }

        const userId = opt.userId || null;
        const spaceIdr = userId ? null : opt.spaceIdr;
        const dataReferenceId =
            (opt.dataReferenceId ?? spaceIdr?.spaceId) || null;
        const dataTypeName =
            (opt.dataTypeName ??
                WorkspaceIdentifier.getServerTypeName(spaceIdr)) ||
            null;
        return this.activityLogService.createGetActivityLogParameter(
            dataReferenceId,
            dataTypeName,
            userId,
        );
    }

    private updateActivityLogElements() {
        const elements = this.activityLogService.getActivityLogEntryElements(
            this.activityLogEntries,
        );
        const sortedElements = CollectionsHelper.orderBy(
            elements,
            (e) => e.entry.CreationTime,
            'desc',
        );
        this.activityLogElements = this.currentLimit
            ? sortedElements.slice(0, this.currentLimit)
            : sortedElements;
    }
}
