<div class="dg5-entity-card">
    <dxy-meta-object-type-card
        size="xxl"
        [metaObject]="entityData | metaObjectTypeCardAdapter"
    ></dxy-meta-object-type-card>
    <div class="entity-data-container">
        <div class="breadcrumb-container">
            <dxy-nav-breadcrumb
                [hierarchicalData]="entityData.HddData"
                [readOnly]="readOnly"
            ></dxy-nav-breadcrumb>
        </div>
        <div class="tab-header-row">
            <!--Property name section-->
            <div class="tab-header-container">
                <dxy-entity-header-title
                    [entity]="entityData"
                    [readOnly]="readOnly"
                ></dxy-entity-header-title>
            </div>
        </div>
        <div class="header-attributes">
            <dxy-screen-layout-category
                [screenData]="headerScreenData"
                [screenCategory]="headerScreenCategory"
                [reloadOnSourceChange]="true"
            ></dxy-screen-layout-category>
        </div>
    </div>
</div>
