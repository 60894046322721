import { inject, Injectable } from '@angular/core';
import { Language } from './language';
import { getLanguageName } from '@datagalaxy/webclient/multilingual/ui';
import { LanguageService } from '@datagalaxy/translate';

@Injectable({ providedIn: 'root' })
export class AvailableLanguagesService {
    private readonly availableLanguages: Language[];
    private languageService = inject(LanguageService);

    constructor() {
        this.availableLanguages = this.languageService.locales
            .map((locale) => {
                return {
                    languageCode: locale,
                    languageName: getLanguageName(locale),
                };
            })
            .filter((language) => !!language.languageName);
    }

    public getAvailableLanguages(): Language[] {
        return this.availableLanguages;
    }
}
