import {
    BaseMetaAttributeValue,
    UsersSelectAttributeValue,
} from '@datagalaxy/meta-attribute-value-domain';

export function buildUsersValue(
    baseMetaAttributeValue: BaseMetaAttributeValue,
    userIds: string[] | undefined,
): UsersSelectAttributeValue {
    return {
        ...baseMetaAttributeValue,
        userIds: userIds?.map((id) => id.split(':')[1]) ?? [],
    };
}
