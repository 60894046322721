import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import {
    FunnelAction,
    ValidatedFunnelStepComponent,
} from '@datagalaxy/ui/funnel';
import { map, Observable, startWith } from 'rxjs';
import { MultilingualFunnelStateService } from '../multilingual-funnel-state.service';
import { languageAdapter, LanguageForm } from '../../../languages';
import { MultilingualApiService } from '@datagalaxy/webclient/multilingual/data-access';
import { MultilingualFunnelStepComponent } from '../multilingual-funnel-step.component';
import { DxyFieldSelectComponent } from '@datagalaxy/core-ui';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'dxy-source-language-step',
    templateUrl: './source-language-step.component.html',
    styleUrls: ['../funnel-step.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DxyFieldSelectComponent,
    ],
})
export class SourceLanguageStepComponent
    extends MultilingualFunnelStepComponent
    implements ValidatedFunnelStepComponent
{
    private formBuilder = inject(FormBuilder);
    private stateService = inject(MultilingualFunnelStateService);
    private multilingualApiService = inject(MultilingualApiService);

    public valid$: Observable<boolean>;

    public override actions: FunnelAction[] = [
        this.previousAction,
        {
            ...this.nextAction,
            callback: () => this.saveSourceLanguage(),
        },
    ];

    protected form: FormGroup<LanguageForm>;
    protected languages = this.stateService.getAvailableSourceLanguages();
    protected languageAdapter = languageAdapter;

    public constructor() {
        super();
        const defaultValue = this.stateService.getSourceLanguage() ?? null;
        this.form = this.formBuilder.group<LanguageForm>({
            language: new FormControl(defaultValue, [Validators.required]),
        });
        this.valid$ = this.form.statusChanges.pipe(
            startWith(this.form.status),
            map((status) => status === 'VALID'),
        );
        this.form.controls.language.valueChanges.subscribe((value) => {
            if (!value) {
                return;
            }
            this.stateService.setSourceLanguage(value);
        });
    }

    public async saveSourceLanguage() {
        await this.multilingualApiService.updateSettings({
            sourceLanguageCode:
                this.form.value?.language?.languageCode ?? undefined,
            enabled: false,
        });
    }
}
