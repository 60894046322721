import { SerializableEnumeration } from 'cerialize';
//REWITE
export enum FilterModuleName {
    Glossary,
    Usage,
    Processing,
    Catalog,
    MainSearch,
}
SerializableEnumeration(FilterModuleName);
