<div class="commentary-header">
    <span class="commentary-user-info">
        <dxy-profile-avatar
            [userId]="commentary.CreationUserId"
            [isMiniIcon]="true"
        ></dxy-profile-avatar>
        <span class="commentary-user-name">{{ userName }}</span>
    </span>
    <span class="commentary-date">{{ modificationDate }}</span>
    <div class="header-actions">
        <button
            *ngIf="isDeleteEnabled"
            dxyIconButton
            variant="tertiary"
            color="destructive"
            (click)="deleteCommentary()"
            [dxyTooltip]="'UI.CommentaryContainer.btnDelete' | translate"
            dxyTooltipPosition="below"
        >
            <i class="glyph-delete"></i>
        </button>
        <button
            *ngIf="isEditEnabled"
            dxyIconButton
            variant="tertiary"
            (click)="editCommentary()"
            [dxyTooltip]="'UI.CommentaryContainer.btnEdit' | translate"
            dxyTooltipPosition="below"
        >
            <i class="glyph-edit"></i>
        </button>
    </div>
</div>
<form
    name="commentaryEditForm"
    class="commentary-body"
    (ngSubmit)="submitUpdateCommentary()"
>
    <dxy-rich-text-field
        name="contentText"
        class="commentary-text no-padding no-underline no-min-width"
        [readonly]="!isEditActive"
        [class.read-only]="!isEditActive"
        [class.form-control]="isEditActive"
        [(ngModel)]="commentary.Text"
        toolbarDisplayMode="focus"
        [takeFocus]="true"
        [mentionResolvers]="mentionResolvers"
        [noTabCapture]="true"
    ></dxy-rich-text-field>
    <div *ngIf="isEditActive" class="dxy-actions">
        <button
            type="button"
            dxyButton
            variant="secondary"
            (click)="cancelUpdateCommentary()"
        >
            <i class="glyph-cancelsearch"></i>
            <span translate>UI.CommentaryContainer.btnCancel</span>
        </button>
        <button
            dxyButton
            type="submit"
            [disabled]="isSubmitUpdateCommentaryDisabled"
            dxyLogFunctional="featureCode"
        >
            <i class="glyph glyph-check"></i>
            <span translate>UI.CommentaryItem.btnSubmit</span>
        </button>
    </div>
</form>
