<dxy-message-timer
    [color]="color()"
    [duration]="timerDuration()"
></dxy-message-timer>
<article>
    @if (hasHeader()) {
        <header>
            @if (titleGlyphClass()) {
                <i [ngClass]="iconClasses()"></i>
            }
            <h3>
                <ng-content select="[message-title]"></ng-content>
            </h3>
            @if (canClose()) {
                <button
                    dxyIconButton
                    type="button"
                    variant="tertiary"
                    (click)="closeClick.emit()"
                >
                    <i class="glyph-cancelsearch"></i>
                </button>
            }
        </header>
    }
    <section>
        <ng-content select="[message-content]"></ng-content>
    </section>
</article>
