import { inject, Pipe, PipeTransform } from '@angular/core';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { MetaObject } from '@datagalaxy/meta-object-domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { EntityItemAdapter } from '@datagalaxy/meta-object-data-access';

@Pipe({
    name: 'metaObjectTypeCardAdapter',
    standalone: true,
})
export class MetaObjectTypeCardAdapterPipe implements PipeTransform {
    private entityItemAdapter = inject(EntityItemAdapter);

    public transform(entity: EntityItem | HierarchicalData): MetaObject {
        if (entity instanceof HierarchicalData) {
            return this.entityItemAdapter.buildMetaObjectFromHierarchicalData(
                entity,
            );
        }
        return this.entityItemAdapter.buildMetaObjectFromEntityItem(entity);
    }
}
