import { Injectable } from '@angular/core';
import { LineageVersionState } from './lineage-version-toggler.type';
import { BaseStateService } from '@datagalaxy/utils';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';
import { UserSettingValueService } from '@datagalaxy/users/data-access';

@Injectable({ providedIn: 'root' })
export class LineageVersionTogglerService extends BaseStateService<LineageVersionState> {
    private static blueprint: LineageVersionState = { useLegacyLineage: null };
    private _initPromise: Promise<void>;

    constructor(private userSettingsService: UserSettingValueService) {
        super({ ...LineageVersionTogglerService.blueprint });
    }

    public async shouldUseLegacyLineage() {
        if (!this._initPromise) {
            this._initPromise = this.init();
        }

        await this._initPromise;

        return !!this.state.useLegacyLineage;
    }

    public async setUseLegacyLineage(shouldUse: boolean) {
        this.setState({ useLegacyLineage: shouldUse });
        await this.userSettingsService.setUserSettingValue(
            userSettingsValues.dks.category,
            userSettingsValues.dks.routes.linagev2,
            JSON.stringify({ useLegacyLineage: shouldUse }),
        );
    }

    private async init() {
        const res = await this.userSettingsService.getUserSettingValue(
            userSettingsValues.dks.category,
            userSettingsValues.dks.routes.linagev2,
        );

        this.setState(
            res
                ? JSON.parse(res)
                : { ...LineageVersionTogglerService.blueprint },
        );
    }
}
