import {
    ChangeDetectionStrategy,
    Component,
    computed,
    input,
} from '@angular/core';
import { MetaObjectType } from '@datagalaxy/meta-object-domain';
import { MetaObjectTypeUtils } from '@datagalaxy/meta-object-utils';
import { IconComponent, IconSize } from '@datagalaxy/ui/icon';
import { TooltipDirective } from '@datagalaxy/ui/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    standalone: true,
    selector: 'dxy-object-type-icon',
    templateUrl: './object-type-icon.component.html',
    styleUrls: ['./object-type-icon.component.scss'],
    imports: [TranslateModule, IconComponent, TooltipDirective],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectTypeIconComponent {
    public type = input.required<MetaObjectType>();
    public size = input<IconSize>('medium');
    public tooltipDisabled = input(false);

    protected glyphClass = computed(() =>
        MetaObjectTypeUtils.getColoredGlyphClass(this.type()),
    );

    protected tooltipKey = computed(() =>
        MetaObjectTypeUtils.getTranslateKey(this.type()),
    );
}
