import {
    AfterViewInit,
    Component,
    ElementRef,
    forwardRef,
    NgZone,
    OnInit,
    ViewChild,
} from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DxyFieldMultiSelectComponent } from '@datagalaxy/core-ui/fields';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { DxyAttributeBaseInput } from '../DxyAttributeBaseInput';
import { IMultiSelectData } from '@datagalaxy/core-ui';
import { BehaviorSubject } from 'rxjs';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { IObjectDataTags } from '../../attribute.types';
import {
    getUiMultiSelectDataType,
    MultiSelectAdapter,
} from '../../../shared-ui/UiMultiSelect.util';
import { EntityEventService } from '../../../entity/services/entity-event.service';
import { AttributeDataService } from '../../attribute-data.service';
import { multiSelectEntityCardParams } from '../../../entityCard/entity-card/entity-card-cell.types';
import {
    AttributeMetaType,
    AttributeMetaValue,
} from '@datagalaxy/webclient/attribute/domain';
import { DomUtils, generateGuid } from '@datagalaxy/utils';
import { AttributeSuggestionListComponent } from '../../attribute-suggestion-list/attribute-suggestion-list.component';
import { DxyUnitaryFieldActionsComponent } from '../../../fields/unitary/dxy-unitary-field-actions/dxy-unitary-field-actions.component';
import { AttributeCollectionCellComponent } from '../../../shared-ui/cells/attribute-collection-cell/attribute-collection-cell.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'dxy-attribute-multi-select-input',
    templateUrl: './dxy-attribute-multi-select-input.component.html',
    styleUrls: ['./dxy-attribute-multi-select-input.component.scss'],
    standalone: true,
    imports: [
        DxyFieldMultiSelectComponent,
        NgIf,
        forwardRef(() => AttributeCollectionCellComponent),
        TranslateModule,
        DxyUnitaryFieldActionsComponent,
        AttributeSuggestionListComponent,
        AsyncPipe,
    ],
})
export class DxyAttributeMultiselectInputComponent
    extends DxyAttributeBaseInput<(AttributeMetaValue | string)[]>
    implements OnInit, AfterViewInit
{
    @ViewChild('field') field: DxyFieldMultiSelectComponent<unknown>;

    public multiSelectData: IMultiSelectData<AttributeMetaValue>;
    public emptySearchTerm: string;
    public get attributeInfo() {
        return this.attributeMeta;
    }
    public get canAddNewValue() {
        return this.attributeType == AttributeMetaType.MultiValueList;
    }
    /** ordered items array to prevent collection cell to refresh layout to often */
    public collectionCellItems: AttributeMetaValue[];
    public selectedItems: AttributeMetaValue[] = [];
    /** app-attribute-collection-cell width.
     * Set every time component size changed. */
    public get collectionWidth$() {
        return this.collectionWidth.asObservable();
    }
    private readonly collectionWidth = new BehaviorSubject<number>(0);

    private availableItems: AttributeMetaValue[] = [];
    private initialItems: AttributeMetaValue[] = [];
    private isInitialDataLoad = true;
    private readonly attributeTypesTag = [
        AttributeMetaType.ManagedTag,
        AttributeMetaType.UserReference,
        AttributeMetaType.ClientTag,
        AttributeMetaType.StewardUserReference,
        AttributeMetaType.Hierarchy,
    ];

    private get el() {
        return this.elementRef.nativeElement;
    }

    constructor(
        private entityEventService: EntityEventService,
        private ngZone: NgZone,
        translate: TranslateService,
        elementRef: ElementRef<HTMLElement>,
    ) {
        super(elementRef, translate);
    }

    ngOnInit() {
        const entityData = this.getEntityData();
        if (entityData) {
            super.registerSubscription(
                this.entityEventService.subscribeEntityUpdate(
                    entityData.ServerType,
                    (entity) => this.onEntityUpdate(entity),
                ),
            );
        }
        if (!this.isBulkForm) {
            this.setSelectedItems(this.makeItems());
            this.availableItems = this.selectedItems.slice();
            this.setClean();
        }
        this.buildMultiSelectData();
        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        this.initCollectionWidth();
    }

    //#region IAttributeBaseInputOverride

    public async setAttributeActive() {
        this.log(
            'setAttributeActive',
            this.hasLoadReferenceOptions,
            this.isInitialDataLoad,
            !!this.selectedItems,
        );
        await super.setAttributeActive();

        if (
            !this.hasLoadReferenceOptions ||
            (!this.isInitialDataLoad && this.selectedItems)
        ) {
            return;
        }

        //this.multiSelectData.isLoading = true
        try {
            this.availableItems =
                await this.loadAvailableAttributeValues('setAttributeActive');
            this.updateSelectedItems('loadReferenceOptions');
            this.buildMultiSelectData();
            this.isInitialDataLoad = false;
        } finally {
            /*this.multiSelectData.isLoading = false*/
        }
    }

    public async onBeforeValidate() {
        if (this.isMandatoryAttributeEmpty()) {
            this.log('onBeforeValidate-isMandatoryAttributeEmpty');
            return false;
        }

        if (this.isDirty()) {
            await this.setData(this.selectedItems);
            this.log('onBeforeValidate-dirty', this.selectedItems);
        }

        return true;
    }

    public isDirty() {
        return (
            this.isActive &&
            !CollectionsHelper.contentEquals(
                this.selectedItems,
                this.initialItems,
                false,
                true,
                (v1, v2) => v1.Key == v2.Key,
            )
        );
    }

    public async onServerSuccess(result: any) {
        this.log('onServerSuccess', result);
        this.setClean();
        this.setInitialValue(this.getData(), true);
    }

    public onAfterUndo() {
        this.updateSelectedItems('undo');
        this.setClean();
    }

    public isMandatoryAttributeEmpty() {
        return (
            AttributeDataService.isDataOwnerOrDataStewardAttributeKey(
                this.attributeKey,
            ) && !this.selectedItems?.length
        );
    }

    public onCreateOption() {
        const searchTerm = this.emptySearchTerm;
        const attribute = new AttributeMetaValue(
            this.attributeMeta,
            `${generateGuid()}:${generateGuid()}`,
            searchTerm,
            { newValue: searchTerm, color: 'white' },
        );
        this.availableItems.push(attribute);
        this.selectedItems.push(attribute);
        this.buildMultiSelectData();
    }

    public focusField() {
        this.field.doFocus();
    }
    public blurField() {
        this.field.doBlur();
    }
    //#endregion - IAttributeBaseInputOverride

    private async onEntityUpdate(entity: IEntityIdentifier) {
        this.log(
            'onEntityUpdate',
            this.isActive,
            this.isValidating,
            entity?.ReferenceId,
        );
        if (
            this.isActive ||
            this.isValidating ||
            !entity?.ReferenceId ||
            this.getEntityData()?.ReferenceId != entity.ReferenceId
        ) {
            return;
        }

        this.availableItems = await this.loadAvailableAttributeValues();
        this.updateSelectedItems('onEntityUpdate');
    }

    private updateSelectedItems(debugFrom: string) {
        this.log('updateSelectedItems', debugFrom);
        const currentKeys = this.getObjectDataAsTags()?.map(
            (odt) => odt.ObjectId,
        );
        this.setSelectedItems(
            this.availableItems.filter((it) => currentKeys?.includes(it.Key)),
        );
        this.buildMultiSelectData();
        this.setClean();
    }

    private getObjectDataAsTags() {
        return this.getObjectData() as IObjectDataTags[];
    }

    private setClean() {
        this.log('setClean', this.selectedItems);
        this.initialItems = this.selectedItems.slice();
    }

    private makeItems() {
        return this.getObjectDataAsTags()?.map(
            (odt) =>
                new AttributeMetaValue(
                    this.attributeMeta,
                    odt.ObjectId,
                    odt.DisplayName,
                    {
                        translatedDescription: odt.TagDescription,
                        color: odt.TagColor,
                    },
                ),
        );
    }

    private async loadAvailableAttributeValues(logId?: string) {
        const amvs = await this.loadReferenceOptions();
        this.log(`${logId}-loadReferenceOptions-result`, amvs);
        return amvs ?? [];
    }

    private alphaSortOptions(options: AttributeMetaValue[]) {
        return !options
            ? []
            : this.attributeType == AttributeMetaType.Hierarchy
              ? options
              : CollectionsHelper.alphaSort(options, 'translatedDisplayName');
    }

    private getNoDataMessage() {
        if (
            this.selectedItems.length ||
            !this.attributeTypesTag.includes(this.attributeType)
        ) {
            return;
        }

        let suffix: string;
        switch (this.attributeType) {
            case AttributeMetaType.UserReference:
            case AttributeMetaType.StewardUserReference:
                suffix = 'noUserAvailable';
                break;
            case AttributeMetaType.Hierarchy:
                suffix = 'noHierarchyAvailable';
                break;
            default:
                suffix = 'noTagAvailable';
                break;
        }
        return this.translate.instant(`UI.Components.TagsField.${suffix}`);
    }

    private buildMultiSelectData() {
        this.multiSelectData = {
            items: this.availableItems.slice(),
            selectedItems: this.selectedItems.slice(),
            dataType: getUiMultiSelectDataType(this.attributeType),
            searchParams: {
                enabled: true,
                threshold: false,
            },
            hasSelectAll: true,
            noDataMessage: this.getNoDataMessage(),
            adapter: MultiSelectAdapter.attributeMetaValue(
                this.attributeType,
                (data) => ({
                    ...multiSelectEntityCardParams,
                    data,
                }),
            ),
            sortOptions: (items) => this.alphaSortOptions(items),
            onEmptySearchResult: (searchTerm) =>
                (this.emptySearchTerm = searchTerm),
            onSelectionChange: (selectedItems) =>
                this.onSelectionChange(selectedItems),
        };
    }

    private async onSelectionChange(selectedItems: AttributeMetaValue[]) {
        this.log('onSelectionChange', selectedItems, this.initialItems);
        this.setSelectedItems(selectedItems);

        this.resetMessages();

        if (this.isBulkForm) {
            await this.setAttributeValue(selectedItems);
        } else {
            const ids = selectedItems.map((amv) => amv.Key);
            await this.setAttributeValue(ids);
        }

        this.checkError();
        this.logResult('onSelectionChange');
    }

    private checkError() {
        if (this.isMandatoryAttributeEmpty()) {
            super.setInternalError(
                this.translate.instant(
                    'UI.Components.TagsField.fieldCannotBeEmpty',
                ),
            );
        }
    }

    private logResult(from: string) {
        if (!this.debug) {
            return;
        }
        this.log(
            `${from}-result`,
            this.isActive,
            this.isDirty(),
            this.selectedItems,
            this.getData(),
        );
    }

    private setSelectedItems(items: AttributeMetaValue[]) {
        this.selectedItems = items;
        if (!this.mini) {
            return;
        }
        const sortedItems = this.alphaSortOptions(items);
        if (
            CollectionsHelper.anyDifference(
                sortedItems?.map((item) => item.Value),
                this.collectionCellItems?.map((item) => item.Value),
            )
        ) {
            this.collectionCellItems = sortedItems;
        }
    }

    private initCollectionWidth() {
        if (!this.mini) {
            return;
        }
        this.setCollectionWidthOnResize();
        super.subscribe(DomUtils.resizeObservable(this.el, 10), (entries) =>
            this.setCollectionWidthOnResize(entries),
        );
    }

    /** Set collection width when component html element is resized */
    private setCollectionWidthOnResize(entry?: ResizeObserverEntry) {
        const width = entry?.contentRect.width ?? this.el.offsetWidth;
        const cw = (width ?? 70) - 23;
        if (cw != this.collectionWidth.value) {
            this.collectionWidth.next(cw);
        }
    }
}
