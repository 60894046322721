import {
    IEntityPanelData,
    IEntityPanelToolsData,
} from '../../shared/entity/interfaces/entity-panel.interface';
import { EntityPreviewPanelService } from '../../shared/entity/services/entity-preview-panel.service';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { IDxyPreviewPaneContent } from '../../shared/shared-ui/dxy-preview-panel-slider/dxy-preview-panel-slider.types';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { EntityPanelTool } from '../entity-panels.types';
import { EntityEventService } from '../../shared/entity/services/entity-event.service';
import { EntityService } from '../../shared/entity/services/entity.service';
import { DiagramSecurityService } from '../../diagrams/diagram/diagram-security.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import {
    EntityIdentifier,
    EntityUtils,
} from '@datagalaxy/webclient/entity/utils';
import { EntitySuggestionStoreService } from '../../suggestions/entity-suggestion-store.service';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ApiServiceErrorType, isApiError } from '@datagalaxy/data-access';
import { LegacyToaster } from '@datagalaxy/ui/toaster';
import { TranslateService } from '@ngx-translate/core';
import { EntityPreviewPanelSkeletonComponent } from '../entity-preview-panel-skeleton/entity-preview-panel-skeleton.component';
import { DxyEntityPanelBodyComponent } from '../dxy-entity-panel-body/dxy-entity-panel-body.component';
import { DxyEntityPanelHeaderComponent } from '../dxy-entity-panel-header/dxy-entity-panel-header.component';
import { AsyncPipe, NgIf } from '@angular/common';

/**
 * ## Role
 *  Encapsulates the header and body for an Entity Preview Panel
 */
@Component({
    selector: 'dxy-entity-preview-panel-content',
    templateUrl: 'dxy-entity-preview-panel-content.component.html',
    styleUrls: ['dxy-entity-preview-panel-content.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        DxyEntityPanelHeaderComponent,
        DxyEntityPanelBodyComponent,
        EntityPreviewPanelSkeletonComponent,
        AsyncPipe,
    ],
})
export class DxyEntityPreviewPanelContentComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges, IDxyPreviewPaneContent<IEntityPanelData>
{
    @Input() entityIdr: IEntityIdentifier;
    @Input() preferredTool: EntityPanelTool;
    @Input() readOnly: boolean;
    @Input() noNavLink: boolean;
    @Output() readonly onReady = new EventEmitter<IEntityPanelData>();

    public entityData: EntityItem;
    public panelBodyData: IEntityPanelData;
    public get activeState$() {
        return this.panelService.activeState$;
    }
    public get dragDropConfig$() {
        return this.panelService.dragDropConfig$;
    }

    private prevEntityIdr: IEntityIdentifier;

    constructor(
        private cdRef: ChangeDetectorRef,
        private panelService: EntityPreviewPanelService,
        private entityEventService: EntityEventService,
        private entityService: EntityService,
        private diagramSecurityService: DiagramSecurityService,
        private toaster: LegacyToaster,
        private translate: TranslateService,
        private entitySuggestionStoreService: EntitySuggestionStoreService,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'entityIdr', () => this.asyncInit());
    }

    ngOnInit() {
        this.log('$onInit');
        this.asyncInit();
        this.subscribeEvents();
    }

    public onCloseClick() {
        this.panelService.hidePanel();
    }

    public onToolClick(tool: EntityPanelTool) {
        this.panelService.setActiveTool(tool);
    }

    private async asyncInit() {
        const isSame = !!EntityIdentifier.areSame(
            this.entityIdr,
            this.prevEntityIdr,
        );
        this.prevEntityIdr = this.entityIdr;
        this.log('asyncInit', '(entityIdr,isSame)', !!this.entityIdr, isSame);
        if (isSame && this.panelBodyData) {
            this.signalReady();
            return;
        }
        await this.loadEntityData();
        const { entityMoreData, orderedTools, activeTool } =
            await this.loadToolsData();

        let readOnlyTools: EntityPanelTool[];
        if (
            !this.readOnly &&
            this.diagramSecurityService.isReaderUserAndPublicDiagram(
                this.entityData,
            )
        ) {
            readOnlyTools = CollectionsHelper.except(
                orderedTools,
                [EntityPanelTool.Commentaries, EntityPanelTool.Tasks],
                true,
            );
        }

        this.panelBodyData = {
            entityData: this.entityData,
            entityMoreData,
            orderedTools,
            activeTool,
            readOnly: this.readOnly,
            noNavLink: this.noNavLink,
            readOnlyTools,
        };
        this.log('setup-panelBodyData', !!this.panelBodyData);
        this.signalReady();
    }

    private async loadEntityData() {
        try {
            this.entityData =
                await this.entityService.getEntityForEntityDockingPane(
                    this.entityIdr,
                );
            this.entitySuggestionStoreService.setEntity(this.entityData);
        } catch (error) {
            this.panelService.hidePanel();
            if (isApiError(error)) {
                let messageKey = 'UI.Errors.unknownError';

                switch (error.type) {
                    case ApiServiceErrorType.ObjectNotFound:
                        messageKey = 'UI.Errors.objectNotFound';
                        break;
                    case ApiServiceErrorType.AccessDenied:
                        messageKey = 'UI.Errors.objectAccessDenied';
                        break;
                }
                this.toaster.warningToast({
                    title: this.translate.instant(messageKey),
                    message: this.translate.instant(messageKey),
                });
                return;
            }

            throw error;
        }
    }

    private async loadToolsData(): Promise<IEntityPanelToolsData> {
        const orderedPossibleTools = [
            EntityPanelTool.Details,
            EntityPanelTool.Insights,
            EntityPanelTool.DataQuality,
            EntityPanelTool.EntityTree,
            EntityPanelTool.PrimaryKey,
            EntityPanelTool.LinkedObject,
            EntityPanelTool.Diagrams,
            EntityPanelTool.Assets,
            EntityPanelTool.Tasks,
            EntityPanelTool.Commentaries,
            EntityPanelTool.Suggestion,
            EntityPanelTool.VersionComparison,
            EntityPanelTool.Campaign,
            EntityPanelTool.ActivityLog,
        ];

        const preferredTool = this.preferredTool,
            prevActiveTool = this.panelService.activeTool;
        this.debug &&
            this.log(
                'setup-getToolsData (prevActive,preferred)',
                EntityPanelTool[prevActiveTool],
                EntityPanelTool[preferredTool],
            );

        const toolsData = await this.panelService.getToolsData(
            this.entityData,
            this.readOnly,
            orderedPossibleTools,
            preferredTool,
        );

        if (this.debug) {
            const { activeTool, orderedTools } = toolsData;
            if (activeTool != prevActiveTool) {
                this.log(
                    'invalid current tool',
                    EntityPanelTool[prevActiveTool],
                    '->',
                    EntityPanelTool[activeTool],
                );
            }
            this.log(
                'setup-(orderedTools,activeTool)',
                orderedTools.map((t) => EntityPanelTool[t]),
                EntityPanelTool[activeTool],
            );
        }

        return toolsData;
    }

    private subscribeEvents() {
        this.registerSubscriptions(
            this.entityEventService.subscribeEntityUpdate(
                this.entityIdr.ServerType,
                (entity, external) => this.onUpdateEntity(entity, external),
            ),
            this.entityEventService.subscribeEntityBulkUpdate(
                this.entityIdr.ServerType,
                (entities) => {
                    if (!entities?.length) {
                        return;
                    }
                    const updatedEntity = entities.find(
                        (entity) =>
                            entity.ReferenceId === this.entityIdr.ReferenceId,
                    );
                    if (updatedEntity) {
                        this.onUpdateEntity(updatedEntity, true);
                    }
                },
            ),
        );
    }

    private onUpdateEntity(updatedEntity: EntityItem, external: boolean) {
        if (
            !updatedEntity ||
            !this.entityData ||
            this.entityData.ReferenceId !== updatedEntity.ReferenceId
        ) {
            return;
        }
        this.entityEventService.notifyEntityFieldsUpdate(
            updatedEntity,
            external,
        );
        EntityUtils.mergeEntity(
            this.entityData as EntityItem,
            updatedEntity,
            true,
        );
    }

    private signalReady() {
        this.log('signaling ready');
        this.cdRef.detectChanges();
        this.onReady.emit(this.panelBodyData);
    }
}
