import { CollectionsHelper } from '@datagalaxy/core-util';
import { IFilterFormModel } from '../../shared/attribute/attribute-filter/attribute-filter-form/IFilterFormModel';
import { AttributeFilterService } from '../../shared/attribute/attribute-filter/attribute-filter.service';
import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FacetGroup } from './models/FacetGroup';
import { EntityType } from '@datagalaxy/dg-object-model';
import { FacetItem, IFacetItemOptData } from './models/FacetItem';
import { FacetGroupType } from './models/FacetGroupType';
import { SearchSettingsService } from '../search-settings.service';
import { GlyphService } from '../../services/glyph.service';
import { TechnologyUiService } from '../../client-admin/services/technology-ui.service';
import { SearchResult } from '../search.service';
import { GlyphUtil } from '../../shared/util/GlyphUtil';
import { DataUtil } from '../../shared/util/DataUtil';
import {
    AttributeFilter,
    AttributeFilterValue,
} from '@datagalaxy/webclient/attribute/data-access';
import { EntityTypeUtils } from '@datagalaxy/webclient/entity/utils';
import {
    AttributeMetaType,
    AttributeMetaValue,
    EntityLifecycleStatus,
} from '@datagalaxy/webclient/attribute/domain';
import { FilterOperator } from '@datagalaxy/filter-domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

@Injectable({ providedIn: 'root' })
export class SearchFacetService extends BaseService {
    //#region static

    /** https://datagalaxy.atlassian.net/wiki/spaces/DG3/pages/1752465412/La+barre+des+filtres+multicrit+res#EXG-02.3 */
    public static isSectionToBeHidden(
        root: FacetGroup,
        operator: FilterOperator,
    ) {
        const fgt = root.groupType;
        if (
            SearchFacetService.isDate(fgt) ||
            SearchFacetService.isBoolean(fgt) ||
            SearchFacetService.isListValue(fgt)
        ) {
            return true;
        } else if (SearchFacetService.isMultiValue(fgt)) {
            return (
                operator == FilterOperator.ListContains ||
                operator == FilterOperator.FieldHasValue
            );
        }
        return false;
    }

    /** https://datagalaxy.atlassian.net/wiki/spaces/DG3/pages/1752465412/La+barre+des+filtres+multicrit+res#EXG-02.3 */
    public static getPreferredOperator(facet: FacetItem) {
        return SearchFacetService.isMultiValue(facet.groupType)
            ? FilterOperator.ListMatchAll
            : undefined;
    }

    private static isListValue(fgt: FacetGroupType) {
        switch (fgt) {
            case FacetGroupType.EntityType:
            case FacetGroupType.EntityStatus:
            case FacetGroupType.Module:
            case FacetGroupType.TechnologyCode:
            case FacetGroupType.GdprRiskLevel:
            case FacetGroupType.GdprPersonalDataType:
            case FacetGroupType.GdprPhysicalPersonType:
            case FacetGroupType.GdprSensitiveDataType:
                return true;
            default:
                return false;
        }
    }
    public static isMultiValue(fgt: FacetGroupType) {
        switch (fgt) {
            case FacetGroupType.Tag:
            case FacetGroupType.DataOwners:
            case FacetGroupType.DataStewards:
            case FacetGroupType.CdoUsers:
            case FacetGroupType.CisoUsers:
            case FacetGroupType.DpoUsers:
            case FacetGroupType.ExpertUsers:
                return true;
            default:
                return false;
        }
    }
    private static isDate(fgt: FacetGroupType) {
        switch (fgt) {
            case FacetGroupType.CreationTime:
            case FacetGroupType.LastModificationTime:
                return true;
            default:
                return false;
        }
    }
    private static isBoolean(fgt: FacetGroupType) {
        switch (fgt) {
            case FacetGroupType.GdprContainsPersonalData:
            case FacetGroupType.GdprContainsSensitiveData:
            case FacetGroupType.GdprIsPersonalData:
            case FacetGroupType.GdprIsSensitiveData:
                return true;
            default:
                return false;
        }
    }
    //#endregion

    private readonly orderedDateTimeOperatorNames: string[];

    constructor(
        private translate: TranslateService,
        private searchSettingsService: SearchSettingsService,
        private glyphService: GlyphService,
        private technologyUiService: TechnologyUiService,
    ) {
        super();
        this.orderedDateTimeOperatorNames = [
            FilterOperator.DateTimeToday,
            FilterOperator.DateTimeYesterday,
            FilterOperator.DateTimeCurrentWeek,
            FilterOperator.DateTimePastWeek,
            FilterOperator.DateTimeCurrentMonth,
            FilterOperator.DateTimePastMonth,
            FilterOperator.DateTimeCurrentYear,
        ].map((v) => FilterOperator[v]);
    }

    public buildRoots(sr: SearchResult, ffm: IFilterFormModel) {
        const groups = new Array<FacetGroup>();
        const facetGroupTypes =
            sr &&
            CollectionsHelper.getEnumValues(
                FacetGroupType,
                FacetGroupType.unknown,
            );
        facetGroupTypes.forEach((fgt) =>
            groups.push(this.buildGroup(fgt, sr, ffm)),
        );
        this.log('buildRoots', sr, groups);
        return groups;
    }

    private buildGroup(
        fgt: FacetGroupType,
        sr: SearchResult,
        ffm: IFilterFormModel,
    ) {
        const getItems = (ak: string) =>
            CollectionsHelper.flattenGroups(
                sr.attributeFilters.filter((af) => af.AttributeKey == ak),
                (af) =>
                    af.Values.map((afv) =>
                        this.buildFacetFilterItem({ af, afv, fgt, ffm }),
                    ).filter((o) => o),
            );

        const makeGroup = (
            groupKey: string,
            items: FacetItem[],
            subGroups: FacetGroup[],
            addTradPrefix: boolean,
            isCollapsible = false,
        ) => {
            const groupDisplayName = this.translate.instant(
                `${addTradPrefix ? 'UI.Search.Filters.group' : ''}${groupKey}`,
            );
            const sorter = this.getInGroupSorter(fgt);
            items = sorter && items?.length ? items.sort(sorter) : items;
            return new FacetGroup(
                fgt,
                groupDisplayName,
                items,
                subGroups,
                isCollapsible,
            );
        };

        if (fgt == FacetGroupType.EntityType) {
            const attributeKey = ServerConstants.Search.EntityTypeFilterKey;
            const etItems = getItems(attributeKey);
            const subGroupKeys =
                this.searchSettingsService.getSearchResultGroupKeys();
            const subgroups = subGroupKeys.map((sgk) => {
                const entityTypes =
                    this.searchSettingsService.getSearchResultGroupEntityTypes(
                        sgk,
                    );
                const subGroupItems = etItems.filter((it) =>
                    entityTypes.includes(EntityType[it.valueId]),
                );
                return makeGroup(sgk, subGroupItems, undefined, false);
            });
            return makeGroup(attributeKey, undefined, subgroups, true, true);
        }

        const attributeKey =
            fgt == FacetGroupType.Tag
                ? ServerConstants.AttributeConstants.SystemTagsAttributeKey
                : FacetGroupType[fgt];
        const items = getItems(attributeKey);
        return makeGroup(attributeKey, items, undefined, true, true);
    }

    private getInGroupSorter(fgt: FacetGroupType) {
        switch (fgt) {
            case FacetGroupType.CreationTime:
            case FacetGroupType.LastModificationTime:
                return (a: FacetItem, b: FacetItem) =>
                    this.orderedDateTimeOperatorNames.indexOf(a.valueId) -
                    this.orderedDateTimeOperatorNames.indexOf(b.valueId);
            default:
                // items default sort order returned by the server is by: count, desc
                return;
        }
    }

    private buildFacetFilterItem(data: IFacetItemData) {
        switch (data.fgt) {
            case FacetGroupType.Module: {
                const module = DgModule[data.afv.ValueKey] ?? DgModule.unknown;
                return this.makeFacetItem(data, {
                    glyphClassName:
                        this.glyphService.getModuleColoredGlyphClass(module),
                });
            }
            case FacetGroupType.EntityType: {
                const entityType: EntityType = EntityType[data.afv.ValueKey];
                return this.makeFacetItem(data, {
                    glyphClassName:
                        EntityTypeUtils.getColoredGlyphClass(entityType),
                });
            }

            case FacetGroupType.EntityStatus:
                return this.makeFacetItem(data, {
                    glyphClassName: GlyphUtil.getStatusGlyphClass(
                        EntityLifecycleStatus[data.afv.ValueKey],
                    ),
                });

            case FacetGroupType.TechnologyCode: {
                const technology =
                    this.technologyUiService.getTechnologyFromCode(
                        data.afv.ValueKey,
                    );
                return this.makeFacetItem(data, {
                    iconUrl:
                        this.technologyUiService.getTechnologyIconUrl(
                            technology,
                        ),
                    hasIcon: true,
                });
            }

            case FacetGroupType.Tag: {
                const ov = data.afv.ObjectValue;
                return this.makeFacetItem(data, {
                    tooltipText: ov?.TagDescription,
                    glyphColor: ov?.TagColor,
                });
            }

            default:
                return this.makeFacetItem(data);
        }
    }
    private makeFacetItem(data: IFacetItemData, opt?: IFacetItemOptData) {
        const amv = this.getAttributeValue(data);
        if (!amv?.attributeInfo) {
            return;
        }

        const dataCount = data.afv.Count ?? 0;
        const compareAsLocalId = AttributeFilterService.isToBeComparedAsLocalId(
            data.af.AttributeKey,
        );
        return new FacetItem(data.fgt, dataCount, amv, compareAsLocalId, opt);
    }
    private getAttributeValue(data: IFacetItemData) {
        const { af, afv, ffm } = data,
            attributeKey = af.AttributeKey;
        const ami = AttributeFilterService.getAttributeMeta(attributeKey, ffm);
        if (!ami) {
            this.warn('AMI not found for', attributeKey, ffm);
        }

        let amv: AttributeMetaValue;
        switch (attributeKey) {
            case '_EntityType':
            case 'EntityStatus':
                amv = ami.ListValues.find((v) => v.Key == afv.ValueKey);
                break;
        }
        if (!amv) {
            amv = new AttributeMetaValue(ami, afv.ValueKey, afv.ValueName);
            amv.translatedDescription = afv.ObjectValue?.TagDescription;
            amv.Color = afv.ObjectValue?.TagColor;
            if (ami.AttributeKey == ServerConstants.PropertyName.Technology) {
                const code = afv.ValueKey;
                amv.translatedDisplayName = code
                    ? this.technologyUiService.getTechnologyFromCode(code)
                          ?.DisplayName
                    : this.translate.instant(
                          'UI.TechnologyAdmin.undefinedTechno',
                      );
            }
            const translateKey = this.getValueTranslateKey(data);
            if (translateKey) {
                amv.translatedDisplayName =
                    this.translate.instant(translateKey);
            }

            this.debug &&
                this.log('amv created for', attributeKey, afv.ValueKey);
        }
        return amv;
    }
    private getValueTranslateKey(data: IFacetItemData) {
        switch (data.af.AttributeType) {
            case AttributeMetaType.Module:
                return DataUtil.getModuleTranslateKey(
                    DgModule[data.afv.ValueKey],
                );
            case AttributeMetaType.DateTime:
                return `UI.Filter.operator.DateTime.${data.afv.ValueKey}`;
            case AttributeMetaType.Boolean:
                return `DgServerTypes.BooleanValue.${
                    data.afv.ValueKey === '0' ? 'False' : 'True'
                }`;
        }
    }
}

interface IFacetItemData {
    af: AttributeFilter;
    afv: AttributeFilterValue;
    fgt: FacetGroupType;
    ffm: IFilterFormModel;
}
