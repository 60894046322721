import {
    ChangeDetectionStrategy,
    Component,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { AttributeFilterService } from '../attribute-filter.service';
import { BaseAttributeFilter } from '../base-attribute-filter.component';
import { AttributeTextFilterModel } from './AttributeTextFilterModel';
import {
    DxyTextFilterComponent,
    ITextFilterData,
    TextFilterOperator,
} from '@datagalaxy/core-ui/filters';
import { AttributeFilterAction } from '../attribute-filter.types';
import { FilterOperator } from '@datagalaxy/filter-domain';

/**
 * ## Role
 * Display a text attribute filter
 */
@Component({
    selector: 'app-attribute-text-filter',
    templateUrl: './attribute-text-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [DxyTextFilterComponent],
})
export class AttributeTextFilterComponent
    extends BaseAttributeFilter<AttributeTextFilterModel>
    implements OnInit, OnChanges
{
    @ViewChild(DxyTextFilterComponent) filterComponent: DxyTextFilterComponent;
    filter: ITextFilterData;

    public get operators() {
        return this.filterItemData.operators.map((op) =>
            this.getTypedOperator(op),
        );
    }

    constructor(attributeFilterService: AttributeFilterService) {
        super(attributeFilterService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.init();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'filterItemData', () => this.init());
    }

    public onFilterChange() {
        this.filterItemData.value = this.filter.value;
        this.filterItemData.operator =
            FilterOperator[TextFilterOperator[this.filter.operator]];
        this.onAction.emit(AttributeFilterAction.itemChanged);
    }

    private init() {
        this.filter = {
            operator: this.getTypedOperator(this.filterItemData.operator),
            value: this.filterItemData.value,
        };
    }

    private getTypedOperator(filterOperator: FilterOperator) {
        const operatorText = FilterOperator[filterOperator];
        return TextFilterOperator[operatorText];
    }
}
