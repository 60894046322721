import { AttributeFilterModel } from '../attribute-filter/attributeFilterModel';
import { IFilterFormModel } from '../attribute-filter-form/IFilterFormModel';
import { FilterOperator } from '@datagalaxy/filter-domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

export class AttributeBooleanFilterModel extends AttributeFilterModel {
    public static readonly defaultAvailableOperators = [FilterOperator.Equals];

    public static readonly quickFilterAvailableOperators = [
        FilterOperator.Equals,
    ];

    public static availableOperators(isQuickFilter?: boolean) {
        return isQuickFilter
            ? AttributeBooleanFilterModel.quickFilterAvailableOperators
            : AttributeBooleanFilterModel.defaultAvailableOperators;
    }

    public value: boolean;

    constructor(
        filterFormData: IFilterFormModel,
        attributeMeta: AttributeMetaInfo,
        operators: FilterOperator[],
    ) {
        super(attributeMeta, operators, filterFormData);
    }

    getValuesAsArray() {
        return [String(this.value)];
    }

    isValid() {
        return this.value != undefined;
    }

    setValuesFromDb(values: string[]) {
        this.value = values?.[0]?.toLowerCase() == 'true';
    }

    copy(filterItem: AttributeBooleanFilterModel) {
        this.operator = filterItem.operator;
        this.value = filterItem.value;
    }

    protected sameTypeAndValue(other: AttributeFilterModel) {
        return (
            other instanceof AttributeBooleanFilterModel &&
            other.value == this.value
        );
    }
}
