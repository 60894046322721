import { inject, Injectable } from '@angular/core';
import { DenodoApiService } from './denodo-api.service';
import { IDenodoSampleParameters } from './denodo';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { UserSettingValueService } from '@datagalaxy/users/data-access';

@Injectable({
    providedIn: 'root',
})
export class DenodoSampleService {
    private userSettingsService = inject(UserSettingValueService);
    private denodoApi = inject(DenodoApiService);

    private _clientDenodoSampleParameters?: IDenodoSampleParameters;

    public get clientDenodoSampleParameters():
        | IDenodoSampleParameters
        | undefined {
        return this._clientDenodoSampleParameters;
    }

    public async init() {
        const result = await this.userSettingsService.getUserSettingValue(
            userSettingsValues.denodo.category,
            userSettingsValues.denodo.routes.settings,
        );

        if (!result) {
            return;
        }

        this._clientDenodoSampleParameters = JSON.parse(
            result,
        ) as IDenodoSampleParameters;
    }

    public getDenodoSampleData(entity: EntityItem) {
        if (!this.clientDenodoSampleParameters) {
            return;
        }

        return this.denodoApi.getDenodoSampleData(
            entity.TechnicalName,
            this.clientDenodoSampleParameters,
        );
    }
}
