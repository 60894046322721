import { ClientErrorService } from '../../services/clientError.service';
import { CoreUtil } from '@datagalaxy/core-util';
import { IConfig } from '@datagalaxy/webclient/config';
import { ITranslationConfig } from '@datagalaxy/translate';

export const appLanguageAssets = [
    '/i18n/core-ui/',
    '/i18n/webclient/analytics/',
    '/i18n/webclient/auth/',
    '/i18n/webclient/dashboard/',
    '/i18n/webclient/filter/',
    '/i18n/webclient/multilingual/',
    '/i18n/webclient/suggestion/',
    '/i18n/main/',
    '/i18n/translation-common/',
    '/i18n/admin/',
    '/i18n/libs/',
    '/i18n/webclient/libs/',
];

export function translateConfigFactory(
    config: IConfig,
    clientErrorService: ClientErrorService,
) {
    return getTranslateConfig(config, clientErrorService);
}

/** To be called as soon as the config has been downloaded */
function getTranslateConfig(
    config: IConfig,
    clientErrorService: ClientErrorService,
) {
    return {
        buildRandomKey: config.buildConfig.randomKey,
        resources: appLanguageAssets,
        messageFormatPatch: {
            doReplaceShortcutAll: true,
            doLog: false,
            doCheckAllOnError: false,
        },
        onError: (error: Error, cause: string, type: string) => {
            if (!error) {
                return;
            }
            try {
                clientErrorService.notify(error, cause, type);
            } catch (e) {
                if (!CoreUtil.isProduction) {
                    console.warn(e);
                }
            }
        },
    } as ITranslationConfig;
}
