import { Directive, HostBinding, Input } from '@angular/core';
import { ButtonColor } from '../common/button-color';
import { ButtonSize } from '../common/button-size';
import { ButtonVariant } from '../common/button-variant';

/**
 * ## Role
 * Add DataGalaxy CSS classes to an icon button based on inputs values
 */
@Directive({
    selector: '[dxyIconButton]',
    standalone: true,
})
export class DxyIconButtonDirective {
    @Input() variant: ButtonVariant = 'primary';
    @Input() color: ButtonColor = 'default';
    @Input() size: ButtonSize = 'medium';
    @Input() active = false;

    @HostBinding('class.active') get activeClass() {
        return this.active;
    }
    @HostBinding('class') get classes() {
        return `dxy-button dxy-button--${this.variant} dxy-button--${this.color} dxy-button--${this.size} dxy-icon-button`;
    }
}
