import { MetaObjectType } from '@datagalaxy/meta-object-domain';
import { StringUtils } from '@datagalaxy/utils';

export class MetaObjectTypeUtils {
    public static getGlyphClass(type: MetaObjectType): string {
        switch (type) {
            // Glossary types
            case 'BusinessDomain':
                return 'glyph-domain';
            case 'BusinessDomainGroup':
                return 'glyph-domain-group';
            case 'Universe':
                return 'glyph-universe';
            case 'BusinessTerm':
                return 'glyph-term';
            case 'Concept':
                return 'glyph-concept';
            case 'Dimension':
                return 'glyph-dimension';
            case 'DimensionGroup':
                return 'glyph-dimension-group';
            case 'Indicator':
                return 'glyph-indicator';
            case 'IndicatorGroup':
                return 'glyph-indicator-group';
            case 'ReferenceData':
                return 'glyph-reference-data';
            case 'ReferenceDataValue':
                return 'glyph-value';

            // Dictionary types
            case 'RelationalModel':
                return 'glyph-database';
            case 'Model':
                return 'glyph-model';
            case 'NonRelationalModel':
                return 'glyph-filestore';
            case 'NoSqlModel':
                return 'glyph-nosql';
            case 'Table':
                return 'glyph-table';
            case 'Column':
                return 'glyph-columnview';
            case 'View':
                return 'glyph-view';
            case 'PrimaryKey':
                return 'glyph-pk';
            case 'ForeignKey':
                return 'glyph-fk';
            case 'Directory':
                return 'glyph-containerfolder';
            case 'Document':
                return 'glyph-document';
            case 'Equipment':
                return 'glyph-equipment';
            case 'Field':
                return 'glyph-datafield';
            case 'File':
                return 'glyph-file';
            case 'SubStructure':
                return 'glyph-sub-structure';
            case 'TagBaseModel':
                return 'glyph-tagbase';
            case 'Tag':
                return 'glyph-tag';

            // Usage types
            case 'Algorithm':
                return 'glyph-algorithm';
            case 'Application':
                return 'glyph-software-application';
            case 'Dashboard':
                return 'glyph-software-dashboard';
            case 'DataSet':
                return 'glyph-dataset';
            case 'OpenDataSet':
                return 'glyph-opendataset';
            case 'Feature':
                return 'glyph-features';
            case 'Process':
                return 'glyph-process';
            case 'Report':
                return 'glyph-report';
            case 'Screen':
                return 'glyph-software-screen';
            case 'Usage':
                return 'glyph-software';
            case 'UsageComponent':
                return 'glyph-usage-component';
            case 'UsageField':
                return 'glyph-usage-field';

            // Data Processing types
            case 'DataFlow':
                return 'glyph-data-flow';
            case 'DataProcessing':
                return 'glyph-dataprocessing';
            case 'DataProcessingItem':
                return 'glyph-processItem';

            // DKS types
            case 'PhysicalDiagram':
            case 'FreeDiagram':
            case 'DiagramNode':
                return 'glyph-diagram1';

            // Miscellaneous types
            case 'FilteredView':
                return 'glyph-filter-empty';

            case 'unknown':
            default:
                return 'glyph-glossary';
        }
    }

    public static getGlyphColorClass(type: MetaObjectType): string {
        switch (type) {
            // Glossary types - functional
            case 'BusinessDomain':
            case 'BusinessDomainGroup':
            case 'Universe':
            case 'BusinessTerm':
            case 'Concept':
            case 'Dimension':
            case 'DimensionGroup':
            case 'Indicator':
            case 'IndicatorGroup':
            case 'ReferenceData':
            case 'ReferenceDataValue':
                return 'functional-static-color';

            // Dictionary types - technical
            case 'Column':
            case 'Directory':
            case 'Document':
            case 'Equipment':
            case 'Field':
            case 'File':
            case 'ForeignKey':
            case 'Model':
            case 'NonRelationalModel':
            case 'NoSqlModel':
            case 'PrimaryKey':
            case 'RelationalModel':
            case 'SubStructure':
            case 'Table':
            case 'Tag':
            case 'TagBaseModel':
            case 'View':
                return 'technical-static-color';

            // Usage types - usage
            case 'Algorithm':
            case 'Application':
            case 'Dashboard':
            case 'DataSet':
            case 'OpenDataSet':
            case 'Feature':
            case 'Process':
            case 'Report':
            case 'Screen':
            case 'Usage':
            case 'UsageComponent':
            case 'UsageField':
                return 'functional-dynamic-color';

            // Data Processing types - processing
            case 'DataFlow':
            case 'DataProcessing':
            case 'DataProcessingItem':
                return 'technical-dynamic-color';

            // DKS types - no color
            case 'PhysicalDiagram':
            case 'FreeDiagram':
            case 'DiagramNode':
                return '';

            // Miscellaneous types - no color
            case 'FilteredView':
            case 'unknown':
            default:
                return '';
        }
    }

    public static getColoredGlyphClass(type: MetaObjectType): string {
        return `${this.getGlyphClass(type)} ${this.getGlyphColorClass(type)}`;
    }

    public static getTranslateKey(type: MetaObjectType): string {
        return `metaObject.objectType.name.${StringUtils.unCapitalize(type)}`;
    }

    public static getShortTranslateKey(type: MetaObjectType): string {
        return `metaObject.objectType.shortName.${StringUtils.unCapitalize(type)}`;
    }
}
