import { Injectable } from '@angular/core';
import { RestApiService } from '@datagalaxy/data-access';
import { getContextId, getLocalId } from '@datagalaxy/utils';
import { FilteredViewFilterElement } from './filtered-view-filter-element';
import { FilteredViewsUtil } from './filtered-views.util';
import {
    GetFavoriteFilteredViewQueryResult,
    GetFilteredViewQueryResult,
    GetFilteredViewsQueryResult,
} from '../queries';
import { GenericDeserialize } from 'cerialize';
import {
    CreateFilteredViewCommand,
    CreateFilteredViewCommandResult,
    PublishFilteredViewCommand,
    UpdateFilteredViewCommand,
    UpdateFilteredViewCommandResult,
} from '../commands';
import {
    Filter,
    FilteredViewDto,
    FilterModuleName,
    FilterOperator,
} from '@datagalaxy/filter-domain';
import { DgZone } from '@datagalaxy/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

/**
 * low level service, not to be used by components
 */
@Injectable({ providedIn: 'root' })
export class FilteredViewApiService {
    public constructor(private restApiService: RestApiService) {}

    public async getFilteredViews(
        moduleNames: FilterModuleName[],
        spaceIdr: { spaceId?: string | null; versionId?: string | null },
    ) {
        const result =
            await this.restApiService.getPromise<GetFilteredViewsQueryResult>(
                'filtered-views',
                {
                    SpaceId: getContextId(spaceIdr?.spaceId),
                    ModuleNames: moduleNames.map((m) => m.toString()),
                },
            );
        const filteredViews = result.FilteredViews.map((fv) =>
            GenericDeserialize(fv, FilteredViewDto),
        );
        filteredViews.forEach((fv) => {
            const hasSearchTermFilter = fv.filters?.some(
                (filter) =>
                    filter.AttributeKey ===
                    ServerConstants.Search.SearchTermFilterKey,
            );
            if (!hasSearchTermFilter) {
                const filter = new Filter(
                    ServerConstants.Search.SearchTermFilterKey,
                    FilterOperator.TextContains,
                    [''],
                );
                fv.filters ? fv.filters.push() : (fv.ListFilter = [filter]);
            }
            fv.init();
        });
        return filteredViews;
    }

    public async getUserFavoriteFilteredView(
        filteredViewCategory: string,
        spaceId: string,
    ) {
        const spaceLocalId = getLocalId(spaceId) as string;
        const res =
            await this.restApiService.getPromise<GetFavoriteFilteredViewQueryResult>(
                'filtered-views/favorite',
                {
                    SpaceId: spaceLocalId,
                    ModuleName: filteredViewCategory,
                },
            );
        return GenericDeserialize(res?.FilteredView, FilteredViewDto)?.init();
    }

    public async getSearchFilteredViews(spaceIdr: {
        spaceId?: string | null;
        versionId?: string | null;
    }) {
        return this.getFilteredViews([FilterModuleName.MainSearch], spaceIdr);
    }

    public async updateFilteredViewProperties(
        spaceId: string,
        filteredView: FilteredViewDto,
    ) {
        await this.updateFilteredViewInternal(
            filteredView,
            getContextId(spaceId),
        );
    }

    public async updateFilteredView(filteredView: FilteredViewDto) {
        await this.updateFilteredViewInternal(filteredView);
    }

    public async publish(fv: FilteredViewDto) {
        if (!fv.FilteredViewUid) {
            return;
        }
        const command: PublishFilteredViewCommand = {
            FilteredViewGuid: fv.FilteredViewUid,
        };
        await this.restApiService.putPromise(
            `filtered-views/${command.FilteredViewGuid}/publish`,
        );
    }

    public async createFilteredView(
        spaceId: string,
        name: string,
        description: string,
        filters: Filter[],
        dgZone: DgZone,
        dgModule?: DgModule,
    ) {
        const spaceUid = getContextId(spaceId);
        const moduleName = FilteredViewsUtil.moduleNameFromZoneAndModule(
            dgZone,
            dgModule,
        );
        const filterElements = this.getCreateFilteredViewItems(filters);
        const command: CreateFilteredViewCommand = {
            Description: description,
            DgZone: dgZone,
            DisplayName: name,
            Filters: filterElements,
            ModuleName: moduleName ?? FilterModuleName.MainSearch,
            SpaceId: spaceUid,
        };
        const result =
            await this.restApiService.postPromise<CreateFilteredViewCommandResult>(
                'filtered-views',
                command,
            );
        return GenericDeserialize(result.FilteredView, FilteredViewDto).init();
    }

    public async getFilteredView(filteredViewGuid: string) {
        const result =
            await this.restApiService.getPromise<GetFilteredViewQueryResult>(
                `filtered-views/${filteredViewGuid}`,
            );
        return GenericDeserialize(result.FilteredView, FilteredViewDto).init();
    }

    public async setFilteredViewFavorite(
        isFavorite: boolean,
        filteredViewGuid: string,
    ) {
        await this.restApiService.postPromise('filtered-views/favorite', {
            FilteredViewGuid: filteredViewGuid,
            IsFavorite: isFavorite,
        });
    }

    public async deleteFilteredView(filteredViewGuid: string) {
        await this.restApiService.deletePromise(
            `filtered-views/${filteredViewGuid}`,
        );
    }

    private getCreateFilteredViewItems(filters: Filter[]) {
        return filters.map(
            (f) =>
                ({
                    AttributePath: f.AttributePath,
                    FilterOperator: f.FilterOperator,
                    ListValues: f.SearchValues,
                }) as FilteredViewFilterElement,
        );
    }

    private makeUpdateFilteredViewCommand(
        filteredView: FilteredViewDto,
        listFilters: FilteredViewFilterElement[],
        spaceUid = filteredView.SpaceUid,
    ): UpdateFilteredViewCommand {
        return {
            FilteredViewGuid: filteredView.FilteredViewUid ?? '',
            DisplayName: filteredView.DisplayName,
            Description: filteredView.Description ?? '',
            SpaceId: spaceUid,
            Filters: listFilters,
        };
    }

    private async updateFilteredViewInternal(
        filteredView: FilteredViewDto,
        spaceId?: string,
    ) {
        const command = this.makeUpdateFilteredViewCommand(
            filteredView,
            this.getCreateFilteredViewItems(filteredView.ListFilter),
            spaceId ? getContextId(spaceId) : filteredView.SpaceUid,
        );
        const result =
            await this.restApiService.putPromise<UpdateFilteredViewCommandResult>(
                `filtered-views/${filteredView.FilteredViewUid}`,
                command,
            );
        const updatedFilteredView = GenericDeserialize(
            result.FilteredView,
            FilteredViewDto,
        );
        updatedFilteredView.copyTo(filteredView, { includeListFilter: true });
        filteredView.init();
    }
}
