import {
    ChangeDetectionStrategy,
    Component,
    inject,
    input,
    OnInit,
    viewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DxyRichTextFieldComponent } from '@datagalaxy/core-ui';
import { TranslateModule } from '@ngx-translate/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { TranslationInputActionsComponent } from '@datagalaxy/webclient/multilingual/feature';
import {
    DxyButtonDirective,
    DxyIconButtonDirective,
} from '@datagalaxy/ui/buttons';
import {
    FeatureFlagDirective,
    FeatureFlagService,
} from '@datagalaxy/feature-flag';
import {
    ReportAiGeneratedTextService,
    TextAttributeAiGenerationButtonComponent,
    TextAttributeAiGenerationResultComponent,
} from '@datagalaxy/webclient/suggestion/feature';
import { SummaryFieldStore } from './summary-field-store';
import { AttributeFieldComponent } from '../../common/attribute-field-component.interface';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { DxyFreshdeskHelpLinkDirective } from '@datagalaxy/webclient/support/feature';

@Component({
    selector: 'dxy-summary-field',
    templateUrl: './summary-field.component.html',
    styleUrls: ['./summary-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    providers: [SummaryFieldStore],
    imports: [
        DxyFieldTextComponent,
        FormsModule,
        TranslateModule,
        TranslationInputActionsComponent,
        DxyIconButtonDirective,
        FeatureFlagDirective,
        TextAttributeAiGenerationButtonComponent,
        TextAttributeAiGenerationResultComponent,
        DxyFreshdeskHelpLinkDirective,
        DxyButtonDirective,
    ],
})
export class SummaryFieldComponent implements OnInit, AttributeFieldComponent {
    readonly store = inject(SummaryFieldStore);
    readonly reportService = inject(ReportAiGeneratedTextService);
    readonly featureFlagService = inject(FeatureFlagService);

    entity = input.required<EntityItem>();
    readonly = input<boolean>(false);
    mini = input<boolean>(false);

    field = viewChild(DxyRichTextFieldComponent);

    protected textMaxLength =
        ServerConstants.AttributeConstants.AttributeTextLimit;

    ngOnInit() {
        this.store.init({
            entity: this.entity(),
            attributePath: 'Description',
            readonly: this.readonly(),
        });
        this.store.loadTranslations();
        if (this.featureFlagService.isFeatureEnabled('autodescription')) {
            this.store.loadAcceptedSuggestion();
        }
    }

    public onValueChange(value: string) {
        this.store.setUpdatedValue(value);
    }
    public async onFieldBlur(_$event: FocusEvent) {
        await this.store.persistUpdatedValue();
    }
    public onFieldFocus() {
        this.store.touch();
    }

    public focusField() {
        this.field()?.doFocus();
    }

    protected reportGeneration() {
        this.reportService.reportSummary(this.entity());
    }
}
