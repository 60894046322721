import {
    ChangeDetectionStrategy,
    Component,
    computed,
    HostBinding,
    inject,
    input,
} from '@angular/core';
import { MetaObject } from '@datagalaxy/meta-object-domain';
import { ObjectTypeIconComponent } from '@datagalaxy/meta-object-ui';
import { MetaObjectTypeUtils } from '@datagalaxy/meta-object-utils';
import { TechnologyStore } from '@datagalaxy/technology/data-access';
import {
    IconCardComponent,
    IconCardSize,
    IconComponent,
} from '@datagalaxy/ui/icon';
import { SkeletonComponent } from '@datagalaxy/ui/skeleton';
import { TooltipDirective } from '@datagalaxy/ui/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MetaObjectTypeCardStore } from '../meta-object-type-card-display/meta-object-type-card-display.store';
import { MetaObjectCardDisplayMode } from '../meta-object-type-card-display/meta-object-type-card-display.types';

@Component({
    standalone: true,
    selector: 'dxy-meta-object-type-card',
    templateUrl: './meta-object-type-card.component.html',
    imports: [
        ObjectTypeIconComponent,
        TranslateModule,
        IconCardComponent,
        IconComponent,
        TooltipDirective,
        SkeletonComponent,
    ],
    styleUrls: ['./meta-object-type-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaObjectTypeCardComponent {
    public metaObject = input.required<MetaObject>();
    public size = input<IconCardSize>('small');
    public displayMode = input<MetaObjectCardDisplayMode>();

    @HostBinding('class')
    private get sizeClass(): string {
        return this.size();
    }

    private translate = inject(TranslateService);
    private technologyStore = inject(TechnologyStore);
    private displayModeStore = inject(MetaObjectTypeCardStore);

    protected displayModeValue = computed(() => {
        if (!this.displayMode()) {
            return this.displayModeStore.objectCardDisplayMode();
        }

        return this.displayMode();
    });

    protected loading = computed(() => {
        return (
            (this.displayModeStore.isPending() && !this.displayMode()) ||
            this.technologyStore.isPending()
        );
    });

    protected technologyTooltip = computed(() => {
        const techno = this.technology();

        return techno?.displayName || '';
    });

    protected technology = computed(() => {
        const technologyCode = this.metaObject().technologyCode;

        if (!technologyCode) {
            return null;
        }

        const techno = this.technologyStore.get(technologyCode);

        return techno();
    });

    protected technologyImageUrl = computed(() => {
        const techno = this.technology();
        return techno?.imageUrl || '';
    });

    protected objectType = computed(() => this.metaObject().type);

    protected tooltipDisabled = computed(() => {
        if (this.iconType() === 'url') {
            return false;
        }

        switch (this.size()) {
            case 'medium':
                return false;
            case 'xxl':
                return !this.isNameShortened();
            default:
                return false;
        }
    });

    protected objectTypeNameKey = computed(() => {
        return this.isNameShortened()
            ? MetaObjectTypeUtils.getShortTranslateKey(this.objectType())
            : MetaObjectTypeUtils.getTranslateKey(this.objectType());
    });

    protected iconTooltip = computed(() => {
        return this.isIconGlyph()
            ? MetaObjectTypeUtils.getTranslateKey(this.objectType())
            : this.technologyTooltip();
    });

    protected icon = computed(() => {
        const objectTypeClassName = MetaObjectTypeUtils.getColoredGlyphClass(
            this.objectType(),
        );

        switch (this.iconType()) {
            case 'glyph': {
                return objectTypeClassName;
            }
            case 'url': {
                return this.technologyImageUrl() ?? objectTypeClassName;
            }
        }
    });

    protected iconType = computed(() => {
        return this.isIconGlyph() ? 'glyph' : 'url';
    });

    protected chipType = computed(() => {
        const hasIconUrl = !!this.technologyImageUrl();

        if (!hasIconUrl || this.displayModeValue() === 'techno-with-chip') {
            return 'object-type';
        } else {
            return 'techno';
        }
    });

    protected isIconGlyph = computed(() => {
        return (
            ['glyph-only', 'glyph-with-chip'].includes(
                this.displayModeValue() || '',
            ) || !this.technologyImageUrl()
        );
    });

    protected chipSize = computed(() => {
        switch (this.size()) {
            case 'xs':
            case 'small':
                return 'medium';
            case 'medium':
            case 'large':
            case 'xl':
            case 'xxl':
                return 'large';
        }
    });

    protected hasChip = computed(() => {
        if (this.size() === 'xs') {
            return false;
        }

        const hasIconUrl = !!this.technologyImageUrl();
        const canHaveChip = ['glyph-with-chip', 'techno-with-chip'].includes(
            this.displayModeValue() || '',
        );

        return canHaveChip && hasIconUrl;
    });

    private isNameShortened = computed(() => {
        if (this.size() !== 'xxl') {
            return true;
        }

        const maxLength = 10;
        const translateKey = MetaObjectTypeUtils.getTranslateKey(
            this.objectType(),
        );
        const translatedName = this.translate.instant(translateKey);

        return translatedName?.length >= maxLength;
    });
}
