import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import { DxyModalService } from '../../../shared/dialogs/DxyModalService';
import { MsTeamsIntegrationActivationModalComponent } from './ms-teams-integration-activation-modal/ms-teams-integration-activation-modal.component';
import { MsTeamsIntegrationDeactivationModalComponent } from './ms-teams-integration-deactivation-modal/ms-teams-integration-deactivation-modal.component';

@Injectable({ providedIn: 'root' })
export class MsTeamsIntegrationUiService extends BaseService {
    constructor(private dxyModalService: DxyModalService) {
        super();
    }

    public openActivateTeamsIntegrationModal() {
        this.dxyModalService.open<
            MsTeamsIntegrationActivationModalComponent,
            void,
            boolean
        >({
            loadComponent: () =>
                import(
                    './ms-teams-integration-activation-modal/ms-teams-integration-activation-modal.component'
                ).then((m) => m.MsTeamsIntegrationActivationModalComponent),
        });
    }

    public openDeactivateTeamsIntegrationModal() {
        this.dxyModalService.open<
            MsTeamsIntegrationDeactivationModalComponent,
            void,
            boolean
        >({
            loadComponent: () =>
                import(
                    './ms-teams-integration-deactivation-modal/ms-teams-integration-deactivation-modal.component'
                ).then((m) => m.MsTeamsIntegrationDeactivationModalComponent),
        });
    }
}
