import { deserialize, SerializableEnumeration } from 'cerialize';
export class TextQualityStatistics {
    @deserialize public LikeVoteCount!: number;
    @deserialize public DislikeVoteCount!: number;
    @deserialize public Score!: number;
}

export enum TextQualityVoteStatus {
    None,
    Like,
    Dislike,
}
export class TextQualityData {
    @deserialize public ReferenceId!: string;
    @deserialize public AttributePath!: string;
    @deserialize public VersionId!: string;
    @deserialize public TextHashcode!: number;
    @deserialize public TextQualityStatistics!: TextQualityStatistics;
}

export class TextQualityUserVoteDto {
    @deserialize public Vote!: TextQualityVoteStatus;
    @deserialize public AttributePath!: string;
    @deserialize public IsVoteOutdated!: boolean;
    @deserialize public CurrentTextHashcode!: number;
    @deserialize public UserLastInteractionTextHashcode!: number;
    @deserialize public TextQualityStat!: TextQualityStatistics;
}

SerializableEnumeration(TextQualityVoteStatus);
