import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    contentChildren,
    effect,
    ElementRef,
    HostBinding,
    inject,
    input,
    output,
    viewChild,
} from '@angular/core';
import { FilterCarouselItemDirective } from './filter-carousel-item.directive';
import { firstValueFrom, timer } from 'rxjs';
import { FitlerCarouselScroller } from './filter-carousel-scroller';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DomUtils } from '@datagalaxy/utils';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

export type FilterCarouselDisplayMode =
    | 'default'
    | 'quick-filters'
    | 'active-filters'
    | 'recent-filters';

/**
 * ## Role
 * Display an horizontal list of filters with scroll with buttons capabilites
 */
@Component({
    selector: 'dxy-filter-carousel',
    templateUrl: './filter-carousel.component.html',
    styleUrls: ['./filter-carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [DxyIconButtonDirective],
})
export class FilterCarouselComponent
    extends DxyBaseComponent
    implements AfterContentInit
{
    public scrollLeftClick = output();
    public scrollRightClick = output();
    public displayMode = input<FilterCarouselDisplayMode>('default');

    private el = inject(ElementRef<HTMLElement>);
    private cd = inject(ChangeDetectorRef);

    private filtersEl = viewChild<ElementRef<HTMLElement>>('filters');
    private items = contentChildren(FilterCarouselItemDirective);

    @HostBinding('class') get _displayModeClass() {
        return this.displayMode();
    }

    @HostBinding('class.no-item') get _noItemClass() {
        return !this.items?.length;
    }

    protected get translateX() {
        return this.scroller.translateX;
    }

    private scroller = new FitlerCarouselScroller(
        [...this.items()],
        this.availableWidth,
    );

    protected get availableWidth() {
        const node = this.el.nativeElement;
        const style = getComputedStyle(node);
        const width = node.clientWidth;
        const paddingLeft = parseFloat(style.paddingLeft);
        const paddingRight = parseFloat(style.paddingLeft);
        return width - paddingLeft - paddingRight;
    }

    protected get leftButtonVisible() {
        return this.translateX < 0;
    }

    protected get rightButtonVisible(): boolean {
        return this.translateX > this.minTranslateX;
    }

    private get minTranslateX() {
        return this.scroller.minTranslateX;
    }

    constructor() {
        super();
        effect(() => {
            if (this.items()) {
                this.ajustScrollAfterContentUpdate();
            }
        });
    }

    ngAfterContentInit() {
        this.ajustScrollAfterContentUpdate();
        this.subscribe(
            DomUtils.resizeObservable(this.el.nativeElement),
            (e) => {
                if (e?.contentBoxSize) {
                    this.ajustScrollAfterContentUpdate();
                }
            },
        );
    }

    public async ajustScrollAfterContentUpdate() {
        await firstValueFrom(timer(100));
        this.scroller.items = [...this.items()];
        this.scroller.width = this.availableWidth;
        this.scroller.refresh();
        this.cd.detectChanges();
    }

    public async scrollToEnd() {
        await firstValueFrom(timer(300));
        this.scroller.items = [...this.items()];
        this.scroller.width = this.availableWidth;
        this.scroller.scrollToEnd();
        this.cd.detectChanges();
    }

    protected scrollLeft() {
        this.scrollLeftClick.emit();
        this.scroller.scrollLeft();
    }
    protected scrollRight() {
        this.scrollRightClick.emit();
        this.scroller.scrollRight();
    }
}
