import { Component } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { ViewTypeService } from '../../../services/viewType.service';
import { EntityUiService } from '../../../shared/entity/services/entity-ui.service';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { IEntityNavLinkCellParams } from '../nav-link.types';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import {
    MetaObjectTypeCardAdapterPipe,
    MetaObjectTypeCardComponent,
} from '@datagalaxy/meta-object-feature-object-card';

/**
 * ## Role
 * Link to open an entity preview panel
 */
@Component({
    selector: 'app-entity-nav-link',
    templateUrl: './entity-nav-link.component.html',
    styleUrls: ['../nav-link.scss', 'entity-nav-link.component.scss'],
    standalone: true,
    imports: [
        EllipsisTooltipDirective,
        MetaObjectTypeCardComponent,
        MetaObjectTypeCardAdapterPipe,
    ],
})
export class EntityNavLinkComponent extends BaseCellComponent<
    unknown,
    HierarchicalData,
    IEntityNavLinkCellParams<HierarchicalData>
> {
    public constructor(
        private viewTypeService: ViewTypeService,
        private entityUiService: EntityUiService,
    ) {
        super();
    }

    protected get displayName() {
        return this.viewTypeService.getTechnicalOrDisplayName(this.value) ?? '';
    }

    public async openEntityPreview() {
        const hd = this.value;
        if (this.params?.disableNavigation) {
            return;
        }
        await this.entityUiService.openPreviewOrDetailsOrDefault({
            HddData: hd,
        });
    }
}
