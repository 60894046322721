import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import {
    DxyTabsHeaderComponent,
    ITabItem,
    ITabsHeaderData,
} from '@datagalaxy/core-ui';
import { StringUtil } from '@datagalaxy/core-util';
import { DiagramListUiService } from '../diagram-list-ui.service';
import { DiagramStore } from '../DiagramStore';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { EntityEventService } from '../../shared/entity/services/entity-event.service';
import { EntityService } from '../../shared/entity/services/entity.service';
import { ViewTypeService } from '../../services/viewType.service';
import { ModelerDataUtil } from '../../shared/util/ModelerDataUtil';
import { SearchApiService } from '@datagalaxy/webclient/search/data-access';
import { PublishingStatus } from '@datagalaxy/webclient/diagram/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { ISearchTermEvent, SearchInputComponent } from '@datagalaxy/ui/search';
import { EntityGridConfig } from '../../entity/entity-grid/entity-grid/entity-grid-config';
import { EntityGridComponent } from '../../entity/entity-grid/entity-grid/entity-grid/entity-grid-component.component';
import { DxyGridColumnSelectorComponent } from '@datagalaxy/ui/grid';
import { EntityGridStateService } from '../../entity/entity-grid/entity-grid/entity-grid-state.service';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { DiagramService } from '../diagram/diagram.service';
import { TooltipDirective } from '@datagalaxy/ui/tooltip';
import PropertyName = ServerConstants.PropertyName;

/**
 * ## Role
 * List of all diagrams for a specified workspace or a specified entity
 */
@Component({
    selector: 'app-diagrams-list',
    templateUrl: './diagrams-list.component.html',
    styleUrls: ['./diagrams-list.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        TranslateModule,
        DxyTabsHeaderComponent,
        DxyGridColumnSelectorComponent,
        SearchInputComponent,
        EntityGridComponent,
        SpinnerComponent,
        DxyIconButtonDirective,
        TooltipDirective,
    ],
})
export class DiagramsListComponent
    extends DxyBaseComponent
    implements OnChanges, OnDestroy
{
    private static readonly defaultDisplayedColumns = [
        PropertyName.EntityType,
        PropertyName.Description,
        PropertyName.CreationUserId,
        PropertyName.CreationTime,
    ];

    @Input() persistenceGridId = userSettingsValues.omniGrid.routes.diagramList;
    /** If true, show a button to create a diagram */
    @Input() canCreate: boolean;
    @Input() space: IWorkspaceIdentifier;

    @Input() entityData: EntityItem;

    /** If true, private & public diagrams will be split in separate header tabs */
    @Input() usePublishingStatusTabs: boolean;
    /** If true, show a large placeholder with a title and a text if diagrams list is empty */
    @Input() useLargePlaceholder = false;

    /** Preview-panel context header */
    @Input() showHeader: boolean;

    @Input() excludedDefaultColumns: string[] = null;

    @ViewChild(EntityGridComponent)
    entityGrid: EntityGridComponent;

    public searchTerm: string;

    public get loading() {
        return this.diagramStore?.isLoading;
    }
    public get showSearch() {
        return this.diagrams?.length >= 10;
    }
    public get gridVisible() {
        return this.diagrams?.length >= 1;
    }
    public get placeholderVisible() {
        return !this.loading && !this.gridVisible;
    }
    public get diagramCount() {
        return this.diagrams?.length;
    }
    public get showDiagramCount() {
        return !this.usePublishingStatusTabs && this.diagramCount;
    }
    public get placeholderSrc() {
        return `images/placeholders/diagrams-${
            this.activeTab.tabId === 'private' ? 'private' : 'public'
        }.svg`;
    }

    // #region tabs
    public tabsHeaderData: ITabsHeaderData<PublishingStatus> = {
        tabItems: [
            {
                tabId: 'public',
                tabTranslateKey: 'UI.Diagrams.Public',
                showEmptyDataCount: true,
                data: PublishingStatus.Public,
            },
            {
                tabId: 'private',
                tabTranslateKey: 'UI.Diagrams.Private',
                showEmptyDataCount: true,
                data: PublishingStatus.Private,
            },
            {
                tabId: 'other',
                tabTranslateKey: 'UI.Diagrams.unknownStatus',
                showEmptyDataCount: true,
                data: undefined,
                hiddenIfNoDataCount: true,
            },
        ],
    };
    protected activeTab = this.tabsHeaderData.tabItems[0];
    protected config: EntityGridConfig = {};
    // #endregion

    protected entities: EntityItem[] = [];

    private diagramStore: DiagramStore;
    private get diagrams() {
        if (this.usePublishingStatusTabs) {
            return this.activeTab.data === PublishingStatus.Public
                ? this.diagramStore.getPublicDiagrams()
                : this.diagramStore.getPrivateDiagrams();
        }
        return this.diagramStore?.getAllDiagrams();
    }

    constructor(
        private diagramListUiService: DiagramListUiService,
        private searchApiService: SearchApiService,
        private entityEventService: EntityEventService,
        private entityService: EntityService,
        private viewTypeService: ViewTypeService,
        private entityGridStateService: EntityGridStateService,
        private diagramService: DiagramService,
    ) {
        super();
    }

    ngOnChanges() {
        this.initAsync();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.diagramStore.dispose();
    }

    //#region events
    public async onTabChange(tab: ITabItem<PublishingStatus>) {
        this.activeTab = tab;
        this.searchTerm = '';
        this.updateGridItems(this.diagrams);
    }

    public async onCreateDiagram() {
        const model = this.entityData;
        const modelIdr: IEntityIdentifier =
            model &&
            ModelerDataUtil.isModelRelational(
                model.ServerType,
                model.HddData.Data,
            )
                ? model
                : null;
        await this.diagramListUiService.createDiagram(modelIdr);
    }

    public onSearchTermChange(event: ISearchTermEvent) {
        const items = StringUtil.filterSearched(
            event.searchString,
            this.diagrams,
            (d) => this.viewTypeService.getTechnicalOrDisplayName(d),
        );
        this.updateGridItems(items);
    }

    protected async onAttributeVisibilityChange(attributeKeys: string[]) {
        this.diagramStore.setAdditionalAttributeKeys(attributeKeys);
        await this.diagramStore.init(this.entityData || this.space);
    }
    //#endregion events

    protected async goToDiagram(entity: EntityItem) {
        return await this.diagramService.goToDiagram(entity);
    }

    private updateGridItems(items: EntityItem[]) {
        this.entities = items;
    }

    private async initAsync() {
        await this.initStore();
    }

    private async initStore() {
        this.log('initStore', this.entityData, this.space);
        const entityAttributes =
            await this.entityService.getEntityAttributesForDiagramGrid();
        this.diagramStore = new DiagramStore(
            this.searchApiService,
            this.entityEventService,
            this.entityService,
            {
                onChange: () => this.onStoreUpdate(),
                debug: this.debug,
                logId: this.logId ?? 'diagrams-list',
                additionalAttributeKeys: entityAttributes.map(
                    (a) => a.AttributeKey,
                ),
            },
        );
        const savedAttributeKeys =
            await this.entityGridStateService.getGridStateAttributeKeys(
                this.persistenceGridId,
            );
        const attributeKeys = savedAttributeKeys?.length
            ? savedAttributeKeys
            : this.computeDefaultcolumns();
        this.diagramStore.setAdditionalAttributeKeys(attributeKeys);
        await this.diagramStore.init(this.entityData || this.space);
        await this.onStoreUpdate();
    }

    private async onStoreUpdate() {
        const entityAttributes =
            await this.entityService.getEntityAttributesForDiagramGrid();
        const wantedColumns = this.computeDefaultcolumns();

        if (!this.usePublishingStatusTabs) {
            wantedColumns.push(ServerConstants.Diagram.PublishingStatus);
        }

        this.entities = this.diagrams;

        this.config = {
            persistedUserSettingsId: this.persistenceGridId,
            attributeConfig: {
                defaultAttributeKeys: wantedColumns,
                attributes: entityAttributes,
            },
            rowCursorOnHover: true,
            entity: {
                noLabelNavLink: true,
                actions: this.diagramListUiService.getAvailableEntityActions({
                    navigateOnClone: false,
                }),
            },
        };

        this.setTabCounts();
    }

    private setTabCounts() {
        this.tabsHeaderData.tabItems.forEach((ti) => {
            switch (ti.data) {
                case PublishingStatus.Private:
                    ti.contentDataCount =
                        this.diagramStore.getPrivateDiagrams()?.length;
                    break;
                case PublishingStatus.Public:
                    ti.contentDataCount =
                        this.diagramStore.getPublicDiagrams()?.length;
                    break;
            }
        });
    }

    private computeDefaultcolumns(): string[] {
        // Consumer has the opportunity to drop some of the defaults columns
        return DiagramsListComponent.defaultDisplayedColumns.filter(
            (column) => !this.excludedDefaultColumns?.includes(column),
        );
    }
}
