<dxy-icon
    [size]="iconSize()"
    [icon]="icon()"
    [type]="iconType()"
    [dxyTooltip]="iconTooltip()"
    [dxyTooltipDisabled]="iconTooltipDisabled()"
></dxy-icon>

@if (displaySeparator()) {
    <div class="separator"></div>
}

@if (displayText()) {
    <span class="icon-card-text" dxyEllipsisTooltip>
        {{ text() }}
    </span>
}
