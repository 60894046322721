import { patchState, signalStore, withMethods } from '@ngrx/signals';
import { withTextValue } from '../common/text-value-feature/text-value-feature';
import { withAutoDescription } from '../common/auto-description-feature/auto-description-feature';
import { inject } from '@angular/core';
import { AutoDescriptionApiService } from '@datagalaxy/webclient/suggestion/data-access';
import { ProblemDetails } from '@datagalaxy/data-access';
import { HttpStatusCode } from '@angular/common/http';

export const SummaryFieldStore = signalStore(
    withTextValue(),
    withAutoDescription(),
    withMethods((store, apiService = inject(AutoDescriptionApiService)) => ({
        async generateSummary() {
            const entity = store.entity();
            if (!entity) {
                return;
            }
            patchState(store, { generating: true });
            try {
                const pendingSuggestion =
                    await apiService.getSummarySuggestion(entity);
                patchState(store, {
                    pendingSuggestion,
                });
            } catch (error) {
                patchState(store, {
                    pendingSuggestion: undefined,
                    generationError: error,
                });
            } finally {
                patchState(store, { generating: false });
            }
        },
        async acceptSuggestion() {
            const entityIdr = store.entity();
            const suggestion = store.pendingSuggestion();
            if (!entityIdr || !suggestion?.proposedValue) {
                return;
            }
            patchState(store, { pendingSuggestion: undefined });
            await apiService.acceptSummarySuggestion(entityIdr, suggestion);
            patchState(store, { acceptedSuggestion: suggestion });
            store.setUpdatedValue(suggestion.proposedValue);
            store.persistUpdatedValue();
        },
        async loadAcceptedSuggestion() {
            const entityIdr = store.entity();
            if (!entityIdr) {
                return;
            }
            try {
                const suggestion =
                    await apiService.getAcceptedSummarySuggestion(entityIdr);
                patchState(store, { acceptedSuggestion: suggestion });
            } catch (error) {
                if (
                    error instanceof ProblemDetails &&
                    error.status === HttpStatusCode.NotFound
                ) {
                    return;
                }
                throw error;
            }
        },
    })),
);
