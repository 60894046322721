import { InjectionToken } from '@angular/core';

export const CHATBOT_CURRENT_WORKSPACE_RESOLVER =
    new InjectionToken<CurrentWorkspaceResolver>(
        'CHATBOT_CURRENT_WORKSPACE_RESOLVER',
    );

export interface CurrentWorkspaceResolver {
    currentSpace: {
        versionId: string;
        spaceId: string;
    };
}
