import { BaseMetaAttributeValue } from '@datagalaxy/meta-attribute-value-domain';

export function buildBaseValue(
    objectId: string,
    attributePath: string,
): BaseMetaAttributeValue {
    return {
        attributeId: attributePath ?? '',
        id: `${objectId}-${attributePath}`,
        origin: 'human',
    };
}
