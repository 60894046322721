@if (loading()) {
    <dxy-skeleton />
} @else {
    <dxy-icon-card
        [size]="size()"
        [icon]="icon()"
        [iconType]="iconType()"
        [iconTooltip]="iconTooltip() | translate"
        [iconTooltipDisabled]="tooltipDisabled()"
        [text]="objectTypeNameKey() | translate"
    />

    @if (hasChip()) {
        <div class="chip-container">
            @switch (chipType()) {
                @case ('object-type') {
                    <dxy-object-type-icon
                        [size]="chipSize()"
                        [type]="objectType()"
                        [tooltipDisabled]="tooltipDisabled()"
                    />
                }
                @case ('techno') {
                    <dxy-icon
                        [size]="chipSize()"
                        type="url"
                        [icon]="technologyImageUrl()"
                        [dxyTooltip]="technologyTooltip()"
                    />
                }
            }
        </div>
    }
}
