<div *ngIf="showHeader" class="panel-content-tool">
    <div class="panel-content-toolname" translate>
        UI.EntityDockingPane.Tool.LinkedObject
    </div>
    <div class="actions-container">
        <div
            *ngIf="!isLoading && hasWriteAccess && !noCreateButton"
            class="tool-add-entity"
        >
            <button
                dxyIconButton
                variant="primary"
                [dxyTooltip]="'UI.EntityLinkedObjects.addLink' | translate"
                dxyTooltipPosition="left"
                (click)="createLinkedObject()"
            >
                <i class="glyph glyph-add"></i>
            </button>
        </div>
    </div>
</div>

<div class="panel-content-body">
    <dxy-tabs-header
        *ngIf="tabs"
        class="stretch-tabs"
        [tabs]="tabItems"
        [activeTabItem]="activeTabItem"
        (tabChange)="onTabChange($event)"
    >
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </dxy-tabs-header>
    <ng-container *ngIf="!tabs" [ngTemplateOutlet]="content"></ng-container>
</div>

<ng-template #content>
    <dxy-search-input
        *ngIf="isSearchAvailable"
        class="height-50"
        name="searchInput"
        placeholderKey="UI.Global.filter.filterByName"
        [(searchString)]="searchTerm"
        (searchStringChange)="applyNameFilter()"
        [showCancelSearch]="true"
    ></dxy-search-input>

    <div
        *ngIf="!isFirstLoading && !isAnyLinkedObjects"
        class="no-item-container"
    >
        <div class="image-container">
            <img
                src="/images/placeholders/linked-objects.svg"
                class="dg_no-item-image"
                alt="entity linked objects"
            />
        </div>
        <div class="no-item-title" translate>
            UI.EntityLinkedObjects.titleLbl
        </div>
        <div class="no-item-text" translate>
            UI.EntityLinkedObjects.lblNoLinkedObjects
        </div>
        <button
            *ngIf="hasWriteAccess && !noCreateButton"
            (click)="createLinkedObject()"
            dxyButton
            variant="primary"
        >
            <i class="glyph-add"></i>
            <span translate>UI.EntityLinkedObjects.addLinkBtn</span>
        </button>
    </div>

    <div
        cdkDropList
        [cdkDropListConnectedTo]="dragDrop?.dropTo"
        [cdkDropListDisabled]="!dragDrop || dragDrop.disabled"
        cdkDropListSortingDisabled
        [cdkDropListData]="dragDrop"
    >
        <dxy-collapsible
            *ngFor="let g of filteredLinkTypeGroups"
            [titleText]="g.displayName"
            [(isCollapsed)]="g.isCollapsed"
        >
            <div
                *ngFor="let r of g.rows"
                class="linked-entity-container"
                (click)="onRowClick(r)"
                cdkDrag
                [cdkDragData]="r.linkIdr"
                [cdkDragDisabled]="
                    !r.linkIdr ||
                    !this.dragDrop ||
                    this.dragDrop.disabled ||
                    this.dragDrop.isDisabled?.(r.linkIdr)
                "
                [cdkDragPreviewContainer]="dragDrop?.previewContainer"
                [cdkDragPreviewClass]="dragDrop?.previewClass"
            >
                <dxy-renderer
                    [renderer]="r.renderer"
                    [param]="r.rendererParam"
                    [ngClass]="dragDrop?.itemClass"
                ></dxy-renderer>
                <ng-container *ngIf="dragDrop?.useCustomPreview?.(r.linkIdr)">
                    <div *cdkDragPreview class="custom-preview">
                        <div class="object source">
                            <dxy-renderer
                                [renderer]="renderableEntity.renderer"
                                [param]="renderableEntity.rendererParam"
                            ></dxy-renderer>
                        </div>
                        <div class="link"></div>
                        <div class="object target">
                            <dxy-renderer
                                [renderer]="r.renderer"
                                [param]="r.rendererParam"
                            ></dxy-renderer>
                        </div>
                    </div>
                </ng-container>
            </div>
        </dxy-collapsible>
    </div>
    <dxy-spinner *ngIf="isFirstLoading"></dxy-spinner>
</ng-template>
