import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreUtil } from '@datagalaxy/core-util';
import { Serialize } from 'cerialize';
import { IDenodoSampleParameters } from './denodo';

@Injectable({ providedIn: 'root' })
export class DenodoApiService {
    constructor(private httpClient: HttpClient) {}

    public async getDenodoSampleData(
        structureName: string,
        denodoParameters: IDenodoSampleParameters,
    ) {
        const builtUrl =
            `${denodoParameters.apiBaseUrl}?base=${encodeURIComponent(
                denodoParameters.sourceTechnicalName,
            )}` +
            `&view=${encodeURIComponent(
                structureName,
            )}&top=${encodeURIComponent(denodoParameters.rowSizeLimit)}`;
        const param = {
            login: denodoParameters.username,
            password: denodoParameters.password,
        };
        const responseBody = await CoreUtil.toPromise(
            this.httpClient.post(builtUrl, Serialize(param), {
                headers: {
                    'content-type': 'application/json',
                },
            }),
        ).catch();
        return (responseBody as { value: any[] })?.value ?? null;
    }
}
