import { BaseService } from '@datagalaxy/core-ui';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TechnologyService } from './technology.service';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { ClientAdminTechnologyCreateModalComponent } from '../client-admin-technology/client-admin-technology-create-modal/client-admin-technology-create-modal.component';
import { DataUtil } from '../../shared/util/DataUtil';
import { TechnologySpaceUsage } from '@datagalaxy/webclient/technology/data-access';
import { TechnologyStore } from './technology-store.service';
import { DialogType } from '@datagalaxy/ui/dialog';
import { TechnologyDto } from '@datagalaxy/webclient/technology/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { LanguageService } from '@datagalaxy/translate';

@Injectable({ providedIn: 'root' })
export class TechnologyUiService extends BaseService {
    constructor(
        private technologyService: TechnologyService,
        private technologyStore: TechnologyStore,
        private translate: TranslateService,
        private dxyModalService: DxyModalService,
        private languageService: LanguageService,
    ) {
        super();
    }

    public async openCreateTechnologyModal() {
        return await this.dxyModalService.open<
            ClientAdminTechnologyCreateModalComponent,
            void,
            TechnologyDto
        >({
            loadComponent: () =>
                import(
                    '../client-admin-technology/client-admin-technology-create-modal/client-admin-technology-create-modal.component'
                ).then((m) => m.ClientAdminTechnologyCreateModalComponent),
        });
    }

    public async openDeleteTechnologyModal(technologyCode: string) {
        const confirm = await this.dxyModalService.confirm({
            type: DialogType.Action,
            confirmButtonKey: 'UI.Global.btnDelete',
            cancelButtonKey: 'UI.Global.btnCancel',
            titleKey: 'UI.TechnologyAdmin.deleteModalTitle',
            messageKey: 'UI.TechnologyAdmin.deleteModalText',
            featureCode: 'ADMIN_CS_TECHNOLOGY,D',
            confirmButtonColor: 'destructive',
        });
        if (!confirm) {
            return confirm;
        }

        await this.technologyService.deleteTechnology(technologyCode);
    }

    public async openDeleteTechnologyForbiddenModal(
        spaceUsages: TechnologySpaceUsage[],
    ) {
        const total = CollectionsHelper.sum(spaceUsages, (su) => su.UsageCount);
        let message = this.translate.instant(
            'UI.TechnologyAdmin.deleteModalErrorText',
            { count: total },
        );
        const workspaceNameLbl = this.translate.instant(
            'UI.TechnologyAdmin.deleteModalErrorDetail',
        );
        spaceUsages.forEach((spaceUsage) => {
            message += `<br/>${workspaceNameLbl}${spaceUsage.SpaceHdd.DisplayName}`;
        });
        return await this.dxyModalService.inform({
            type: DialogType.Close,
            message,
            titleKey: 'UI.TechnologyAdmin.deleteModalTitle',
        });
    }

    public async openUpdateTechnologyForbiddenModal(usedModules: DgModule) {
        const moduleName = this.translate.instant(
            DataUtil.getModuleTranslateKey(usedModules),
        );
        const message = this.translate.instant(
            'UI.TechnologyAdmin.updateModalErrorText',
            { moduleName },
        );
        return await this.dxyModalService.inform({
            type: DialogType.Close,
            message,
            titleKey: 'UI.TechnologyAdmin.updateModalTitle',
        });
    }

    public async preCreateTechnology(
        technologyName: string,
        modules: DgModule[],
        technologyCode?: string,
    ) {
        return await this.technologyService.preCreateTechnology(
            technologyName,
            modules,
            technologyCode,
        );
    }

    public async createTechnology(
        technologyName: string,
        technologyCode: string,
        modules: DgModule[],
        description: string,
    ) {
        const result = await this.technologyService.createTechnology(
            technologyName,
            technologyCode,
            modules,
            description,
        );

        this.technologyStore.dispatchCreate(result);

        return result;
    }

    public async preUpdateTechnology(
        technology: TechnologyDto,
        modules: DgModule[],
    ) {
        return await this.technologyService.preUpdateTechnology(
            technology,
            modules,
        );
    }

    public async preDeleteTechnology(technologyCode: string) {
        return this.technologyService.preDeleteTechnology(technologyCode);
    }

    public getTechnologyIconUrl(technology: TechnologyDto) {
        return this.technologyService.getTechnologyIconUrl(technology);
    }

    public getTechnologyFromCode(technoCode: string) {
        return (
            this.technologyStore.technologies?.find(
                (techno) => techno.TechnologyCode == technoCode,
            ) ?? null
        );
    }

    public getAvailableTechnologiesFromModule(module: DgModule) {
        return (
            this.technologyStore.technologies?.filter((techno) =>
                techno.dgModules.includes(module),
            ) ?? []
        );
    }

    public getTechnologyCurrentLangDescription(technology: TechnologyDto) {
        if (!technology) {
            return '';
        }
        switch (this.languageService.getLanguage()) {
            case 'fr':
                return technology.DescriptionFr;
            case 'en':
            default:
                return technology.DescriptionEn;
        }
    }
}
