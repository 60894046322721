import { inject, Injectable } from '@angular/core';
import { LanguageService } from '@datagalaxy/translate';

const langPlaceholder = '{langCode}';
const baseUrl = `https://datagalaxy.freshdesk.com/${langPlaceholder}/support/solutions/articles`;

@Injectable({ providedIn: 'root' })
export class FreshDeskLinkResolver {
    languageService = inject(LanguageService);

    getFreshdeskUrlFromArticleId(articleId: string) {
        const url = `${baseUrl}/${articleId}`;
        return url.replace(
            langPlaceholder,
            this.languageService.getLanguage() ?? 'fr',
        );
    }
}
