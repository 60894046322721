import {
    BaseMetaAttributeValue,
    ParentObjectAttributeValue,
} from '@datagalaxy/meta-attribute-value-domain';

export function buildParentValue(
    baseMetaAttributeValue: BaseMetaAttributeValue,
    value: unknown,
): ParentObjectAttributeValue | undefined {
    if (!value || typeof value !== 'object') {
        return undefined;
    }
    return {
        ...baseMetaAttributeValue,
        ...getParentHierarchyItemFromValue(value),
    };
}

function getParentHierarchyItemFromValue(value: unknown) {
    const typedObject = value as {
        DataReferenceId: string;
        DisplayName: string;
        TechnicalName: string;
        SubTypeName: string;
        VersionId: string;
    };

    return {
        id: typedObject.DataReferenceId,
        versionId: typedObject.VersionId,
        displayName: typedObject.DisplayName,
        technicalName: typedObject.TechnicalName,
        metaObjectTypeName: typedObject.SubTypeName,
    };
}
