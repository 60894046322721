<div class="team-header">
    <dxy-image-input [params]="imageParams"></dxy-image-input>
    <h1 class="team-name">{{ teamData.TeamName }}</h1>
    <div class="close-button-wrapper">
        <button dxyIconButton variant="tertiary" (click)="onCloseClick()">
            <i class="glyph-cancelsearch"></i>
        </button>
    </div>
</div>

<mat-tab-group
    (selectedTabChange)="onChangeTab($event)"
    class="content-fill-available no-padding"
    #matTabGroup
>
    <mat-tab dxyLogFunctional="TEAM_PROFILE,R">
        <ng-template mat-tab-label>
            <span translate>UI.Teams.Profile.title</span>
        </ng-template>

        <app-team-profile
            [teamData]="teamData"
            [showJoinBtn]="showJoinBtn"
            [hasEditRights]="hasEditRights"
            [teamOwnersInput]="teamData.TeamOwners"
        ></app-team-profile>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label dxyLogFunctional="TEAM_MEMBERS,R">
            <span translate>UI.Teams.Members.title</span>
            <span *ngIf="teamData.MembersCount" class="dg5-badge">
                {{ teamData.MembersCount }}
            </span>
        </ng-template>
        <ng-template matTabContent>
            <app-team-members
                [teamData]="teamData"
                [teamMembers]="teamMembers"
                [hasEditRights]="hasEditRights"
            ></app-team-members>
        </ng-template>
    </mat-tab>
    <mat-tab
        *ngIf="isMembershipRequestsTabVisible"
        labelClass="disable-default-opacity"
    >
        <ng-template mat-tab-label dxyLogFunctional="TEAM_ACCESS_REQUEST,R">
            <span class="apply-opacity" translate>UI.Teams.Requests.title</span>
            <ng-container *ngIf="teamRequests?.length">
                <span class="dg5-badge apply-opacity">{{
                    teamRequests?.length
                }}</span>
                <dxy-color-point-cell
                    [dxyTooltip]="'UI.Teams.Requests.dotTooltip' | translate"
                    class="small primary"
                ></dxy-color-point-cell>
            </ng-container>
        </ng-template>
        <ng-template matTabContent>
            <app-team-requests
                [teamData]="teamData"
                [teamRequests]="teamRequests"
            ></app-team-requests>
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label dxyLogFunctional="TEAM_HISTORY,R">
            <span translate>UI.Teams.History.title</span>
        </ng-template>
        <ng-template matTabContent>
            <app-team-history [teamData]="teamData"></app-team-history>
        </ng-template>
    </mat-tab>
</mat-tab-group>
