import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { BaseService } from '@datagalaxy/core-ui';
import { Subject } from 'rxjs';
import { DpiEditModalComponent } from '../dpi-edit-modal/dpi-edit-modal.component';
import {
    EditedDpi,
    IDpiEditResolve,
} from '../dpi-edit-modal/dpi-edit-modal.types';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    DataProcessingLinkDirection,
    DataProcessingLinkEntityType,
} from '../data-processing.types';
import { GraphicalItem } from '../mapping/models/GraphicalItem';
import { GraphicalEntityDataDpLink } from '../mapping/models/GraphicalEntityDataDpLink';
import { GraphicalEntityDataDpLinkColumn } from '../mapping/models/GraphicalEntityDataDpLinkColumn';
import { DataProcessingLinkDto } from '../mapping/models/DataProcessingLinkDto';
import { DataProcessingLinkColumnDto } from '../mapping/models/DataProcessingLinkColumnDto';
import { IDpMappingItemOptions } from '../mapping/dp-mapping.types';
import { DataProcessingService } from './data-processing.service';
import { AttributeDataService } from '../../shared/attribute/attribute-data.service';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { ToasterService } from '../../services/toaster.service';
import {
    DataIdentifier,
    EntityType,
    HierarchicalData,
    IDataIdentifier,
    IEntityIdentifier,
    ModelType,
    ObjectLinkType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { GraphicalContainer } from '../mapping/models/GraphicalContainer';
import { GraphicalEntityData } from '../mapping/models/GraphicalEntityData';
import {
    DataProcessingItemDto,
    DataProcessingItemType,
} from '@datagalaxy/webclient/data-processing/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import {
    isUnmodifiedApiError,
    isUnsuccessfulApiError,
} from '@datagalaxy/data-access';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import {
    EntityItem,
    EntityTypeMeta,
    LinkedDataItem,
} from '@datagalaxy/webclient/entity/domain';
import { ABaseSecurityData } from '@datagalaxy/webclient/security/domain';
import { EntityFunctionalLogService } from '@datagalaxy/webclient/entity/data-access';

@Injectable({ providedIn: 'root' })
export class DataProcessingUiService extends BaseService {
    //#region static
    public static getDataListName(direction: DataProcessingLinkDirection) {
        return direction === DataProcessingLinkDirection.In
            ? 'InputLinks'
            : 'OutputLinks';
    }
    //#endregion

    public get mappingToggleShowUnmappedGlobalColumns$() {
        return this.mappingToggleShowUnmappedGlobalColumns.asObservable();
    }
    private mappingToggleShowUnmappedGlobalColumns = new Subject<void>();
    public emitMappingToggleShowUnmappedGlobalColumns() {
        this.mappingToggleShowUnmappedGlobalColumns.next();
    }

    public readonly implementationData = new DataProcessingImplementationData();
    public showGlobalUnmappedChildren = false;
    public get hasWriteAccess() {
        return this.implementationData.entitySecurityData?.HasWriteAccess;
    }

    constructor(
        private translate: TranslateService,
        private attributeDataService: AttributeDataService,
        private dataProcessingService: DataProcessingService,
        private functionalLogService: FunctionalLogService,
        private dxyModalService: DxyModalService,
        private toasterService: ToasterService,
    ) {
        super();
    }

    //#region for dpMappingUiService
    public async loadImplementation(dataProcessing: EntityItem) {
        this.log('loadImplementation', dataProcessing);
        const inputLinksData: DataProcessingLinkDto[] = [];
        const outputLinksData: DataProcessingLinkDto[] = [];

        const data = this.implementationData;
        data.init();
        data.entityData = dataProcessing;
        data.entitySecurityData = dataProcessing.SecurityData;

        const res = await this.dataProcessingService.getDPItems(dataProcessing);
        const dpItems = res?.Entities.map(
            (dp) => new DataProcessingItemDto(dp),
        );

        await this.attributeDataService.loadAttributes(['DataProcessingItem']);
        const attributes = this.attributeDataService.getAttributes(
            ['DataProcessingItem'],
            null,
            null,
        );
        data.itemMeta = new EntityTypeMeta(attributes);

        const result =
            await this.dataProcessingService.getDPEntityLinks(dataProcessing);

        const linkedEntities = result.Entities;

        const inputLinks = result.Groups.filter(
            (grp) => grp.UniversalObjectLinkType == ObjectLinkType.HasInput,
        )[0]?.Items;
        const outputLinks = result.Groups.filter(
            (grp) => grp.UniversalObjectLinkType == ObjectLinkType.HasOutput,
        )[0]?.Items;

        const filterLdi = (ldi: LinkedDataItem) =>
            linkedEntities.find((le) => le.ReferenceId == ldi.DataReferenceId);
        const inputItems = inputLinks?.map(filterLdi) ?? [];
        const outputItems = outputLinks?.map(filterLdi) ?? [];

        this.getDpLinkColumns(
            inputItems,
            inputLinksData,
            DataProcessingLinkDirection.In,
        );
        this.getDpLinkColumns(
            outputItems,
            outputLinksData,
            DataProcessingLinkDirection.Out,
        );

        this.getDpLinkExceptColumns(
            inputItems,
            inputLinksData,
            DataProcessingLinkDirection.In,
        );
        this.getDpLinkExceptColumns(
            outputItems,
            outputLinksData,
            DataProcessingLinkDirection.Out,
        );

        data.inputLinksData.push(...inputLinksData);
        data.outputLinksData.push(...outputLinksData);

        CollectionsHelper.withFirstFound(
            data.itemMeta.Attributes,
            (a) => a.AttributeKey == 'Type',
            (a) => (a.AttributeKey = 'ItemType'),
        );

        dpItems.forEach((item) => (item.Meta = data.itemMeta));
        data.items = dpItems;

        this.log('loadImplementation-result', this.implementationData);
    }
    public findContainerByParentDataReferenceId(
        dataId: string,
        direction: DataProcessingLinkDirection,
    ): GraphicalContainer {
        const dataList =
            direction == DataProcessingLinkDirection.In
                ? this.implementationData.inputLinks
                : this.implementationData.outputLinks;
        return dataList.find(
            (container) => container.parentData.DataReferenceId == dataId,
        );
    }
    //#endregion

    //#region for dp-mapping

    /* Test current DP & DPI objects */
    public isUsedDataProcessingItemsId(id: string) {
        return this.implementationData.items.some(
            (it) => it.ReferenceId === id,
        );
    }

    public async deleteDataProcessingItem(
        dpIdr: IEntityIdentifier,
        item: DataProcessingItemDto,
    ) {
        await this.dataProcessingService.deleteDataProcessingItem(
            dpIdr,
            item.ReferenceId,
        );
        this.deleteLocalItem(item);
    }
    private deleteLocalItem(updatedItem: DataProcessingItemDto) {
        CollectionsHelper.removeOne(
            this.implementationData.items,
            (c) => c.ReferenceId == updatedItem.ReferenceId,
        );
    }

    public findContainingDpLink(
        dataIdentifier: IDataIdentifier,
        direction: DataProcessingLinkDirection,
    ) {
        if (dataIdentifier.DataTypeName == 'Column') {
            return this.findContainerForColumn(
                dataIdentifier.DataReferenceId,
                direction,
            );
        } else {
            return this.findContainerForTableOrModelOrContainer(
                dataIdentifier.DataReferenceId,
                direction,
            );
        }
    }
    public refreshImplementation(isInitializing: boolean) {
        this.refreshInputLinks(isInitializing);
        this.refreshOutputLinks(isInitializing);
    }
    private refreshInputLinks(isInitializing: boolean) {
        const result = [];
        if (this.implementationData.inputLinksData) {
            this.refreshLinks(
                this.implementationData.inputLinksData,
                result,
                'InputLinks',
                isInitializing,
            );
        }
        this.updateContainers(this.implementationData.inputLinks, result);
    }
    private refreshOutputLinks(isInitializing: boolean): void {
        const result = [];
        if (this.implementationData.inputLinksData) {
            this.refreshLinks(
                this.implementationData.outputLinksData,
                result,
                'OutputLinks',
                isInitializing,
            );
        }
        this.updateContainers(this.implementationData.outputLinks, result);
    }
    private updateContainers(
        array: GraphicalContainer[],
        result: GraphicalContainer[],
    ) {
        array.slice().forEach((c, index) => {
            const existing = result.filter((o) => c.uniqueId == o.uniqueId);
            if (!existing.length) {
                array.splice(index, 1);
            } else {
                const resultData = existing[0];

                // Add/Remove Items inside
                c.getItemKeys().forEach((key) => {
                    if (!resultData.hasItem(key)) {
                        c.removeItem(key);
                    }
                });

                resultData.getItemKeys().forEach((key) => {
                    if (!c.hasItem(key)) {
                        c.setItem(key, resultData.getItem(key));
                    }
                });

                // CALLBACK method to UPDATE the displayed content in the container
                c?.updateContainerContent?.();
            }
        });

        result.forEach((c) => {
            if (!array.some((o) => c.uniqueId == o.uniqueId)) {
                array.push(c);
            }
        });
    }

    public async deleteLinkedData(
        dataProcessingIdr: IEntityIdentifier,
        graphicalEntity: GraphicalEntityData,
        direction: DataProcessingLinkDirection,
    ) {
        const objectLinkType =
            direction == DataProcessingLinkDirection.In
                ? ObjectLinkType.HasInput
                : ObjectLinkType.HasOutput;

        let linkedDataIds: string[];
        if (graphicalEntity instanceof GraphicalEntityDataDpLink) {
            linkedDataIds = this.getAllEntityIds(graphicalEntity.LinkData);
        } else if (graphicalEntity instanceof GraphicalEntityDataDpLinkColumn) {
            linkedDataIds = [graphicalEntity.DataReferenceId];
        } else {
            throw new Error('Not implemented!');
        }

        const result = await this.dataProcessingService.deleteDPEntityLinks(
            objectLinkType,
            dataProcessingIdr,
            linkedDataIds,
        );
        const updateSourceIds = Array.from(
            result.SourceTargetsDictionary.keys(),
        );
        const updatedDpis = this.implementationData.items.filter((item) =>
            updateSourceIds.includes(item.ReferenceId),
        );
        this.updateDeletedDpLinks(
            linkedDataIds,
            updatedDpis,
            graphicalEntity,
            direction,
        );
    }
    private getAllEntityIds(dpLink: DataProcessingLinkDto): string[] {
        switch (dpLink.EntityType) {
            case DataProcessingLinkEntityType.Model:
            case DataProcessingLinkEntityType.Container:
            case DataProcessingLinkEntityType.Table:
                return [dpLink.LinkedDataReferenceId];
            case DataProcessingLinkEntityType.Column:
                return dpLink.Columns.map((c) => c.ColumnId);
        }
    }

    public async addDataProcessingEntityLink(
        dataProcessingIdr: IEntityIdentifier,
        objectLink: ObjectLinkType,
        entityDatas: IDataIdentifier[],
        entityType: DataProcessingLinkEntityType,
    ) {
        let includedAttributes = [];

        if (
            entityType == DataProcessingLinkEntityType.Column &&
            ServerType[entityDatas[0]?.DataTypeName] == ServerType.Table
        ) {
            const result = await this.dataProcessingService.getEntities(
                entityDatas[0],
                ServerType.Column,
                ['ReferenceId'],
                dataProcessingIdr.VersionId,
                false,
            );
            entityDatas = result.Entities;
            includedAttributes = [
                'DataTypeDisplayName',
                'SizeString',
                'IsPrimaryKey',
            ];
        }

        try {
            const res = await this.dataProcessingService.addDPEntityLinks(
                objectLink,
                dataProcessingIdr,
                entityDatas,
                includedAttributes,
            );

            const dPLinks: DataProcessingLinkDto[] = [];
            const linkedEntities = CollectionsHelper.flatten(
                res.Links.map((link) => link.LinkedEntities),
            );
            const linkDirection =
                objectLink == ObjectLinkType.HasInput
                    ? DataProcessingLinkDirection.In
                    : DataProcessingLinkDirection.Out;

            this.getDpLinkColumns(linkedEntities, dPLinks, linkDirection);
            this.getDpLinkExceptColumns(linkedEntities, dPLinks, linkDirection);

            this.updateLinks(dPLinks, linkDirection);
            const typeName =
                DataProcessingLinkEntityType[entityType]?.toUpperCase();
            const typeNameCode = EntityFunctionalLogService.getTypeNameCode(
                typeName,
                false,
            );
            this.functionalLogService.logFunctionalAction(
                `DATA_PROCESSING_ITEM_${typeNameCode}`,
                CrudOperation.C,
            );
        } catch (e) {
            if (isUnmodifiedApiError(e)) {
                const isInput = objectLink === ObjectLinkType.HasInput;
                this.toasterService.warningToast({
                    messageKey: `UI.DataProcessing.mapping.btnAddTo${
                        isInput ? 'Input' : 'Output'
                    }Disabled`,
                });
            } else if (isUnsuccessfulApiError(e)) {
                this.toasterService.warningToast({
                    messageKey: e.error.ErrorDetails,
                });
            } else {
                throw e;
            }
        }
    }
    private updateLinks(
        updatedLinks: DataProcessingLinkDto[],
        direction: DataProcessingLinkDirection,
    ) {
        const { array, graphicalArray, listName } =
            this.getUpdateLinkProperties(direction);

        updatedLinks.forEach((ul) => {
            if (ul.EntityType == DataProcessingLinkEntityType.Column) {
                const linkedData = CollectionsHelper.findUnique(
                    array,
                    (c) =>
                        c.LinkHierarchyData.DataReferenceId ===
                            ul.LinkHierarchyData.DataReferenceId &&
                        c.EntityType === ul.EntityType,
                );
                if (linkedData) {
                    linkedData.Columns.push(...ul.Columns);
                    ul = linkedData;
                }
            }

            CollectionsHelper.replaceOrAppend(
                array,
                (c) =>
                    c.LinkHierarchyData.DataReferenceId ===
                        ul.LinkHierarchyData.DataReferenceId &&
                    c.EntityType === ul.EntityType,
                ul,
            );
        });

        const result = [];
        this.refreshLinks(array, result, listName, false);
        this.updateContainers(graphicalArray, result);
    }
    private getUpdateLinkProperties(direction: DataProcessingLinkDirection) {
        const isIn = direction == DataProcessingLinkDirection.In;
        const array = isIn
            ? this.implementationData.inputLinksData
            : this.implementationData.outputLinksData;
        const graphicalArray = isIn
            ? this.implementationData.inputLinks
            : this.implementationData.outputLinks;
        const listName = DataProcessingUiService.getDataListName(direction);
        return { array, graphicalArray, listName, isIn };
    }
    private updateDeletedDpLinks(
        deletedEntityLinkIds: string[],
        updatedDpis: DataProcessingItemDto[],
        graphicalEntity: GraphicalEntityData,
        direction: DataProcessingLinkDirection,
    ) {
        const { array, graphicalArray, listName, isIn } =
            this.getUpdateLinkProperties(direction);

        if (graphicalEntity instanceof GraphicalEntityDataDpLink) {
            const dpLink = graphicalEntity.LinkData;
            CollectionsHelper.remove(
                array,
                (c) => c.ReferenceId === dpLink.ReferenceId,
            );
        } else if (graphicalEntity instanceof GraphicalEntityDataDpLinkColumn) {
            const dpLinkReferenceId = graphicalEntity.LinkData.ReferenceId;
            const updatedDpLink = array.find(
                (link) => link.ReferenceId == dpLinkReferenceId,
            );
            deletedEntityLinkIds.forEach((id) =>
                CollectionsHelper.remove(
                    updatedDpLink.Columns,
                    (c) => c.ColumnId === id,
                ),
            );

            if (!updatedDpLink.Columns.length) {
                CollectionsHelper.remove(
                    array,
                    (c) => c.ReferenceId === dpLinkReferenceId,
                );
            }
        }

        updatedDpis.forEach((item) =>
            CollectionsHelper.remove(
                isIn ? item.InputLinks : item.OutputLinks,
                (c) => deletedEntityLinkIds.includes(c.DataReferenceId),
            ),
        );

        const result = [];
        this.refreshLinks(array, result, listName, false);
        this.updateContainers(graphicalArray, result);
    }
    private refreshLinks(
        linksData: DataProcessingLinkDto[],
        containers: GraphicalContainer[],
        dataListName: string,
        isInitializing: boolean,
    ) {
        if (!linksData) {
            return;
        }

        linksData.forEach((link) => {
            const entityData = new GraphicalEntityDataDpLink(link);
            const isCollapsed =
                link.EntityType !== DataProcessingLinkEntityType.Column ||
                isInitializing;
            const itemOptions =
                link.EntityType === DataProcessingLinkEntityType.Column
                    ? null
                    : this.getMappingItemOptions(entityData, dataListName);

            const container = new GraphicalContainer(
                entityData,
                dataListName,
                isCollapsed,
                itemOptions,
            );
            containers.push(container);

            if (link.EntityType === DataProcessingLinkEntityType.Column) {
                link.Columns.forEach((col) => {
                    const graphicalEntityData =
                        GraphicalEntityDataDpLinkColumn.fromDataProcessingLinkColumnDto(
                            col,
                            link,
                        );
                    const options = this.getMappingItemOptions(
                        graphicalEntityData,
                        dataListName,
                    );
                    container.addItem(
                        new GraphicalItem(graphicalEntityData, options),
                    );
                });
            }
        });
    }
    private getMappingItemOptions(
        item: GraphicalEntityData,
        dataListName: string,
    ): IDpMappingItemOptions {
        return {
            id: `${dataListName}_${item.DataReferenceId}`,
        };
    }
    private getDpLinkExceptColumns(
        inputItems: EntityItem[],
        dpLinks: DataProcessingLinkDto[],
        direction: DataProcessingLinkDirection,
    ) {
        const items = inputItems.filter(
            (it) => it.ServerType != ServerType.Column,
        );
        items.forEach((item) =>
            dpLinks.push(this.makeDpLinkFromItem(item, direction)),
        );
    }
    private makeDpLinkFromItem(
        value: EntityItem,
        direction: DataProcessingLinkDirection,
    ) {
        const dpLinkEntityType = <DataProcessingLinkEntityType>(
            (<any>DataProcessingLinkEntityType[value.DataTypeName])
        );
        const dpLink = new DataProcessingLinkDto(
            [],
            direction,
            dpLinkEntityType,
            value.HddData,
        );

        if (
            dpLink.EntityType == DataProcessingLinkEntityType.Container ||
            dpLink.EntityType == DataProcessingLinkEntityType.Table
        ) {
            const modelSubTypeName =
                dpLink.LinkHierarchyData.Parents[
                    dpLink.LinkHierarchyData.Parents.length - 2
                ].SubTypeName;
            dpLink.Type = ModelType[modelSubTypeName];
        }

        if (dpLink.EntityType == DataProcessingLinkEntityType.Table) {
            dpLink.TableDisplayName = dpLink.LinkHierarchyData.DisplayName;
            dpLink.TableTechnicalName = dpLink.LinkHierarchyData.TechnicalName;
        }

        if (dpLink.EntityType == DataProcessingLinkEntityType.Model) {
            const modelSubTypeName = dpLink.LinkHierarchyData.SubTypeName;
            dpLink.Type = ModelType[modelSubTypeName];
        }
        return dpLink;
    }

    private getDpLinkColumns(
        linkedItems: EntityItem[],
        dpLinks: DataProcessingLinkDto[],
        direction: DataProcessingLinkDirection,
    ) {
        const columnItems = linkedItems.filter(
            (it) => it.ServerType == ServerType.Column,
        );

        const columnsByTableMap = CollectionsHelper.groupToMap(
            columnItems || [],
            (item) => item.HddData.Parents[0].DataReferenceId,
        );

        columnsByTableMap.forEach((items) =>
            dpLinks.push(this.makeDpLinkFromItems(items, direction)),
        );
    }
    private makeDpLinkFromItems(
        items: EntityItem[],
        direction: DataProcessingLinkDirection,
    ) {
        const parent = items[0].HddData.Parents[0];
        const columns = items.map((v) => new DataProcessingLinkColumnDto(v));

        const columnParents = items[0].HddData.Parents;
        const parentParents = columnParents.slice(1, columnParents.length);
        const parentHdd = new HierarchicalData(
            parent,
            parentParents,
            items[0].HddData.TechnologyCode,
        );
        const dpLink = new DataProcessingLinkDto(
            columns,
            direction,
            DataProcessingLinkEntityType.Column,
            parentHdd,
        );

        const modelSubTypeName =
            dpLink.LinkHierarchyData.Parents[
                dpLink.LinkHierarchyData.Parents.length - 2
            ].SubTypeName;
        dpLink.Type = ModelType[modelSubTypeName];
        dpLink.TableDisplayName = dpLink.LinkHierarchyData.DisplayName;
        dpLink.TableTechnicalName = dpLink.LinkHierarchyData.TechnicalName;
        return dpLink;
    }

    public async createDataProcessingItem(
        dpIdr: IEntityIdentifier,
        itemType: DataProcessingItemType,
        input: IDataIdentifier,
        output: IDataIdentifier,
    ) {
        const defaultName = this.translate.instant(
            `DgServerTypes.ServerTypeName.${
                ServerType[ServerType.DataProcessingItem]
            }`,
        );
        const result =
            await this.dataProcessingService.createDataProcessingItem(
                dpIdr,
                defaultName,
                itemType,
                input,
                output,
            );
        result.Meta = this.implementationData.itemMeta;
        this.implementationData.items.push(result);
        return result;
    }

    public async addItemLinkData(
        dpIdr: IEntityIdentifier,
        sourceData: GraphicalEntityData,
        targetData: GraphicalEntityData,
    ) {
        const dpc = {} as IDataProcessingConnection;
        this.setConnectionParameter(dpc, sourceData);
        this.setConnectionParameter(dpc, targetData);

        if (!dpc.DataProcessingItem) {
            return await this.createDataProcessingItem(
                dpIdr,
                DataProcessingItemType.Copy,
                dpc.InputLink,
                dpc.OutputLink,
            );
        }

        const dpi = new EntityIdentifier(
            dpc.DataProcessingItem.DataReferenceId,
            dpIdr.VersionId,
            dpIdr.entityType,
        );
        const objectLink = dpc.InputLink
            ? ObjectLinkType.HasInput
            : ObjectLinkType.HasOutput;
        const linkedData =
            objectLink == ObjectLinkType.HasInput
                ? dpc.InputLink
                : dpc.OutputLink;

        const result = await this.dataProcessingService.addDPEntityLinks(
            objectLink,
            dpi,
            [linkedData],
            [],
            AttributeDataService.dataProcessingItemAttributes,
        );
        if (!result.Links?.length) {
            return;
        }

        const entityItem = result.UpdatedEntities[0];
        const linkedData2 = CollectionsHelper.flatten(
            result.Links.map((link) => link.LinkedEntities),
        )[0];
        const dpiDto = this.implementationData.items.find(
            (c) => c.ReferenceId == entityItem.ReferenceId,
        );
        if (objectLink == ObjectLinkType.HasInput) {
            dpiDto.InputLinks.push(linkedData2.HddData);
        } else {
            dpiDto.OutputLinks.push(linkedData2.HddData);
        }
        return dpiDto;
    }
    private setConnectionParameter(
        dpc: IDataProcessingConnection,
        data: GraphicalEntityData,
    ) {
        let linkData: DataProcessingLinkDto;
        let linkDataSource: IDataIdentifier;
        if (data instanceof GraphicalEntityDataDpLinkColumn) {
            linkData = data.LinkData;
            linkDataSource = new DataIdentifier(
                data.ColumnData.ColumnId,
                ServerType[ServerType.Column],
            );
        } else if (data instanceof GraphicalEntityDataDpLink) {
            linkData = data.LinkData;
            linkDataSource = linkData.LinkHierarchyData.Data;
        } else if (
            data.DataTypeName != ServerType[ServerType.DataProcessingItem]
        ) {
            throw new Error();
        } else {
            dpc.DataProcessingItem = data;
        }

        if (linkDataSource) {
            if (linkData.Direction === DataProcessingLinkDirection.In) {
                dpc.InputLink = linkDataSource;
            } else {
                dpc.OutputLink = linkDataSource;
            }
        }
    }

    public async deleteItemLinkData(
        dpIdr: IEntityIdentifier,
        dataProcessingItemId: string,
        entity: IDataIdentifier,
        direction: DataProcessingLinkDirection,
    ) {
        const objectLinkType =
            direction === DataProcessingLinkDirection.In
                ? ObjectLinkType.HasInput
                : ObjectLinkType.HasOutput;
        const result = await this.dataProcessingService.deleteDPEntityLinks(
            objectLinkType,
            new EntityIdentifier(
                dataProcessingItemId,
                dpIdr.VersionId,
                EntityType.DataProcessingItem,
            ),
            [entity.DataReferenceId],
        );
        if (!result.DeletedEntityIds?.length) {
            return { result, dpiDto: undefined };
        }

        const dpiDto = this.implementationData.items.find(
            (c) => c.ReferenceId == dataProcessingItemId,
        );
        if (objectLinkType == ObjectLinkType.HasInput) {
            CollectionsHelper.remove(
                dpiDto.InputLinks,
                (hd) => hd.DataReferenceId == entity.DataReferenceId,
            );
        } else {
            CollectionsHelper.remove(
                dpiDto.OutputLinks,
                (hd) => hd.DataReferenceId == entity.DataReferenceId,
            );
        }
    }

    //#endregion - for dp-mapping

    //#region for dp-item-element
    public findContainerForColumn(
        columnId: string,
        direction: DataProcessingLinkDirection,
    ): GraphicalContainer {
        const dataList =
            direction === DataProcessingLinkDirection.In
                ? this.implementationData.inputLinks
                : this.implementationData.outputLinks;
        const dataListName = DataProcessingUiService.getDataListName(direction);
        return (
            dataList.find((container) =>
                container.hasItem(`${dataListName}_${columnId}`),
            ) ?? null
        );
    }

    public getGraphicalItemTargetId(
        hierarchicalData: HierarchicalData,
        direction: DataProcessingLinkDirection,
    ) {
        if (hierarchicalData.ServerType === ServerType.Column) {
            return hierarchicalData.DataReferenceId;
        }
        const container = this.findContainerForTableOrModelOrContainer(
            hierarchicalData.DataReferenceId,
            direction,
        );
        return container?.parentData.DataReferenceId;
    }

    public async showDpiEditModalFromEntityIdr(
        dpiEntityIdr: IEntityIdentifier,
    ) {
        const dpi = await this.dataProcessingService.getDPItem(dpiEntityIdr);
        return this.showEditModal(
            dpi,
            WorkspaceIdentifier.fromEntity(dpiEntityIdr),
        );
    }

    public async showDpiEditModal(
        dpi: DataProcessingItemDto,
        dpEntityData: EntityItem,
    ) {
        await this.showEditModal(
            dpi,
            WorkspaceIdentifier.fromEntity(dpEntityData),
            this.dpiAsEntityItem(dpi, dpEntityData),
        );
    }
    private async showEditModal(
        dpi: DataProcessingItemDto,
        spaceIdr: IWorkspaceIdentifier,
        dpiAsEntity?: EntityItem,
    ) {
        this.log('showEditModal (dpi,dpiAsEntity)', dpi, dpiAsEntity);
        await this.dxyModalService.open<
            DpiEditModalComponent,
            IDpiEditResolve,
            void
        >({
            loadComponent: () =>
                import('../dpi-edit-modal/dpi-edit-modal.component').then(
                    (m) => m.DpiEditModalComponent,
                ),
            data: { dpi, spaceIdr },
        });
    }
    private dpiAsEntityItem(
        dpi: DataProcessingItemDto,
        dpEntityData: EntityItem,
    ) {
        // When Editing a Data Processing Item, we create a Virtual Entity Item so that
        // the normal EntityForm mechanisms work normally (with same visual designs).

        const entityData = new EntityItem();

        entityData.DataTypeName = ServerType[ServerType.DataProcessingItem];
        entityData.Attributes = {};
        entityData.ReferenceId = dpi.ReferenceId;
        entityData.Description = dpi.Description;
        entityData.DisplayName = dpi.DisplayName;
        entityData.TechnicalName = dpi.TechnicalName;
        entityData.setAttributeValue('LongDescription', dpi.LongDescription);
        entityData.setAttributeValue(
            'ItemType',
            DataProcessingItemType[dpi.ItemType],
        );

        entityData.SecurityData = dpEntityData.SecurityData;

        const hddData = CoreUtil.cloneDeep(dpEntityData.HddData);
        hddData.Parents.unshift(hddData.Data);
        hddData.Data.SubTypeName = null;

        entityData.setManualHddData(hddData);

        entityData.Meta = dpi.Meta;
        dpi.Meta.Attributes.forEach((ami) => {
            ami.DataTypeName = ServerType[ServerType.DataProcessingItem];
            ami.init();
            this.attributeDataService.setTranslatedNames(ami);
        });

        return entityData;
    }

    //#endregion

    //#region for dp-implem-element
    public getShowGlobalUnmappedChildren() {
        return this.showGlobalUnmappedChildren;
    }
    public isColumnMapped(columnId: string, isInputContainer: boolean) {
        return !!this.implementationData.items?.some((item) =>
            (isInputContainer ? item.InputLinks : item.OutputLinks).some(
                (hd) => hd.Data.DataReferenceId == columnId,
            ),
        );
    }
    //#endregion

    //#region for dpi-edit-modal
    public getEditedDpi(dpi: DataProcessingItemDto) {
        const edited: EditedDpi = {};
        this.dpiEditFields.forEach((k) => (edited[k as string] = dpi[k]));
        return edited;
    }
    public getEditedDpiLabel(property: keyof EditedDpi) {
        return this.attributeDataService.getAttributeDisplayNameInternal(
            property,
            false,
            null,
            ServerType.DataProcessingItem,
        );
    }
    public canSaveEditedDpi(edited: EditedDpi) {
        return !!(edited && edited.DisplayName && edited.TechnicalName);
    }
    public async updateEditedDpi(
        dto: DataProcessingItemDto,
        edited: EditedDpi,
        spaceIdr: IWorkspaceIdentifier,
    ) {
        const values = CollectionsHelper.objArrayToMap(
            this.dpiEditFields,
            (k) => k,
            (k) =>
                k == 'ItemType' ? DataProcessingItemType[edited[k]] : edited[k],
        );
        return await this.updateDataProcessingItem(
            dto,
            spaceIdr.versionId,
            values,
        );
    }

    private readonly dpiEditFields: (keyof EditedDpi)[] = [
        'DisplayName',
        'TechnicalName',
        'Description',
        'ItemType',
        'LongDescription',
    ];
    /** NOTE: A single call can be used to update all DPI attributes */
    private async updateDataProcessingItem(
        item: DataProcessingItemDto,
        versionId: string,
        values: Map<string, string>,
    ): Promise<DataProcessingItemDto> {
        const updatedItem =
            await this.dataProcessingService.updateDataProcessingItem(
                item,
                versionId,
                values,
            );
        item.DisplayName = updatedItem.DisplayName;
        item.TechnicalName = updatedItem.TechnicalName;
        item.Description = updatedItem.Description;
        item.LongDescription = updatedItem.LongDescription;
        item.ItemType = updatedItem.ItemType;
        return updatedItem;
    }
    //#endregion

    //#region for dxy-entity-dashboard
    public setShowGlobalUnmappedChildren(showGlobalUnmappedChildren: boolean) {
        this.showGlobalUnmappedChildren = showGlobalUnmappedChildren;
    }
    //#endregion

    //#region common helpers

    private findContainerForTableOrModelOrContainer(
        dataId: string,
        direction: DataProcessingLinkDirection,
    ): GraphicalContainer {
        const dataList =
            direction == DataProcessingLinkDirection.In
                ? this.implementationData.inputLinks
                : this.implementationData.outputLinks;
        const found = dataList.find((container) => {
            const linkData = (container.parentData as GraphicalEntityDataDpLink)
                .LinkData;
            return (
                !linkData.Columns?.length &&
                linkData.LinkHierarchyData.DataReferenceId == dataId
            );
        });
        if (!found) {
            CoreUtil.warn(
                'findContainerForTableOrModelOrContainer',
                dataId,
                DataProcessingLinkDirection[direction],
                dataList,
            );
        }
        return found;
    }

    //#endregion
}

interface IDataProcessingConnection {
    InputLink: IDataIdentifier;
    OutputLink: IDataIdentifier;
    DataProcessingItem: IDataIdentifier;
}

class DataProcessingImplementationData {
    public entityData: EntityItem;

    public entitySecurityData: ABaseSecurityData;
    public inputLinksData: DataProcessingLinkDto[];
    public outputLinksData: DataProcessingLinkDto[];
    public items: DataProcessingItemDto[];
    public itemMeta: EntityTypeMeta;
    public linkedEntities: EntityItem[];

    public outputLinks: GraphicalContainer[];
    public inputLinks: GraphicalContainer[];

    public init() {
        this.items = [];
        this.inputLinks = [];
        this.outputLinks = [];
        this.inputLinksData = [];
        this.outputLinksData = [];
    }
}
