<dxy-workspace-icon
    *ngIf="spaceInfo"
    dxyTooltipPosition="below"
    [routerLink]="firstItemHierarchicalData | entityUrl"
    size="xs"
    [dxyTooltip]="spaceInfo.DisplayName"
    [workspace]="spaceInfo | workspaceIconAdapter"
></dxy-workspace-icon>

<div
    #breadcrumbContainer
    class="breadcrumb-container"
    [class.is-displayed]="isReadyToDisplay"
    [dxyTooltip]="breadcrumbTooltip"
>
    <div
        *ngFor="let it of renderedItems"
        class="ancestor-container"
        [class.dot-ancestor]="!it.isDisplayed"
        [style.max-width.px]="it.maxWidth"
    >
        @if (it.isDisplayed && !readOnly && !openPreview) {
            <a
                [routerLink]="
                    it.itemData.ancestorData.hierarchicalData | entityUrl
                "
                class="navto-href"
                [attr.data-dtname]="it.itemData.dtName"
            >
                {{ it.itemData.ancestorData.displayName }}
            </a>
        } @else if (it.isDisplayed && !readOnly && openPreview) {
            <a
                (click)="onClick($event, it)"
                [attr.href]="
                    it.itemData.ancestorData.hierarchicalData | entityUrl
                "
                class="navto-href"
                [attr.data-dtname]="it.itemData.dtName"
            >
                {{ it.itemData.ancestorData.displayName }}
            </a>
        } @else if (it.isDisplayed && readOnly) {
            <span class="read-only-item"
                >{{ it.itemData.ancestorData.displayName }}
            </span>
        } @else {
            <span class="dot-item"></span>
        }
    </div>
</div>
