import { deserialize, deserializeAs } from 'cerialize';
import { LicenseLevel } from './user-api.types';

export interface IUserCommonDto {
    FullName?: string;
    FirstName?: string;
    LastName?: string;
    Email?: string;
    ProfileImageHash?: string;
    ProfileThumbnailHash?: string;
    UserId?: string;
    Initials?: string;
    LicenseLevel?: LicenseLevel;
    isMetaBot?: boolean;
}

export class UserDto implements IUserCommonDto {
    @deserialize ReferenceId!: string;
    @deserialize LastName!: string;
    @deserialize FirstName!: string;
    @deserialize Email!: string;
    @deserialize UserId!: string;
    @deserialize ProfileImageHash!: string;
    @deserialize ProfileThumbnailHash!: string;
    @deserializeAs(LicenseLevel) LicenseLevel!: LicenseLevel;
}
