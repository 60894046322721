import { Injectable } from '@angular/core';
import {
    AttributeMetaInfo,
    AttributeMetaType,
    AttributeObjectValue,
    TimeSeriesColorRule,
    TimeSeriesObject,
} from '@datagalaxy/webclient/attribute/domain';
import { GridCellType, TColDef } from '@datagalaxy/ui/grid';
import {
    EntityItem,
    LinkedDataItem,
} from '@datagalaxy/webclient/entity/domain';
import { AttributeDataService } from '../../../../shared/attribute/attribute-data.service';
import { EntityGridConfig, EntityGridGroup } from '../entity-grid-config';
import {
    DxyBooleanCellComponent,
    DxyDgDateTimeCellComponent,
    DxyIconCellComponent,
    IHyperlinkModel,
} from '@datagalaxy/core-ui';
import { RichTextContent } from '@datagalaxy/rich-text-utils';
import { ServerTimeService } from '../../../../services/serverTime.service';
import { DgAttributeHtmlLinkModelCellComponent } from '../../../../shared/shared-ui/cells/dg-attribute-html-link-model-cell/dg-attribute-html-link-model-cell.component';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { AttributeCollectionCellComponent } from '../../../../shared/shared-ui/cells/attribute-collection-cell/attribute-collection-cell.component';
import { DgEntityLinkShortcutCollectionCellComponent } from '../../../../shared/entity/cells/dg-entity-link-shortcut-collection-cell/dg-entity-link-shortcut-collection-cell.component';
import { DgEntityLinkShortcutCellComponent } from '../../../../shared/entity/cells/dg-entity-link-shortcut-cell/dg-entity-link-shortcut-cell.component';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { DgEntityPathCellComponent } from '../../../../shared/entity/cells/dg-entity-path-cell/dg-entity-path-cell.component';
import { DgTimeSeriesCellComponent } from '../../../../shared/shared-ui/cells/dg-time-series-cell/dg-time-series-cell.component';
import { TranslateService } from '@ngx-translate/core';
import { UserCellComponent } from '@datagalaxy/webclient/user/ui';
import { UserService } from '../../../../services/user.service';
import { EntitySimpleLinkCellComponent } from '../../../../shared/entity/cells/entity-simple-link-cell/entity-simple-link-cell.component';
import { getAttributeGlyphClass } from '@datagalaxy/webclient/attribute/feature';
import { ArrayUtils } from '@datagalaxy/utils';
import { fromSerializedServerHyperlinkModel } from '../../../../shared/fields/field-hyperlink.utils';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { DgStatusCellComponent } from '../../../../shared/shared-ui/cells/dg-status-cell/dg-status-cell.component';
import { DataQualityService } from '../../../../data-quality/data-quality.service';
import { GlyphUtil } from '../../../../shared/util/GlyphUtil';
import { DataQualityResult } from '@datagalaxy/webclient/data-quality/data-access';
import { ViewTypeService } from '../../../../services/viewType.service';
import { EntityCardCellComponent } from '../../../../shared/entityCard/entity-card/entity-card-cell.component';
import PropertyName = ServerConstants.PropertyName;
import {
    AttributeValueTranslationService,
    TranslatedTextAttributeValueComponent,
} from '@datagalaxy/webclient/multilingual/feature';

const sortableServerSideTypes = [
    AttributeMetaType.Boolean,
    AttributeMetaType.Date,
    AttributeMetaType.DateTime,
    AttributeMetaType.Text,
    AttributeMetaType.FormattedText,
    AttributeMetaType.MultiLineText,
    AttributeMetaType.Number,
    AttributeMetaType.TimeSeriesLastEntry,
];

const sortableComputedAttributes = [PropertyName.Order];

@Injectable()
export class EntityGridColumnService {
    public static defaultColumnId = 'object';

    private serverSideSorting?: boolean;

    constructor(
        private attributeValueTranslationService: AttributeValueTranslationService,
        private attributeDataService: AttributeDataService,
        private serverTimeService: ServerTimeService,
        private translate: TranslateService,
        private userService: UserService,
        private viewTypeService: ViewTypeService,
    ) {}

    buildColumns(config: EntityGridConfig): TColDef<EntityItem>[] {
        const { defaultAttributeKeys, attributes } =
            config?.attributeConfig || {};

        this.serverSideSorting = config?.sorting?.isServerSide;

        const attributeColumns = (attributes || [])
            ?.sort(
                ArrayUtils.sortByIndexOf(
                    defaultAttributeKeys,
                    (attribute) => attribute.AttributeKey,
                ),
            )
            .map((attribute) => {
                const hidden = !defaultAttributeKeys?.includes(
                    attribute.AttributeKey,
                );
                return this.buildColumn(attribute, hidden);
            })
            .filter(
                (col) =>
                    !!col &&
                    col.id !== this.viewTypeService.getViewAttributeKey(),
            );

        return [
            this.buildDefaultColumn(config),
            ...attributeColumns,
            ...this.buildGroupColumns(config?.groupBy),
        ];
    }

    private buildColumn(
        attribute: AttributeMetaInfo,
        hidden: boolean,
    ): TColDef<EntityItem> | undefined {
        const column = this.getAttributeColumn(attribute);

        if (!column) {
            CoreUtil.warn(
                'not implemented? AttributeType, SourceAttributeType:',
                AttributeMetaType[attribute.AttributeType],
                AttributeMetaType[attribute.SourceAttributeType],
                attribute,
            );
            return;
        }

        column.hidden = hidden;

        return column;
    }

    private isAttributeSortable(attribute: AttributeMetaInfo): boolean {
        if (!this.serverSideSorting) {
            return true;
        }

        const isNonComputedAndSortable =
            !attribute.IsComputed &&
            sortableServerSideTypes.includes(attribute.AttributeType);
        const isSortable = sortableComputedAttributes.includes(
            attribute.AttributeKey,
        );

        return isNonComputedAndSortable || isSortable;
    }

    private getCommonColumnProps(attribute: AttributeMetaInfo) {
        const displayName = this.attributeDataService.getAttributeDisplayName(
            attribute,
            attribute.serverType,
        );

        return {
            headerLabel: displayName,
            sortable: this.isAttributeSortable(attribute),
            id: attribute.AttributePath,
            width: 200,
            glyph: {
                before: getAttributeGlyphClass(attribute),
                after: attribute.IsSystem ? 'glyph-logo-dg' : '',
            },
        };
    }

    private buildGroupColumns(
        groups?: EntityGridGroup[],
    ): TColDef<EntityItem>[] {
        return (
            groups?.map((group) => {
                return {
                    id: group.id,
                    hidden: true,
                    type: GridCellType.text,
                    rowGroup: true,
                    getValue: group.getValue,
                    getGroupingText: group.getGroupText,
                };
            }) || []
        );
    }

    private buildDefaultColumn(config?: EntityGridConfig): TColDef<EntityItem> {
        const {
            breadcrumbOpenPreview,
            noLabelNavLink,
            hideBreadcrumb,
            actions,
        } = config?.entity || {};
        const defaultColumn = config?.defaultColumn;

        return {
            id: EntityGridColumnService.defaultColumnId,
            headerLabel: this.translate.instant('UI.Search.primaryDisplayName'),
            customCellComponent:
                defaultColumn?.customCellComponent ??
                EntityCardCellComponent<EntityItem>,
            fixed: true,
            width: 200,
            sortable: true,
            getInputs:
                defaultColumn?.getInputs ??
                ((entity: EntityItem) => ({
                    hierarchicalData: entity.HddData,
                    entity,
                    actions,
                    breadcrumbOpenPreview,
                    hideBreadcrumb,
                    noLabelNavLink,
                    dragDrop: config?.entity?.dragDrop,
                    attributes: entity.Attributes,
                })),
        };
    }

    private getAttributeColumn(
        attribute: AttributeMetaInfo,
    ): TColDef<EntityItem> {
        switch (attribute.AttributeKey) {
            case 'ModelType':
            case 'Type':
                return this.getDataTypeColumn(attribute);
            case 'CreationUserId':
                return this.getCreationUserIdColumn(attribute);
            case 'EntityStatus':
                return this.getEntityStatusColumn(attribute);
            case 'QualityStatus':
                return this.getDataQualityColumn(attribute);
        }

        switch (attribute.AttributeType) {
            case AttributeMetaType.FormattedText:
                return this.getFormattedTextColumn(attribute);
            case AttributeMetaType.ObjectNameList:
                return this.getObjectNameColumn(attribute);
            case AttributeMetaType.DateTime:
            case AttributeMetaType.Date:
                return this.getDateColumn(attribute);
            case AttributeMetaType.Boolean:
                return this.getBooleanColumn(attribute);
            case AttributeMetaType.HtmlLink:
                return this.getHtmlLinkColumn(attribute);
            case AttributeMetaType.ValueList:
                return this.getValueListColumn(attribute);
            case AttributeMetaType.ObjectValueList:
                return this.getObjectValueListColumn(attribute);
            case AttributeMetaType.EntityLinkShortcut:
                return this.getEntityLinkShortcutColumn(attribute);
            case AttributeMetaType.Number:
                return this.getNumberColumn(attribute);
            case AttributeMetaType.EntityLogicalParent:
                return this.getEntityLogicalParentColumn(attribute);
            case AttributeMetaType.EntityLogicalPath:
                return this.getEntityLogicalPathColumn(attribute);
            case AttributeMetaType.TimeSeriesObject:
            case AttributeMetaType.TimeSeriesLastEntry:
            case AttributeMetaType.TimeSeriesLastEntries:
                return this.getTimeSeriesColumn(attribute);
            case AttributeMetaType.SystemEntityType:
                return this.getSystemEntityTypeColumn(attribute);
            case AttributeMetaType.Text:
            case AttributeMetaType.UserGuid:
            case AttributeMetaType.EntityReference:
            case AttributeMetaType.EntityLogicalPathString:
            case AttributeMetaType.Module:
                return this.getTextTypeColumn(attribute);
        }
    }

    private getDataQualityColumn(
        attribute: AttributeMetaInfo,
    ): TColDef<EntityItem> {
        return {
            ...this.getCommonColumnProps(attribute),
            customCellComponent: DxyIconCellComponent,
            getInputs: (entityItem: EntityItem) => {
                const value = entityItem
                    .getAttributeValue<DataQualityResult>(
                        attribute.AttributeKey,
                    )
                    ?.toLowerCase() as DataQualityResult;

                return {
                    text: this.translate.instant(
                        DataQualityService.getDataQualityResultTranslateKey(
                            value,
                        ),
                    ),
                    glyphClass: GlyphUtil.getDataQualityGlyphClass(value),
                    glyphTooltip: this.translate.instant(
                        DataQualityService.getDataQualityResultTranslateKey(
                            value,
                        ),
                    ),
                };
            },
        };
    }

    private getEntityStatusColumn(
        attribute: AttributeMetaInfo,
    ): TColDef<EntityItem> {
        return {
            ...this.getCommonColumnProps(attribute),
            customCellComponent: DgStatusCellComponent,
            getInputs: (entityItem: EntityItem) => ({
                status: entityItem.Status,
            }),
        };
    }

    private getCreationUserIdColumn(
        attribute: AttributeMetaInfo,
    ): TColDef<EntityItem> {
        return {
            ...this.getCommonColumnProps(attribute),
            customCellComponent: UserCellComponent,
            getInputs: (entity: EntityItem) => {
                return {
                    userId: entity.CreationUserId,
                };
            },
            getSortableValue: (entity: EntityItem) => {
                return this.userService.getUserName(entity.CreationUserId);
            },
        };
    }

    private getDataTypeColumn(
        attribute: AttributeMetaInfo,
    ): TColDef<EntityItem> {
        const getText = (entity: EntityItem) =>
            (entity &&
                this.translate.instant(
                    `DgServerTypes.${entity.DataTypeName}Type.${entity.SubTypeName}`,
                )) ??
            '';

        return {
            ...this.getCommonColumnProps(attribute),
            type: GridCellType.text,
            getValue: (entity: EntityItem) => {
                return getText(entity);
            },
            getSortableValue: (entity: EntityItem) => {
                return getText(entity);
            },
        };
    }

    private isTranslatedAttribute(attribute: AttributeMetaInfo): boolean {
        return this.attributeValueTranslationService.displayTranslatedValues(
            attribute.AttributePath,
        );
    }

    private getTranslatedTextColumn(
        attribute: AttributeMetaInfo,
    ): TColDef<EntityItem> {
        return {
            ...this.getCommonColumnProps(attribute),
            type: GridCellType.custom,
            customCellComponent: TranslatedTextAttributeValueComponent,
            getInputs: (entity: EntityItem) => {
                return {
                    entity,
                    attributePath: attribute.AttributePath,
                };
            },
        };
    }

    private getTextTypeColumn(
        attribute: AttributeMetaInfo,
    ): TColDef<EntityItem> {
        if (this.isTranslatedAttribute(attribute)) {
            return this.getTranslatedTextColumn(attribute);
        }

        return {
            ...this.getCommonColumnProps(attribute),
            type: GridCellType.text,
            getValue: (entityItem: EntityItem) => {
                return entityItem?.getAttributeValue<string>(
                    attribute.AttributeKey,
                );
            },
        };
    }

    private getSystemEntityTypeColumn(
        attribute: AttributeMetaInfo,
    ): TColDef<EntityItem> {
        return {
            ...this.getCommonColumnProps(attribute),
            type: GridCellType.text,
            getValue: (entityItem: EntityItem) => {
                return this.attributeDataService.getEntityTypeTranslation(
                    entityItem.entityType,
                );
            },
        };
    }

    private getTimeSeriesColumn(
        attribute: AttributeMetaInfo,
    ): TColDef<EntityItem> {
        return {
            ...this.getCommonColumnProps(attribute),
            customCellComponent: DgTimeSeriesCellComponent,
            getInputs: (entity: EntityItem) => {
                const value = entity?.getAttributeValue<string>(
                    attribute.AttributeKey,
                );

                const timeSeries: TimeSeriesObject = JSON.parse(value || '{}');

                return {
                    timeSeriesObject: timeSeries,
                    colorRule:
                        attribute.TimeSeriesColorRule ??
                        TimeSeriesColorRule.ShouldIncrease,
                };
            },
        };
    }

    private getEntityLogicalPathColumn(
        attribute: AttributeMetaInfo,
    ): TColDef<EntityItem> {
        return {
            ...this.getCommonColumnProps(attribute),
            customCellComponent: DgEntityPathCellComponent,
            getInputs: (entity: EntityItem) => {
                return {
                    dtContext: 'Entity Grid',
                    hddData: entity?.HddData,
                };
            },
        };
    }

    private getEntityLogicalParentColumn(
        attribute: AttributeMetaInfo,
    ): TColDef<EntityItem> {
        return {
            ...this.getCommonColumnProps(attribute),
            customCellComponent: DgEntityLinkShortcutCellComponent,
            getInputs: (entity: EntityItem) => {
                const parents = entity?.HddData?.Parents;
                if (!parents?.length) {
                    return;
                }
                const hdd = new HierarchicalData(parents[0], parents.slice(1));

                return {
                    hdd,
                };
            },
            getSortableValue: (entity: EntityItem) => {
                const parents = entity?.HddData?.Parents;
                if (!parents?.length) {
                    return;
                }
                const hdd = new HierarchicalData(parents[0], parents.slice(1));

                return hdd.DisplayName;
            },
        };
    }

    private getNumberColumn(attribute: AttributeMetaInfo): TColDef<EntityItem> {
        return {
            ...this.getCommonColumnProps(attribute),
            type: GridCellType.text,
            getValue: (entityItem: EntityItem) => {
                return entityItem
                    .getAttributeValue<number>(attribute.AttributeKey)
                    ?.toString();
            },
        };
    }

    private getEntityLinkShortcutColumn(
        attribute: AttributeMetaInfo,
    ): TColDef<EntityItem> {
        return {
            ...this.getCommonColumnProps(attribute),
            customCellComponent: DgEntityLinkShortcutCollectionCellComponent,
            getInputs: (entity: EntityItem) => {
                return {
                    hdds: DgEntityLinkShortcutCollectionCellComponent.getHdsFromLinkDataItems(
                        entity.getAttributeValue<LinkedDataItem[]>(
                            attribute.AttributeKey,
                        ),
                    ),
                };
            },
            getSortableValue: (entity: EntityItem) => {
                return DgEntityLinkShortcutCollectionCellComponent.getValueAsJoinedString(
                    entity.getAttributeValue<LinkedDataItem[]>(
                        attribute.AttributeKey,
                    ),
                );
            },
        };
    }

    private getObjectValueListColumn(
        attribute: AttributeMetaInfo,
    ): TColDef<EntityItem> {
        const getSortableValue = (entity: EntityItem) => {
            return entity
                .getAttributeValue<AttributeObjectValue[]>(
                    attribute.AttributeKey,
                )
                ?.map((v) => v.DisplayName)
                .join(', ');
        };

        if (
            AttributeDataService.isTagAttribute(attribute.SourceAttributeType)
        ) {
            return {
                ...this.getCommonColumnProps(attribute),
                customCellComponent: AttributeCollectionCellComponent,
                getInputs: (entity: EntityItem) => {
                    return {
                        entity,
                        attributeMeta: attribute,
                        maxLines: 2,
                        values: entity.getAttributeValue<
                            AttributeObjectValue[]
                        >(attribute.AttributeKey),
                    };
                },
                getSortableValue,
            };
        } else if (
            AttributeDataService.isUserOrPersonAttribute(
                attribute.SourceAttributeType,
            )
        ) {
            return {
                ...this.getCommonColumnProps(attribute),
                customCellComponent: AttributeCollectionCellComponent,
                getInputs: (entity: EntityItem) => {
                    return {
                        entity,
                        attributeMeta: attribute,
                        enablePopover: true,
                        values: entity.getAttributeValue<
                            AttributeObjectValue[]
                        >(attribute.AttributeKey),
                    };
                },
                getSortableValue,
            };
        } else {
            CoreUtil.warn(
                'not implemented? ObjectValueList:',
                AttributeMetaType[attribute.SourceAttributeType],
                attribute,
            );
        }
    }

    private getValueListColumn(
        attribute: AttributeMetaInfo,
    ): TColDef<EntityItem> {
        return {
            ...this.getCommonColumnProps(attribute),
            type: GridCellType.text,
            getValue: (entityItem: EntityItem) => {
                const value = entityItem?.getAttributeValue<string>(
                    attribute.AttributeKey,
                );
                return CollectionsHelper.getFromFirst(
                    attribute.ListValues,
                    (o) => o.Value === value,
                    (o) => o.translatedDisplayName,
                );
            },
        };
    }

    private getHtmlLinkColumn(
        attribute: AttributeMetaInfo,
    ): TColDef<EntityItem> {
        return {
            ...this.getCommonColumnProps(attribute),
            customCellComponent: DgAttributeHtmlLinkModelCellComponent,
            getInputs: (entityItem: EntityItem) => {
                const value = entityItem?.getAttributeValue<string>(
                    attribute.AttributeKey,
                );
                const data: IHyperlinkModel =
                    fromSerializedServerHyperlinkModel(value);
                const url = data?.url;
                return {
                    text: data?.name || url || '',
                    href: url,
                };
            },
        };
    }

    private getBooleanColumn(
        attribute: AttributeMetaInfo,
    ): TColDef<EntityItem> {
        return {
            ...this.getCommonColumnProps(attribute),
            customCellComponent: DxyBooleanCellComponent,
            getInputs: (entityItem: EntityItem) => ({
                checked: entityItem.getAttributeValue<boolean>(
                    attribute.AttributeKey,
                ),
            }),
        };
    }

    private getDateColumn(attribute: AttributeMetaInfo): TColDef<EntityItem> {
        return {
            ...this.getCommonColumnProps(attribute),
            customCellComponent: DxyDgDateTimeCellComponent,
            getInputs: (entityItem: EntityItem) => {
                const value = entityItem?.getAttributeValue<string>(
                    attribute.AttributeKey,
                );
                const date = this.serverTimeService
                    .getServerDateTimeAsMoment(value)
                    ?.toISOString();

                return {
                    noTime: attribute.AttributeType === AttributeMetaType.Date,
                    useDgFormat: true,
                    dgDate: date,
                };
            },
            getSortableValue: (entityItem: EntityItem) => {
                const value = entityItem?.getAttributeValue<string>(
                    attribute.AttributeKey,
                );
                const date =
                    this.serverTimeService.getServerDateTimeAsMoment(value);

                return date.unix();
            },
        };
    }

    private getObjectNameColumn(
        attribute: AttributeMetaInfo,
    ): TColDef<EntityItem> {
        return {
            ...this.getCommonColumnProps(attribute),
            customCellComponent: EntitySimpleLinkCellComponent,
            getInputs: (entityItem: EntityItem) => {
                const text = entityItem?.getAttributeValue<string>(
                    attribute.AttributeKey,
                );
                return {
                    propertyKey: attribute.AttributeKey,
                    text,
                };
            },
        };
    }

    private getFormattedTextColumn(
        attribute: AttributeMetaInfo,
    ): TColDef<EntityItem> {
        if (this.isTranslatedAttribute(attribute)) {
            return this.getTranslatedTextColumn(attribute);
        }
        return {
            ...this.getCommonColumnProps(attribute),
            type: GridCellType.text,
            getValue: (entityItem: EntityItem) => {
                const text = entityItem?.getAttributeValue<string>(
                    attribute.AttributeKey,
                );
                return RichTextContent.getRawText(text);
            },
        };
    }
}
