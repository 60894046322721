import * as moment from 'moment';
import { AttributeFilterModel } from '../attribute-filter/attributeFilterModel';
import { DateTimeUtil } from '@datagalaxy/core-util';
import { IFilterFormModel } from '../attribute-filter-form/IFilterFormModel';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { FilterOperator } from '@datagalaxy/filter-domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

export class AttributeDateFilterModel extends AttributeFilterModel {
    public static readonly defaultAvailableOperators = [
        FilterOperator.RangeContains,
        FilterOperator.DateTimePastHour,
        FilterOperator.DateTimeToday,
        FilterOperator.DateTimeBeforeToday,
        FilterOperator.DateTimeYesterday,
        FilterOperator.DateTimeCurrentWeek,
        FilterOperator.DateTimePastWeek,
        FilterOperator.DateTimeBeforeCurrentWeek,
        FilterOperator.DateTimeBeforePastWeek,
        FilterOperator.DateTimeCurrentMonth,
        FilterOperator.DateTimePastMonth,
        FilterOperator.DateTimeBeforeCurrentMonth,
        FilterOperator.DateTimeBeforePastMonth,
        FilterOperator.DateTimeCurrentYear,
        FilterOperator.FieldIsEmpty,
        FilterOperator.FieldHasValue,
    ];

    public static readonly quickFilterAvailableOperators = [
        FilterOperator.DateTimePastHour,
        FilterOperator.DateTimeToday,
        FilterOperator.DateTimeBeforeToday,
        FilterOperator.DateTimeYesterday,
        FilterOperator.DateTimeCurrentWeek,
        FilterOperator.DateTimePastWeek,
        FilterOperator.DateTimeBeforeCurrentWeek,
        FilterOperator.DateTimeBeforePastWeek,
        FilterOperator.DateTimeCurrentMonth,
        FilterOperator.DateTimePastMonth,
        FilterOperator.DateTimeBeforeCurrentMonth,
        FilterOperator.DateTimeBeforePastMonth,
        FilterOperator.DateTimeCurrentYear,
    ];

    public static availableOperators(isQuickFilter?: boolean) {
        return isQuickFilter
            ? AttributeDateFilterModel.quickFilterAvailableOperators
            : AttributeDateFilterModel.defaultAvailableOperators;
    }

    public static get language() {
        return moment.locale();
    }

    private _momentUserFormat = DateTimeUtil.getFormatForDisplay(
        false,
        moment.locale(),
        true,
    );
    private _values: moment.Moment[];

    public get values() {
        return this._values.map((m) => m && m.toISOString());
    }

    public get areSameValues() {
        return this.values.length == 2 && this.values[0] == this.values[1];
    }

    public get showValue1() {
        return this.operator == FilterOperator.RangeContains;
    }
    public get showValue2() {
        return this.operator == FilterOperator.RangeContains;
    }

    public get userValue1() {
        return this.getUserValue(0);
    }
    public set userValue1(value: string) {
        this.setUserValue(0, value);
    }
    public get userValue2() {
        return this.getUserValue(1);
    }
    public set userValue2(value: string) {
        this.setUserValue(1, value);
    }

    public get momentValue1() {
        return this._values[0];
    }
    public set momentValue1(m: moment.Moment) {
        this._values[0] = m;
    }
    public get momentValue2() {
        return this._values[1];
    }
    public set momentValue2(m: moment.Moment) {
        this._values[1] = m;
    }

    private getUserValue(valueIndex: number) {
        return DateTimeUtil.format(
            this._values[valueIndex],
            this._momentUserFormat,
        );
    }
    private setUserValue(valueIndex: number, value: string) {
        this._values[valueIndex] = DateTimeUtil.getUtcFromUser(
            value,
            this._momentUserFormat,
            true,
        );
    }
    private isValueValid(valueIndex: number) {
        return !!this._values[valueIndex]?.isValid();
    }

    constructor(
        filterFormData: IFilterFormModel,
        attributeMeta: AttributeMetaInfo,
        operators: FilterOperator[],
    ) {
        super(attributeMeta, operators, filterFormData);
        this._values = [];
    }

    setValuesFromDb(values: string[]) {
        if (this.operator !== FilterOperator.RangeContains) {
            return;
        }
        this._values =
            values?.map((v) => DateTimeUtil.getFromIso(v, true)) || [];
    }

    copy(filterItem: AttributeDateFilterModel) {
        this.operator = filterItem.operator;
        this.setValuesFromDb(filterItem.getValuesAsArray());
    }

    getValuesAsArray() {
        return this.values;
    }

    isValid() {
        return (
            this.operator != FilterOperator.RangeContains ||
            this.isValueValid(0) ||
            this.isValueValid(1)
        );
    }

    isNotEmpty() {
        return this.isValid();
    }

    protected onOperatorChangedInternal() {
        if (this.operator != FilterOperator.RangeContains) {
            this._values = [];
        }
    }

    protected sameTypeAndValue(other: AttributeFilterModel) {
        return (
            other instanceof AttributeDateFilterModel &&
            CollectionsHelper.contentEquals(
                this._values,
                other._values,
                true,
                true,
                (a, b) => (!a && !b) || a?.isSame(b),
            )
        );
    }
}
