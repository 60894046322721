import {
    ChangeDetectionStrategy,
    Component,
    contentChildren,
    input,
    output,
    TemplateRef,
    viewChild,
} from '@angular/core';
import { MenuListDirective } from '../menu-list/menu-list.directive';
import { MenuTriggerDirective } from '../menu-trigger-directive';
import { CommonModule } from '@angular/common';
import { MenuDisplayMode } from './menu-display-mode';
import { MenuOpener } from '../menu-opener';

@Component({
    selector: 'dxy-menu',
    standalone: true,
    imports: [MenuListDirective, CommonModule],
    templateUrl: './menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MenuOpener],
})
export class MenuComponent {
    public displayMode = input<MenuDisplayMode>('default');
    public close = output();
    public menuTemplate =
        viewChild.required<TemplateRef<unknown>>('menuTemplate');
    public childTriggers = contentChildren(MenuTriggerDirective, {
        descendants: true,
    });
}
